import { cn } from "services/UtilServices";

export default function AppLogo({
  mini,
  className,
  ...props
}: {
  mini?: boolean;
} & React.ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      alt="logo"
      {...props}
      src={mini ? "/mini-logo-white-bg.png" : "/images/logo.png"}
      className={cn("object-contain ", className)}
    />
  );
}
