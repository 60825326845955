import React from "react";

import { DialogContent, DialogFooter } from "components/ui/dialog";
// import { InputField, SubmitButton } from "components/ui/form-fields";
// import { Form, FormDefaultValues, FormOnSubmit } from "components/ui/form";
import { OfficeFormType, officeFormSchema } from "models/Office";
import {
  ColorPicker,
  Form,
  Input,
  SubmitButton,
} from "components/common/Forms/Form";
import Dict from "models/Dict";

export default function OfficeCreateUpdateModal({
  formData,
  onSubmit,
}: {
  formData?: Dict; //FormDefaultValues<OfficeFormType>;
  onSubmit: (d: Dict) => Promise<Dict>; //FormOnSubmit<OfficeFormType>;
}) {
  return (
    <DialogContent title="Offices">
      <Form
        onSubmit={onSubmit}
        // schema={officeFormSchema}
        data={
          //defaultValues
          formData !== undefined && "id" in formData
            ? {
                ...formData,
              }
            : {}
        }
      >
        <Input name="name" required maxLength={256} autoFocus />
        <Input name="manager" required maxLength={256} />
        <Input name="location" required maxLength={256} />
        {/* <InputField name="color" required /> */}
        <ColorPicker name="color" required />

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
