import User, { CurrentUser } from "../../models/User"

import LocalStorageServices from "./LocalStorageServices";

const CURRENT_USER_KEY = "current_user";


export default class CurrentUserLocalServices {

    static async isLoggedIn() {
        return await LocalStorageServices.get(CURRENT_USER_KEY) !== null;
    }

    static async getFromLocal() {
        var current_user = await LocalStorageServices.get(CURRENT_USER_KEY);

        // if(current_user) {
        //     current_user.id = JSON.parse(
        //         atob(current_user.token.split('.')[1])
        //     ).id;

        //     return current_user;
        // }

        return current_user;
    }

    static async setToLocal(currentUser: CurrentUser) {
        await LocalStorageServices.set(
            CURRENT_USER_KEY,
            currentUser
        );
    }

    static async clear() {
        await LocalStorageServices.clear();
    }

}