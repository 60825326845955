import React from "react";
import { Button } from "components/ui/button";
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  SheetFooter,
  SheetClose,
} from "components/ui/sheet";
import { FaBars } from "react-icons/fa6";
import { MobileSideMenu } from "../sideMenu/DesktopSideMenu";
import { cn } from "services/UtilServices";
import AppLogo from "components/pages/AppLogo";

export default function MobileHamburgerButton() {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(false);
  }, [window.location.pathname]);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant={"light"} className=" relative ">
          <FaBars />
        </Button>
      </SheetTrigger>
      <SheetContent side={"left"}>
        <SheetHeader>
          <SheetTitle>
            <AppLogo
              className={cn(
                "object-contain h-7 hover:brightness-100 hover:invert-0 "
              )}
            />
          </SheetTitle>
        </SheetHeader>
        <div className="py-4">
          <MobileSideMenu />
        </div>
      </SheetContent>
    </Sheet>
  );
}
