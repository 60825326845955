import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";

import useUserLogApi from "hooks/api/UseUserLogApi";
import UserLog from "models/UserLog";
import { useCurrentUserContext } from "./CurrentUserProvider";
import { useMainContext } from "./MainProvider";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

interface NotificationsContextProps {
  notifs: UserLog[] | undefined;
  totalCount: number;

  markLogsAsRead: (effortId?: number) => Promise<Dict>;
  get: () => Promise<Dict>;
}

const NotificationsContext = React.createContext(
  {} as NotificationsContextProps
);
NotificationsContext.displayName = "NotificationsContext";

function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const [_notifs, _setNotifs] = React.useState<UserLog[] | undefined | null>();

  const userLogApi = useUserLogApi();
  const _currentUserContext = useCurrentUserContext();
  const _mainContext = useMainContext();
  const _effortUtils = useEffortUtils();

  const get = async () => {
    let _errors = {};

    try {
      let res = await userLogApi.getNotifs();

      _setNotifs(res.items);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const markLogsAsRead = async (effortId?: number) => {
    let _errors = {};

    try {
      const res = await userLogApi.markAsRead(effortId);

      const targetEffort = _mainContext.efforts?.find(e => e.id === effortId);
      if (targetEffort?.notificationCount) {
        _currentUserContext.set({
          notificationCount: _currentUserContext.user!.notificationCount - targetEffort.notificationCount,
        });

        const subEffortsDeep = _effortUtils.getSubEffortsDeep({ id: effortId }).map(e => e.id);
        const parentEffortsDeep = _effortUtils.getParentEffortsDeep(effortId).map(e => e.id);

        _mainContext.setEfforts(
          _mainContext.efforts?.map(e =>
            parentEffortsDeep.includes(e.id) ?
              ({
                ...e,
                notificationCount: e.notificationCount - targetEffort.notificationCount,
              }) :
              subEffortsDeep.includes(e.id) ?
                ({
                  ...e,
                  notificationCount: 0,
                }) : e
          )
        );

      }
      else {
        _currentUserContext.set({
          notificationCount: 0,
        });
      }


      // _currentUserContext.set({
      //   notificationCount:
      //     _currentUserContext.user!.notificationCount - res.count,
      // });
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <NotificationsContext.Provider
      value={
        {
          notifs: _notifs,
          totalCount: _currentUserContext.user!.notificationCount,

          get,
          markLogsAsRead,
        } as NotificationsContextProps
      }
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export function useNotificationsContext() {
  const _context = React.useContext(NotificationsContext);

  if (!_context) {
    throw new Error("cannot use NotificationsContext outside of its provider.");
  }

  return _context;
}

export { NotificationsContext, NotificationsProvider };
