import Dict from "models/Dict";
import Effort from "models/Effort";
import React from "react";
import { cn, isEmpty } from "services/UtilServices";
import EffortSingleFieldUpdateModal, {
  EffortSingleFieldUpdateModalFieldNameType,
} from "./EffortSingleFieldUpdateModal";
import { useModalContext } from "providers/ModalProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import { LuPencil } from "react-icons/lu";
import { Button, buttonVariants } from "components/ui/button";
import { VariantProps } from "class-variance-authority";
import { useEffortAuthUtils } from "hooks/utils/UseEffortUtils";
import { useEffortResources } from "hooks/utils/UseEffortResources";

export function SingleFieldUpdatePen({
  effort,
  fieldName,
  className = "",
  variant = "faded",
  size,
}: {
  effort: Effort;
  fieldName: EffortSingleFieldUpdateModalFieldNameType;
  className?: string;
} & VariantProps<typeof buttonVariants>) {
  const singleFieldEdit = useSingleFieldUpdate();
  const _effortAuthUtils = useEffortAuthUtils();

  return (
    <>
      {_effortAuthUtils.canEdit([effort]) && (
        <Button
          asChild
          variant={variant}
          size={size}
          title={"Edit " + fieldName}
          onClick={(ev) =>
            singleFieldEdit.onClick({
              effort,
              fieldName,
              ev,
            })
          }
          className={cn(
            "aspect-square p-0 md:invisible group-hover:visible h-7 absolute left-0 top-0 ",
            className
          )}
        >
          <div>
            <LuPencil className="!text-base" />
          </div>
        </Button>
      )}
    </>
  );
}

export default function useSingleFieldUpdate() {
  const _effortsContext = useEffortsContext();
  const _modalContext = useModalContext();
  const _effortAuthUtils = useEffortAuthUtils();

  const onSubmit = async (data: Dict) => {
    let _errors = await _effortsContext.update(data);

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openSingleFieldUpdateModal = (
    fieldName: EffortSingleFieldUpdateModalFieldNameType,
    effort: Effort
  ) => {
    _modalContext.open(
      <EffortSingleFieldUpdateModal
        effort={effort}
        onSubmit={onSubmit}
        fieldName={fieldName}
      />
    );
  };

  const onClick = ({
    fieldName,
    effort,
    ev,
  }: {
    fieldName: EffortSingleFieldUpdateModalFieldNameType;
    effort: Effort;
    ev?: React.MouseEvent<HTMLElement, MouseEvent>;
  }) => {
    ev?.stopPropagation();

    if (_effortAuthUtils.canEdit([effort])) {
      openSingleFieldUpdateModal(fieldName, effort);
    }
  };

  return {
    onClick,
  };
}
