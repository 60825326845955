import { hasOtherKeysExcept } from "services/UtilServices";
import State, { stateSchema } from "./State";
import Tag, { tagSchema } from "./Tag";
import Type, { typeSchema } from "./Type";
import { z } from "zod";




export type DepartmentSortType = "name" | "dateadd";


export const DepartmentSortTypes: { [key: string]: DepartmentSortType } = {
  dateadd: "dateadd",
  name: "name",
};

export interface DepartmentFilter {
  "pageNumber": number,
  "sortBy": DepartmentSortType,
  "ascOrder": boolean,
  numberInPage?: number,

  name?: string,
  dateAddFrom?: Date,
  dateAddTo?: Date
}

export const departmentSchema = z.object({
  id: z.number(),
  name: z.string(),
  dateAdd: z.string(),

  tags: tagSchema.array(),
  types: typeSchema.array(),
  states: stateSchema.array(),
});

type Department = z.infer<typeof departmentSchema>;
export default Department;

// export default interface Department {
//   id: number,
//   name: string,
//   dateAdd: string,

//   tags: Tag[],
//   types: Type[],
//   states: State[],
// }
