import React from "react";

import MainSection from "components/layouts/MainSection";
import {
  EffortsListSectionBody,
  EffortsSectionProvider,
} from "components/pages/EffortsSection/EffortsSection";
import { EffortsProvider } from "providers/EffortsProvider";
import EffortSidePanel from "components/pages/EachEffort/EffortSidePanel";
import EffortHeaderSection from "components/pages/EachEffort/EffortHeaderSection";
import MainHeaderBody from "components/layouts/MainHeader/MainHeader";

function HomePage() {
  return (
    <EffortsProvider>
      <EffortsSectionProvider>
        <MainHeaderBody>
          <EffortHeaderSection />
        </MainHeaderBody>

        <div className="flex flex-1">
          <MainSection>
            <EffortsListSectionBody />
          </MainSection>

          <EffortSidePanel />
        </div>
      </EffortsSectionProvider>
    </EffortsProvider>
  );
}

export default HomePage;
