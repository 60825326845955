import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import Dict from "models/Dict";
import Login from "models/views/Login";

import { useCurrentUserContext } from "providers/CurrentUserProvider";
import CurrentUserServices from "services/localServices/CurrentUserLocalServices";

import { Form, Input, SubmitButton } from "components/common/Forms/Form";
import { isEmpty } from "services/UtilServices";
import { useToast } from "components/ui/use-toast";
import Link from "components/common/Link";
import LoginLayout from "layouts/LoginLayout";
import Lottie from "react-lottie-player";
import LoginSection from "components/pages/login/LoginSection";

function LoginPage() {
  const currentUserContext = useCurrentUserContext();
  const navigate = useNavigate();
  const { toast } = useToast();

  React.useEffect(() => {
    CurrentUserServices.isLoggedIn().then((result) => {
      if (result) {
        navigate("/", { replace: true });
      }
    });
  }, []);

  const onSubmit = async (credential: Dict) => {
    const _errors = await currentUserContext.login(credential as Login);

    if (isEmpty(_errors)) {
      navigate("/", { replace: true });
    }
    // else {
    // toast({
    //   variant: "destructive",
    //   description: 'Username or password is wrong!'
    // });
    // }

    return _errors;
  };

  return (
    <LoginLayout>
      <div className="bg-muted rounded-md flex lg:w-3/4 max-w-6xl xl:aspect-golden-h border border-foreground/40 ">
        <div className="flex-1 p-8 hidden lg:flex items-center justify-center border-e">
          <Lottie
            play
            loop
            path="/lottie/login-2.json"
            className="w-full h-full object-contain"
            style={{
              maxHeight: 400,
            }}
          />
        </div>
        <LoginSection>
          <div className="rounded-md my-4 relative ">
            <div className="card-body p-4">
              <h4 className="mb-3 text-center md:mb-4 mt-3">Login</h4>

              <div className="row m-0 max-w-[350px]">
                <div className="col-12 pt-3 px-0 md:px-3 ">
                  <div>
                    <p className="text-sm mb-3 ">
                      Please enter your credentials to login.
                    </p>
                  </div>

                  <Form onSubmit={onSubmit}>
                    {/* <Input name="userName" label="username" required /> */}
                    <Input name="email" label="email" required />

                    <Input
                      name="password"
                      label="password"
                      type={"password"}
                      required
                      showPasswordBtn
                      // actions={[
                      //   {
                      //     text: "Forgot Password",
                      //     onClick: () => navigate("/forgotpassword")
                      //   }
                      // ]}
                    />

                    <SubmitButton className="mx-auto block w-1/2">
                      Login
                    </SubmitButton>

                    <Link
                      size={"sm"}
                      variant={"faded"}
                      to={"/forgotpassword"}
                      className={"w-full mt-3 "}
                      style={{ marginRight: -10 }}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   navigate("/forgotpassword");
                      // }}
                    >
                      Forgot Password
                    </Link>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </LoginSection>
      </div>
    </LoginLayout>
  );
}

export default LoginPage;
