import React from "react";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";

import { Route, Routes } from "react-router-dom";

import { StorageProvider } from "services/localServices/LocalStorageServices";

import { ThemeProvider } from "providers/ThemeProvider";
import { AccessProvider } from "providers/AccessProvider";
import { ModalProvider } from "./providers/ModalProvider";
import { SideMenuProvider } from "./providers/SideMenuProvider";
import { useMainContext, MainProvider } from "providers/MainProvider";
import {
  useCurrentUserContext,
  CurrentUserProvider,
} from "providers/CurrentUserProvider";

import HomePage from "./pages/HomePage";
import Error404Page from "./pages/Error404Page";
import Error403Page from "./pages/Error403Page";
import Error500Page from "./pages/Error500Page";
import AboutUsPage from "pages/AboutUsPage";
import ProfilePage from "pages/ProfilePage";
import AdminPage, { useAdminPageSection } from "pages/AdminPage";
import LoginPage from "pages/LoginPage";
import SplashPage from "pages/SplashPage";
import DepricatedPage from "pages/DepricatedPage";
import StatsPage from "pages/StatsPage";
import EachEffortPage from "pages/EachEffortPage";
import { GalleryViewProvider } from "providers/GalleryViewProvider";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import { Toaster } from "components/ui/toaster";
import { IconContext } from "react-icons/lib";
import { cn } from "services/UtilServices";
import MainLayout from "layouts/MainLayout";
import FeedPage from "pages/FeedPage";
import ReportPage, { useReportPageSection } from "pages/ReportPage";
import ActivitiesPage from "pages/ActivitiesPage";
import { Dialog } from "components/ui/dialog";
import { NotificationsProvider } from "providers/NotificationsProvider";

function AppRoutes() {
  const _mainProvider = useMainContext();
  const _adminPageSections = useAdminPageSection();
  const _reportsPageSections = useReportPageSection();

  return (
    <AccessProvider>
      <NotificationsProvider>
        <SideMenuProvider>
          <ModalProvider>
            <GalleryViewProvider>
              {_mainProvider.efforts === undefined ? (
                <SplashPage />
              ) : (
                <MainLayout>
                  <Routes>
                    <Route path="/" element={<HomePage />} />

                    <Route path="/about/" element={<AboutUsPage />} />
                    <Route path="/stats/" element={<StatsPage />} />
                    <Route path="/feed/" element={<FeedPage />} />
                    <Route path="/activities/" element={<ActivitiesPage />} />

                    <Route path="/profile/:index" element={<ProfilePage />} />
                    <Route path="/profile/" element={<ProfilePage />} />

                    {/* <Route path="/admin/:index" element={<AdminPage />} /> */}
                    <Route path="/admin/" element={<AdminPage />}>
                      {_adminPageSections.map((eachSection) => (
                        <Route
                          key={"eachAdminSection" + eachSection.url}
                          path={eachSection.url}
                          element={eachSection.content}
                        />
                      ))}
                    </Route>

                    <Route path="/reports/" element={<ReportPage />}>
                      {_reportsPageSections.map((eachSection) => (
                        <Route
                          key={"eachReportSection" + eachSection.url}
                          path={eachSection.url}
                          element={eachSection.content}
                        />
                      ))}
                    </Route>

                    <Route
                      path="/effort/:proj/:task/:subtask/:index"
                      element={<EachEffortPage />}
                    />
                    <Route
                      path="/effort/:proj/:task/:subtask/"
                      element={<EachEffortPage />}
                    />
                    <Route
                      path="/effort/:proj/:task/"
                      element={<EachEffortPage />}
                    />
                    <Route path="/effort/:proj/" element={<EachEffortPage />} />

                    <Route path="/500" element={<Error500Page />} />
                    <Route path="/403" element={<Error403Page />} />

                    <Route path="*" element={<Error404Page />} />
                  </Routes>
                </MainLayout>
              )}
            </GalleryViewProvider>
          </ModalProvider>
        </SideMenuProvider>
      </NotificationsProvider>
    </AccessProvider>
  );
}

function AppBody() {
  const _currentUserProvider = useCurrentUserContext();

  return (
    <div className={cn("w-screen h-screen ")}>
      {_currentUserProvider.user === undefined ? (
        <SplashPage />
      ) : _currentUserProvider.user === null ? (
        <Routes>
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      ) : (
        <MainProvider>
          <AppRoutes />
        </MainProvider>
      )}
    </div>
  );
}

export default function App() {
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      isVerboseMode={true}
      metaFileDirectory={"."}
      isEnabled={isProduction}
      // loadingComponent={<Loading />}
      currentVersion={packageInfo.version}
    >
      <StorageProvider>
        <IconContext.Provider
          value={{ className: "inline group-[]/button:text-xl" }}
        >
          <Routes>
            <Route path="/depricated" element={<DepricatedPage />} />

            <Route
              path="*"
              element={
                <ModalProvider>
                  <CurrentUserProvider>
                    <ThemeProvider>
                      <AppBody />
                    </ThemeProvider>
                  </CurrentUserProvider>
                </ModalProvider>
              }
            />
          </Routes>

          <Toaster />
        </IconContext.Provider>
      </StorageProvider>
    </CacheBuster>
  );
}
