import { Badge } from "components/ui/badge";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { cn, listFirst } from "services/UtilServices";
import Link, { LinkProps } from "./Link";
import { Error404PageBody } from "pages/Error404Page";
import { buttonVariants } from "components/ui/button";

export type TabItemType = {
  text: React.ReactNode;
  icon?: React.ReactNode;
  count?: number;
} & TabContentType &
  Omit<LinkProps, "content">;

export type TabContentType = {
  url: string;
  exact?: boolean;
  content?: React.ReactNode;
};

export function TabContents({
  activeUrl,
  items,
}: {
  activeUrl?: string;
  items?: TabContentType[];
}) {
  if (!activeUrl) {
    activeUrl = listFirst(items)?.url;
  }

  return (
    <>
      {items?.find((e) => e.url === activeUrl)?.content ?? <Error404PageBody />}
    </>
  );
}

export function EachTabItem({
  url,
  text,
  icon,
  count = 0,
  content,
  variant = "light",
  className,
  needText = true,
  ...props
}: TabItemType & {
  needText?: boolean;
}) {
  return (
    <Link
      to={url}
      variant={variant}
      {...props}
      activeVariant="light"
      activeClassName="rounded-b-none border-foreground box-border"
      className={cn(" border-b-4 border-transparent p-3 pb-2", className)}
    >
      {icon} {needText && text} {count > 0 && <Badge>{count}</Badge>}
    </Link>
  );
}

export function TabItems({
  className = "",
  baseUrl = "/",
  activeClassName,
  items,
  needText = true,
}: {
  baseUrl?: string;
  className?: string;
  activeClassName?: string;
  items?: TabItemType[];
  needText?: boolean;
}) {
  return (
    <div
      className={cn(
        "flex items-center border-b sticky top-0 bg-muted z-10 p-2 overflow-x-auto scrollbar-hide ",
        className
      )}
    >
      {items?.map((e) => (
        <EachTabItem
          key={"eachItem" + e.url}
          {...e}
          needText={needText}
          // activeClassName={
          //   activeClassName ?? buttonVariants({ variant: "primary" })
          // }
          url={baseUrl + (baseUrl.endsWith("/") ? "" : "/") + e.url}
        />
      ))}
    </div>
  );
}
