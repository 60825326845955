import React from "react";

import Dict from "../../../models/Dict";

import ItemsListProvider, {
  useItemsListContext,
  ItemsListProviderInputProps,
} from "./ItemsListProvider";

import ItemListSearchbarSection from "./ItemsListSearchbarSection";
import useMediaQuery, { MediaQuery2Num } from "../useMediaQuery";
import { RESPONSIVE_KEYS } from "models/views/responsive";
import MainHeaderBody from "components/layouts/MainHeader/MainHeader";
import MainSection from "components/layouts/MainSection";
import { ItemsListSectionBody } from "./ItemsListSection";

function ItemsListMainSectionBody({
  tableBreakPointSize = "md",
}: {
  tableBreakPointSize?: RESPONSIVE_KEYS;
}) {
  const _itemsListContext = useItemsListContext();
  const { onSearch } = _itemsListContext;

  let size = useMediaQuery();

  return (
    <>
      {size > MediaQuery2Num.md ? (
        <MainHeaderBody>
          <ItemListSearchbarSection
            isSearchable={onSearch !== null}
            tableModeBreakPoint={tableBreakPointSize}
            {..._itemsListContext}
          />
        </MainHeaderBody>
      ) : (
        <ItemListSearchbarSection
          isSearchable={onSearch !== null}
          tableModeBreakPoint={tableBreakPointSize}
          {..._itemsListContext}
        />
      )}

      <MainSection>
        <ItemsListSectionBody
          needSearch={false}
          tableBreakPointSize={tableBreakPointSize}
        />
      </MainSection>
    </>
  );
}

interface PropTypes<T extends Dict>
  extends React.PropsWithChildren<ItemsListProviderInputProps<T>> {
  tableBreakPointSize?: RESPONSIVE_KEYS;
}

function ItemsListMainSection<T extends Dict>(props: PropTypes<T>) {
  // let size = useMediaQuery();

  return (
    <ItemsListProvider<T> {...props}>
      <ItemsListMainSectionBody
        tableBreakPointSize={props.tableBreakPointSize}
      />

      {/* {size > MediaQuery2Num.md ? (
        <ItemsListMainSectionBody
          tableBreakPointSize={props.tableBreakPointSize}
        />
      ) : (
        <ItemsListSectionBody tableBreakPointSize={props.tableBreakPointSize} />
      )} */}
    </ItemsListProvider>
  );
}

export default ItemsListMainSection;
