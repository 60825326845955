import React from "react";

import { isEmpty } from "services/UtilServices";

import { useModalContext } from "providers/ModalProvider";
import { useActivitysContext } from "providers/ActivitysProvider";
import { Form, SubmitButton } from "components/common/Forms/Form";
import { DialogContent } from "components/ui/dialog";
import LoadingButton from "components/common/LoadingButton";
import { useToast } from "components/ui/use-toast";
import Activity, { ActivityFilter } from "models/Activity";
import { AxiosError } from "axios";
import useExportApi from "hooks/api/UseExportApi";
import useDownloadServices from "hooks/UseDownloadServices";

export default function ExportModal({
  selectedItems,
  filter,
  effortId
}: {
  selectedItems?: Activity[];
  filter: ActivityFilter,
  effortId?: number
}) {
  const exportApi = useExportApi();
  const _modalContext = useModalContext();
  const downloadServices = useDownloadServices();
  const { toast } = useToast();

  const onExport = async (type: "xlsx" | "pdf") => {
    let _errors = {};

    try {
      let response = await exportApi.getActivityReport(type, {
        ...filter,

        numberInPage: 999,
        activityIds: selectedItems?.map((e) => e.id),
        effortIdList: effortId
          ? [effortId]
          : undefined,
      });

      downloadServices.downloadByBlob({
        blob: response.blob,
        filename: response.filename,
      });

      toast("Exported.");
      setTimeout(() => _modalContext.dismiss(), 2000);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;

    // let _errors = await _activitysContext.exportReport(type, selectedItems);

    // if (isEmpty(_errors)) {
    //   toast("Exported.");
    //   setTimeout(() => _modalContext.dismiss(), 2000);
    // }

    // return _errors;
  };

  return (
    <DialogContent
      title={"Export"}
      size={"sm"}
      fullScreen={false}
      onBgClickClosable
    >
      <span className="mb-3 block text-foreground">
        You may now choose the export format that you want to use.
      </span>

      <LoadingButton
        variant={"primary"}
        onClick={async () => await onExport("xlsx")}
        className=" w-full p-3 mb-2"
      >
        <img
          src={"/images/excel-logo.png"}
          width="30px"
          alt="excel-icon"
          className="inline"
        />
        <span>Excel</span>
      </LoadingButton>

      {/* <Form allowInitSubmit
        onSubmit={() => onExport("pdf")}
      >
        <SubmitButton 
            variant={"light"}
          className=" border w-full p-3 bg-transparent"
        >
            <img
                src={"/images/pdf-logo.png"}
                width="30px"
                alt="pdf-icon"
                className="me-2"
            />
            <span>PDF</span>
        </SubmitButton>
      </Form> */}
    </DialogContent>
  );
}
