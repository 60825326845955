import React from "react";

import Effort from "models/Effort";
import {
  useEffortAuthUtils,
  useEffortUtils,
  useEffortViewUtils,
} from "hooks/utils/UseEffortUtils";

import TagsSection from "./TagsSection";
import TypesSection from "./TypesSection";
import StatesSection from "./StatesSection";
import PrefixsSection from "./PrefixsSection";
import TicketsAvailableSection from "./TicketsAvailableSection";
import Skeleton from "components/common/Skeleton";
import PrioritiesSection from "./PrioritiesSection";
import MembersSection from "../MembersSection/MembersSection";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import { CollapsableTextEditorValue } from "components/common/Forms/TextEditor";
import AttachmentsSection from "../AttachmentsSection/AttachmentsSection";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import useSingleFieldUpdate, {
  SingleFieldUpdatePen,
} from "components/pages/EffortsSection/SingleFieldUpdateButton";
import LocalDateTime from "components/common/LocalDateTime";
import TicketsCountSection from "./TicketsCountSection";
import EffortMenuButton from "../EffortMenuButton";
import { LuListTree, LuPencil, LuPlus, LuX } from "react-icons/lu";
import { Button } from "components/ui/button";
import Directionality from "components/common/Directionality";
import Link from "components/common/Link";
import EachCardItem from "components/pages/EffortsSection/EffortsListTrelloSection/EachCardItem";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { cn } from "services/UtilServices";

export default function TaskSection({ effort }: { effort: Effort }) {
  const _effortUtils = useEffortUtils();
  const _effortAuthUtils = useEffortAuthUtils();
  const _effortViewUtils = useEffortViewUtils(effort.id);

  const _project = _effortUtils.getParentProject(effort?.id);
  const _parentEffort = _effortUtils.getEffortById(effort.parentId);
  const _subTasks = _effortUtils.getSubEfforts(effort.id);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center">
        <Link
          variant={"light"}
          activeVariant={"light"}
          className="rounded-full "
          to={_effortUtils.makeUrl(_project?.id)}
        >
          <LuX />
        </Link>
      </div>

      {effort.level >= 2 && (
        <div className="flex items-center flex-wrap text-sm p-3 bg-muted -mx-2 mb-2 gap-1">
          Subtask of
          <LuListTree className="text-lg -mt-1" />
          <Link
            to={_effortUtils.makeUrl(_parentEffort?.id)}
            className="justify-start p-0"
          >
            <Directionality className="capitalize break-words">
              {_parentEffort?.title}
            </Directionality>
          </Link>
        </div>
      )}

      <div className="flex items-center my-1">
        <EffortMenuButton effort={effort} />

        <Directionality className="capitalize text-lg font-bold break-words">
          {effort.title}
        </Directionality>
      </div>

      <DescriptionSection effort={effort} />

      <Tabs defaultValue={effort.level === 1 ? "sub" : "settings"}>
        <div className="-mx-3">
          <TabsList className="mb-2 ">
            {effort.level === 1 && (
              <TabsTrigger value={"sub"}>Sub Tasks</TabsTrigger>
            )}
            <TabsTrigger value={"settings"}>Settings</TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value={"settings"} className="flex flex-col gap-2">
          <SettingsSection effort={effort} />
        </TabsContent>

        {effort.level === 1 && (
          <TabsContent value={"sub"}>
            {_effortAuthUtils.canCreate(effort.id) && (
              <div className="flex items-center mb-2">
                {/* <h6 className="mb-0 capitalize">Sub Tasks</h6> */}

                <Button
                  variant={"light"}
                  onClick={() => _effortViewUtils.openCreateUpdateModal()}
                >
                  <LuPlus />
                  New Sub Task
                </Button>

                {/* <NewButtonMini
                  onClick={() => _effortViewUtils.openCreateUpdateModal()}
                /> */}
              </div>
            )}

            <div className="flex flex-col gap-2">
              {_subTasks?.map((eachSubTask, index) => (
                <EachCardItem
                  mini
                  effortId={eachSubTask.id}
                  key={"eachSubTask" + eachSubTask.id}
                  className="hover:border-foreground/50 bg-secondary"
                />
              ))}
            </div>
          </TabsContent>
        )}
      </Tabs>

      <MobileFooterPadding />
    </div>
  );
}

function SettingsSection({ effort }: { effort: Effort }) {
  const _effortUtils = useEffortUtils();

  return (
    <>
      <span className="text-xs">Created by:</span>
      <div className="flex items-center text-xs w-full text-foreground gap-2">
        {effort.creator ? (
          <span>
            <ProfileAvatar user={effort.creator} needName />
          </span>
        ) : (
          <Skeleton />
        )}
        <div className="flex-1"></div>
        <LocalDateTime date={effort.dateAdd} className="text-xs" />
      </div>

      <TicketsCountSection effort={effort} />
      <MembersSection effort={effort} />
      <AttachmentsSection effort={effort} />

      {_effortUtils.isProject(effort) && (
        <>
          <StatesSection effort={effort} />
          <PrefixsSection effort={effort} />
          <PrioritiesSection effort={effort} />
          <TicketsAvailableSection effort={effort} />
        </>
      )}

      <TypesSection effort={effort} />
      <TagsSection effort={effort} />
    </>
  );
}

function DescriptionSection({ effort }: { effort: Effort }) {
  const singleFieldUpdate = useSingleFieldUpdate();

  return (
    <div className={cn(" p-0 relative ")}>
      {effort.description?.content || effort.description?.attachments ? (
        <div className={cn("bg-card p-2 rounded border relative group ", {})}>
          <CollapsableTextEditorValue value={effort.description} />

          <SingleFieldUpdatePen
            effort={effort}
            fieldName="description"
            variant={"faded"}
            className="absolute right-0 top-0 m-3 bg-muted aspect-square rounded-full border"
          />
        </div>
      ) : (
        <Button
          variant={"faded"}
          onClick={() =>
            singleFieldUpdate.onClick({
              effort,
              fieldName: "description",
            })
          }
          className="text-sm p-4 border bg-muted w-full"
        >
          Add Description <LuPencil className="!text-base" />
        </Button>
      )}
    </div>
  );
}
