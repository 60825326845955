"use client";

import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import { cn } from "services/UtilServices";
import { Button } from "./button";
import { LuX } from "react-icons/lu";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverClose = PopoverPrimitive.Close;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  Omit<
    React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>,
    "title"
  > & {
    title?: React.ReactNode;
  }
>(
  (
    { className, align = "center", title, sideOffset = 4, children, ...props },
    ref
  ) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          "z-50 min-w-[200px] rounded-md border bg-popover p-4 text-popover-foreground",
          "shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out",
          "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
          "data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
          "data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2",
          "data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          className
        )}
        {...props}
      >
        {title && (
          <div className="flex items-center">
            {typeof title === "string" ? <b>{title}</b> : title}

            <PopoverClose asChild onClick={(e) => e.stopPropagation()}>
              <Button variant="faded" className="ms-auto">
                <LuX />
              </Button>
            </PopoverClose>
          </div>
        )}
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  )
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverClose };
