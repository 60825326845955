import React from "react";

import Prefix, { PrefixFilter, PrefixSortType } from "models/Prefix";
import Dict from "models/Dict";

import { dateGetTime, isEmpty } from "services/UtilServices";

import { usePrefixsContext, PrefixsProvider } from "providers/PrefixsProvider";
import { useModalContext } from "providers/ModalProvider";

import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import PrefixCreateUpdateModal from "./PrefixCreateUpdateModal";
import PrefixFilterModal from "./PrefixFilterModal";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import NewButton from "components/common/AdminSection/NewButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import LocalDateTime from "components/common/LocalDateTime";
import usePrefixUtils from "hooks/utils/UsePrefixUtils";
import ItemsListMainSection from "components/common/ItemsListSection/ItemsListMainSection";

function EachPrefixItem({ prefix, index }: { prefix: Prefix; index: number }) {
  return (
    <div className="flex items-center ">
      <div>
        <h6 className="mb-0 "> {index + 1} </h6>
      </div>

      <div>
        <h6 className="mb-0 me-2 ms-4">{prefix.name}</h6>
      </div>
    </div>
  );
}

function PrefixsPageBody() {
  const _modalContext = useModalContext();
  const _prefixsContext = usePrefixsContext();
  const prefixUtils = usePrefixUtils();

  const onRemove = async (datas: Prefix[]) => {
    _prefixsContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _prefixsContext.update(data)
        : await _prefixsContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <PrefixCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    _prefixsContext.get({
      ...formData,
      pageNumber: -1,
    } as PrefixFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <PrefixFilterModal
        filter={_prefixsContext.filter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  return (
    <ItemsListMainSection<Prefix>
      title="Prefixes"
      data={_prefixsContext.prefixs}
      // ellipsisResponsiveBreakPoint=""

      fields={[
        // {
        //   value: "",
        //   label: "#",
        //   className: "text-start whitespace-nowrap",
        //   width: "70px",
        //   isSortable: false,
        //   getUiContent: (e, i) => i + 1,
        // },

        {
          value: "name",
          label: "name",
          isTitleField: true,
          width: "auto",
          // isSortable: false,
          className: "whitespace-nowrap",
        },

        {
          value: "dateAdd",
          label: "Created on",
          width: "auto",
          // isSortable: false,
          getValue: (e) => dateGetTime(e.dateAdd),
          getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
          className: "whitespace-nowrap",
        },
      ]}
      onLoadMore={
        !_prefixsContext.hasNext
          ? undefined
          : async () => {
              await _prefixsContext.get({
                ..._prefixsContext.filter,
                pageNumber: _prefixsContext.filter.pageNumber + 1,
              });
            }
      }
      onSortField={(v) => {
        _prefixsContext.get({
          ..._prefixsContext.filter,
          sortBy: v as PrefixSortType,
          pageNumber: -1,
        });
      }}
      onSortDir={(v) => {
        _prefixsContext.get({
          ..._prefixsContext.filter,
          ascOrder: v === 1,
          pageNumber: -1,
        });
      }}
      initialSelectedSortFieldValue="dateAdd"
      buildCardTitleRow={(v, i) => <EachPrefixItem prefix={v} index={i} />}
      onItemEdit={(u) => openCreateUpdateModal}
      onItemClick={(u) => openCreateUpdateModal}
      onItemDelete={() => _prefixsContext.removeMany}
      actionsSection={
        <>
          <FilterButton
            count={_prefixsContext.totalNumber}
            isActive={prefixUtils.isFilterActive({
              filter: _prefixsContext.filter,
            })}
            onClick={openFilterModal}
          />

          <NewButton onClick={() => openCreateUpdateModal()} />
        </>
      }
    />
  );
}

export default function PrefixsPage() {
  return (
    <PrefixsProvider>
      <PrefixsPageBody />
    </PrefixsProvider>
  );
}
