import Activity from "models/Activity";
import Effort from "models/Effort";
import TicketCount from "models/TicketCount";
import { listFlatten, listSum, groupBy, listFirst } from "services/UtilServices";

export default function useTicketCountUtils() {
  const sortByTicketName = (ticketCounts: TicketCount[] | undefined) => {
    return ticketCounts?.sort((a, b) => a.ticket.name.localeCompare(b.ticket.name));
  }

  const sumEachTicket = <T = Effort | Activity>(l?: T[] | null, fieldName?: keyof T) => {
    if (!fieldName) fieldName = "ticketsCount" as keyof T;

    return l === undefined || l === null ? l :
      add(
        ...listFlatten(
          l.map((e) => (e[fieldName!] ?? []) as TicketCount[])
        ) ?? []
      );
  }

  const sumTotal = (l?: TicketCount[] | null, fieldName: "count" | "countApproved" = "count") => {
    return listSum(l, fieldName) ?? 0;
  }

  const add = (...l: TicketCount[]) => {
    return Object.entries(
      groupBy({
        list: l,
        callbackFunc: (e) => e.ticketId
      })!
    ).map(([k, v]) => ({
      ...listFirst(v),
      count: listSum(v, (e) => e.count),
      countApproved: listSum(v, (e) => e.countApproved),
    }) as TicketCount);
  }


  const subtract = (l1?: TicketCount[] | null, ...l2: TicketCount[]) => {
    l2 = add(...l2);

    return l1?.map(e => {
      const _l = l2.find(a => a.ticketId === e.ticketId);

      return ({
        ...e,
        ..._l ? {
          count: e.count - _l.count,
          countApproved: e.countApproved - _l.countApproved,
        } : {}
      })
    });
  }

  return {
    sortByTicketName,
    sumEachTicket,
    sumTotal,
    add,
    subtract,
  }
}
