import React from "react";

import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { cn, isFarsi } from "services/UtilServices";

import Skeleton from "components/common/Skeleton";
import Breadcrumb from "components/common/Breadcrumb";
import Cheeps from "components/common/Cheeps";
import { ViewTypeRow } from "../EffortsSection/ViewTypeSelectButton";
import EffortMenuButton from "./EffortMenuButton";
import { useSidePanelSections } from "./EffortSidePanel";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import { TabItems } from "components/common/Tabs";
import EffortBanner from "../EffortsSection/EffortBanner";
import { FaEllipsisVertical } from "react-icons/fa6";
import Directionality from "components/common/Directionality";
import {
  ItemsListSearchbarActionsSeciton,
  ItemsListSearchbarSearchSection,
} from "components/common/ItemsListSection/ItemsListSearchbarSection";
import { useItemsListContext } from "components/common/ItemsListSection/ItemsListProvider";
import GroupBySelectSection from "../EffortsSection/GroupBySelectSection";
import CollapseText from "components/common/CollapseText";

export default function EffortHeaderSection({ effort }: { effort?: Effort }) {
  const _itemsListContext = useItemsListContext();

  return (
    <div className="items-center flex  gap-2">
      {effort ? (
        <>
          <EffortMenuButton
            effort={effort}
            ellipsisClassName="opacity-100 p-0 group min-w-0 "
          >
            <FaEllipsisVertical className="group-hover:opacity-100 opacity-50" />

            <EffortBanner
              effort={effort}
              className="object-cover aspect-square border rounded h-10 w-10"
            />

            <Directionality
              className={cn(
                "mb-0 text-start text-lg font-bold truncate inline-block capitalize flex-1 "
              )}
            >
              {effort.title}
              {/* <CollapseText text={effort.title} /> */}
            </Directionality>
          </EffortMenuButton>

          {effort.isActive ? (
            effort.state === undefined ? (
              <Skeleton />
            ) : (
              <Cheeps color={effort.state?.color}>{effort.state!.name}</Cheeps>
            )
          ) : (
            <Cheeps color="#00000055">Not Active</Cheeps>
          )}
        </>
      ) : (
        <h5 className="mb-0">Projects</h5>
      )}

      <ItemsListSearchbarSearchSection />

      <div className="flex-1"></div>

      <ViewTypeRow />

      <div className="flex-1"></div>

      <GroupBySelectSection />
      <ItemsListSearchbarActionsSeciton {..._itemsListContext} />
    </div>
  );
}
