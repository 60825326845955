"use client";

import * as React from "react";

import { Badge } from "components/ui/badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "components/ui/command";
import { Command as CommandPrimitive } from "cmdk";
import {
  LuCheck,
  LuChevronDown,
  LuPencil,
  LuTrash,
  LuTrash2,
  LuX,
} from "react-icons/lu";
import {
  cn,
  isEmpty,
  listFirst,
  listUnique,
  sortedStringify,
} from "services/UtilServices";
import { Button } from "./button";
import { Input } from "./input";
import Cheeps from "components/common/Cheeps";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { PopoverAnchor } from "@radix-ui/react-popover";

export type OptionValueType = string | number | boolean | null | undefined;
export interface OptionType {
  name?: string;
  value: OptionValueType;
  label: React.ReactNode;
  description?: React.ReactNode;
  onEdit?: () => void;
  onDelete?: () => void;
}

function EachSelectedItem({
  option,
  onRemove,
}: {
  option: OptionType;
  onRemove?: () => void;
}) {
  return (
    <Cheeps
      className={cn("text-muted-foreground bg-muted  ", {
        "pe-0": Boolean(onRemove),
      })}
    >
      {option.label}
      {onRemove && (
        <Button
          className="ml-1 p-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onRemove();
            }
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={onRemove}
        >
          <LuX className="h-3 w-3 text-muted-foreground hover:text-foreground" />
        </Button>
      )}
    </Cheeps>
  );
}

function EachItem({
  option,
  onSelect,
  isSelected,
}: {
  option: OptionType;
  onSelect: () => void;
  isSelected?: boolean;
}) {
  return (
    <CommandItem
      // value={sortedStringify(option.value)}
      onSelect={onSelect}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={cn(
        "cursor-pointer flex items-center capitalize gap-1 w-full",
        {
          "bg-muted": isSelected,
        }
      )}
    >
      <LuCheck className={cn("", { invisible: !isSelected })} />

      <span className="inline-block truncate">{option.label}</span>

      <div className="flex-1"></div>
      {option.onEdit && (
        <Button
          size={"sm"}
          variant={"faded"}
          onClick={() => option.onEdit!()}
          className=" p-1"
        >
          <LuPencil className="!text-lg" />
        </Button>
      )}
      {option.onDelete && (
        <Button
          size={"sm"}
          variant={"faded"}
          onClick={() => option.onDelete!()}
          className=" p-1"
        >
          <LuTrash2 className="!text-lg" />
        </Button>
      )}
    </CommandItem>
  );
}

export function SelectPrimitive({
  options,
  value = [],
  multiple = false,
  onChange,
  disabled,
}: {
  multiple?: boolean;
  options?: OptionType[] | null;
  value?: OptionType[] | null;
  onChange?: (v?: OptionType[] | null) => void;
} & Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "value" | "onChange">) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const _options = listUnique(options, (v, b) => v.value === b.value);
  const _value =
    value?.filter((e) => _options?.some((a) => a?.value === e?.value)) ?? [];

  // console.log(_value, listFirst(_value)?.label?.toString());

  const handleSelect = (o: OptionType[]) => {
    onChange?.(
      multiple
        ? listUnique([..._value, ...o], (a, b) => a.value === b.value)
        : o
    );
  };

  const handleUnselect = (o: OptionType[]) => {
    onChange?.(
      multiple
        ? _value.filter((s) => !o.some((a) => a.value === s.value))
        : null
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (input) {
      // if (e.key === "Delete" || e.key === "Backspace") {
      //   if (input.value === "" && !isEmpty(_value)) {
      //     handleUnselect([listFirst(_value)!]);
      //     // setSelected((prev) => {
      //     //   const newSelected = [...prev];
      //     //   newSelected.pop();
      //     //   return newSelected;
      //     // });
      //   }
      // }
      if (e.key === "Escape") {
        input.blur();
      }
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Command
        onKeyDown={handleKeyDown}
        className="overflow-visible bg-transparent"
      >
        <PopoverTrigger asChild>
          <div className="group " ref={containerRef}>
            {multiple ? (
              <div className="flex items-start relative border border-input text-sm ring-offset-background rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
                <div className="flex flex-wrap flex-1 ">
                  {!isEmpty(_value) && (
                    <div className="inline-flex flex-wrap gap-1 p-2">
                      {_value.map((e, i) => (
                        <EachSelectedItem
                          key={"eachSelectedItem" + i}
                          option={e}
                          onRemove={
                            disabled ? undefined : () => handleUnselect([e])
                          }
                        />
                      ))}
                    </div>
                  )}
                  {/* Avoid having the "Search" Icon */}
                  <CommandPrimitive.Input
                    ref={inputRef}
                    value={inputValue}
                    onValueChange={setInputValue}
                    onBlur={() => setOpen(false)}
                    onFocus={() => setOpen(true)}
                    placeholder={
                      options === undefined || options === null
                        ? "Loading..."
                        : "Select..."
                    }
                    readOnly={!multiple}
                    disabled={disabled}
                    className="px-3 py-2 bg-transparent outline-none placeholder:text-muted-foreground flex-1"
                  />
                </div>

                <div
                  className={cn("absolute right-2 top-2", {
                    "pointer-events-none": isEmpty(value),
                  })}
                >
                  {isEmpty(value) ? (
                    <LuChevronDown className="opacity-50 !text-lg pointer-events-none p-0" />
                  ) : (
                    <Button
                      size={"sm"}
                      variant={"faded"}
                      disabled={disabled}
                      onClick={() => handleUnselect(_value as OptionType[])}
                      className="py-1 px-0 rounded-full aspect-square "
                    >
                      <LuX className="!text-sm " />
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div className="relative flex items-center ">
                <Input
                  readOnly
                  onBlur={() => setOpen(false)}
                  onFocus={() => setOpen(true)}
                  disabled={disabled}
                  value={listFirst(_value)?.label?.toString() ?? ""}
                  placeholder={
                    options === undefined || options === null
                      ? "Loading..."
                      : "Select..."
                  }
                  className="cursor-pointer capitalize"
                />
                <LuChevronDown className="absolute right-0 opacity-50 !text-lg pointer-events-none mx-3" />
              </div>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="p-1"
          style={{ width: containerRef.current?.clientWidth }}
        >
          <div className="flex items-center">
            {Boolean(multiple && _options) && (
              <Button
                variant={"light"}
                className="rounded-full border p-1"
                onClick={() => {
                  _value.length === _options?.length
                    ? handleUnselect(_value)
                    : handleSelect(_options!);
                }}
              >
                {_value.length === _options?.length ? (
                  <LuX className="!text-sm" />
                ) : (
                  <LuCheck className="!text-sm" />
                )}
              </Button>
            )}

            <CommandPrimitive.Input
              // ref={inputRef}
              autoFocus
              value={inputValue}
              onValueChange={setInputValue}
              placeholder={
                options === undefined || options === null
                  ? "Loading..."
                  : "Search..."
              }
              className="px-3 py-2 bg-transparent outline-none placeholder:text-muted-foreground flex-1 w-full"
            />
          </div>
          <CommandEmpty>Nothing found.</CommandEmpty>
          <CommandGroup className="max-h-[300px] overflow-y-auto">
            {_options
              ?.sort((a, b) =>
                a
                  .label!.toLocaleString()
                  .localeCompare(b.label!.toLocaleString())
              )
              ?.map((e, i) => (
                <EachItem
                  key={"eachOption" + i}
                  option={e}
                  onSelect={() => {
                    setInputValue("");
                    if (_value.some((a) => a.value === e.value)) {
                      handleUnselect([e]);
                    } else {
                      handleSelect([e]);
                    }

                    if (!multiple) {
                      setOpen(false);
                    }
                  }}
                  isSelected={_value.some((a) => a.value === e.value)}
                />
              ))}
          </CommandGroup>
        </PopoverContent>
      </Command>
    </Popover>
  );
}
