import { RESPONSIVE_KEYS } from "models/views/responsive";
import React from "react";

export const MediaQuery2Num = {
  never: -1,
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  "2xl": 5,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  always: 99,
};

export default function useMediaQuery() {
  const getSize = () => {
    let _result: number = 0;

    /* if(window.innerWidth < 640)
          {
              _result = MediaQueryTypes.XS;
          }
          else  */

    if (window.innerWidth < 640) {
      _result = MediaQuery2Num.sm;
    } else if (window.innerWidth < 768) {
      _result = MediaQuery2Num.md;
    } else if (window.innerWidth < 1024) {
      _result = MediaQuery2Num.lg;
    } else if (window.innerWidth < 1280) {
      _result = MediaQuery2Num.xl;
    } else {
      _result = MediaQuery2Num["2xl"];
    }

    return _result;
  };

  const [size, _setSize] = React.useState<number>(getSize());

  React.useEffect(() => {
    const handleResize = () => {
      let _result: number = getSize();
      _setSize((prev) => (_result === prev ? prev : _result));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
}
