import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import React from "react";
import { LuFilter } from "react-icons/lu";
import { cn } from "services/UtilServices";

export default function FilterButton({
  isActive = false,
  onClick = () => {},
  count = -1,
  needText = true,
  className = "",
}) {
  return (
    <Button
      variant={"light"}
      className={cn("px-2 ", className)}
      onClick={onClick}
    >
      {isActive && count >= 0 && <Badge>{count}</Badge>}

      <div className="relative inline-block">
        <LuFilter />
        {isActive && (
          <div className="bg-destructive p-1 aspect-square absolute rounded-full -top-1 -left-1 "></div>
        )}
      </div>

      {needText && <span className="hidden lg:inline">Filter</span>}
    </Button>
  );
}
