import React from "react";

import Dict from "models/Dict";

import {
  Switch,
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import TextEditor from "components/common/Forms/TextEditor";
import { DialogContent, DialogFooter } from "components/ui/dialog";

export default function CommentCreateUpdateModal({
  formData,
  onSubmit,
}: {
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  return (
    <DialogContent title="Comments">
      <Form
        data={
          formData !== undefined
            ? {
              ...formData,
            }
            : {}
        }
        onSubmit={onSubmit}
      >
        <TextEditor name="content" label="Content" required isAdvance />

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent >
  );
}
