

import Dict from 'models/Dict';
import { useStorageSQLite } from 'react-data-storage-sqlite-hook/dist';

type KeyType =  string | "PROJECTS" | "TAGS" | "USERS" | "API_URL" | 
                "current_user" | "LICENSE" | "SIDE_MENU_IS_MINI" | 
                "FILTER_IS_MINI" | "POST_VIEW_TYPE" | "POSTS_TO_UPLOAD" |
                "LOGINS";


export default class LocalStorageServices {

    static async get(key: KeyType): Promise<any> {
        // await openStore({});
        // const value = await getItem(key);

        // if(value) {
        //     return JSON.parse(value);
        // }

        return null;
        // throw new Error("Key Not Found!");
    }

    static async remove(key: KeyType) {
        // await openStore({});
        // await removeItem(key);
    }

    static async set(key: KeyType, data: any) {
        // await setItem(
        //     key,
        //     JSON.stringify(data)
        // );
    }

    static async clear() {
        // await _clear();
    }

}



export function StorageProvider({ children }: { children: React.ReactNode }) {
  
    const { openStore, getItem, setItem, getAllKeys, getAllValues,
     getAllKeysValues, isKey, setTable, removeItem, clear } = useStorageSQLite();
 
 
    LocalStorageServices.get = async (key: KeyType) => {
        await openStore({});
        const value = await getItem(key);
    
        if(value) {
            return JSON.parse(value);
        }
        
        return null;
   }
 
   LocalStorageServices.remove = async (key: KeyType) => {
        // console.log("remove", key)
        await openStore({});
        await removeItem(key);
   }
 
   LocalStorageServices.set = async (key: KeyType, data: any) => {
        // console.log("set", key, data)
        await setItem(
            key,
            JSON.stringify(data)
        );
   }
 
   LocalStorageServices.clear = async () => {
        // console.log("clearrrrrrrrrrrrrrrrrrrrrrrrrr")
        await clear();
   }
 
 
   return (
     <>
       { children }
     </>
   );
}
  
