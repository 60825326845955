import { useEffortsContext } from "providers/EffortsProvider";

import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import MenuButton from "components/ui/menu-button";
import { FaCaretDown } from "react-icons/fa6";
import React from "react";

export type TrelloSectionTypeItemType = {
  label: string;
  multiple: boolean;
  colsSortable: boolean;
  required: boolean;
  draggable?: boolean;
};
export type TrelloSectionTypeKeyType =
  | "state"
  | "prefix"
  | "priority"
  // | "type"
  // | "tag"
  // | "ticket"
  | "user"
  // | "effort"
  // "today" |
  | "thisWeek"
  | "thisMonth";

export const TRELLO_SECTION_TYPES: {
  [key in TrelloSectionTypeKeyType]: TrelloSectionTypeItemType;
} = {
  // effort: {
  //   label: "effort",
  //   multiple: false,
  //   colsSortable: true,
  //   required: true,
  //   draggable: false,
  // },
  state: {
    label: "state",
    multiple: false,
    colsSortable: true,
    required: true,
  },
  prefix: {
    label: "prefix",
    multiple: false,
    colsSortable: true,
    required: false,
  },
  priority: {
    label: "priority",
    multiple: false,
    colsSortable: true,
    required: true,
  },
  // type: {
  //   label: "type",
  //   multiple: true,
  //   colsSortable: true,
  //   required: false,
  // },
  // tag: {
  //   label: "tag",
  //   multiple: true,
  //   colsSortable: true,
  //   required: false,
  // },
  // ticket: {
  //   label: "ticket",
  //   multiple: true,
  //   colsSortable: true,
  //   required: false,
  // },
  user: {
    label: "user",
    multiple: true,
    colsSortable: true,
    required: false,
    // draggable: false,
  },
  // today: { label: "today", multiple: false, colsSortable: false, required: true },
  thisWeek: {
    label: "this Week",
    multiple: false,
    colsSortable: false,
    required: true,
  },
  thisMonth: {
    label: "this Month",
    multiple: false,
    colsSortable: false,
    required: true,
  },
};

export default function GroupBySelectSection() {
  const _effortsContext = useEffortsContext();
  const _effortUtils = useEffortUtils();

  const _isHome = _effortsContext.currentEffort === undefined;
  const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
  const _isTask = !_isHome && !_isProject;

  React.useEffect(() => {
    if (_isHome && _effortsContext.groupBy === "prefix") {
      _effortsContext.setGroubBy(null);
    }
  }, [_isHome, _effortsContext.groupBy]);

  return (
    <MenuButton
      ellipsisClassName="opacity-100 hover:bg-accent "
      ellipsisResponsiveBreakPoint={"always"}
      items={[
        {
          text: "No Group",
          onClick: () => _effortsContext.setGroubBy(null),
        },
        ...Object.entries(TRELLO_SECTION_TYPES)
          .filter(([k, v]) => (_isHome ? k !== "prefix" : true))
          .map(([k, v]) => ({
            text: v.label, //k === "effort" ? (_isHome ? "Project" : "Task") :
            onClick: () =>
              _effortsContext.setGroubBy(
                k as keyof typeof TRELLO_SECTION_TYPES
              ),
          })),
      ]}
    >
      <FaCaretDown className="!text-sm" />
      {_effortsContext.groupBy ?? "Group By"}
    </MenuButton>
  );
}
