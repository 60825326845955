import React from "react";

import Effort from "models/Effort";

import SectionBase from "./SectionBase";
import Cheeps from "components/common/Cheeps";

export default function StatesSection({ effort }: { effort: Effort }) {
  return (
    <SectionBase effort={effort} fieldName="states" title="States">
      {effort.states?.map((eachState, i) => (
        <Cheeps key={"eachState" + i} borderColor={eachState.color}>
          {eachState.name}
        </Cheeps>
      ))}
    </SectionBase>
  );
}
