import React from "react";

import Dict from "models/Dict";
import Ticket from "models/Ticket";

import { useFormContext, Validation } from "components/common/Forms/Form";
import InputStandalone from "components/common/Forms/InputStandalone";
import FormFieldBase from "components/common/Forms/FormFieldBase";
import TicketCount from "models/TicketCount";
import { cn, isBright } from "services/UtilServices";
import { Button } from "components/ui/button";
import { ValidationType } from "components/common/Forms/UseValidation";
import { LuChevronDown, LuChevronUp, LuMinus, LuPlus } from "react-icons/lu";

function EachTicketItem({
  name,
  ticket,
  disabled,
  ticketCountFieldName,
}: {
  name: string;
  ticket: Ticket;
  disabled?: boolean;
  ticketCountFieldName: "count" | "countApproved";
}) {
  const _formContext = useFormContext();

  const _oldTicketCount = _formContext.data[name]?.find(
    (e: TicketCount) => e.ticketId === ticket.id
  );
  const value = _oldTicketCount ? _oldTicketCount[ticketCountFieldName] : 0;

  const _ticketColor = ticket.color + "55";

  const onSet = (v: number) => {
    if (_oldTicketCount) {
      _formContext.setData({
        [name]: _formContext.data[name].map((e: TicketCount) =>
          e.ticketId !== ticket.id
            ? e
            : {
                ...e,
                [ticketCountFieldName]: v,
              }
        ),
      });
    } else {
      _formContext.setData({
        [name]: [
          ...(_formContext.data[name] ?? []),
          {
            ticketId: ticket.id,
            [ticketCountFieldName]: v,
          },
        ],
      });
    }
  };

  return (
    <div
      style={{
        background: _ticketColor,
      }}
      className={cn(
        "flex items-center flex-col first:rounded-s last:rounded-e border "
      )}
    >
      <Button
        disabled={disabled}
        onClick={() => onSet(value + 0.25)}
        className={cn("rounded-b-none")}
      >
        <LuChevronUp />
      </Button>

      <div className="px-2">
        <InputStandalone
          value={value}
          type="number"
          onChange={(v) => onSet(parseFloat(v as string))}
          className={cn("border-0 inline text-center bg-transparent w-8")}
        />
      </div>

      <b className="uppercase text-foreground">{ticket.name}</b>

      <Button
        className={cn("rounded-t-none  ")}
        onClick={() => onSet(value - 0.25)}
        disabled={value === 0 || disabled}
      >
        <LuChevronDown />
      </Button>
    </div>
  );
}

export const ticketIsRequired = (
  ticketCountFieldName: "count" | "countApproved" = "count"
) =>
  ({
    type: "ticketIsRequired",
    text: "This field is required.",
    isValid: (value?: Dict) => {
      return (
        value !== null &&
        value !== undefined &&
        Object.values(value).some((e) => e[ticketCountFieldName] !== 0) &&
        Object.values(value).every(
          (e) => !Number.isNaN(e[ticketCountFieldName])
        )
      );
    },
  } as ValidationType);

export const ticketDecimalIsValid = (
  ticketCountFieldName: "count" | "countApproved" = "count"
) =>
  ({
    type: "ticketDecimalIsValid",
    text: "This value is not valid.",
    isValid: (value?: Dict) => {
      return (
        value !== null &&
        value !== undefined &&
        Object.values(value).every((e) =>
          [0, 0.25, 0.5, 0.75].includes(
            (e[ticketCountFieldName] ?? 0) -
              Math.floor(e[ticketCountFieldName] ?? 0)
          )
        )
      );
    },
  } as ValidationType);

export default function TicketsCountInputField(props: {
  name: string;
  tickets: Ticket[] | undefined;
  ticketCountFieldName?: "count" | "countApproved";
  label: string;
  validations?: ValidationType[];
  required?: boolean;
  disabled?: boolean;
}) {
  return (
    <FormFieldBase {...props}>
      {(baseProps) => (
        <div className="flex flex-wrap items-start gap-1">
          {props.tickets?.map((e) => (
            <EachTicketItem
              key={"eachTicketItem" + e.id}
              ticket={e}
              name={props.name}
              disabled={props.disabled || baseProps.isLoading}
              ticketCountFieldName={props.ticketCountFieldName ?? "count"}
            />
          ))}
        </div>
      )}
    </FormFieldBase>
  );
}
