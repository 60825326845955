import React from "react";

import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { useItemsListContext } from "components/common/ItemsListSection/ItemsListProvider";
import { cn, isFarsi } from "services/UtilServices";
import { useNavigate } from "react-router";
import { useMainContext } from "providers/MainProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import DateDueCheeps from "components/pages/DateDueCheeps";
import { SingleFieldUpdatePen } from "../SingleFieldUpdateButton";
import TicketsCheepsSection from "components/pages/TicketsCheepsSection";
import { LuX } from "react-icons/lu";
import MenuButton from "components/ui/menu-button";
import { Button } from "components/ui/button";
import MiniMembersSection from "components/pages/EachEffort/MembersSection/MiniMembersSection";
import EffortBanner from "../EffortBanner";
import { useEffortMenuButton } from "components/pages/EachEffort/EffortMenuButton";
import EffortCardLittleDetails from "../EffortCardLittleDetails";
import { MyContextMenu } from "components/ui/context-menu";
import Directionality from "components/common/Directionality";
import Skeleton from "components/common/Skeleton";
import { Separator } from "components/ui/separator";
import Cheeps from "components/common/Cheeps";
import { TbArchive } from "react-icons/tb";
import { useLinkUtils } from "components/common/Link";

function EffortCardSkeleton({
  effort,
  className,
}: {
  effort: Effort;
  className?: string;
}) {
  return (
    <Button
      asChild
      variant={"light"}
      className={cn(
        "group mb-1 p-3 flex flex-col gap-2 shadow justify-start items-start min-h-[50px] pointer-events-none border bg-card",
        className
      )}
    >
      <div>
        <Skeleton className="w-full h-36" />
        <div className="flex items-center w-full">
          <span className="animate-pulse">{effort.title}</span>
          <div className="flex-1"></div>
          <Skeleton className="w-10 h-5" />
        </div>
        <Skeleton className="w-full h-7" count={3} />
        <Separator className="my-0" />
        <div className="flex items-center gap-2 w-full">
          <Skeleton count={2} className="rounded-full aspect-square w-9" />
          <div className="flex-1"></div>
          <Skeleton className="w-10 h-5" />
        </div>
      </div>
    </Button>
  );
}

type EachCardItemType = {
  effortId: number;
  onRemove?: (e: Effort) => void;
  className?: string;
  mini?: boolean;
};

const EachCardItem = React.forwardRef<HTMLButtonElement, EachCardItemType>(
  ({ effortId, onRemove, className, mini }, ref) => {
    const {
      selecteds,
      isSelected,
      toggleSelecteds,
      onItemSelect,
      selectedActionsSection,
      onItemDelete,
    } = useItemsListContext();
    const _mainContext = useMainContext();
    const _effortUtils = useEffortUtils();

    const navigate = useNavigate();
    const linkUtils = useLinkUtils();

    const _isMatch = linkUtils.isMatch(_effortUtils.makeUrl(effortId)!);

    const effort = _mainContext.efforts?.find((e) => e.id === effortId);
    const effortMenuButton = useEffortMenuButton(effort?.parentId);
    const _project = _effortUtils.getParentProject(effortId);
    const isSubTask = effort?.level === 2;

    return (
      <>
        {effort && (
          // <EffortInfoLoaderOnView
          //   effort={effort}
          //   skeleton={
          //     <EffortCardSkeleton effort={effort} className={className} />
          //   }
          // >
          <MyContextMenu
            asChild
            deleteMessage={
              <span>
                You're about to delete "<b>{effort.title}</b>"!
              </span>
            }
            onSelect={
              !(
                onItemSelect(effort) ||
                selectedActionsSection ||
                onItemDelete([effort, ...selecteds])
              ) || isSubTask
                ? undefined
                : () => {
                    toggleSelecteds(effort);
                    onItemSelect(effort)?.(effort);
                  }
            }
            items={[
              {
                icon: <LuX />,
                text: "Remove from this column",
                onClick: onRemove ? () => onRemove(effort) : undefined,
              },
              ...effortMenuButton.getItems(effort),
            ]}
          >
            <Button
              ref={ref}
              asChild
              variant={isSelected(effort) ? "primary" : "light"}
              className={cn(
                "group p-5 flex flex-col shadow justify-start items-start min-h-[50px] border ",
                isSelected(effort) ? "" : " bg-background hover:bg-muted",
                {
                  "opacity-60 hover:opacity-100": !effort.isActive,
                  "border-foreground border-2": _isMatch,
                },
                className
              )}
            >
              <div
                id={effortId + ""}
                onClick={() => {
                  if (!isSubTask && isSelected(effort)) {
                    toggleSelecteds(effort);
                  } else if (!isSubTask && selecteds.length > 0) {
                    toggleSelecteds(effort);
                  } else {
                    if (_isMatch) {
                      navigate(_effortUtils.makeUrl(_project!.id)!);
                    } else {
                      navigate(_effortUtils.makeUrl(effortId)!);
                    }
                  }
                }}
              >
                {!mini && (
                  <EffortBanner
                    effort={effort}
                    className="w-full object-cover rounded-t border-b mb-2 h-36"
                  />
                )}

                <div className="flex items-center w-full mb-2">
                  {/* <span className="pe-2">{ effort.url }</span> */}

                  {mini && (
                    <EffortBanner
                      effort={effort}
                      className="object-cover h-10 aspect-square rounded border me-2 "
                    />
                  )}

                  <span
                    className={cn(
                      "text-left capitalize inline-block whitespace-normal break-words",
                      { "line-through": !effort.isActive }
                    )}
                  >
                    <div className=" w-full">
                      <span className="mb-0 !font-normal !text-xs">
                        {effort.url}
                      </span>{" "}
                      {effort.prefix && (
                        <span className="mb-0 !font-normal !text-xs">
                          - {effort.prefix?.name}
                        </span>
                      )}{" "}
                    </div>

                    <Directionality className={cn("mb-0 font-bold text-lg ")}>
                      {effort.title}
                    </Directionality>
                  </span>

                  {/* <SingleFieldUpdatePen
                  className="static"
                  effort={effort}
                  fieldName="title"
                /> */}

                  <div className="flex-1"></div>

                  <div className="md:invisible group-hover:visible">
                    <MenuButton
                      ellipsisResponsiveBreakPoint="always"
                      btnTextResponsiveBreakPoint="never"
                      deleteMessage={
                        <span>
                          You're about to delete "<b>{effort.title}</b>"!
                        </span>
                      }
                      onSelect={
                        !(
                          onItemSelect(effort) ||
                          selectedActionsSection ||
                          onItemDelete([effort, ...selecteds])
                        ) || isSubTask
                          ? undefined
                          : () => {
                              toggleSelecteds(effort);
                              onItemSelect(effort)?.(effort);
                            }
                      }
                      items={[
                        {
                          icon: <LuX />,
                          text: "Remove from this column",
                          onClick: onRemove
                            ? () => onRemove(effort)
                            : undefined,
                        },
                        ...effortMenuButton.getItems(effort),
                      ]}
                    />
                  </div>
                </div>

                <div className="flex items-center w-full text-foreground/80">
                  <EffortCardLittleDetails effort={effort} />

                  <div className="flex-1"></div>

                  {effort.isActive ? (
                    <>
                      <SingleFieldUpdatePen
                        className="static"
                        effort={effort}
                        fieldName="state"
                      />
                      <Cheeps color={effort.state?.color}>
                        {effort.state?.name}
                      </Cheeps>
                    </>
                  ) : (
                    <>
                      <SingleFieldUpdatePen
                        className="static"
                        effort={effort}
                        fieldName="isActive"
                      />
                      <TbArchive /> Archived
                    </>
                  )}
                </div>

                <TicketsCheepsSection
                  ticketCounts={effort.ticketsCount}
                  needIcon
                  wrap
                  className="mb-2 mt-2"
                >
                  {effort.parentId && (
                    <SingleFieldUpdatePen
                      className="static"
                      effort={effort}
                      fieldName="tickets Count"
                    />
                  )}
                </TicketsCheepsSection>

                <div className="flex flex-wrap items-center border-t pt-2 mt-auto w-full">
                  <MiniMembersSection effort={effort} />

                  <div className="flex-1"></div>

                  <SingleFieldUpdatePen
                    className="static"
                    effort={effort}
                    fieldName="dateDue"
                  />
                  <DateDueCheeps effort={effort} />
                </div>
              </div>
            </Button>
          </MyContextMenu>
          // </EffortInfoLoaderOnView>
        )}
      </>
    );
  }
);

export default EachCardItem;
