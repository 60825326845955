import React from "react";
import { cn, isBright } from "services/UtilServices";

export default function TableFillCell({
  children,
  className,
  color,
  onClick,
}: {
  children?: React.ReactNode;
  className?: string;
  color?: string;
  onClick?: () => void;
}) {
  return (
    <div>
      <div
        className={cn(
          "inset-0 absolute opacity-50 group-hover/row:opacity-100 ",
          className
        )}
        style={{
          background: color,
        }}
        onClick={onClick}
      ></div>
      <div
        onClick={onClick}
        className={cn(
          "relative capitalize text-foreground ",
          color
            ? isBright(color)
              ? "group-hover/row:text-dark"
              : "group-hover/row:text-white"
            : "text-foreground"
        )}
      >
        {children}
      </div>
    </div>
  );
}
