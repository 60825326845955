import User, { UserRoles, UserFilter } from "models/User";
import { hasOtherKeysExcept } from "services/UtilServices";



export default function useUserUtils() {

  const getFullName = (user?: User) => {
    let _nameLastName = (user?.firstName ?? "") + ' ' + (user?.lastName ?? "");
    return _nameLastName == ' ' ? user?.userName : _nameLastName.trim();
  }

  const isSudo = (user?: User | null) => {
    return user && [UserRoles.ADMIN, UserRoles.MANAGER].includes(user.role);
  }

  const isFilterActive = ({ filter }: { filter: UserFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage"]);
  }

  return {
    getFullName,
    isSudo,
    isFilterActive,
  }
}

