import React from "react";

import Dict from "models/Dict";
import { StateFilter } from "models/State";

import { hasOtherKeysExcept } from "services/UtilServices";

import DatePickerInput from "components/common/Forms/DatePickerInput";
import { Form, Input, SubmitButton } from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useStateUtils from "hooks/utils/UseStateUtils";

export default function StateFilterModal({
  filter,
  onSubmit,
}: {
  filter: StateFilter;
  onSubmit: (data: Dict) => void;
}) {
  const stateUtils = useStateUtils();

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        <Input name="name" label="State Name" maxLength={256} />

        <DatePickerInputRange name="dateAdd" label="Date Add" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={stateUtils.isFilterActive({ filter })}
            onClick={() => onSubmit({ pageNumber: -1 })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
