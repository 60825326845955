"use client";

import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";

import { LuX } from "react-icons/lu";
import { cn } from "services/UtilServices";
import useEffectStrictMode from "hooks/UseEffectStrictMode";
import { VariantProps, cva } from "class-variance-authority";
import { Button } from "./button";
import { useModalContext } from "providers/ModalProvider";
import Sticky from "components/common/Sticky";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>(({ className, children, ...props }, ref) => {
  const _modalContext = useModalContext();

  return (
    <Button
      variant={"faded"}
      onClick={() => _modalContext.dismiss()}
      className={cn(
        " rounded-sm ring-offset-background transition-opacity",
        " focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
        " disabled:pointer-events-none text-foreground", //group-data-[state=open]/dialog:bg-accent group-data-[state=open]/dialog:text-foreground
        className
      )}
    >
      {children ?? (
        <>
          <LuX />
          <span className="sr-only">Close</span>
        </>
      )}
    </Button>
  );
});
DialogClose.displayName = DialogPrimitive.Close.displayName;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      // "fixed inset-0 z-50 ",
      "absolute inset-0 ",
      "bg-slate-600/50 backdrop-blur-sm overflow-y-auto overflow-x-hidden",
      " group-data-[state=open]/dialog:animate-in",
      " group-data-[state=closed]/dialog:animate-out",
      "group-data-[state=closed]/dialog:fade-out-0",
      "group-data-[state=open]/dialog:fade-in-0 ",
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const dialogContentVariants = cva(
  //fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] group-data-[state=open]/dialog:slide-in-from-left-1/2 group-data-[state=closed]/dialog:slide-out-to-bottom-1/2
  ` relative transition-all mx-auto w-full pointer-events-auto
    border border-foreground/50 bg-background p-6 shadow-lg shadow-slate-600 duration-100
    md:rounded-lg md:my-7 ease-in-out
    group-data-[state=open]/dialog:animate-in group-data-[state=closed]/dialog:animate-out 
    group-data-[state=closed]/dialog:fade-out-0 group-data-[state=open]/dialog:fade-in-0 
    md:data-[state=closed]:zoom-out-95 md:data-[state=open]:zoom-in-95 
    max-md:data-[state=closed]:slide-out-to-bottom-[50px] max-md:data-[state=open]:slide-in-from-bottom-[50px]`,
  {
    variants: {
      size: {
        xs: "md:max-w-xs",
        sm: "md:max-w-sm",
        md: "md:max-w-lg",
        lg: "md:max-w-lg lg:max-w-5xl",
        xl: "md:max-w-lg lg:max-w-5xl xl:max-w-7xl",
        full: "md:w-full",
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
);
type DialogContentType = Omit<
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
  "title"
> &
  VariantProps<typeof dialogContentVariants> & {
    id?: string;
    title?: React.ReactNode;
    dir?: "ltr" | "rtl";
    fullScreen?: boolean;
    needCloseBtn?: boolean;
    onBgClickClosable?: boolean;
    onDismiss?: () => void;
  };
const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentType
>(
  (
    {
      className,
      children,
      title,
      onDismiss,
      needCloseBtn = true,
      size = "md",
      onBgClickClosable = false,
      fullScreen = true,
      ...props
    },
    ref
  ) => {
    const _modalContext = useModalContext();

    useEffectStrictMode(undefined, [], () => onDismiss?.());

    return (
      // <DialogPortal>
      <DialogOverlay
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          if (onBgClickClosable) {
            _modalContext.dismiss();
          }
        }}
      >
        <div
          ref={ref}
          className={cn(
            dialogContentVariants({ size }),
            fullScreen
              ? "max-md:min-h-full"
              : "bottom-0 md:bottom-auto md:relative absolute",
            className
          )}
          {...props}
          // onInteractOutside={(ev) => {
          //   if (!onBgClickClosable) {
          //     ev.stopPropagation();
          //     ev.preventDefault();
          //   }
          // }}
          onClick={(ev) => ev.stopPropagation()}
        >
          {title && (
            <DialogHeader>
              <div className="flex-1">
                {typeof title === "string" ? (
                  <DialogTitle>{title}</DialogTitle>
                ) : (
                  title
                )}
              </div>
              {needCloseBtn && <DialogClose />}
            </DialogHeader>
          )}

          {children}
        </div>
      </DialogOverlay>
      // </DialogPortal>
    );
  }
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <Sticky side="top" className="group/header data-[stick=true]:z-20">
    <div>
      <div
        className={cn(
          "flex items-center mb-2 capitalize sticky top-0 bg-background group-data-[stick=true]/header:shadow-md group-data-[stick=true]/header:py-3 group-data-[stick=true]/header:border-b",
          className
        )}
        {...props}
      />
    </div>
  </Sticky>
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <Sticky side="bottom" className="group/footer data-[stick=true]:z-20 mt-3">
    <div>
      {/* <div className="h-3 hidden group-data-[stick=true]/footer:block bg-gradient-to-t from-slate-300/50"></div> */}
      <div
        className={cn(
          "flex items-center justify-center gap-2 w-full sticky bottom-0 bg-background pb-2 group-data-[stick=true]/footer:border-t",
          className
        )}
        {...props}
      />
    </div>
  </Sticky>
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight mb-0 text-foreground",
      className
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
