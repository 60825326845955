import { tagSchema } from "./Tag";
import { typeSchema } from "./Type";
import Dict from "./Dict";
import State, { stateSchema } from "./State";
import { prefixSchema } from "./Prefix";
import { ticketSchema } from "./Ticket";
import { prioritySchema } from "./Priority";
import { attachmentSchema } from "./Attachment";
import { departmentSchema } from "./Department";
import {
  ticketCountSchema,
} from "./TicketCount";
import { userSchema } from "./User";
import { commentSchema } from "./Comment";
import { MemberAccessTypes, memberSchema } from "./Member";

import { z } from "zod";
import TicketCount from "./TicketCount";
import { textEditorTypeSchema } from "components/common/Forms/TextEditor";

export type EffortSortType =
  | "state"
  | "creator"
  | "title"
  | "level"
  | "timeestimate"
  | "timemax"
  | "timeapproved"
  | "timeactual"
  | "dateadd"
  | "datestart"
  | "datedue"
  | "weight"
  | "qualityfactor"
  | "priority"
  | "isactive"
  | "dateend";

export class Priorities {
  static LOW = 0;
  static NORMAL = 1;
  static HIGH = 2;

  static options: Dict = {
    0: "Low",
    1: "Normal",
    2: "High",
  };

  static colors: Dict = {
    0: "#6c757d",
    1: "#17a2b8",
    2: "#dc3545",
  };

  static toLabelValues = () =>
    Object.entries(Priorities.options).map(([key, val]) => ({
      value: parseInt(key),
      label: val,
    }));
}

export const EffortSortTypes: { [key: string]: EffortSortType } = {
  state: "state",
  title: "title",
  level: "level",
  creator: "creator",
  timeestimate: "timeestimate",
  timemax: "timemax",
  timeapproved: "timeapproved",
  timeactual: "timeactual",
  dateadd: "dateadd",
  datestart: "datestart",
  datedue: "datedue",
  weight: "weight",

  qualityfactor: "qualityfactor",
  dateend: "dateend",
  priority: "priority",
  isactive: "isactive",
  // DEPARTMENTID: "DEPARTMENTID"
};

export interface EffortFilter {
  effortIds?: number[];

  pageNumber: number;
  sortBy?: EffortSortType;
  ascOrder: boolean;
  numberInPage?: number;

  stateIds?: number[];
  prefixIds?: (number | null)[];
  typeIds?: number[];
  tagIds?: number[];
  userIds?: number[];
  ticketIds?: number[];
  priorityIds?: number[];
  departmentIds?: number[];
  creatorIds?: string[];

  parentId?: number | null;
  url?: "string";

  states?: State[];
  memberIds?: string[];
  listPrefix?: string[];
  title?: string;
  level?: number;

  timeEstimateFrom?: string;
  timeEstimateTo?: string;

  timeMaxFrom?: string;
  timeMaxTo?: string;

  timeApprovedFrom?: string;
  timeApprovedTo?: string;

  timeActualFrom?: string;
  timeActualTo?: string;

  dateAddFrom?: string | number | Date | null;
  dateAddTo?: string | number | Date | null;

  dateDueFrom?: string | number | Date | null;
  dateDueTo?: string | number | Date | null;

  dateStartFrom?: string;
  dateStartTo?: string;

  dateEndFrom?: string;
  dateEndTo?: string;

  weight?: number;
  qualityFactor?: number;
  priority?: Priorities;
  isActive?: boolean;
  includes?: boolean;
  filter?: boolean;
}

export const effortSchema = z.object({
  id: z.number(),
  parentId: z.number().nullable().optional(),
  // parent: effortSchema.nullable().optional(),

  title: z.string(),
  description: textEditorTypeSchema.optional(),
  avatarUrl: z.string().optional(),
  level: z.number(),
  isActive: z.boolean(),
  url: z.string(),
  index: z.number(),
  subTasksCount: z.number().optional(),
  dateAdd: z.string(),

  // parent: effortSchema.optional(),
  // subs: effortSchema[],
  departmentId: z.string().optional(),
  department: departmentSchema.optional(),

  creatorId: z.string().optional(),
  creator: userSchema.optional(),

  prefixes: prefixSchema.array().optional(),
  prefix: prefixSchema.optional(),
  prefixId: z.number().optional(),

  ticketsCount: ticketCountSchema.array().optional(),
  ticketsAvailable: ticketSchema.array().optional(),

  stateId: z.number().optional(),
  state: stateSchema.nullable().optional(),
  states: stateSchema.array().optional(),

  priority: prioritySchema.nullable().optional(),
  priorityId: z.number().optional(),
  priorities: prioritySchema.array().optional(),

  attachments: attachmentSchema.array().optional(),
  members: memberSchema.array().optional(),
  types: typeSchema.array().optional(),
  tags: tagSchema.array().optional(),
  comments: commentSchema.array().optional(),

  timeEstimate: z.string().optional(),
  timeMax: z.string().optional(),
  timeApproved: z.string().optional(),
  timeActual: z.string().optional(),

  dateStart: z.string().optional(),
  dateDue: z.string().optional(),
  dateEnd: z.string().optional(),

  effortTicketSum: z.number().optional(),
  activityTicketSum: z.number().optional(),
  approvedActivityTicketSum: z.number(),
  activitySum: ticketCountSchema.array().optional(),

  effortActivityRecordsCount: z.number().optional(),

  notificationCount: z.number()
});

type Effort = z.infer<typeof effortSchema>;
export default Effort;

// export default interface Effort {
//   id: number;
//   parentId?: number | null;
//   parent?: Effort | null;

//   title: string;
//   description?: TextEditorType;
//   avatarUrl?: string;
//   level: number;
//   isActive: boolean;
//   url: string;
//   index: number;
//   subTasksCount?: number;
//   dateAdd: string;

//   // parent?: Effort,
//   // subs: Effort[],
//   departmentId?: string;
//   department?: Department;

//   creatorId?: string;
//   creator?: User;

//   prefixes?: Prefix[];
//   prefix?: Prefix;

//   ticketsCount?: TicketCount[];
//   ticketsAvailable?: Ticket[];

//   stateId?: number;
//   state?: State | null;
//   states?: State[];

//   priority?: Priority | null;
//   priorityId?: number;
//   priorities?: Priority[];

//   attachments?: Attachment[];
//   members?: Member[];
//   types?: Type[];
//   tags?: Tag[];
//   comments?: Comment[];

//   timeEstimate?: string;
//   timeMax?: string;
//   timeApproved?: string;
//   timeActual?: string;

//   dateStart?: string;
//   dateDue?: string;
//   dateEnd?: string;

//   effortTicketSum?: number;
//   activityTicketSum?: number;
//   approvedActivityTicketSum: number;
//   activitySum?: TicketCount[];

//   effortActivityRecordsCount?: number;
// }
