import React from "react";

import Dict from "models/Dict";
import { UserRoles } from "models/User";

import { cn, objList2Options } from "services/UtilServices";

import { useOfficesContext } from "providers/OfficesProvider";
import AvatarSection from "components/pages/profile/account/AvatarSection";
import {
  Form,
  Input,
  Select,
  SubmitButton,
  Validation,
} from "components/common/Forms/Form";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useValidation from "components/common/Forms/UseValidation";

function PasswordInput({ editing = false }) {
  const [_editing, _setEditing] = React.useState(editing);
  const validation = useValidation();

  return (
    <div onClick={() => _setEditing(true)}>
      <Input
        name="password"
        type="password"
        minLength={8}
        maxLength={18}
        autoComplete="new-password"
        className={cn({ "pointer-events-none": !_editing })}
        disabled={!_editing}
        placeholder={_editing ? "" : "Click to edit the password"}
        validations={
          _editing
            ? [validation.isRequired(), validation.tooSimplePassword()]
            : []
        }
      />
    </div>
  );
}

export default function UserCreateUpdateModal({
  formData,
  onSubmit,
}: {
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  const _officesContext = useOfficesContext();
  const validation = useValidation();

  return (
    <DialogContent title="Users">
      <Form
        data={
          formData
            ? {
                ...formData,
              }
            : {
                role: UserRoles.USER,
                avatarUrl: null,
                // phone: null,
                // email: null,
                // officeId: null,
              }
        }
        onSubmit={async (d) => {
          const _d = {
            ...d,

            office: _officesContext.offices?.find((e) => e.id === d.officeId),
          };

          return await onSubmit(_d);
        }}
      >
        <AvatarSection />

        <div className="flex gap-2">
          <div className="flex-1">
            <Input
              name="userName"
              maxLength={256}
              required
              autoFocus
              validations={[validation.notSpecificCharsUsed([" "])]}
            />
          </div>
          <div className="flex-1">
            <PasswordInput editing={formData === undefined} />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="flex-1">
            <Select name="role" options={UserRoles.toLabelValues()} required />
          </div>
          <div className="flex-1">
            <Select
              name="officeId"
              label="office"
              required
              options={objList2Options(_officesContext.offices)}
            />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="flex-1">
            <Input name="firstName" required maxLength={256} />
          </div>
          <div className="flex-1">
            <Input name="lastName" required maxLength={256} />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="flex-1">
            <Input name="phone" type="number" />
          </div>
          <div className="flex-1">
            <Input
              name="email"
              type="email"
              placeholder="example@email.com"
              required={formData === undefined}
              maxLength={256}
            />
          </div>
        </div>

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
