import React from "react";

import { useThemeContext } from "providers/ThemeProvider";

import Avatar from "components/common/Avatar";
import FormFieldBase from "components/common/Forms/FormFieldBase";
import { useFormContext } from "components/common/Forms/Form";
import SelectImageButtonStandalone from "components/common/Forms/SelectImageButtonStandalone";
import { FaCamera } from "react-icons/fa6";
import { cn } from "services/UtilServices";
import { ApiVariables } from "hooks/api/UseApiServices";
import { Button } from "components/ui/button";

export default function AvatarSection({ name = "avatarUrl" }) {
  const _formContext = useFormContext();
  const _themeContext = useThemeContext();

  return (
    <FormFieldBase name={name} needLabel={false} needFocus={false}>
      {(baseProps) => (
        <div className="flex justify-center is-invalid">
          <div className="relative">
            <SelectImageButtonStandalone
              onChange={async (files) => {
                _formContext.setData({ [name]: files[0] });
              }}
            >
              <Avatar
                size={150}
                src={
                  !_formContext.data[name]
                    ? _formContext.data[name]
                    : _formContext.data[name] instanceof File
                    ? _formContext.data[name]
                    : (_formContext.data[name]?.startsWith("http")
                        ? ""
                        : ApiVariables.PUBLIC_FILE_URL) +
                      _formContext.data[name]
                }
              />

              <Button
                asChild
                variant={"primary"}
                size={"sm"}
                className="border rounded-full aspect-square absolute bottom-3 right-3 "
              >
                <span>
                  <FaCamera className="!text-base" />
                </span>
              </Button>
            </SelectImageButtonStandalone>
          </div>
        </div>
      )}
    </FormFieldBase>
  );
}
