import { Separator } from "components/ui/separator";
import React from "react";

export default function DividerWithTitle({
  title,
  position = "RIGHT",
}: {
  title?: string;
  position?: "LEFT" | "CENTER" | "RIGHT";
}) {
  return (
    <div className="flex items-center my-2">
      {position !== "RIGHT" && <Separator className="flex-1 me-2" />}

      {title && <h6 className="mb-0 capitalize text-foreground">{title}</h6>}

      {position !== "LEFT" && <Separator className="flex-1 ms-2" />}
    </div>
  );
}
