import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import Tag, { TagFilter, TagSortTypes } from "models/Tag";

import useTagApi from "hooks/api/UseTagApi";
import { listFlatten, listUnique } from "services/UtilServices";

import { MainContext, useMainContext } from "./MainProvider";
import { EffortsContext, useEffortsContext } from "./EffortsProvider";
import {
  CurrentUserContext,
  useCurrentUserContext,
} from "./CurrentUserProvider";
import { useToast } from "components/ui/use-toast";

interface TagsContextProps {
  tags: Tag[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  get: (filter: TagFilter) => Promise<void>;
  getAll: () => Promise<Dict | undefined>;
  filter: TagFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const TagsContext = React.createContext({} as TagsContextProps);
TagsContext.displayName = "TagsContext";

function TagsProvider({ children }: { children: React.ReactNode }) {
  const DEFAULT_FILTER = {
    pageNumber: -1,
    ascOrder: false,
    sortBy: TagSortTypes.ADDWHEN,
  };
  const itemsRef = React.useRef<Tag[] | undefined>(undefined);
  const globalFilterRef = React.useRef<TagFilter>({ ...DEFAULT_FILTER });

  const [_items, _setTags] = React.useState<Tag[]>();
  const [_filter, _setFilter] = React.useState<TagFilter>(
    globalFilterRef.current
  );
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const { toast } = useToast();
  const tagApi = useTagApi();
  const _effortUtils = useEffortUtils();

  const _mainContext = useMainContext();
  const _currentUserContext = useCurrentUserContext();
  const _effortsContext = useEffortsContext();

  React.useEffect(() => {
    setTags(undefined);
    setFilter({ ...DEFAULT_FILTER });
    setHasNext(true);
    setTotalNumber(undefined);
    return () => {
      setTags(undefined);
      setFilter({ ...DEFAULT_FILTER });
      setHasNext(true);
      setTotalNumber(undefined);
    };
  }, []);

  const setTags = (o?: Tag[]) => {
    itemsRef.current = o;
    _setTags(o);
  };

  const setFilter = (o: TagFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const getAll = async () => {
    await get({
      ..._filter,
      pageNumber: 0,
      numberInPage: 999,
    });
  };

  const get = async (filter: TagFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setTags(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setTags(undefined);
      setTotalNumber(undefined);
      return;
    }

    try {
      let { items, totalNumber } = await tagApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setTags([...(itemsRef.current ?? []), ...(items as Tag[])]);
    } catch (e) {
      toast((e as AxiosError).message, {
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setTags([]);
      }
    }
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData = await tagApi.create(formData);

      setTags([formData as Tag, ...(itemsRef.current ?? [])]);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      await tagApi.update(formData);

      setTags(
        itemsRef.current?.map((e) =>
          e.id !== formData.id
            ? e
            : ({
                ...formData,
              } as Tag)
        )
      );

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) => ({
          ...eachEffort,
          tags: eachEffort.tags?.map((e) =>
            e.id !== formData.id ? e : (formData as Tag)
          ),
        }))
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Tag[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await tagApi.remove(ids);

      setTags(itemsRef.current?.filter((e) => !ids.includes(e.id)));
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <TagsContext.Provider
      value={
        {
          tags: itemsRef.current,
          create,
          update,
          removeMany,

          get,
          getAll,
          filter: _filter,
          hasNext,
          totalNumber,
        } as TagsContextProps
      }
    >
      {children}
    </TagsContext.Provider>
  );
}

export function useTagsContext() {
  const _context = React.useContext(TagsContext);

  if (!_context) {
    throw new Error("cannot use TagsContext outside of its provider.");
  }

  return _context;
}

export { TagsContext, TagsProvider };
export type { TagsContextProps };
