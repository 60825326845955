import React from "react";

import Dict from "models/Dict";
import { TicketFilter } from "models/Ticket";

import { Form, Input, SubmitButton } from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useTicketUtils from "hooks/utils/UseTicketUtils";

export default function TicketFilterModal({
  filter,
  onSubmit,
}: {
  filter: TicketFilter;
  onSubmit: (data: Dict) => void;
}) {
  const ticketUtils = useTicketUtils();

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        <Input name="name" label="Ticket Name" maxLength={256} />

        <DatePickerInputRange name="dateAdd" label="Date Add" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={ticketUtils.isFilterActive({ filter })}
            onClick={() => onSubmit({ pageNumber: -1 })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
