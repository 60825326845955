import React from "react";

import Dict from "models/Dict";
import { PrefixFilter } from "models/Prefix";

import { Form, Input, SubmitButton } from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import usePrefixUtils from "hooks/utils/UsePrefixUtils";

export default function PrefixFilterModal({
  filter,
  onSubmit,
}: {
  filter: PrefixFilter;
  onSubmit: (data: Dict) => void;
}) {
  const prefixUtils = usePrefixUtils();

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        <Input name="name" label="Prefix Name" maxLength={256} />

        <DatePickerInputRange name="dateAdd" label="Date Add" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={prefixUtils.isFilterActive({ filter })}
            onClick={() => onSubmit({ pageNumber: -1 })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
