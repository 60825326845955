import React from "react";

import Effort from "models/Effort";

import useSingleFieldUpdate from "components/pages/EffortsSection/SingleFieldUpdateButton";
import { EffortSingleFieldUpdateModalFieldNameType } from "components/pages/EffortsSection/EffortSingleFieldUpdateModal";

import { LuPencil } from "react-icons/lu";
import { cn } from "services/UtilServices";
import { Button } from "components/ui/button";
import { useEffortAuthUtils } from "hooks/utils/UseEffortUtils";

export default function SectionBase({
  effort,
  title,
  fieldName,
  children,
  wrapperClassName,
  editable,
  onClick,
  icon,
}: {
  effort: Effort;
  title?: string;
  children?: React.ReactNode;
  fieldName?: EffortSingleFieldUpdateModalFieldNameType;
  wrapperClassName?: string;
  editable?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
}) {
  const singleFieldUpdate = useSingleFieldUpdate();
  const _effortAuthUtils = useEffortAuthUtils();

  if (editable === undefined) {
    editable = _effortAuthUtils.canEdit([effort]);
  }

  return (
    <div className={cn("", wrapperClassName)}>
      <Button
        asChild
        variant="light"
        onClick={
          editable && fieldName
            ? (ev) =>
                singleFieldUpdate.onClick({
                  effort,
                  fieldName,
                  ev,
                })
            : onClick
        }
        className={cn(
          "border p-3 w-full bg-card disabled:opacity-100 block",
          editable ? "group" : "pointer-events-none"
        )}
        // className={cn(
        //   "bg-card text-foreground border rounded p-3 h-full min-h-[100px] ",
        //   {
        //     "hover:bg-light  hover:text-dark cursor-pointer group": editable,
        //   }
        // )}
        disabled={!editable}
      >
        <div>
          <div className="flex items-center">
            <h6 className="mb-0 capitalize">{title ?? fieldName}</h6>

            {editable && (
              <div className="ms-2 invisible group-hover:visible">
                {icon ?? <LuPencil className="!text-base" />}
              </div>
            )}
          </div>

          <div className="flex flex-wrap mt-2 gap-1">{children}</div>
        </div>
      </Button>
    </div>
  );
}
