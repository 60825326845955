import React from "react";
import { ReactSortable, Sortable } from "react-sortablejs";

import Effort from "models/Effort";

import { cn, isEmpty } from "services/UtilServices";
import {
  ItemType,
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";
import { useMainContext } from "providers/MainProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import Dict from "models/Dict";
import EffortCreateUpdateModal from "../EffortCreateUpdateModal";
import { useModalContext } from "providers/ModalProvider";
import { EffortGroupType, useEffortUtils } from "hooks/utils/UseEffortUtils";
import Directionality from "components/common/Directionality";
import { TrelloSectionTypeItemType } from "../GroupBySelectSection";
import { TableBody, TableCell, TableHead, TableRow } from "components/ui/table";
import {
  ItemsListTableHeaderCell,
  ItemsListTableRow,
  useItemsListTableSectionContext,
} from "components/common/ItemsListSection/ItemsListTableSection";
import { useLinkUtils } from "components/common/Link";

export default function EachTableSection({
  col,
  index,
  basedOnType,
  onDragEnd,
  onDragStart,
  onCardRemove,
  disabled,
  colActions,
  colClick,
}: {
  col: EffortGroupType;
  index: number;
  disabled?: boolean;
  basedOnType: TrelloSectionTypeItemType;
  onDragEnd: (ev: Sortable.SortableEvent) => void;
  onDragStart: (ev: Sortable.SortableEvent) => void;
  onCardRemove: (e: Effort) => void;
  colActions?: (col: EffortGroupType) => ItemType[];
  colClick?: (col: EffortGroupType) => void;
}) {
  const { data, onItemDelete, onItemEdit } = useItemsListContext();
  const _mainContext = useMainContext();
  const _modalContext = useModalContext();
  const _effortsContext = useEffortsContext();
  const _effortUtils = useEffortUtils();

  const _isHome = _effortsContext.currentEffort === undefined;
  const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
  const _isTask = !_isHome && !_isProject;
  const { sortableFields } = useItemsListTableSectionContext();

  const _colEfforts = _effortsContext.efforts?.filter((e) =>
    col.effortIds?.includes(e.id)
  );

  const colData = data?.filter((e) =>
    _colEfforts?.some((a) => a.url === e.url)
  );

  const _effort =
    basedOnType.label === "effort"
      ? _mainContext.efforts?.find((e) => e.id === parseInt(col.id))
      : undefined;

  const onSubmit = async (data: Dict) => {
    let _errors = await _effortsContext.create(data);

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    // title: _isHome
    //   ? "Project Information"
    //   : _isProject
    //   ? "Task Information"
    //   : "SubTask Information",
    _modalContext.open(
      <EffortCreateUpdateModal onSubmit={onSubmit} parentId={_effort!.id} />
    );
  };

  return (
    <TableBody
      asDiv
      className={cn("  relative ", {
        "opacity-25": disabled,
      })}
    >
      {/* <ReactSortable
      id={col.id + ""}
      group={"Col"}
      ghostClass="opacity-25" //invisible
      setList={() => {}}
      onEnd={onDragEnd}
      onStart={onDragStart}
      list={col.effortIds?.map((e) => ({ id: e })) ?? []}
      disabled={disabled || basedOnType.draggable === false}
      draggable=".draggable"
      // style={{
      //   maxHeight: `calc(100vh - ${ref.current?.offsetTop}px - 30px)`,
      // }}
      className={cn("table-row-group min-h-[300px] relative", {
        "opacity-25": disabled,
      })}
    > */}
      <TableRow asDiv className="sticky -top-1 z-[3] border-0">
        <TableCell asDiv className="border-0 p-0 ps-1.5 bg-muted" />

        <TableHead
          asDiv
          colSpan={2}
          className="sticky -left-1 z-[3] bg-muted border-0  "
        >
          <Directionality
            className={cn(
              "block capitalize truncate text-foreground font-bold text-lg cursor-grab section-handle"
            )}
          >
            {col.title}
          </Directionality>
        </TableHead>

        {!isEmpty(col.effortIds) &&
          sortableFields!
            .filter(
              (e) =>
                (e.isActive === true || e.isActive === undefined) &&
                !e.isTitleField
            )
            .map((eachField, eachFieldIndex) => (
              <ItemsListTableHeaderCell
                asDiv
                key={"sortCol" + eachFieldIndex}
                eachField={eachField}
                className=" border-0"
              />
            ))}
      </TableRow>

      {colData?.map((e, i) => (
        <EachRowItem key={"eachItem" + e.id} item={e} index={i} col={col} />
      ))}

      <TableRow asDiv className="border-0">
        <TableCell asDiv className="border-0 p-0 pt-10   ps-1.5" />
      </TableRow>
      {/* </ReactSortable> */}
    </TableBody>
  );
}
function EachRowItem({
  item,
  index,
  col,
}: {
  item: any;
  index: number;
  col: EffortGroupType;
}) {
  const _effortUtils = useEffortUtils();
  const linkUtils = useLinkUtils();

  const _isMatch = linkUtils.isMatch(_effortUtils.makeUrl(item.id)!);

  return (
    <ItemsListTableRow
      asDiv
      id={item.id + ""}
      eachItem={item} //_effortsContext.efforts?.find((e) => e.id === eachItem)
      eachItemIndex={index}
      className="draggable"
      preppend={
        <TableCell
          asDiv
          className="sticky -left-1 z-[2] rounded-s-md rounded-bl-md p-0 ps-1.5 border-0"
          style={{
            backgroundColor: _isMatch ? "white" : col.color,
          }}
        >
          {/* {_isMatch && (
            <div
              className={cn(
                "absolute left-0 inset-0 w-full pointer-events-none",
                "border-2 border-foreground border-e-0 rounded-s"
              )}
            ></div>
          )} */}
        </TableCell>
      }
    />
  );
}
