import { useActivitysContext } from "providers/ActivitysProvider";
import { ChartProvider } from "providers/ChartProvider";
import ChartItem from "components/pages/chart/ChartItem";
import { ChartTypes } from "models/Chart";
import { dateAdd, dateGetOnlyDate } from "services/UtilServices";

export function ActivityChartSection() {
  const { filter } = useActivitysContext();

  return (
    <ChartProvider>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        <ChartItem
          chart={{
            title: "Last 7 days - Activity Hours Per Day",
            type: ChartTypes.HORIZONTAL_STACKED,
            valueBy: "Type",
            // stackBy: "Type",
            labelBy: "DateDone", //DateDone
          }}
          initFilter={{
            ...filter,
            dateDoneTo: dateGetOnlyDate(new Date()),
            dateDoneFrom: dateGetOnlyDate(
              dateAdd({ date: new Date(), days: -7 })
            ),
          }}
        />

        {/* <ChartItem
          chart={{
            title: "Last 30 days - Activity Hours Per Day",
            type: ChartTypes.HORIZONTAL_STACKED,
            valueBy: "Type",
            // stackBy: "Type",
            labelBy: "DateDone", //DateDone
          }}
          initFilter={{
            ...filter,
            dateDoneTo: dateGetOnlyDate(new Date()),
            dateDoneFrom: dateGetOnlyDate(
              dateAdd({ date: new Date(), days: -30 })
            ),
          }}
        /> */}

        <ChartItem
          chart={{
            title: "Last 30 days - Percentage of working hours by Types",
            type: ChartTypes.PIE,
            valueBy: "User",
            // stackBy: "Type",
            labelBy: "Type", //DateDone
          }}
          initFilter={{
            ...filter,
            dateDoneTo: dateGetOnlyDate(new Date()),
            dateDoneFrom: dateGetOnlyDate(
              dateAdd({ date: new Date(), days: -30 })
            ),
          }}
        />
      </div>
    </ChartProvider>
  );
}
