import React from "react";
// import { Clipboard } from '@capacitor/clipboard';

import Comment from "models/Comment";
import { useCommentAuthUtils } from "hooks/utils/UseCommentUtils";
import Dict from "models/Dict";

import { cn, dateGetTime, isEmpty } from "services/UtilServices";
import useClipboard from "components/common/UseClipboard";

import { useModalContext } from "providers/ModalProvider";

import {
  useCommentsContext,
  CommentsProvider,
} from "providers/CommentsProvider";

import Skeleton from "components/common/Skeleton";
import NewButton from "components/common/AdminSection/NewButton";
import CommentCreateUpdateModal from "./CommentCreateUpdateModal";
import { CollapsableTextEditorValue } from "components/common/Forms/TextEditor";
import LocalDateTime from "components/common/LocalDateTime";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import OnLoadMoreIndicator from "components/common/OnLoadMoreIndicator";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import ItemsListToolsSection, {
  ItemsListContextMenu,
} from "components/common/ItemsListSection/ItemsListToolsSection";
import ItemListSearchbarSection from "components/common/ItemsListSection/ItemsListSearchbarSection";
import ItemsListProvider, {
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";

import { Button } from "components/ui/button";
import { BsReply } from "react-icons/bs";
import { LuShare2 } from "react-icons/lu";
import { useEffortsContext } from "providers/EffortsProvider";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

function EachCommentItemBody({
  comment,
  index,
}: {
  comment: Comment;
  index: number;
}) {
  const {
    itemClassName,
    selecteds,
    isSelected,
    onItemSelect,
    toggleSelecteds,
    onItemClick,
  } = useItemsListContext();

  return (
    <ItemsListContextMenu asChild item={comment}>
      <Button
        asChild
        variant={isSelected(comment) ? "primary" : "light"}
        className={cn(
          "group relative mb-1 p-2 block break-words min-h-[50px]",
          itemClassName?.(comment.id, index),
          isSelected(comment) ? " " : "border bg-card"
        )}
      >
        <div
          onClick={() => {
            if (isSelected(comment)) {
              toggleSelecteds(comment);
              onItemSelect?.(comment)?.(comment);
            } else if (selecteds.length > 0) {
              toggleSelecteds(comment);
              onItemSelect?.(comment)?.(comment);
            } else {
              onItemClick?.(comment)?.(comment);
            }
          }}
        >
          <div className="flex items-center gap-1 w-full">
            {comment.parentId && <BsReply className="!font-base" />}

            {comment.user && (
              <>
                <span className="text-xs ">
                  <ProfileAvatar size={30} user={comment.user} needName />
                </span>
              </>
            )}

            <div className="flex-1"></div>
            <span className="mb-0 text-justify text-xs">
              <LocalDateTime date={comment.dateAdd} />
            </span>

            <ItemsListToolsSection
              eachItem={comment}
              ellipsisResponsiveBreakPoint={"always"}
              btnTextResponsiveBreakPoint={"always"}
            />
          </div>

          <CollapsableTextEditorValue
            value={comment.content}
            className="mt-3"
          />
        </div>
      </Button>
    </ItemsListContextMenu>
  );
}

function EachCommentItem({
  comment,
  index,
}: {
  comment: Comment;
  index: number;
}) {
  const { data } = useItemsListContext();

  return (
    <div>
      <EachCommentItemBody index={index} comment={comment} />

      <div className="ms-2 md:ms-3">
        {data
          ?.filter((e) => e.parentId === comment.id)
          .map((e, i) => (
            <EachCommentItem key={"eachComment" + e.id} index={i} comment={e} />
          ))}
      </div>
    </div>
  );
}

function ItemsListSectionBody() {
  const _itemsListContext = useItemsListContext();
  const { data, title, onLoadMore } = _itemsListContext;

  return (
    <div>
      <ItemListSearchbarSection
        tableModeBreakPoint={"never"}
        {..._itemsListContext}
      />

      <div className="pt-2">
        {data
          ?.filter((e) => !e.parentId)
          .map((e, i) => (
            <EachCommentItem key={"eachComment" + e.id} index={i} comment={e} />
          ))}

        {onLoadMore && (
          <OnLoadMoreIndicator onLoadMore={onLoadMore}>
            <Skeleton count={5} className="w-full mb-1 h-24" />
          </OnLoadMoreIndicator>
        )}

        {!onLoadMore && data?.length === 0 && (
          <EmptyListIndicator text={"No " + title + " Found!"} />
        )}
      </div>
    </div>
  );
}

function CommentsPageBody() {
  const _modalContext = useModalContext();
  const _effortsContext = useEffortsContext();
  const _commentsContext = useCommentsContext();
  const _commentAuthUtils = useCommentAuthUtils(
    _effortsContext.currentEffort?.id
  );
  const _effortUtils = useEffortUtils();
  const clipboard = useClipboard();

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _commentsContext.update(data)
        : await _commentsContext.create(data);

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <CommentCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  return (
    <div className="">
      {!_effortsContext.currentEffort?.creator ? (
        <Skeleton count={5} className="w-full mb-1 h-24" />
      ) : (
        <ItemsListProvider<Comment>
          title="Comments"
          data={_commentsContext.comments ?? []}
          fields={[
            {
              value: "",
              label: "#",
              className: "text-start whitespace-nowrap",
              width: "70px",
              isSortable: false,
              getUiContent: (e, i) => i + 1,
            },

            {
              value: "content",
              label: "content",
              width: "auto",
              isSortable: false,
              getValue: (e) => {
                var parser = new DOMParser();
                const doc = parser.parseFromString(
                  e.content.content ?? "",
                  "text/html"
                );
                return doc.documentElement.innerText;
              },
            },

            {
              value: "dateAdd",
              label: "Created on",
              width: "auto",
              isSortable: false,
              getValue: (e) => dateGetTime(e.dateAdd),
              getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
            },
          ]}
          // onLoadMore={_eachEffortContext.isLoading ? async () => {} : undefined}
          // onSortField={(v) => {
          //   _commentsContext.get({
          //     ..._commentsContext.filter,
          //     sortBy: v as CommentSortType,
          //     pageNumber: -1,
          //   });
          // }}
          // onSortDir={(v) => {
          //   _commentsContext.get({
          //     ..._commentsContext.filter,
          //     ascOrder: v === 1,
          //     pageNumber: -1,
          //   });
          // }}

          initialSelectedSortFieldValue="dateAdd"
          onItemEdit={(item) =>
            _commentAuthUtils.canEdit(item) ? openCreateUpdateModal : undefined
          }
          onItemDelete={(items) =>
            _commentAuthUtils.canDelete(items)
              ? _commentsContext.removeMany
              : undefined
          }
          getItemActions={(item) => [
            ...(_commentAuthUtils.canCreate()
              ? [
                  {
                    icon: <BsReply />,
                    text: "Reply",
                    onClick: () => openCreateUpdateModal({ parentId: item.id }),
                  },
                ]
              : []),
            {
              text: "Copy Link",
              icon: <LuShare2 />,
              onClick: () =>
                clipboard.write(
                  encodeURI(
                    window.location.origin +
                      _effortUtils.makeUrl(_effortsContext.currentEffort?.id) +
                      "#" +
                      item.id
                  )
                ),
            },
          ]}
          actionsSection={
            <>
              {_commentAuthUtils.canCreate() && (
                <NewButton
                  needText={false}
                  onClick={() => openCreateUpdateModal()}
                />
              )}
            </>
          }
        >
          <ItemsListSectionBody />
        </ItemsListProvider>
      )}
    </div>
  );
}

export default function CommentsPage({ effortId }: { effortId: number }) {
  return (
    <CommentsProvider effortId={effortId}>
      <CommentsPageBody />
    </CommentsProvider>
  );
}
