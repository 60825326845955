import React from "react";

import Dict from "models/Dict";
import State from "models/State";
import Tag from "models/Tag";
import Type from "models/Type";

import { useModalContext } from "providers/ModalProvider";

import {
  Switch,
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import { useTagsContext } from "providers/TagsProvider";
import { useTypesContext } from "providers/TypesProvider";
import { useStatesContext } from "providers/StatesProvider";
import { objList2Options } from "services/UtilServices";
import { DialogContent, DialogFooter } from "components/ui/dialog";

export default function DepartmentCreateUpdateModal({
  formData,
  onSubmit,
}: {
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  const _modalContext = useModalContext();
  const _tagsContext = useTagsContext();
  const _typesContext = useTypesContext();
  const _statesContext = useStatesContext();

  return (
    <DialogContent title="Departments">
      <Form
        data={
          formData !== undefined && "id" in formData
            ? {
              ...formData,
              tagList: formData.tags.map((e: Tag) => e.id),
              typeList: formData.types.map((e: Type) => e.id),
              stateList: formData.states.map((e: State) => e.id),
            }
            : {
              tagList: [],
              typeList: [],
              stateList: [],
            }
        }
        onSubmit={async (d) => {
          d.tags =
            _tagsContext.tags?.filter((e) => d.tagList.includes(e.id)) ?? [];
          d.types =
            _typesContext.types?.filter((e) => d.typeList.includes(e.id)) ?? [];
          d.states =
            _statesContext.states?.filter((e) => d.stateList.includes(e.id)) ??
            [];

          return await onSubmit(d);
        }}
      >
        <Input name="name" label="Name" required maxLength={256} autoFocus />

        <Select
          name="tagList"
          label="Tags"
          multiple
          options={objList2Options(_tagsContext.tags)}
        />

        <Select
          name="typeList"
          label="Types"
          multiple
          options={objList2Options(_typesContext.types)}
        />

        <Select
          name="stateList"
          label="States"
          multiple
          options={objList2Options(_statesContext.states)}
        />

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent >
  );
}
