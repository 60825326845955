import { OfficeFilter } from "models/Office";
import { hasOtherKeysExcept } from "services/UtilServices";




export default function useOfficeUtils() {
  const isFilterActive = ({ filter }: { filter: OfficeFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage"]);
  }

  return {
    isFilterActive
  }
}
