import React from "react";
import AppLogo from "../AppLogo";
import { cn } from "services/UtilServices";

export default function LoginSection({
  children,
  className,
  back,
}: {
  children: React.ReactNode;
  className?: string;
  back?: boolean;
}) {
  return (
    <div
      className={cn(
        "flex flex-col relative justify-center items-center p-8 text-foreground",
        "dark:bg-slate-800 bg-slate-300 h-100 lg:rounded-s-0 rounded-md",
        "aspect-golden-v ",
        className
      )}
    >
      {/* <div className="absolute left-0 top-0 m-4">
        <Button variant="faded">
          <LuArrowLeft /> back to Login Page
        </Button>
      </div> */}
      <div className="flex flex-col items-center gap-2">
        <AppLogo width={200} />
      </div>

      <div className="rounded-md my-4 relative ">{children}</div>

      <div className="flex items-center justify-center w-full">
        <div className="text-sm text-foreground">
          Designed by{" "}
          <a
            target="_blank"
            href="https://perxact.de/"
            className=" text-blue-500 underline"
          >
            PerXact
          </a>
        </div>
      </div>
    </div>
  );
}
