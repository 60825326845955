import { LuEye, LuPencil, LuTrash2 } from "react-icons/lu";
import Dict from "./Dict";
import User, { userSchema } from "./User";
import { FaPlus } from "react-icons/fa6";
import Effort from "./Effort";
import { z } from "zod";
import { VscEye } from "react-icons/vsc";

export type MemberSortType =
  | "USERID"
  | "EFFORTID"
  | "ACCESSTYPE"
  | "MEMBERTYPE"
  | "CREATEDON"
  | "WEIGHT";

export const MemberSortTypes: { [key: string]: MemberSortType } = {
  USERID: "USERID",
  EFFORTID: "EFFORTID",
  ACCESSTYPE: "ACCESSTYPE",
  MEMBERTYPE: "MEMBERTYPE",
  CREATEDON: "CREATEDON",
  WEIGHT: "WEIGHT",
};

export class MemberAccessTypes {
  static Read = 0;
  static Create = 1;
  static Edit = 2;
  static Delete = 3;

  static options: Dict = {
    0: "Read",
    1: "Create",
    2: "Edit",
    3: "Delete",
  };

  static icons: Dict = {
    0: VscEye,
    1: FaPlus,
    2: LuPencil,
    3: LuTrash2,
  };

  static descriptions: Dict = {
    0: "User has read-only access to associated projects, their tasks, and their subtasks, and can filter and export them as well as share comments and filter activity logs.",
    1: "User cannot alter or eliminate projects. Under time restrictions, the user can delete his own tasks.The comments and tasks created by other users, as well as their attachments, cannot be deleted or updated by the user. User has a finite amount of time to update or delete his own comments.",
    2: "Projects cannot be deleted by the user. User cannot delete the tasks and comments of another user. Under time restrictions, user can remove their own comments.",
    3: "User cannot delete the projects",
  };

  static toLabelValues = () =>
    Object.entries(MemberAccessTypes.options).map(([key, val]) => ({
      value: parseInt(key),
      label: val,
      description: MemberAccessTypes.descriptions[parseInt(key)],
    }));
}

export class MemberTypes {
  static Client = 0;
  static User = 1;

  static options: Dict = {
    0: "Client",
    1: "User",
  };

  static descriptions: Dict = {
    0: "",
    1: "",
  };

  static toLabelValues = () =>
    Object.entries(MemberTypes.options).map(([key, val]) => ({
      value: parseInt(key),
      label: val,
      description: MemberTypes.descriptions[parseInt(key)],
    }));
}

export interface MemberFilter {
  pageNumber: number;
  sortBy: MemberSortType;
  ascOrder: boolean;

  userId?: string;
  effortId?: string;
  accessType?: number;
  memberType?: number;
  createdON?: string;
  weight?: string;
}

export const memberSchema = z.object({
  id: z.string(),
  userId: z.string(),
  effortId: z.number(),
  accessType: z.number(),
  memberType: z.number(),
  createdON: z.string(),
  isAssign: z.string(),
  isDeleted: z.boolean(),
  weight: z.string(),
  effortUpdate: z.boolean(),
  commentUpdate: z.boolean(),

  user: userSchema,
  dateAdd: z.string(),
});

type Member = z.infer<typeof memberSchema>;
export default Member;

// export default interface Member {
//   id: string;
//   userId: string;
//   effortId: number;
//   accessType: number;
//   memberType: number;
//   createdON: string;
//   isAssign: string;
//   isDeleted: boolean;
//   weight: string;
//   effortUpdate: boolean;
//   commentUpdate: boolean;

//   user: User;
//   dateAdd: string;
// }
