import React from "react";
import { Outlet, useNavigate } from "react-router";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import AuditLogSection from "components/pages/AuditLogSection/AuditLogSection";
import ActivityReportsSection from "components/pages/report/Activities/ActivityReportsSection";

export function useReportPageSection() {
  const _currentUserContext = useCurrentUserContext();
  // const _isAdmin = _currentUserContext.user?.role === UserRoles.ADMIN;

  return [
    {
      url: "activities",
      text: "Activities",
      content: <ActivityReportsSection />,
    },

    {
      url: "efforts",
      text: "Efforts",
      content: <AuditLogSection pageType="admin" />,
    },
  ];
}

export default function ReportPage() {
  const _currentUserContext = useCurrentUserContext();
  const navigate = useNavigate();

  // if (!_currentUserContext.isSudo()) {
  //   navigate("/403");
  //   return null;
  // }

  return (
    <>
      {/* <MainHeaderBody></MainHeaderBody>
      <MainSection header={null}>
        <TabItems baseUrl="/admin/" items={SECTIONS} />
        <TabContents activeUrl={params.index} items={SECTIONS} />
      </MainSection> */}
      <Outlet />
    </>
  );
}
