import { DialogContent, DialogFooter } from "components/ui/dialog";
import Effort from "models/Effort";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import {
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import useValidation from "components/common/Forms/UseValidation";
import TicketCount from "models/TicketCount";
import TextEditor from "components/common/Forms/TextEditor";
import { objList2Options } from "services/UtilServices";
import DatePickerInput from "components/common/Forms/DatePickerInput";
import Dict from "models/Dict";
import { useEffortViewUtils } from "hooks/utils/UseEffortUtils";
import TicketsCountInputField from "./TicketsCountInputField";
import {
  EffortResource,
  useEffortResources,
} from "hooks/utils/UseEffortResources";
import React from "react";

// export const EFFORT_SETTING_MODAL_TABS = [
//   {
//     index: "",
//     text: "Info",
//     content: InfoSection,
//   },
//   {
//     index: "Available Lists",
//     text: "Available Lists",
//     content: AvailableListsSection,
//   },
// ];

function InfoSection({
  effort,
  resourceData,
}: {
  effort: Effort;
  resourceData: EffortResource;
}) {
  const { states, ticketsAvailable, prefixes, priorities, departments } =
    resourceData;
  const validation = useValidation();

  return (
    <div>
      <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
        <Input
          name="title"
          required
          autoFocus
          validations={[
            validation.notSpecificCharsUsed([
              "\\",
              "/",
              ":",
              "*",
              '"',
              "<",
              ">",
              "|",
              "?",
              "=",
              "#",
              "'",
            ]),
          ]}
        />
        <Select
          name="stateId"
          label="State"
          required
          options={objList2Options(states)}
        />

        {/* <Switch name="isActive" label="Active" /> */}

        <Select
          name="priorityId"
          label="Priority"
          required
          options={objList2Options(priorities)}
        />

        <DatePickerInput
          name="dateDue"
          label="Deadline"
          // fromDate={effort ? undefined : dateGetToday()}
        />

        {effort.parentId && (
          <>
            <TicketsCountInputField
              name="ticketsCount"
              label="Tickets Count"
              tickets={ticketsAvailable}
            />

            <Select
              name="prefixId"
              label="Prefix"
              options={objList2Options(prefixes)}
            />
          </>
        )}
      </div>

      {!effort.parentId && (
        <Select
          name="departmentId"
          label="Department"
          required
          options={objList2Options(departments)}
        />
      )}

      <TextEditor name="description" label="Description" isAdvance />

      <DialogFooter>
        <SubmitButton />
      </DialogFooter>
    </div>
  );
}

function AvailableListsSection({
  effort,
  resourceData,
}: {
  effort: Effort;
  resourceData: EffortResource;
}) {
  const { tags, types, states, ticketsAvailable, prefixes, priorities } =
    resourceData;

  return (
    <div>
      <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
        <Select
          name="tagList"
          label="tags"
          multiple
          options={objList2Options(tags)}
        />

        <Select
          name="typeList"
          label="types"
          multiple
          options={objList2Options(types)}
        />

        <Select
          name="stateList"
          label="states List"
          multiple
          options={objList2Options(states)}
        />

        <Select
          name="prefixList"
          label="prefixes"
          multiple
          options={objList2Options(prefixes)}
        />

        <Select
          name="ticketsAvailable"
          label="ticket types"
          multiple
          options={objList2Options(ticketsAvailable)}
        />

        <Select
          name="priorities"
          label="priority types"
          multiple
          options={objList2Options(priorities)}
        />
      </div>

      <DialogFooter>
        <SubmitButton />
      </DialogFooter>
    </div>
  );
}

function Title({
  effort,
  onArchiveClick,
}: {
  effort: Effort;
  onArchiveClick?: () => void;
}) {
  return (
    <div className="flex items-center gap-2 flex-1">
      <span>
        <b>Setting</b> "{effort.title}"
      </span>

      {/* <Button variant={"light"}>
        <LuBell />
      </Button> */}

      {/* <Button variant={"primary"} onClick={onArchiveClick}>
        Archive
      </Button> */}
    </div>
  );
}

export default function EffortSettingModal({
  effort,
  initTab = "",
  onSubmit,
}: {
  effort: Effort;
  initTab?: string;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  const _effortViewUtils = useEffortViewUtils();
  const { resourceData, getResources } = useEffortResources();

  const {
    tags,
    types,
    states,
    ticketsAvailable,
    prefixes,
    priorities,
    departments,
  } = resourceData;

  React.useEffect(() => {
    getResources({
      effortId: effort.parentId,
      fieldNames: [
        "tags",
        "types",
        "states",
        "prefixes",
        "ticketsAvailable",
        "priorities",
        "departments",
      ],
    });
  }, []);

  const postProcess = (d: Dict) => ({
    ...d,

    tags: tags?.filter((e) => d.tagList?.includes(e.id)) ?? [],
    types: types?.filter((e) => d.typeList?.includes(e.id)) ?? [],
    states: states?.filter((e) => d.stateList?.includes(e.id)) ?? [],
    prefixes: prefixes?.filter((e) => d.prefixList?.includes(e.id)) ?? [],
    priorities: priorities?.filter((e) => d.priorities?.includes(e.id)) ?? [],
    ticketsAvailable:
      ticketsAvailable?.filter((e) => d.ticketsAvailable?.includes(e.id)) ?? [],
    ticketsCount:
      d.ticketsCount?.map((eachCount: TicketCount) => ({
        ...eachCount,
        ticket: ticketsAvailable?.find((a) => a.id === eachCount.ticketId),
      })) ?? [],

    department: departments?.find((e) => e.id === d.departmentId),
    state: states?.find((e) => e.id === d.stateId),
    priority: priorities?.find((e) => e.id === d.priorityId),
    prefix: prefixes?.find((e) => e.id === d.prefixId),
  });

  return (
    <DialogContent
      size="lg"
      className="lg:aspect-golden-h"
      title={
        <Title
          effort={effort}
          onArchiveClick={async () =>
            await onSubmit(
              postProcess({
                ..._effortViewUtils.preproccessFormData(effort),
                isActive: !effort.isActive,
              })
            )
          }
        />
      }
    >
      <Form
        data={_effortViewUtils.preproccessFormData(effort)}
        onSubmit={async (d) => await onSubmit(postProcess(d))}
      >
        <Tabs defaultValue={initTab}>
          <TabsList className="rounded sticky top-0 mb-4">
            <TabsTrigger value={""}>Info</TabsTrigger>
            <TabsTrigger value={"Available Lists"}>Available Lists</TabsTrigger>

            {/* {EFFORT_SETTING_MODAL_TABS.map((eachTab) => (
              <TabsTrigger
                key={"eachTab" + eachTab.index}
                value={eachTab.index}
              >
                {eachTab.text}
              </TabsTrigger>
            ))} */}
          </TabsList>

          {/* <div className="font-bold text-2xl">Me</div> */}

          <TabsContent value={""}>
            <InfoSection effort={effort} resourceData={resourceData} />
          </TabsContent>

          <TabsContent value={"Available Lists"}>
            <AvailableListsSection
              effort={effort}
              resourceData={resourceData}
            />
          </TabsContent>

          {/* {EFFORT_SETTING_MODAL_TABS.map((eachTab) => (
            <TabsContent key={"eachTab" + eachTab.index} value={eachTab.index}>
              <eachTab.content effort={effort} />
            </TabsContent>
          ))} */}
        </Tabs>
      </Form>
    </DialogContent>
  );
}
