import React from "react";

import Effort from "models/Effort";

import { useItemsListContext } from "components/common/ItemsListSection/ItemsListProvider";
import { isEmpty, listFirst } from "services/UtilServices";
import BoardSection from "./BoardSection";
import { useEffortsContext } from "providers/EffortsProvider";
import { useMembersContext } from "providers/MembersProvider";
import { useMainContext } from "providers/MainProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { useNavigate } from "react-router";
import { useToast } from "components/ui/use-toast";
import {
  useEffortUtils,
  useEffortAuthUtils,
  useEffortViewUtils,
  EffortGroupType,
} from "hooks/utils/UseEffortUtils";
import Dict from "models/Dict";
import { TRELLO_SECTION_TYPES } from "../GroupBySelectSection";
import EachCardItem from "./EachCardItem";
import ItemsListTableSection from "components/common/ItemsListSection/ItemsListTableSection";
import TablesSection from "./TablesSection";
import { useEffortResources } from "hooks/utils/UseEffortResources";

export default function EffortsListTrelloSection({
  effortId,
}: {
  effortId?: number;
}) {
  const _effortsContext = useEffortsContext();
  const _mainContext = useMainContext();
  const _itemsListContext = useItemsListContext();
  const _currentUserContext = useCurrentUserContext();
  const _effortUtils = useEffortUtils();
  const _effortViewUtils = useEffortViewUtils();
  const _effortAuthUtils = useEffortAuthUtils();

  const _membersContext = useMembersContext();

  const { toast } = useToast();

  const _efforts = _itemsListContext.data as Effort[];
  const _isHome = _effortsContext.currentEffort === undefined;
  const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
  const _isTask = !_isHome && !_isProject;

  const navigate = useNavigate();
  const { resourceData, getResources } = useEffortResources();

  var cols = _effortViewUtils.getItemsGroupBy({
    efforts: _efforts,
    resourceData,
  });

  React.useEffect(() => {
    if (_effortsContext.groupBy === "state") {
      getResources({
        effortId,
        fieldNames: ["states"],
      });
    } else if (_effortsContext.groupBy === "prefix") {
      getResources({
        effortId,
        fieldNames: ["prefixes"],
      });
    } else if (_effortsContext.groupBy === "user") {
      getResources({
        effortId,
        fieldNames: ["users"],
      });
    } else if (_effortsContext.groupBy === "priority") {
      getResources({
        effortId,
        fieldNames: ["priorities"],
      });
    }
  }, [_effortsContext.groupBy, effortId]);

  const onUpdate = async ({
    effortId,
    desColId,
    srcColId,
    isCloning,
  }: {
    effortId: number;
    desColId: string | null;
    srcColId: string;
    isCloning?: boolean;
  }) => {
    let _errors = {};
    const _effort = _effortsContext.efforts?.find((e) => e.id === effortId);

    let _newEffort = {};
    if (_effortsContext.groupBy === "state") {
      const stateId = parseInt(desColId!);
      _newEffort = {
        ..._effort,
        stateId,
        state: resourceData.states?.find((e) => e.id === stateId),
      };
    } else if (_effortsContext.groupBy === "prefix") {
      const prefixId = desColId ? parseInt(desColId) : null;
      _newEffort = {
        ..._effort,
        prefixId,
        prefix: desColId
          ? resourceData.prefixes?.find((e) => e.id === prefixId)
          : null,
      };
    } else if (_effortsContext.groupBy === "user") {
      let _user = resourceData.users?.find((e) => e.id === desColId);
      let _srcMember = _effort!.members!.find((e) => e.userId === srcColId)!;

      let createFormData = undefined;
      if (_user) {
        createFormData = {
          ..._srcMember,
          effortId,
          userId: _user.id,
          user: _user,
          isDeleted: false,
        };
      }

      let itemsToDelete = undefined;
      if (!isCloning) {
        itemsToDelete = [_srcMember];
      }

      _membersContext.createAndDelete({
        createFormData,
        itemsToDelete,
      });
    } else if (_effortsContext.groupBy === "priority") {
      const priorityId = parseInt(desColId!);
      _newEffort = {
        ..._effort,
        priorityId,
        priority: resourceData.priorities?.find((e) => e.id === priorityId),
      };
    } else if (_effortsContext.groupBy === "thisWeek") {
      _newEffort = {
        ..._effort,
        dateDue: desColId,
      };
    } else if (_effortsContext.groupBy === "thisMonth") {
      _newEffort = {
        ..._effort,
        dateDue: desColId,
      };
    }

    if (!isEmpty(_newEffort)) {
      _errors = await _effortsContext.update(_newEffort);
    }

    if (!isEmpty(_errors)) {
      let _message = listFirst(listFirst(Object.values(_errors) as Dict[]));
      if (_message) {
        toast(_message, {
          variant: "destructive",
        });
      }
    }

    return _errors;
  };

  const isColDisabled = (
    eachCol: EffortGroupType,
    draggingEffortId?: number
  ) => {
    let _isDisabled: boolean | undefined = false;

    if (draggingEffortId) {
      const _effort = _mainContext.efforts?.find(
        (a) => a.id === draggingEffortId
      )!;

      if (!_effortAuthUtils.canEdit([_effort])) {
        _isDisabled = true;
      } else if (eachCol.effortIds?.some((a) => a === draggingEffortId)) {
        _isDisabled = true;
      } else {
        let _project = _effortUtils.getParentProject(draggingEffortId);
        const _isDraggingAProject = _project?.id === draggingEffortId;
        const _getAll = _isDraggingAProject && _currentUserContext.isSudo();

        if (_effortsContext.groupBy === "state") {
          const _states = _getAll ? resourceData.states : _project?.states;
          _isDisabled = !_states?.some((e) => e.id + "" === eachCol.id);
        } else if (_effortsContext.groupBy === "prefix") {
          const _prefixes = _getAll
            ? resourceData.prefixes
            : _project?.prefixes;
          _isDisabled = !_prefixes?.some((e) => e.id + "" === eachCol.id);
        } else if (_effortsContext.groupBy === "user") {
          if (_getAll) {
            _isDisabled = !resourceData.users?.some((e) => e.id === eachCol.id);
          } else {
            _isDisabled = !_project?.members?.some(
              (e) => e.userId === eachCol.id
            );
          }
        } else if (_effortsContext.groupBy === "priority") {
          const _priorities = _getAll
            ? resourceData.priorities
            : _project?.priorities;
          _isDisabled = !_priorities?.some((e) => e.id + "" === eachCol.id);
        }
      }
    }

    return _isDisabled;
  };

  return (
    <>
      {_effortsContext.viewType === "board" ? (
        <>
          {_effortsContext.groupBy ? (
            <BoardSection
              cols={cols}
              onUpdate={onUpdate}
              isColDisabled={isColDisabled}
              basedOnType={TRELLO_SECTION_TYPES[_effortsContext.groupBy]}
              colClick={(col) =>
                navigate(_effortUtils.makeUrl(parseInt(col.id))!)
              }
            />
          ) : (
            <div
              className="grid gap-1"
              style={{
                gridTemplateColumns:
                  "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
              }}
            >
              {_efforts?.map((eachItem, eachItemIndex) => (
                <EachCardItem
                  key={"eachItem" + eachItemIndex}
                  effortId={eachItem.id}
                  className="flex-1 "
                />
              ))}
            </div>
          )}
        </>
      ) : _effortsContext.viewType === "table" ? (
        <>
          {_effortsContext.groupBy ? (
            <TablesSection
              cols={cols}
              onUpdate={onUpdate}
              isColDisabled={isColDisabled}
              basedOnType={TRELLO_SECTION_TYPES[_effortsContext.groupBy]}
              colClick={(col) =>
                navigate(_effortUtils.makeUrl(parseInt(col.id))!)
              }
            />
          ) : (
            <ItemsListTableSection />
          )}
        </>
      ) : null}
    </>
  );
}
