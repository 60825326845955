
import User, { userSchema } from './User';
import { textEditorTypeSchema } from 'components/common/Forms/TextEditor';

import { z } from 'zod';
import FilterBase from './views/filterBase';






export type CommentSortType = "CREATEDON" | "CONTENT" | "USER";


export const CommentSortTypes: { [key: string]: CommentSortType } = {
  CREATEDON: "CREATEDON",
  CONTENT: "CONTENT",
  USER: "USER",
};

export interface CommentFilter extends FilterBase {
  "sortBy": CommentSortType,

  "createdOn"?: number,

  "content"?: string,
  effortId: string,
  // "user"?: User,
}


export const baseCommentSchema = z.object({
  id: z.number(),
  parentId: z.number().optional(),
  effortId: z.string(),

  dateAdd: z.string(),

  content: textEditorTypeSchema,
  oldContent: z.string(),

  userId: z.string(),
  user: userSchema,
});


type Comment = z.infer<typeof baseCommentSchema> & {
  repliedTo?: Comment,
  children: Comment[],
};

export const commentSchema: z.ZodType<Comment> = baseCommentSchema.extend({
  repliedTo: z.lazy(() => commentSchema.optional()),
  children: z.lazy(() => commentSchema.array()),
});

export default Comment;

// export default interface Comment {
//   id: number,
//   parentId?: number | null,
//   effortId: string,

//   dateAdd: string,

//   content: TextEditorType,
//   oldContent: string,

//   userId: string,
//   user: User,

//   repliedTo?: Comment,
//   children: Comment[],
// }
