import { Slot, SlotProps } from "@radix-ui/react-slot";
import React from "react";
import { cn } from "services/UtilServices";

export default function Sticky({
  children,
  side = "top",
  offset = 0,
  disabled,
  className,
  ...rest
}: {
  children: React.ReactNode;
  side?: "top" | "bottom" | "right" | "left";
  offset?: number;
  disabled?: boolean;
} & SlotProps) {
  const ref = React.useRef<HTMLElement>(null);
  const [isSticked, setIsSticked] = React.useState(false);

  React.useEffect(() => {
    var observer: IntersectionObserver | undefined = undefined;
    if (disabled) {
      ref.current?.removeAttribute("data-stick");
    } else {
      observer = new IntersectionObserver(
        ([e]) =>
          e.target.setAttribute("data-stick", String(e.intersectionRatio < 1)),
        { threshold: [1] }
      );

      observer.observe(ref.current!);
    }

    return () => {
      observer?.disconnect();
      ref.current?.removeAttribute("data-stick");
    };
  }, [disabled]);

  return (
    <Slot
      ref={ref}
      className={cn("", { sticky: !disabled }, className)}
      style={
        {
          // [side]: offset - 1,
        }
      }
      {...rest}
    >
      {children}
    </Slot>
  );
}
