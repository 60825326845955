
import Dict from 'models/Dict';
import { UserLogFilter } from 'models/UserLog';
import { dataMapper, dateAdd, dateGetOnlyDate, getNotEmptyFields, getOnlyFields } from 'services/UtilServices';

import useApiServices from './UseApiServices';

export const BASE_URL = "userlogs/";

export default function useUserLogApi() {

  const apiServices = useApiServices();


  const get = async (filter: UserLogFilter) => {
    let response = await apiServices.send({
      url: BASE_URL + "filter",
      method: "post",
      expirationDurationMinutes: 2,
      body: getNotEmptyFields({
        ...filter,
        dateAddFrom: dateGetOnlyDate(filter.dateAddFrom),
        dateAddTo: dateAdd({ date: filter.dateAddTo, days: 1 }),
      }),
    });

    return {
      ...response,
      items: response.result ?? []
    };
  }

  const markAsRead = async (effortId?: number) => {
    await apiServices.send({
      url: BASE_URL + "read",
      method: "post",
      body: { effortId }
    });
  }


  const getNotifs = async (filter?: Dict) => {
    const _response = await apiServices.send({
      url: "accounts/logs/notifications",//BASE_URL + 
      method: "post",
      body: getOnlyFields(filter, [
        "pageNumber",
        "numberInPage",
        "ascOrder",
        "sortBy",
      ])
    });

    return {
      totalNumber: _response.totalNumber,
      items: _response.result,
    };
  }

  return {
    get,
    markAsRead,
    getNotifs
  };
}

