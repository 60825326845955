import React from "react";
import { useNavigate } from "react-router";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { cn, isEmpty } from "services/UtilServices";
import UserLog, {
  UserLogActionTypes,
  UserLogFilter,
  UserLogSortType,
  UserLogEntityNames,
} from "models/UserLog";

import { useMainContext } from "providers/MainProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { useModalContext, ModalProvider } from "providers/ModalProvider";
import {
  useUserLogsContext,
  UserLogsProvider,
} from "providers/UserLogsProvider";

import UserLogFilterModal from "./UserLogFilterModal";
import LoadingButton from "components/common/LoadingButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import LocalDateTime from "components/common/LocalDateTime";
import EmptyListIndicator from "components/common/EmptyListIndicator";

import { useItemsListContext } from "components/common/ItemsListSection/ItemsListProvider";
import { Badge } from "components/ui/badge";
import useEffectStrictMode from "hooks/UseEffectStrictMode";
import useUserLogUtils from "hooks/utils/UseUserLogUtils";
import { VscEye } from "react-icons/vsc";
import { useEffortsContext } from "providers/EffortsProvider";
import { useNotificationsContext } from "providers/NotificationsProvider";

function EachUserLogTitleRow({
  userLog,
  index,
}: {
  userLog: UserLog;
  index: number;
}) {
  const _currentUserContext = useCurrentUserContext();

  return (
    <div className={cn("flex items-center flex-1 gap-2 ")}>
      <ProfileAvatar size={30} user={userLog.user} />

      <div className="flex-1 truncate">
        <span className="text-xs  ">{userLog.user.userName}</span>
      </div>

      {userLog.isNotification && (
        <div>
          <Badge>New</Badge>
        </div>
      )}
      <span className="text-xs">
        <LocalDateTime date={userLog.dateAdd} needTime />
      </span>
    </div>
  );
}

function EachUserLogItem({
  userLog,
  index,
}: {
  userLog: UserLog;
  index: number;
}) {
  return (
    <div className="text-start whitespace-normal">
      <span className="">{userLog.description}</span>
    </div>
  );
}

export function UserLogsPageBody({
  sectionTitle,
}: {
  sectionTitle?: "Logs" | "Feed" | "Notifications";
}) {
  const _notifsContext = useNotificationsContext();
  const _modalContext = useModalContext();
  const _userLogsContext = useUserLogsContext();
  const _effortsContext = useEffortsContext();
  const _effortUtils = useEffortUtils();
  const userLogUtils = useUserLogUtils();

  const navigate = useNavigate();
  const isFeed = sectionTitle === "Feed";
  const isNotif = sectionTitle === "Notifications";

  const onFilterSubmit = (formData: Dict) => {
    _userLogsContext.get({
      ...formData,
      pageNumber: -1,
    } as UserLogFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <UserLogFilterModal
        onSubmit={onFilterSubmit}
        filter={_userLogsContext.filter}
        effortId={_effortsContext.currentEffort?.id}
      />
    );
  };

  const markAsRead = async () => {
    return await _notifsContext.markLogsAsRead(
      _effortsContext.currentEffort?.id
    );
  };

  const { data, onLoadMore, title } = useItemsListContext();
  const _isEmpty = !onLoadMore && data?.length === 0;

  return (
    <div className="">
      {_isEmpty ? (
        <EmptyListIndicator
          wrapperClassName="mt-5"
          text={"No " + title + " Found!"}
        />
      ) : (
        <ItemsListSection<UserLog>
          title={sectionTitle}
          data={_userLogsContext.userLogs}
          tableBreakPointSize={"never"}
          btnTextResponsiveBreakPoint={"never"}
          fields={[
            // {
            //   value: "index",
            //   label: "#",
            //   className: "text-start whitespace-nowrap",
            //   width: "70px",
            //   isSortable: false,
            //   getUiContent: (e, i) => i + 1,
            // },

            {
              value: "",
              label: "New",
              width: "10px",
              isSortable: false,
              getUiContent: (e, i) => <>{e.isNotification && <Badge>New</Badge>}</>,
            },

            {
              value: "user",
              label: "user",
              width: "auto",
              isSortable: !isFeed,
              getUiContent: (e, i) => <ProfileAvatar user={e.user} />,
              className: "whitespace-nowrap",
            },

            {
              value: "description",
              label: "description",
              width: "auto",
              isSortable: true,
              getUiContent: (e, i) => (
                <span className="capitalize">{e.description}</span>
              ),
              className: "text-start whitespace-nowrap",
            },

            {
              value: "actionType",
              label: "Action Type",
              width: "auto",
              isSortable: true,
              getUiContent: (e, i) => (
                <span className="capitalize">
                  {UserLogActionTypes.options[e.actionType]}
                </span>
              ),
              className: "text-start whitespace-nowrap",
            },

            {
              value: "type",
              label: "Type",
              width: "auto",
              isSortable: true,
              getUiContent: (e, i) => (
                <span className="capitalize">
                  {UserLogEntityNames.options[e.entityName]}
                </span>
              ),
              className: "text-start whitespace-nowrap",
            },

            {
              value: "dateAdd",
              label: "date",
              width: "auto",
              isSortable: true,
              // getValue: (e) => dateGetTime(e.dateAdd),
              // getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
              // className: "whitespace-nowrap",
            },
          ]}
          onLoadMore={
            !_userLogsContext.hasNext ||
              (isNotif && _notifsContext.totalCount === 0)
              ? undefined
              : async () => {
                await _userLogsContext.get({
                  ..._userLogsContext.filter,
                  pageNumber: _userLogsContext.filter.pageNumber + 1,
                });
              }
          }
          onSortField={
            isNotif
              ? undefined
              : (v) => {
                _userLogsContext.get({
                  ..._userLogsContext.filter,
                  sortBy: v as UserLogSortType,
                  pageNumber: -1,
                });
              }
          }
          onSortDir={
            isNotif
              ? undefined
              : (v) => {
                _userLogsContext.get({
                  ..._userLogsContext.filter,
                  ascOrder: v === 1,
                  pageNumber: -1,
                });
              }
          }
          onSearch={null}
          initialSelectedSortFieldValue={"dateAdd"}
          buildCardTitleRow={(v, i) => (
            <EachUserLogTitleRow userLog={v} index={i} />
          )}
          buildCardBodyItem={(v, i) => (
            <EachUserLogItem userLog={v} index={i} />
          )}
          onItemClick={
            isFeed || isNotif
              ? (u) => () => {
                const _url = _effortUtils.makeUrl(u.effortId);

                _modalContext.dismiss();
                navigate(_url ? _url + "/Logs" : "/404/");
              }
              : undefined
          }
          actionsSection={
            <>
              {isNotif && //isFeed ||
                _notifsContext.totalCount > 0 && (
                  <LoadingButton onClick={markAsRead}>
                    <VscEye />
                  </LoadingButton>
                )}

              {!isNotif && (
                <FilterButton
                  needText={false}
                  className="border-0"
                  count={_userLogsContext.totalNumber}
                  onClick={openFilterModal}
                  isActive={userLogUtils.isFilterActive({
                    filter: _userLogsContext.filter,
                  })}
                />
              )}
            </>
          }
        />
      )}
    </div>
  );
}

export default function UserLogsSection({
  effortId,
  onExitClear,
  type,
}: {
  effortId?: number;
  onExitClear?: boolean;
  type?: "Logs" | "Feed" | "Notifications";
}) {
  const { markLogsAsRead } = useNotificationsContext();

  React.useEffect(() => {
    return () => {
      console.log("onExitClear")
      if (onExitClear) {
        markLogsAsRead(effortId);
      }
    }
  }, []);
  // useEffectStrictMode(undefined, [], () => {
  //   console.log("onExitClear")
  //   if (onExitClear) {
  //     markLogsAsRead(effortId);
  //   }
  // });

  return (
    <UserLogsProvider effortId={effortId} isNotif={type === "Notifications"}>
      <UserLogsPageBody sectionTitle={type ?? (effortId ? "Logs" : "Feed")} />
    </UserLogsProvider>
  );
}
