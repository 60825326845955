import React from "react";
import { cn } from "services/UtilServices";

export function StickyHorizontalScrollbar({
  scrollRef,
  className,
}: {
  scrollRef: React.RefObject<HTMLDivElement>;
  className?: string;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const x = React.useRef(0);
  const scrollInit = React.useRef(0);
  const [wid, setWid] = React.useState(scrollRef.current?.clientWidth ?? 0);

  React.useEffect(() => {
    let resizeObserver: ResizeObserver | null = null;

    if (scrollRef.current) {
      resizeObserver = new ResizeObserver(() => {
        setWid(scrollRef.current!.clientWidth);
      });
      resizeObserver.observe(scrollRef.current);
    }

    return () => resizeObserver?.disconnect();
  }, [scrollRef.current]);

  React.useEffect(() => {
    const callback = () => {
      const rela =
        Math.abs(-scrollRef.current!.scrollLeft + 1) /
        (scrollRef.current!.scrollWidth - scrollRef.current!.clientWidth);

      ref.current!.style.marginLeft =
        (ref.current!.parentElement!.clientWidth - ref.current!.clientWidth) *
          rela +
        "px";
    };

    if (scrollRef.current && ref.current) {
      scrollRef.current.addEventListener("scroll", callback);
    }

    return () => {
      scrollRef.current?.removeEventListener("scroll", callback);
    };
  }, [scrollRef.current, ref.current]);

  const closeDragElement = () => {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const elementDrag = (e: MouseEvent): void => {
    e = e || window.event;
    e.preventDefault();

    scrollRef.current!.scrollLeft = e.clientX - x.current + scrollInit.current;
  };
  const dragMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e = e || window.event;
    e.preventDefault();

    x.current = e.clientX;
    scrollInit.current = scrollRef.current!.scrollLeft;
    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  };

  return (
    <>
      {scrollRef.current && (
        <div
          className={cn(
            "py-2 bg-muted hidden sticky bottom-0 z-20 ",
            className,
            { " block ": scrollRef.current!.scrollWidth !== wid }
          )}
        >
          <div className="rounded-full w-100 bg-foreground/10 border ">
            <div
              ref={ref}
              onMouseDown={dragMouseDown}
              onMouseDownCapture={dragMouseDown}
              className="bg-foreground/20 hover:bg-foreground/30 rounded-full cursor-pointer"
              style={{
                height: 7,
                width:
                  100 *
                    (scrollRef.current.clientWidth /
                      scrollRef.current.scrollWidth) +
                  "%",
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}
