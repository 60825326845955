import { TagFilter } from "models/Tag";
import { hasOtherKeysExcept } from "services/UtilServices";

export default function useTagUtils() {
  const isFilterActive = ({ filter }: { filter: TagFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage"]);
  }

  return {
    isFilterActive
  }
}
