import React from "react";

import Dict from "models/Dict";
import useUserUtils from "hooks/utils/UseUserUtils";
import { UserLogActionTypes, UserLogFilter, UserLogEntityNames } from "models/UserLog";

import {
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useUserLogUtils from "hooks/utils/UseUserLogUtils";
import { useEffortResources } from "hooks/utils/UseEffortResources";

export default function UserLogFilterModal({
  filter,
  onSubmit,
  effortId,
}: {
  filter: UserLogFilter;
  onSubmit: (data: Dict) => void;
  effortId?: number;
}) {
  const _userUtils = useUserUtils();
  const userLogUtils = useUserLogUtils();

  const {
    resourceData: { users },
    getResources,
  } = useEffortResources();

  React.useEffect(() => {
    getResources({
      effortId,
      fieldNames: ["users"],
    });
  }, [effortId]);

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        <Select
          name="userIds"
          label="User"
          multiple
          options={users?.map((e) => ({
            value: e.id,
            label: _userUtils.getFullName(e),
          }))}
        />

        {/* <div className="flex gap-2">
          <div className="flex-1"> */}
        <Select
          name="actionTypes"
          label="Action Types"
          multiple
          options={UserLogActionTypes.toLabelValues()}
        />

        <div className="flex-1">
          <Select
            name="entityNames"
            label="Entity Types"
            multiple
            options={UserLogEntityNames.toLabelValues()}
          />
        </div>

        {/* <Input name="description" label="What Kind of Action?" /> */}

        <DatePickerInputRange name="dateAdd" label="Date" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={userLogUtils.isFilterActive({ filter })}
            onClick={() =>
              onSubmit({
                pageNumber: -1,
                ascOrder: filter.ascOrder,
                sortBy: filter.sortBy,
                effortIds: filter.effortIds,
              })
            }
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
