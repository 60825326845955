import React from "react";

import { ActivityFilter } from "models/Activity";
import useActivityApi from "hooks/api/UseActivityApi";
import Chart, { ChartData } from "models/Chart";


interface ChartContextProps {
  filter?: Partial<ActivityFilter>;
  getData: (chart: Chart, initFilter?: Partial<ActivityFilter>) => Promise<ChartData>;
}

const ChartContext = React.createContext({} as ChartContextProps);
ChartContext.displayName = "ChartContext";

function ChartProvider({ children }: { children?: React.ReactNode }) {
  const _activityApi = useActivityApi()


  const getData = async (chart: Chart, initFilter?: Partial<ActivityFilter>) => {
    let _result = null;

    try {
      _result = await _activityApi.getChart(chart, initFilter);
    } catch (e) {
      console.error(e);
    }

    return _result;
  };

  return (
    <ChartContext.Provider
      value={
        {
          getData,
        } as ChartContextProps
      }
    >
      {children}
    </ChartContext.Provider>
  );
}

export function useChartContext() {
  const _context = React.useContext(ChartContext);

  if (!_context) {
    throw new Error("cannot use ChartContext outside of its provider.");
  }

  return _context;
}

export { ChartContext, ChartProvider };
export type { ChartContextProps };
