import { useParams } from "react-router";
import Cheeps from "components/common/Cheeps";
import { FieldType } from "components/common/ItemsListSection/ItemsListProvider";
import LocalDateTime from "components/common/LocalDateTime";
import Skeleton from "components/common/Skeleton";
import Truncate from "components/common/Truncate";
import DateDueCheeps from "components/pages/DateDueCheeps";
import MiniMembersSection from "components/pages/EachEffort/MembersSection/MiniMembersSection";
import EffortCreateUpdateModal from "components/pages/EffortsSection/EffortCreateUpdateModal";
import EffortFilterModal from "components/pages/EffortsSection/EffortFilterModal";
import EffortStateUpdateModal from "components/pages/EffortsSection/EffortStateUpdateModal";
import TicketsCheepsSection from "components/pages/TicketsCheepsSection";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import useAuthUtils from "hooks/UseAuthUtils";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import Dict from "models/Dict";
import Effort, { EffortFilter } from "models/Effort";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import {
  useEffortsContext,
  UpdateStateInputType,
} from "providers/EffortsProvider";
import { useMainContext } from "providers/MainProvider";
import { useModalContext } from "providers/ModalProvider";
import {
  isEmpty,
  cn,
  dateGetTime,
  isDateUntil,
  dateCompare,
  objRemoveKeys,
  dateGetOnlyDate,
  str2Color,
  dateGetWeek,
  dateGetWeekDayName,
  dateIsToday,
  listPartition,
  dateGetMonth,
  listFirst,
  listLast,
  isNumeric,
} from "services/UtilServices";
import useSingleFieldUpdate from "components/pages/EffortsSection/SingleFieldUpdateButton";
import useTicketCountUtils from "./UseTicketCountUtils";
import { MemberAccessTypes, MemberTypes } from "models/Member";
import useUserUtils from "./UseUserUtils";
import TicketCount from "models/TicketCount";
import useCommentUtils from "./UseCommentUtils";
import TableFillCell from "components/pages/EffortsSection/TableFillCell";
import { useNavigate } from "react-router";
import User, { UserRoles } from "models/User";
import Directionality from "components/common/Directionality";
import Prefix from "models/Prefix";
import Priority from "models/Priority";
import State from "models/State";
import Tag from "models/Tag";
import Ticket from "models/Ticket";
import Type from "models/Type";
import EffortSettingModal from "components/pages/EffortsSection/EffortSettingModal";
import { EffortResource } from "./UseEffortResources";
import CollapseText from "components/common/CollapseText";

export type EffortGroupType = {
  id: string;
  color?: string;
  effortIds?: number[];
  title: React.ReactNode;
};

export function useEffortViewUtils(parentId?: number | null) {
  const _modalContext = useModalContext();
  const _effortsContext = useEffortsContext();
  const _effortUtils = useEffortUtils();
  const _ticketCountUtils = useTicketCountUtils();

  const singleFieldEdit = useSingleFieldUpdate();
  const _currentUseContext = useCurrentUserContext();
  const navigate = useNavigate();

  const onRemove = async (datas: Effort[]) => {
    return await _effortsContext.removeMany(datas);
  };

  const onCreateUpdateSubmit = async (data: Dict, autoCloseModal = true) => {
    let _errors =
      "id" in data
        ? await _effortsContext.update(data)
        : await _effortsContext.create(data);

    if (isEmpty(_errors) && autoCloseModal) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openUpdateStateModal = (items: Effort[]) => {
    _modalContext.open(
      <EffortStateUpdateModal
        parentId={parentId}
        effortIds={items.map((e) => e.id)}
        onSubmit={onUpdateStateSubmit}
      />
    );
  };

  const onUpdateStateSubmit = async (data: Dict) => {
    let _errors = await _effortsContext.updateState(
      data as UpdateStateInputType
    );

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const preproccessFormData = (formData?: Dict) =>
    formData
      ? {
          ...formData,
          dateDue: dateGetOnlyDate(formData.dateDue),
          departmentId: formData.department?.id,
          stateId: formData.state?.id,
          prefixId: formData.prefixId,
          priorityId: formData.priority?.id,
          tagList: formData.tags?.map((e: Tag) => e.id),
          typeList: formData.types?.map((e: Type) => e.id),
          stateList: formData.states?.map((e: State) => e.id),
          prefixList: formData.prefixes?.map((e: Prefix) => e.id),
          priorities: formData.priorities?.map((e: Priority) => e.id),
          ticketsAvailable: formData.ticketsAvailable?.map((e: Ticket) => e.id),
        }
      : {};

  const openSettingModal = (effort: Effort) => {
    _modalContext.open(
      <EffortSettingModal
        effort={effort}
        onSubmit={async (d) => await onCreateUpdateSubmit(d, true)}
      />
    );
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <EffortCreateUpdateModal
        formData={formData}
        parentId={parentId}
        onSubmit={onCreateUpdateSubmit}
      />
    );
  };

  const onFilterSubmit = (formData: Omit<EffortFilter, "pageNumber">) => {
    _effortsContext.get({
      ...formData,
      pageNumber: -1,
    });

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <EffortFilterModal
        filter={_effortsContext.filter}
        onSubmit={(d) => onFilterSubmit(d as EffortFilter)}
        title={_effortsContext.currentEffort?.title}
        parentId={parentId}
      />
    );
  };

  const getItemsGroupBy = ({
    efforts,
    resourceData,
  }: {
    efforts: Effort[];
    resourceData?: EffortResource;
  }) => {
    var _result: EffortGroupType[] | undefined = undefined;

    if (_effortsContext.groupBy === "state") {
      _result = resourceData?.states?.map((eachItem) => ({
        id: eachItem.id + "",
        title: eachItem.name,
        color: eachItem.color,
        effortIds: _effortUtils
          .filter(efforts, { stateIds: [eachItem.id] })
          ?.map((e) => e.id),
      }));
    } else if (_effortsContext.groupBy === "prefix") {
      _result = [
        ...(resourceData?.prefixes ?? []),
        {
          id: null,
          name: "No Prefix",
        },
      ].map((eachItem) => ({
        id: eachItem.id + "",
        title: eachItem.name,
        color: str2Color(eachItem.name),
        effortIds: _effortUtils
          .filter(efforts, { prefixIds: [eachItem.id] })
          ?.map((e) => e.id),
      }));
    }
    // else if (_effortsContext.groubBy === "effort") {
    //   _result = efforts.map((eachItem) => ({
    //     id: eachItem.id + "",
    //     title: eachItem.title,
    //     color: str2Color(eachItem.title),
    //     effortIds: _mainContext.efforts
    //       ?.filter((a) => a.parentId === eachItem.id)
    //       ?.map((e) => e.id),
    //   }));
    // } else if (_basedOnType === "type") {
    //   cols = _typesContext.types?.map((eachItem) => ({
    //     id: eachItem.id + "",
    //     title: eachItem.name,
    //     color: eachItem.color,
    //     effortIds: _effortUtils
    //       .filter(_efforts, { typeIds: [eachItem.id] })
    //       ?.map((e) => e.id),
    //   }));
    // } else if (_basedOnType === "tag") {
    //   cols = _tagsContext.tags?.map((eachItem) => ({
    //     id: eachItem.id + "",
    //     title: eachItem.name,
    //     color: eachItem.color,
    //     effortIds: _effortUtils
    //       .filter(_efforts, { tagIds: [eachItem.id] })
    //       ?.map((e) => e.id),
    //   }));
    // }
    else if (_effortsContext.groupBy === "user") {
      _result = resourceData?.users?.map((eachItem) => ({
        id: eachItem.id,
        title: (
          <span
          // className={cn("", {
          //   "font-bold": _currentUserContext.user?.id === eachItem.id,
          // })}
          >
            <ProfileAvatar user={eachItem} needName />
          </span>
        ),
        color: str2Color(eachItem.id),
        effortIds: _effortUtils
          .filter(efforts, { memberIds: [eachItem.id] })
          ?.map((e) => e.id),
      }));
      // } else if (_basedOnType === "ticket") {
      //   cols = _ticketsContext.tickets?.map((eachItem) => ({
      //     id: eachItem.id + "",
      //     title: eachItem.name,
      //     color: eachItem.color,
      //     effortIds: _effortUtils
      //       .filter(_efforts, {
      //         ticketIds: [eachItem.id],
      //       })
      //       ?.map((e) => e.id),
      //   }));
    } else if (_effortsContext.groupBy === "priority") {
      _result = resourceData?.priorities?.map((eachItem) => ({
        id: eachItem.id + "",
        title: eachItem.name,
        color: eachItem.color,
        effortIds: _effortUtils
          .filter(efforts, {
            priorityIds: [eachItem.id],
          })
          ?.map((e) => e.id),
      }));
    } /* else if (_basedOnType === "today") {
    cols = [
      {
        id: "today",
        title: "today",
        effortIds: _effortUtils.filter(_efforts, {
          dateDueFrom: dateGetToday(),
          dateDueTo: dateGetToday(),
        }),
      },
    ];
  }  */ else if (_effortsContext.groupBy === "thisWeek") {
      _result = dateGetWeek().map((eachItem) => ({
        id: eachItem.toISOString(),
        color: str2Color(dateGetWeekDayName(eachItem)),
        effortIds: _effortUtils
          .filter(efforts, {
            dateDueFrom: eachItem,
            dateDueTo: eachItem,
          })
          ?.map((e) => e.id),
        title: (
          <div className="">
            <div className={cn("", { "font-bold": dateIsToday(eachItem) })}>
              {dateGetWeekDayName(eachItem)}
            </div>
            <LocalDateTime date={eachItem} className="text-xs" />
          </div>
        ),
      }));
    } else if (_effortsContext.groupBy === "thisMonth") {
      _result = listPartition(
        dateGetMonth(),
        (e) => dateGetWeekDayName(e) === "sunday"
      ).map((eachWeek, i) => ({
        id: listFirst(eachWeek)!.toISOString(),
        color: str2Color(i + 1 + "Week"),
        effortIds: _effortUtils
          .filter(efforts, {
            dateDueFrom: listFirst(eachWeek),
            dateDueTo: listLast(eachWeek),
          })
          ?.map((e) => e.id),
        title: (
          <div className="">
            <div
              className={cn("", {
                "font-bold": eachWeek.some((e) => dateIsToday(e)),
              })}
            >
              {"Week " + (i + 1)}
            </div>
            <span className="text-xs">
              <LocalDateTime date={listFirst(eachWeek)} /> -{" "}
              <LocalDateTime date={listLast(eachWeek)} />
            </span>
          </div>
        ),
      }));
    }

    return _result;
  };

  const getItemsListFields = () => {
    const _isHome = _effortsContext.currentEffort === undefined;
    const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
    const _isTask = !_isHome && !_isProject;
    const _parents = _effortsContext.currentEffort
      ? [
          ..._effortUtils.getParentEffortsDeep(
            _effortsContext.currentEffort?.id
          ),
          _effortsContext.currentEffort,
        ]
      : [];

    let _nearestMember = _parents
      .slice()
      .reverse()
      .find((e) =>
        e.members?.some((a) => a.userId === _currentUseContext.user?.id)
      )
      ?.members?.find((a) => a.userId === _currentUseContext.user?.id);

    return [
      {
        value: "title",
        label: "title",
        isTitleField: true,
        isPositionFixed: true,
        // width: "auto",
        // isSortable: false,
        className: "text-start ",
        onClick: (e) => navigate(e.url), //_effortUtils.makeUrl(e.id)!
        getUiContent: (e, i) => (
          // <EffortInfoLoaderOnView effort={e}>
          <Directionality
            className={cn("capitalize ", { "line-through": !e.isActive })}
          >
            <CollapseText maxLength={50} text={e.title} />
          </Directionality>
          // </EffortInfoLoaderOnView>
        ),
      },

      {
        value: "index",
        label: "#",
        className: "text-start ",
        width: "50px",
        isSortable: true,
        // getUiContent: (e, i) => _isHome ? i+1 : extractIndexFromUrl(e.url),
        // getValue: _isHome ? undefined : (e) => parseInt(extractIndexFromUrl(e.url)),
      },

      ...(_isProject || _isTask
        ? ([
            {
              value: "prefix",
              label: "prefix",
              width: "auto",
              isSortable: false,
              className: "text-center",
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "prefix",
                }),
              getUiContent: (e, i) => e.prefix?.name,
            },
          ] as FieldType<Effort>[])
        : []),

      // ...(_isHome || _isProject
      //   ? ([
      {
        value: "subTasksCount",
        label: _isHome ? "#tasks" : "#sub task",
        width: "auto",
        className: "text-center",
        // isSortable: false,
        // getUiContent: (e, i) => e.subTasksCount,
        // getValue: (e) => e.subTasksCount,
      },
      //   ] as FieldType<Effort>[])
      // : []),

      {
        value: "effortActivityRecordsCount",
        label: "#Activities",
        width: "auto",
        className: "text-center",
        // isSortable: false,
        // getUiContent: (e, i) => e.subTasksCount,
        // getValue: (e) => e.activityRecordsCount,
      },

      ...(_isHome
        ? ([
            {
              value: "department",
              label: "Department",
              width: "auto",
              className: "text-center",
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "department",
                }),
              getUiContent: (e, i) => e.department?.name,
              getValue: (e) => e.department?.name,
            },
          ] as FieldType<Effort>[])
        : []),

      {
        value: "creator",
        label: "creator",
        width: "auto",
        className: "text-center",
        // isSortable: false,
        getUiContent: (e) =>
          e.creator ? <ProfileAvatar user={e.creator} /> : <Skeleton />,
        getValue: (e) => e.creator?.userName ?? "",
      },

      {
        value: "state",
        label: "state",
        width: "auto",
        className: "text-center",
        // isSortable: false,
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "state",
          }),
        getUiContent: (e, i) => (
          <TableFillCell color={e.state === null ? undefined : e.state?.color}>
            {e.state === null ? "no state" : e.state?.name}
          </TableFillCell>
        ),
        getValue: (e) => (e.state === null ? "no state" : e.state?.name ?? ""),
      },

      {
        value: "priority",
        label: "priority",
        width: "auto",
        className: "text-center",
        // isSortable: false,
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "priority",
          }),
        getUiContent: (e, i) => (
          <TableFillCell
            color={e.priority === null ? undefined : e.priority?.color}
          >
            {e.priority === null ? "no priority" : e.priority?.name}
          </TableFillCell>
        ),
        getValue: (e) =>
          e.priority === null ? "no priority" : e.priority?.name ?? "",
      },
      {
        value: "members",
        label: "members",
        width: "auto",
        isSortable: false,
        getUiContent: (p) =>
          p.members === undefined ? (
            <Skeleton />
          ) : (
            <MiniMembersSection effort={p} />
          ),
      },

      {
        value: "dateAdd",
        label: "Created",
        width: "100px",
        // isSortable: false,
        className: "text-center",
        getValue: (e) => dateGetTime(e.dateAdd),
        getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
      },

      {
        value: "dateDue",
        label: "Deadline",
        width: "100px",
        // isSortable: false,
        className: "text-center",
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "dateDue",
          }),
        getValue: (e) => dateGetTime(e.dateDue),
        getUiContent: (e, i) => <DateDueCheeps effort={e} />,
      },

      {
        value: "tags",
        label: "tags",
        width: "auto",
        isSortable: false,
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "tags",
          }),
        getUiContent: (p) =>
          p.tags === undefined ? (
            <Skeleton />
          ) : (
            <Truncate>
              {p.tags?.map((e, i) => (
                <Cheeps key={"eachTag" + i} borderColor={e.color}>
                  {e.name}
                </Cheeps>
              ))}
            </Truncate>
          ),
      },

      {
        value: "types",
        label: "types",
        width: "auto",
        isSortable: false,
        onClick: (e) =>
          singleFieldEdit.onClick({
            effort: e,
            fieldName: "types",
          }),
        getUiContent: (p) =>
          p.types === undefined ? (
            <Skeleton />
          ) : (
            <Truncate>
              {p.types?.map((e, i) => (
                <Cheeps key={"eachType" + i} borderColor={e.color}>
                  {e.name}
                </Cheeps>
              ))}
            </Truncate>
          ),
      },

      ...(_isHome
        ? ([
            {
              value: "states",
              label: "states",
              width: "auto",
              isSortable: false,
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "states",
                }),
              getUiContent: (p) =>
                p.states === undefined ? (
                  <Skeleton />
                ) : (
                  <Truncate>
                    {p.states?.map((e, i) => (
                      <Cheeps key={"eachType" + i} borderColor={e.color}>
                        {e.name}
                      </Cheeps>
                    ))}
                  </Truncate>
                ),
              // getValue: (e) => e.tag.name,
            },
          ] as FieldType<Effort>[])
        : []),

      ...(_isHome
        ? ([
            {
              value: "prefixes",
              label: "prefixes",
              width: "auto",
              isSortable: false,
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "prefixes",
                }),
              getUiContent: (p) =>
                p.prefixes === undefined ? (
                  <Skeleton />
                ) : (
                  <Truncate>
                    {p.prefixes?.map((e, i) => (
                      <Cheeps key={"eachPrefix" + i}>{e.name}</Cheeps>
                    ))}
                  </Truncate>
                ),
              // getValue: (e) => e.tag.name,
            },
          ] as FieldType<Effort>[])
        : []),

      ...(_isHome
        ? ([
            {
              value: "ticketsAvailable",
              label: "tickets",
              width: "auto",
              isSortable: false,
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "Available tickets",
                }),
              getUiContent: (p) =>
                p.ticketsAvailable === undefined ? (
                  <Skeleton />
                ) : (
                  <Truncate>
                    {p.ticketsAvailable?.map((e, i) => (
                      <Cheeps key={"eachType" + i} borderColor={e.color}>
                        {e.name}
                      </Cheeps>
                    ))}
                  </Truncate>
                ),
              // getValue: (e) => e.tag.name,
            },
          ] as FieldType<Effort>[])
        : []),

      ...(_isHome
        ? ([
            {
              value: "priorities",
              label: "priorities",
              width: "auto",
              isSortable: false,
              onClick: (e) =>
                singleFieldEdit.onClick({
                  effort: e,
                  fieldName: "priorities",
                }),
              getUiContent: (p) =>
                p.priorities === undefined ? (
                  <Skeleton />
                ) : (
                  <Truncate>
                    {p.priorities?.map((e, i) => (
                      <Cheeps key={"eachPriority" + i} borderColor={e.color}>
                        {e.name}
                      </Cheeps>
                    ))}
                  </Truncate>
                ),
              // getValue: (e) => e.tag.name,
            },
          ] as FieldType<Effort>[])
        : []),

      {
        value: "effortTicketSum",
        label: _isHome ? "effortTicketSum" : "Tickets",
        width: "auto",
        // isSortable: false,
        className: "whitespace-nowrap",
        onClick: _isHome
          ? undefined
          : (e) =>
              singleFieldEdit.onClick({
                effort: e,
                fieldName: "tickets Count",
              }),
        getUiContent: (e) => (
          <TicketsCheepsSection ticketCounts={e.ticketsCount} visibleIfEmpty />
        ),
        getValue: (e) => _ticketCountUtils.sumTotal(e.ticketsCount),
      },

      ...(_nearestMember?.memberType !== MemberTypes.Client
        ? ([
            {
              value: "activitySum",
              label: "activitySum",
              width: "auto",
              // isSortable: false,
              className: "whitespace-nowrap",
              getUiContent: (e) => (
                <TicketsCheepsSection ticketCounts={e.activitySum} />
              ),
              getValue: (e) => _ticketCountUtils.sumTotal(e.activitySum),
            },

            {
              value: "activitySumApproved",
              label: "activitySumApproved",
              width: "auto",
              // isSortable: false,
              getUiContent: (e) => (
                <TicketsCheepsSection
                  ticketCounts={e.activitySum}
                  fieldName="countApproved"
                />
              ),
              getValue: (e) =>
                _ticketCountUtils.sumTotal(e.activitySum, "countApproved"),
            },
          ] as FieldType<Effort>[])
        : []),
    ] satisfies FieldType<Effort>[];
  };

  return {
    onRemove,
    openUpdateStateModal,
    onUpdateStateSubmit,
    openCreateUpdateModal,
    openFilterModal,
    getItemsListFields,
    onFilterSubmit,
    onCreateUpdateSubmit,
    preproccessFormData,
    openSettingModal,
    getItemsGroupBy,
  };
}

export function useEffortAuthUtils() {
  const _authUtils = useAuthUtils();
  const _effortUtils = useEffortUtils();
  const _currentUserContext = useCurrentUserContext();
  const _userUtils = useUserUtils();

  const canCreate = (parentId?: number | null) => {
    let accessType = _authUtils!.authorize(parentId);
    return accessType >= MemberAccessTypes.Create;
  };

  const canEdit = (items: Effort[]) => {
    if (_userUtils.isSudo(_currentUserContext.user)) {
      return true;
    } else if (items.some((e) => !e.isActive)) {
      return false;
    }

    let accessType = Math.min(...items.map((e) => _authUtils!.authorize(e.id)));

    return (
      accessType >= MemberAccessTypes.Edit ||
      (accessType >= MemberAccessTypes.Create &&
        items.every((e) => !_effortUtils.isProject(e)) &&
        items.every((e) => _currentUserContext.user?.id === e.creatorId))
    );
  };

  const canDelete = (items: Effort[]) => {
    if (_currentUserContext.user?.role === UserRoles.ADMIN) {
      return true;
    } else if (items.some((e) => !e.isActive || _effortUtils.isProject(e))) {
      return false;
    }

    let accessType = Math.min(...items.map((e) => _authUtils!.authorize(e.id)));

    return (
      accessType >= MemberAccessTypes.Delete || //items.every((e) => !_effortUtils.isProject(e))
      (accessType >= MemberAccessTypes.Edit && //items.every((e) => !_effortUtils.isProject(e))
        items.every((e) => _currentUserContext.user?.id === e.creatorId)) ||
      (accessType >= MemberAccessTypes.Create && //items.every((e) => !_effortUtils.isProject(e))
        items.every((e) => _currentUserContext.user?.id === e.creatorId) &&
        items.every((e) => isDateUntil({ date: e.dateAdd, minutes: 30 })))
    );
  };

  return {
    canCreate,
    canEdit,
    canDelete,
  };
}

export function useEffortUtils() {
  const _mainContext = useMainContext();
  const _commentUtils = useCommentUtils();
  const textEditorUtils = useTextEditorUtils();
  const params = useParams();

  const getEffortByUrl = () => {
    let _result: Effort | undefined = undefined;

    const projTitle = params.proj;
    const taskUrl =
      params.task === undefined || !isNumeric(params.task)
        ? undefined
        : parseInt(params.task);

    const subtaskUrl =
      params.subtask === undefined || !isNumeric(params.subtask)
        ? undefined
        : parseInt(params.subtask);

    const proj = _mainContext.efforts?.find(
      (e) => e.title.trim().toLowerCase() === projTitle?.trim().toLowerCase()
    );
    if (proj !== undefined) {
      _result = proj;
      const task = getSubEfforts(proj.id)?.find(
        (e) => e.url === taskUrl?.toString().trim().toLowerCase()
      );
      if (task !== undefined) {
        _result = task;
        const subtask = getSubEfforts(task.id)?.find(
          (e) => e.url === subtaskUrl?.toString().trim().toLowerCase()
        );
        if (subtask !== undefined) {
          _result = subtask;
        }
      }
    }

    return _result;
  };

  const isProject = (effort?: Effort | Dict | number | null) => {
    if (typeof effort === "number") {
      effort = getEffortById(effort);
    }

    return Boolean(
      effort && (effort.parentId === null || effort.parentId === undefined)
    );
  };

  const isTask = (effort?: Effort | Dict | number) => {
    if (typeof effort === "number") {
      effort = getEffortById(effort);
    }

    return Boolean(
      effort &&
        effort.parentId !== null &&
        effort.parentId !== undefined &&
        isProject(effort.parentId)
    );
  };

  const isFilterActive = ({
    filter,
    effortId,
  }: {
    filter: EffortFilter;
    effortId?: number | null;
  }) => {
    const _effortUtils = useEffortUtils();
    const _isProject = _effortUtils.getParentProject(effortId)?.id === effortId;

    return (
      Object.values(
        objRemoveKeys(filter, [
          "pageNumber",
          "ascOrder",
          "sortBy",
          "numberInPage",
          "parentId",
          "isActive",
          ...(_isProject ? [] : ["departmentId"]),
        ])
      ).some((a) => !isEmpty(a)) || filter.isActive !== true
    );
    // return hasOtherKeysExcept(filter, [
    //   "pageNumber",
    //   "ascOrder",
    //   "sortBy",
    //   "numberInPage",
    //   "parentId",
    //   ...(parentId === null ? [] : ["departmentId"]),
    // ]);
  };

  const makeUrl = (effortId?: number) => {
    let _result = undefined;

    let effort = getEffortById(effortId);
    if (effort) {
      const parentEffortsDeep = [...getParentEffortsDeep(effort.id), effort];

      const proj = parentEffortsDeep.find((e) => isProject(e));
      if (proj) {
        const tasks = parentEffortsDeep.filter((e) => e.id !== proj.id);
        _result =
          "/effort/" + [proj.title, ...tasks.map((e) => e.url)].join("/");
      }
    }

    return _result;
  };

  // const getParentProjectLoadInfo = async (
  //   effortId?: number | null,
  //   efforts?: Effort[]
  // ) => {
  //   let _effort = getEffortById(effortId, efforts);
  //   if (!_effort) return undefined;

  //   let _efforts = getParentEffortsDeep(effortId, efforts);

  //   return (
  //     await loadInfo([
  //       _efforts.length === 0 ? _effort : _efforts[_efforts.length - 1],
  //     ])
  //   )[0];
  // };

  const getParentProject = (effortId?: number | null, efforts?: Effort[]) => {
    let _effort = getEffortById(effortId, efforts);
    if (!_effort) return undefined;

    let _efforts = getParentEffortsDeep(effortId, efforts);

    return _efforts.length === 0 ? _effort : _efforts[_efforts.length - 1];
  };

  // const getParentEffortsDeepLoadInfo = async (
  //   effortId?: number | null,
  //   efforts?: Effort[]
  // ) => {
  //   let _result: Effort[] = [];

  //   let effort = getEffortById(effortId, efforts);
  //   if (effort) {
  //     let _currentParent = getEffortById(effort.parentId, efforts);
  //     while (_currentParent !== undefined) {
  //       _result.push(_currentParent);
  //       _currentParent = getEffortById(_currentParent.parentId, efforts);
  //     }
  //   }

  //   return (await loadInfo(_result))!;
  // };

  const getParentEffortsDeep = (
    effortId?: number | null,
    efforts?: Effort[]
  ) => {
    let _result: Effort[] = [];

    let effort = getEffortById(effortId, efforts);
    if (effort) {
      let _currentParent = getEffortById(effort.parentId, efforts);
      while (_currentParent !== undefined) {
        _result.push(_currentParent);
        _currentParent = getEffortById(_currentParent.parentId, efforts);
      }
    }

    return _result;
  };

  const getEffortById = (effortId?: number | null, efforts?: Effort[]) => {
    return (efforts ?? _mainContext.efforts)?.find((e) => e.id === effortId);
  };

  const serverResponse2Effort = (d: Dict) => {
    return {
      ...d,

      description: textEditorUtils.server2Dict(d.description),

      members:
        d.memberships?.map((eachMember: Dict) => ({
          ...eachMember,
          isDeleted: eachMember.recordStatus === 1,
        })) ?? undefined,

      prefixes: d.prefixes ?? undefined,
      states: d.states ?? undefined,
      priorities: d.priorities ?? undefined,
      attachments: d.attachments ?? undefined,
      types: d.types ?? undefined,
      tags: d.tags ?? undefined,
      comments:
        d.comments?.map((e: Dict) => _commentUtils.serverResponse2Comment(e)) ??
        undefined,
      ticketsAvailable: d.ticketsAvailable ?? undefined,
      ticketsCount:
        d.ticketsCount?.map((e: TicketCount) => ({
          ...e,
          ticketId: e.ticketId ?? e.ticket?.id,
        })) ?? undefined,

      departmentId: d.departmentId ?? undefined,
      department: d.department ?? undefined,
      creatorId: d.creatorId ?? undefined,
      creator: d.creator ?? undefined,
      prefix: d.prefix ?? undefined,
      effortActivityRecordsCount: d?.effortActivityRecordsCount ?? 0,
      // state: d.state ?? undefined,
      // priority: d.priority ?? undefined,
    } as Effort;
  };

  const getSubEfforts = (effortId?: number) => {
    return _mainContext.efforts?.filter((e) => e.parentId === effortId);
  };

  const getSubEffortsDeep = ({
    id,
    includeSelf = true,
    efforts,
  }: {
    id?: number;
    includeSelf?: boolean;
    efforts?: Effort[];
  }) => {
    if (!efforts) {
      efforts = _mainContext.efforts;
    }

    let effort = efforts?.find((e) => e.id === id);
    let _result: Effort[] = [];

    if (effort) {
      const subWfforts = getSubEfforts(id) ?? [];
      for (const eachEffort of subWfforts) {
        _result = [..._result, ...getSubEffortsDeep({ id: eachEffort.id })];
      }

      if (includeSelf) _result = [effort, ..._result];
    }

    return _result;
  };

  const filter = (
    efforts?: Effort[],
    filter?: Omit<EffortFilter, "pageNumber" | "ascOrder">
  ) => {
    return efforts?.filter((e) => isMatch(e, filter));
  };

  const isMatch = (
    effort: Effort,
    filter?: Omit<EffortFilter, "pageNumber" | "ascOrder">
  ) => {
    let _result = true;

    if (filter?.title) {
      _result =
        _result &&
        effort.title.toLowerCase().includes(filter.title.toLowerCase());
    }

    if (!isEmpty(filter?.stateIds)) {
      _result =
        _result &&
        !isEmpty(effort.state) &&
        filter!.stateIds!.includes(effort.state!.id);
    }

    if (!isEmpty(filter?.priorityIds)) {
      _result =
        _result &&
        !isEmpty(effort.priority) &&
        filter!.priorityIds!.includes(effort.priority!.id);
    }

    if (!isEmpty(filter?.tagIds)) {
      _result =
        _result &&
        !isEmpty(effort.tags) &&
        filter!.tagIds!.some((e) => effort.tags?.some((a) => a.id === e));
    }

    if (!isEmpty(filter?.typeIds)) {
      _result =
        _result &&
        !isEmpty(effort.types) &&
        filter!.typeIds!.some((e) => effort.types?.some((a) => a.id === e));
    }

    if (!isEmpty(filter?.ticketIds)) {
      _result =
        _result &&
        !isEmpty(effort.ticketsAvailable) &&
        filter!.ticketIds!.some((e) =>
          effort.ticketsAvailable?.some((a) => a.id === e)
        );
    }

    if (!isEmpty(filter?.prefixIds)) {
      if (effort.parentId === null) {
        _result =
          _result &&
          !isEmpty(effort.prefixes) &&
          filter!.prefixIds!.some((e) =>
            effort.prefixes?.some((a) => a.id === e)
          );
        // _result = _result && !isEmpty(effort.prefix) && filter!.prefixIds!.includes(effort.prefix!.id)
      } else {
        _result =
          _result &&
          // !isEmpty(effort.prefix) &&
          filter!.prefixIds!.includes(effort.prefix?.id ?? null);
      }
    }

    if (!isEmpty(filter?.departmentIds) && effort.parentId === null) {
      _result =
        _result &&
        !isEmpty(effort.department) &&
        filter!.departmentIds!.includes(effort.department!.id);
    }

    if (!isEmpty(filter?.creatorIds)) {
      _result =
        _result &&
        !isEmpty(effort.creator) &&
        filter!.creatorIds!.includes(effort.creatorId!);
    }

    if (!isEmpty(filter?.memberIds)) {
      _result =
        _result &&
        !isEmpty(effort.members) &&
        filter!.memberIds!.some((e) =>
          effort.members?.some((a) => !a.isDeleted && a.userId === e)
        );
    }

    if (filter?.dateAddFrom) {
      _result =
        _result &&
        Boolean(effort.dateAdd) &&
        dateCompare(effort.dateAdd, filter.dateAddFrom)! >= 0;
    }

    if (filter?.dateAddTo) {
      _result =
        _result &&
        Boolean(effort.dateAdd) &&
        dateCompare(effort.dateAdd, filter.dateAddTo)! <= 0;
    }

    if (filter?.dateDueFrom) {
      _result =
        _result &&
        Boolean(effort.dateDue) &&
        dateCompare(effort.dateDue, filter.dateDueFrom)! >= 0;
    }

    if (filter?.dateDueTo) {
      _result =
        _result &&
        Boolean(effort.dateDue) &&
        dateCompare(effort.dateDue, filter.dateDueTo)! <= 0;
    }

    if (filter?.isActive !== undefined && filter.isActive !== null) {
      _result = _result && effort.isActive === filter.isActive;
    }

    if (filter?.timeMaxFrom) {
      _result =
        _result &&
        Boolean(effort.timeMax) &&
        effort.timeMax!.localeCompare(filter.timeMaxFrom) >= 0;
    }
    if (filter?.timeMaxTo) {
      _result =
        _result &&
        Boolean(effort.timeMax) &&
        effort.timeMax!.localeCompare(filter.timeMaxTo) >= 0;
    }

    if (filter?.timeEstimateFrom) {
      _result =
        _result &&
        Boolean(effort.timeEstimate) &&
        effort.timeEstimate!.localeCompare(filter.timeEstimateFrom) >= 0;
    }
    if (filter?.timeEstimateTo) {
      _result =
        _result &&
        Boolean(effort.timeEstimate) &&
        effort.timeEstimate!.localeCompare(filter.timeEstimateTo) >= 0;
    }

    if (filter?.timeApprovedFrom) {
      _result =
        _result &&
        Boolean(effort.timeApproved) &&
        effort.timeApproved!.localeCompare(filter.timeApprovedFrom) >= 0;
    }
    if (filter?.timeApprovedTo) {
      _result =
        _result &&
        Boolean(effort.timeApproved) &&
        effort.timeApproved!.localeCompare(filter.timeApprovedTo) >= 0;
    }

    if (filter?.timeActualFrom) {
      _result =
        _result &&
        Boolean(effort.timeActual) &&
        effort.timeActual!.localeCompare(filter.timeActualFrom) >= 0;
    }
    if (filter?.timeActualTo) {
      _result =
        _result &&
        Boolean(effort.timeActual) &&
        effort.timeActual!.localeCompare(filter.timeActualTo) >= 0;
    }

    return _result;
  };

  return {
    // loadInfo,
    getEffortByUrl,
    isProject,
    isTask,
    isFilterActive,
    makeUrl,
    getParentProject,
    getParentEffortsDeep,
    getEffortById,
    serverResponse2Effort,
    getSubEfforts,
    getSubEffortsDeep,
    filter,
    isMatch,
  };
}
