import { hasOtherKeysExcept } from "services/UtilServices";
import { z } from "zod";

export type PrioritySortType = "dateadd" | "name" | "weight";


export const PrioritySortTypes: { [key: string]: PrioritySortType } = {
  dateadd: "dateadd",
  name: "name",
  weight: "weight",
  // COLOR: "COLOR",
};


export interface PriorityFilter {
  "pageNumber": number,
  "sortBy": PrioritySortType,
  "ascOrder": boolean,
  numberInPage?: number,

  name?: string,
  color?: string,
  weight?: number,
  dateAddFrom?: Date,
  dateAddTo?: Date,

}


export const prioritySchema = z.object({
  id: z.number(),
  name: z.string(),
  weight: z.number().optional(),
  color: z.string(),
  dateAdd: z.date(),
});

type Priority = z.infer<typeof prioritySchema>;
export default Priority;

// export default interface Priority {
//   id: number,
//   name: string,
//   weight?: number,
//   color: string,
//   dateAdd: Date,
// }
