import React from "react";
import { useNavigate } from "react-router";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import LoginModel from "models/views/Login";
import { CurrentUser } from "models/User";

import useApiServices, { ApiVariables } from "hooks/api/UseApiServices";
import useUserLogApi from "hooks/api/UseUserLogApi";
import useCurrentUserApi from "hooks/api/UseCurrentUserApi";
import LocalStorageServices from "services/localServices/LocalStorageServices";
import CurrentUserLocalServices from "services/localServices/CurrentUserLocalServices";
import useUserUtils from "hooks/utils/UseUserUtils";
import { isEmpty } from "services/UtilServices";

interface CurrentUserContextProps {
  user: CurrentUser | undefined;
  // themeColor: string,
  // bgImage: string,
  set: (user: Dict) => Promise<Dict>;
  login: (data: LoginModel) => Promise<Dict>;
  // register: (data: Register) => Promise<Dict>;
  updateInfo: (user: Dict) => Promise<Dict>;
  updatePassword: (user: Dict) => Promise<Dict>;
  updatePhone: (user: Dict) => Promise<Dict>;
  updateEmail: (user: Dict) => Promise<Dict>;
  logout: () => void;
  isLoggedIn: () => boolean;
  isSudo: () => boolean;
}

const CurrentUserContext = React.createContext({
  // isLoggedIn: () => CurrentUserLocalServices.isLoggedIn()
} as CurrentUserContextProps);
CurrentUserContext.displayName = "CurrentUserContext";

export class CurrentUserStatics {
  static logout: (() => Promise<void>) | undefined = undefined;
}

function CurrentUserProvider({ children }: { children: React.ReactNode }) {
  const [_user, _setUser] = React.useState<CurrentUser | undefined | null>();

  const currentUserApi = useCurrentUserApi();
  const apiServices = useApiServices();
  const userLogApi = useUserLogApi();
  const _userUtils = useUserUtils();
  // const navigate = useNavigate();

  //   const settingsContext = useContext(SettingsContext);

  React.useEffect(() => {
    CurrentUserLocalServices.isLoggedIn().then(async (_isLoggedIn) => {
      if (_isLoggedIn) {
        let _user = await CurrentUserLocalServices.getFromLocal();
        _setUser(_user);
        get();
      } else {
        _setUser(null);
      }
    });
  }, []);

  const isLoggedIn = () => {
    return !isEmpty(_user) && "id" in _user!; // CurrentUserLocalServices.isLoggedIn();
  };

  const isSudo = () => {
    return _userUtils.isSudo(_user) && isLoggedIn();
  };

  const setUser = (user: CurrentUser) => {
    _setUser(user);
    CurrentUserLocalServices.setToLocal(user);
  };

  // const isSuperAdmin = () => _user?.role === UserRoles.SUPER_ADMIN;

  const get = async () => {
    let _errors = {};
    let _user = await CurrentUserLocalServices.getFromLocal();

    if (_user) {
      try {
        let _serverUser = await currentUserApi.get();
        if (_serverUser) {
          setUser({
            ..._user,
            ..._serverUser,
          });
        }
      } catch (e) {
        _errors = e as AxiosError;
        console.log(e);
      }
    }

    return _errors;
  };



  const login = async (cridentials: LoginModel) => {
    let _errors = {};

    try {
      ApiVariables.TOKEN = await currentUserApi.login(cridentials);
      await LocalStorageServices.set("TOKEN", ApiVariables.TOKEN);

      setUser({ userName: cridentials.userName } as CurrentUser);

      await get();
    } catch (e) {
      _errors = e as AxiosError;
      CurrentUserLocalServices.clear();
      ApiVariables.TOKEN = null;
      console.log(e);
    }

    return _errors;
  };

  const updateInfo = async (user: Dict) => {
    let _errors = {};

    try {
      // if(user.avatarUrl !== null && typeof user.avatarUrl !== "string") {
      user.avatarUrl = await apiServices.sendFile({
        file: user.avatarUrl,
        // size: 256,
        fieldName: "avatarUrl",
      });
      // }

      await currentUserApi.update(user);

      set(user);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const updatePhone = async (user: Dict) => {
    let _errors = {};

    try {
      user.phone = user.phone === "" ? null : user.phone;
      await currentUserApi.updatePhone(user);

      set({ phone: user.phone });
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const updateEmail = async (user: Dict) => {
    let _errors = {};

    try {
      user.email = user.email === "" ? null : user.email;
      await currentUserApi.updateEmail(user);

      set({ email: user.email });
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const updatePassword = async (user: Dict) => {
    let _errors = {};

    try {
      if (user.password === user.repassword) {
        await currentUserApi.updatePassword(user);
      } else {
        _errors = { repassword: "it's not equal with password field." };
      }

      // set(user);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  // const markLogsAsRead = async (logIds: string[]) => {
  //   let _errors = {};

  //   try {
  //     await userLogApi.markAsRead(logIds);

  //     set({
  //       logs: _user!.logs?.filter((e) => !logIds.includes(e.id)) ?? [],
  //     });
  //   } catch (e) {
  //     _errors = e as AxiosError;
  //     console.log(e);
  //   }

  //   return _errors;
  // };

  const set = async (user: Dict) => {
    let _errors = {};

    if (_user) {
      try {
        const _newUser = { ..._user, ...user } as CurrentUser;
        // await currentUserApi.update(_newUser);

        // console.log(_newUser);
        // LocalStorageServices.set('CURRENT_USER', { ..._user, ...user });

        setUser(_newUser);
      } catch (e) {
        _errors = e as AxiosError;
        console.log(e);
      }
    }

    return _errors;
  };

  const logout = async () => {
    // await CurrentUserLocalServices.clear();
    let _token = ApiVariables.TOKEN ? { ...ApiVariables.TOKEN } : undefined;

    await LocalStorageServices.clear();
    ApiVariables.TOKEN = null;
    ApiVariables.CACHE = {};
    _setUser(null);

    // await currentUserApi.logout(_token);
  };

  CurrentUserStatics.logout = logout;

  return (
    <CurrentUserContext.Provider
      value={
        {
          user: _user,
          // themeColor: _user?.themeColor ?? "#607d8b",
          // bgImage: _user?.bgImage,

          set,
          login,
          updateInfo,
          updatePassword,
          updatePhone,
          updateEmail,
          logout,
          isLoggedIn,
          isSudo,
        } as CurrentUserContextProps
      }
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

export function useCurrentUserContext() {
  const _context = React.useContext(CurrentUserContext);

  if (!_context) {
    throw new Error("cannot use CurrentUserContext outside of its provider.");
  }

  return _context;
}

export { CurrentUserContext, CurrentUserProvider };
