import { Slot } from "@radix-ui/react-slot";
import React from "react";
import { LuPaperclip } from "react-icons/lu";
import { PiPaperclipLight } from "react-icons/pi";
import { cn } from "services/UtilServices";

export type DropZoneProps = {
  onDrop?: (files: File[]) => void;
  isDraggingItemValid?: (ev: React.DragEvent | DragEvent) => boolean;
  asChild?: boolean;
  overlayChildren?: React.ReactNode;
  acceptedTypes?: string[];
  exceptTypes?: string[];
  disabled?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "onDrop">;

export function DropZone({
  onDrop,
  isDraggingItemValid,
  acceptedTypes,
  asChild,
  children,
  className,
  overlayChildren,
  exceptTypes,
  disabled,
  ...props
}: DropZoneProps) {
  const [isDragging, setIsDragging] = React.useState(false);

  const _isDraggingItemValid = (ev: React.DragEvent | DragEvent) => {
    let _result = true;

    if (ev.dataTransfer && ev.dataTransfer.items) {
      if (acceptedTypes) {
        _result = Array.from(ev.dataTransfer.items).every((e) =>
          acceptedTypes.includes(e.type)
        );
      }

      if (exceptTypes) {
        _result =
          _result &&
          Array.from(ev.dataTransfer.items).some(
            (e) => !exceptTypes.includes(e.type)
          );
      }
    }

    return _result;
  };

  const _onDragEnter = (ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    // if (
    //   ev.dataTransfer.files ||
    //   ev.dataTransfer.types.some((e) => e === "Files")
    // ) {
    //   _setIsDraggingFile(true);
    // }

    if ((isDraggingItemValid ?? _isDraggingItemValid)(ev)) {
      setIsDragging(true);
    }
  };

  const _onDragOver = (ev: React.DragEvent | DragEvent) => {
    ev.preventDefault();
    if (!isDragging && (isDraggingItemValid ?? _isDraggingItemValid)(ev)) {
      setIsDragging(true);
    }
  };

  const _onDragLeave = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  const _onDrop = (ev: React.DragEvent) => {
    ev.preventDefault();

    let _files: File[] = [];
    if (ev.dataTransfer.items) {
      _files = [...Array.from(ev.dataTransfer.items)]
        .filter((e, i) => e.kind === "file")
        .map((e, i) => e.getAsFile())
        .filter((e, i) => e) as File[];
    } else {
      _files = [...Array.from(ev.dataTransfer.files)];
    }

    onDrop?.(_files);
    setIsDragging(false);
  };

  const Comp = asChild ? Slot : "div";

  return (
    <Comp
      className={cn("relative", className)}
      onDragOver={disabled ? undefined : _onDragOver}
      onDragEnter={disabled ? undefined : _onDragEnter}
      {...props}
    >
      {children}

      <div
        onDrop={_onDrop}
        onDragOver={_onDragOver}
        onDragLeave={_onDragLeave}
        className={cn(
          "absolute inset-0 bg-blue-400/20 rounded flex items-center",
          "justify-center animate-in animate-out border-2 border-dashed border-blue-500 ",
          { hidden: !isDragging }
        )}
      >
        <span className="pointer-events-none p-3 rounded-full aspect-square text-blue-500">
          {overlayChildren || <PiPaperclipLight className="!text-3xl " />}
        </span>
      </div>
    </Comp>
  );
}
