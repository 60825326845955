import React from "react";

import {
  TransformWrapper,
  TransformComponent,
  useTransformContext,
} from "react-zoom-pan-pinch";

import { ImageSizes, NetworkImage } from "components/common/NetworkImage";

function Body({
  url,
  isZooming,
  setIsZooming,
}: {
  url: string;
  isZooming: boolean;
  setIsZooming: (v: boolean) => void;
}) {
  const [loading, setIsLoading] = React.useState(false);

  const _transformContext = useTransformContext();

  React.useEffect(() => {
    setIsLoading(false);
  }, [url]);

  return (
    <div
      className="w-screen h-screen"
      onDoubleClick={() => {
        setIsZooming(!isZooming);

        if (isZooming) {
          _transformContext.resetTransform();
        }
      }}
    >
      <TransformComponent wrapperClass="!w-screen !h-screen" key={url + loading}>
        <NetworkImage
          // className="rounded"
          src={url}
          size={ImageSizes.LARGE}
          onLoaded={() => {
            setIsLoading(true);
          }}
        // className="min-w-[300px] min-h-[200px]"
        />
      </TransformComponent>
    </div>
  );
}

export default function ZoomSection(props: {
  url: string;
  isZooming: boolean;
  setIsZooming: (v: boolean) => void;
}) {
  return (
    <div className="flex items-center justify-center w-screen h-screen transition-all duration-100">
      {
        <TransformWrapper
          minScale={0.5}
          initialScale={1}
          maxPositionX={window.innerWidth}
          maxPositionY={window.innerHeight}
          centerOnInit
          onZoomStop={(e) => {
            const _isZooming = e.state.scale > 1;
            if (props.isZooming !== _isZooming) {
              props.setIsZooming(_isZooming);
            }
          }}
        >
          <Body {...props} />
        </TransformWrapper>
      }
    </div>
  );
}
