
import Dict from 'models/Dict';

import useApiServices, { ApiVariables } from './UseApiServices';
import { dateAdd, getNotEmptyFields } from 'services/UtilServices';

export const BASE_URL = "export/";

export default function useExportApi() {

  const apiServices = useApiServices();


  const getEffortReport = async (exportFormat: "xlsx" | "pdf", filter: Dict) => {
    delete filter["pageNumber"];

    let response = await apiServices.send({
      url: BASE_URL + "efforts",
      method: "post",
      responseType: "blob",
      body: getNotEmptyFields({
        ...filter,
        exportFormat,
      }),
    });

    return response;
  }


  const getActivityReport = async (exportFormat: "xlsx" | "pdf", filter: Dict) => {
    delete filter["pageNumber"];

    let response = await apiServices.send({
      url: BASE_URL + "activities",
      method: "post",
      responseType: "blob",
      body: getNotEmptyFields({
        ...filter,
        exportFormat,
      }),
    });

    return response;
  }


  const getImageSecurly = async (url: string) => {
    let response = await apiServices.send({
      url: (url.startsWith("http") ? "" : ApiVariables.SECURE_FILE_URL) + url,
      method: "get",
      responseType: "blob",
      expirationDurationMinutes: 60 * 24,
      inValidateBaseUrlByDefault: false
    });

    return URL.createObjectURL(response?.blob ?? response);
  }

  return {
    getEffortReport,
    getActivityReport,
    getImageSecurly,
  }
}

