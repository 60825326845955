import React from "react";

import Dict from "models/Dict";
import { EffortFilter } from "models/Effort";
import useUserUtils from "hooks/utils/UseUserUtils";

import { objList2Options } from "services/UtilServices";

import {
  Switch,
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { useEffortResources } from "hooks/utils/UseEffortResources";

export default function EffortFilterModal({
  title,
  filter,
  onSubmit,
  parentId,
}: {
  title?: string;
  filter: EffortFilter;
  onSubmit: (data: Dict) => void;
  parentId?: number | null;
}) {
  const _effortUtils = useEffortUtils();
  const _userUtils = useUserUtils();
  const _isProject = !parentId; // _effortUtils.getParentProject(parentId)?.id === parentId;

  const {
    resourceData: {
      tags,
      types,
      states,
      users,
      ticketsAvailable,
      prefixes,
      priorities,
      departments,
    },
    getResources,
  } = useEffortResources();

  React.useEffect(() => {
    getResources({
      effortId: parentId,
      fieldNames: [
        "tags",
        "types",
        "users",
        "states",
        "prefixes",
        "departments",
        "priorities",
        "ticketsAvailable",
      ],
    });
  }, []);

  return (
    <DialogContent
      size="lg"
      title={title ? `Filter in "${title}"` : "Filter in Projects"}
    >
      <Form data={filter} onSubmit={onSubmit}>
        <div className="inline-flex">
          <Switch name="isActive" label="Active Projects (Not Archived)" />
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <Input name="title" label="Title" placeholder="Title..." />
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="memberIds"
              label="Member"
              multiple
              options={users?.map((e) => ({
                value: e.id,
                label: _userUtils.getFullName(e),
              }))}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <DatePickerInputRange name="dateAdd" label="Creation Date" />
          </div>
          <div className="col-12 lg:col-6">
            <DatePickerInputRange name="dateDue" label="Deadline" />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <Select
              name="creatorIds"
              label="Creator"
              multiple
              options={users?.map((e) => ({
                value: e.id,
                label: _userUtils.getFullName(e),
              }))}
            />
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="stateIds"
              label="State"
              multiple
              options={objList2Options(states)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <Select
              name="typeIds"
              label="type"
              multiple
              options={objList2Options(types)}
            />
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="tagIds"
              label="tag"
              multiple
              options={objList2Options(tags)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            <Select
              name="priorityIds"
              label="priority"
              multiple
              options={objList2Options(priorities)}
            />
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="ticketIds"
              label="ticket"
              multiple
              options={objList2Options(ticketsAvailable)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 lg:col-6">
            {_isProject && (
              <Select
                name="departmentIds"
                label="Department"
                multiple
                options={objList2Options(departments)}
              />
            )}
          </div>
          <div className="col-12 lg:col-6">
            <Select
              name="prefixIds"
              label="Prefix"
              multiple
              options={objList2Options(prefixes)}
            />
          </div>
        </div>

        <DialogFooter className="row items-center justify-center gap-2 w-full">
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={_effortUtils.isFilterActive({
              filter,
              effortId: parentId,
            })}
            onClick={() => onSubmit({ pageNumber: -1, isActive: true })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
