import { DepartmentFilter } from "models/Department";
import { hasOtherKeysExcept } from "services/UtilServices";



export default function useDepartmentUtils() {
  const isFilterActive = ({ filter }: { filter: DepartmentFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage"]);
  }

  return {
    isFilterActive
  }
}


