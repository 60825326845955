
import { z } from "zod";
import Effort from "./Effort";




export type AttachmentSortType = "CREATEDON" | "USERID" | "EFFORTID";


export const AttachmentSortTypes: { [key: string]: AttachmentSortType } = {
  CREATEDON: "CREATEDON",
  USERID: "USERID",
  EFFORTID: "EFFORTID"
};

export interface AttachmentFilter {
  "pageNumber": number,
  "sortBy": AttachmentSortType,
  "ascOrder": boolean,

  "createdOnFrom"?: Date,
  "createdOnTo"?: Date,
  "userId"?: string,
  "effortId"?: string,
  "createdOn"?: string,
}


export const attachmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  userId: z.string(),
  effortId: z.number(),
  url: z.string(),
  dateAdd: z.string(),
});

type Attachment = z.infer<typeof attachmentSchema>;
export default Attachment;

// export default interface Attachment {
//   id: string,
//   name: string,
//   userId: string,
//   effortId: number,
//   url: string,
//   // format: string,
//   dateAdd: string,
// }

