import React from "react";

import Dict from "models/Dict";
import State from "models/State";
import Effort from "models/Effort";

import { useMainContext } from "../../providers/MainProvider";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider";
import { getOnlyFields, listFlatten, listUnique } from "services/UtilServices";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import User from "models/User";
import Type from "models/Type";
import Tag from "models/Tag";
import Department from "models/Department";
import Prefix from "models/Prefix";
import Priority from "models/Priority";
import usePrefixApi from "hooks/api/UsePrefixApi";
import usePriorityApi from "hooks/api/UsePriorityApi";
import useStateApi from "hooks/api/UseStateApi";
import useTagApi from "hooks/api/UseTagApi";
import useTicketApi from "hooks/api/UseTicketApi";
import useTypeApi from "hooks/api/UseTypeApi";
import useUserApi from "hooks/api/UseUserApi";
import useDepartmentApi from "hooks/api/UseDepartmentApi";
import Ticket from "models/Ticket";

export type EffortResource = {
  departments?: Department[];
  prefixes?: Prefix[];
  states?: State[];
  priorities?: Priority[];
  users?: User[];
  types?: Type[];
  tags?: Tag[];
  ticketsAvailable?: Ticket[];
};

export function useEffortResources() {
  const _api = {
    tags: useTagApi(),
    types: useTypeApi(),
    users: useUserApi(),
    states: useStateApi(),
    prefixes: usePrefixApi(),
    priorities: usePriorityApi(),
    departments: useDepartmentApi(),
    ticketsAvailable: useTicketApi(),
  };
  const _effortUtils = useEffortUtils();
  const _mainContext = useMainContext();
  const _currentUserContext = useCurrentUserContext();

  const [data, setData] = React.useState<EffortResource>({});
  const [apiResults, setApiResults] = React.useState<EffortResource>({});

  const getFromProject = ({
    project,
    fieldNames,
  }: {
    project: Effort;
    fieldNames: (keyof EffortResource)[];
  }) => {
    let _result = getOnlyFields(project, fieldNames) as EffortResource;

    if (fieldNames.includes("users")) {
      _result.users = project.members
        ?.filter((e) => !e.isDeleted)
        .map((e) => e.user);
    } else if (fieldNames.includes("departments")) {
      _result.departments = [project.department!];
    }

    setData(_result);
  };

  const getFromProjectsUnion = ({
    fieldNames,
  }: {
    fieldNames: (keyof EffortResource)[];
  }) => {
    let _result: Dict = {};

    for (const fieldName of fieldNames) {
      let _d = [] as any[];

      if (fieldName === "users") {
        _d = _mainContext
          .efforts!.filter((e) => _effortUtils.isProject(e))
          .map(
            (e) =>
              e.members?.filter((e) => !e.isDeleted).map((e) => e.user) ?? []
          );
      } else if (fieldName === "departments") {
        _d = [
          _mainContext
            .efforts!.filter((e) => _effortUtils.isProject(e))
            .map((e) => e.department)
            .filter((e) => e !== undefined || e !== null) as Department[],
        ];
      } else {
        _d = _mainContext
          .efforts!.filter((e) => _effortUtils.isProject(e))
          .map((e) => e[fieldName] ?? []);

        _d?.sort((a, b) => a.id - b.id);
      }

      if (fieldName === "states") {
        _d = [
          ...(_d ?? []),

          _mainContext
            .efforts!.filter((e) => _effortUtils.isProject(e))
            .map((e) => e.state)
            .filter((e) => e !== undefined || e !== null) as State[],
        ];
      } else if (fieldName === "priorities") {
        _d = [
          ...(_d ?? []),

          _mainContext
            .efforts!.filter((e) => _effortUtils.isProject(e))
            .map((e) => e.priority)
            .filter((e) => e !== undefined || e !== null) as Priority[],
        ];
      }

      _result[fieldName] = listUnique(listFlatten(_d)) as any;
    }

    setData(_result);
  };

  const getFromApi = async ({
    fieldNames,
  }: {
    fieldNames: (keyof EffortResource)[];
  }) => {
    let _result = getOnlyFields(apiResults, fieldNames) as EffortResource;
    setData(_result);

    for (const fieldName of fieldNames) {
      try {
        let _res = await _api[fieldName].get({
          ascOrder: false,
          pageNumber: 0,
          numberInPage: 999,
          sortBy: "dateadd",
        });

        _result = {
          ..._result,
          [fieldName]: _res.items,
        };
        setData(_result);
      } catch (_) {}
    }

    setApiResults({
      ...apiResults,
      ..._result,
    });
  };

  const getResources = async ({
    effortId,
    fieldNames,
  }: {
    effortId?: number | null;
    fieldNames: (keyof EffortResource)[];
  }) => {
    const project = _effortUtils.getParentProject(effortId);

    if (project) {
      getFromProject({ project, fieldNames });
    } else if (_currentUserContext.isSudo()) {
      getFromApi({ fieldNames });
    } else {
      getFromProjectsUnion({ fieldNames });
    }
  };

  return { resourceData: data, getResources };
}
