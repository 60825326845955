import React from "react";

import Dict from "models/Dict";

import { useItemsListContext } from "./ItemsListProvider";

import ItemsListToolsSection, {
  ItemsListContextMenu,
} from "./ItemsListToolsSection";
import OnLoadMoreIndicator from "../OnLoadMoreIndicator";
import EmptyListIndicator from "../EmptyListIndicator";
import Skeleton from "../Skeleton";
import { cn } from "services/UtilServices";
import { Button } from "components/ui/button";

export default function ItemsListCardSection<T extends Dict>({
  buildCardTitleRow,
  buildCardBodyItem = () => null,
  onLoadMoreIndicator,
  itemClassName = () => "",
}: {
  buildCardTitleRow?: (v: T, i: number) => React.ReactNode;
  buildCardBodyItem?: (v: T, i: number) => React.ReactNode;
  onLoadMoreIndicator?: React.ReactNode;
  itemClassName?: (v: T, i: number) => string;
}) {
  const {
    data,
    title,
    selecteds,
    sortableFields,
    toggleSelecteds,
    onItemClick,
    onLoadMore,
    isSelected,
    ellipsisResponsiveBreakPoint,
    btnTextResponsiveBreakPoint,
    titleFieldName,
  } = useItemsListContext();

  return (
    <div className="pt-2">
      {data?.map((eachItem, eachItemIndex) => (
        <ItemsListContextMenu
          asChild
          key={"eachItem" + eachItemIndex}
          item={eachItem}
          deleteMessage={
            <span>
              You're about to delete "<b>{eachItem[titleFieldName]}</b>" in{" "}
              <b>"{title}"</b>!
            </span>
          }
        >
          <Button
            asChild
            variant={isSelected(eachItem) ? "primary" : "light"}
            className={cn(
              "group border relative   mb-2 p-3 block break-words min-h-[50px]",
              itemClassName(eachItem, eachItemIndex),
              isSelected(eachItem) ? " " : "bg-card"
            )}
          >
            <div
              onClick={() => {
                if (isSelected(eachItem)) {
                  toggleSelecteds(eachItem);
                } else if (selecteds.length > 0) {
                  toggleSelecteds(eachItem);
                } else {
                  onItemClick?.(eachItem)?.(eachItem);
                }
              }}
            >
              <div className="flex items-center gap-2 w-full relative py-1 pe-6">
                {buildCardTitleRow &&
                  buildCardTitleRow(eachItem, eachItemIndex)}

                <div className="absolute -right-3">
                  <ItemsListToolsSection
                    eachItem={eachItem}
                    ellipsisResponsiveBreakPoint={ellipsisResponsiveBreakPoint}
                    btnTextResponsiveBreakPoint={btnTextResponsiveBreakPoint}
                    deleteMessage={
                      <span>
                        You're about to delete "
                        <b>{eachItem[titleFieldName]}</b>" in <b>"{title}"</b>!
                      </span>
                    }
                  />
                </div>
              </div>

              {buildCardBodyItem(eachItem, eachItemIndex)}
            </div>
          </Button>
        </ItemsListContextMenu>
      ))}

      {onLoadMore &&
        (onLoadMoreIndicator ?? (
          <OnLoadMoreIndicator onLoadMore={onLoadMore}>
            <Skeleton count={5} className="w-full mb-1 h-24" />
          </OnLoadMoreIndicator>
        ))}

      {!onLoadMore && data?.length === 0 && (
        <EmptyListIndicator text={"No " + title + " Found!"} />
      )}
    </div>
  );
}
