import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import UserLog, { UserLogFilter, UserLogSortTypes } from "models/UserLog";

import useUserLogApi from "hooks/api/UseUserLogApi";
import { useToast } from "components/ui/use-toast";

interface UserLogsContextProps {
  userLogs: UserLog[] | undefined;

  get: (filter: UserLogFilter) => Promise<void>;
  filter: UserLogFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const UserLogsContext = React.createContext({} as UserLogsContextProps);
UserLogsContext.displayName = "UserLogsContext";

function UserLogsProvider({
  children,
  effortId,
  isNotif,
}: {
  children: React.ReactNode;
  effortId?: number;
  isNotif?: boolean;
}) {
  const itemsRef = React.useRef<UserLog[] | undefined>(undefined);
  const globalFilterRef = React.useRef<UserLogFilter>({
    pageNumber: -1,
    ascOrder: false,
    sortBy: UserLogSortTypes.ADDWHEN,
  });

  const userLogApi = useUserLogApi();

  const [_items, _setUserLogs] = React.useState<UserLog[]>();
  const [_filter, _setFilter] = React.useState<UserLogFilter>({
    pageNumber: -1,
    ascOrder: false,
    sortBy: UserLogSortTypes.ADDWHEN,
    effortIds: effortId ? [effortId] : [],
  });
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const { toast } = useToast();

  React.useEffect(() => {
    setUserLogs(undefined);
    setFilter({
      pageNumber: -1,
      ascOrder: false,
      sortBy: UserLogSortTypes.ADDWHEN,
      effortIds: effortId ? [effortId] : [],
    });
    setHasNext(true);
    setTotalNumber(undefined);
    return () => {
      setUserLogs(undefined);
      setFilter({
        pageNumber: -1,
        ascOrder: false,
        sortBy: UserLogSortTypes.ADDWHEN,
        effortIds: effortId ? [effortId] : [],
      });
      setHasNext(true);
      setTotalNumber(undefined);
    };
  }, [effortId]);

  // React.useEffect(() => {
  //   setUserLogs([...(itemsRef.current ?? [])]);

  const setUserLogs = (o?: UserLog[]) => {
    // o?.forEach((e) => {
    //   e.isNew =
    //     _currentUserContext.user?.logs?.some((a) => a.id === e.id) ?? false;
    // });

    itemsRef.current = o;
    _setUserLogs(o);
  };

  const setFilter = (o: UserLogFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const get = async (filter: UserLogFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setUserLogs(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setUserLogs(undefined);
      setTotalNumber(undefined);

      return;
    }

    try {
      let { items, totalNumber } = isNotif
        ? await userLogApi.getNotifs(filter)
        : await userLogApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setUserLogs([...(itemsRef.current ?? []), ...(items as UserLog[])]);
    } catch (e) {
      toast((e as AxiosError).message, {
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setUserLogs([]);
      }
    }
  };

  return (
    <UserLogsContext.Provider
      value={
        {
          userLogs: itemsRef.current,

          get,
          filter: _filter,
          hasNext,
          totalNumber,
        } as UserLogsContextProps
      }
    >
      {children}
    </UserLogsContext.Provider>
  );
}

export function useUserLogsContext() {
  const _context = React.useContext(UserLogsContext);

  if (!_context) {
    throw new Error("cannot use UserLogsContext outside of its provider.");
  }

  return _context;
}

export { UserLogsContext, UserLogsProvider };
export type { UserLogsContextProps };
