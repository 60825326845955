import React from "react";

import Dict from "models/Dict";
import TicketCount from "models/TicketCount";

import {
  dateGetToday,
  isEmpty,
  listFirst,
  objList2Options,
} from "services/UtilServices";

import { useModalContext } from "providers/ModalProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import {
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";

import TextEditor from "components/common/Forms/TextEditor";
import TicketsCountInputField from "./TicketsCountInputField";
import DatePickerInput from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import { useToast } from "components/ui/use-toast";
import { Separator } from "components/ui/separator";
import useValidation from "components/common/Forms/UseValidation";
import { useEffortResources } from "hooks/utils/UseEffortResources";
import { useEffortUtils, useEffortViewUtils } from "hooks/utils/UseEffortUtils";
import { useEffortsContext } from "providers/EffortsProvider";

export default function EffortCreateUpdateModal({
  title,
  formData,
  onSubmit,
  parentId,
}: {
  title?: string;
  formData?: Dict;
  parentId?: number | null;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  // const _effortsUtils = useEffortUtils();
  const _modalContext = useModalContext();
  const _currentUserContext = useCurrentUserContext();
  const {
    resourceData: {
      tags,
      types,
      states,
      ticketsAvailable,
      prefixes,
      priorities,
      departments,
    },
    getResources,
  } = useEffortResources();
  const validation = useValidation();
  const _effortUtils = useEffortUtils();
  const _effortViewUtils = useEffortViewUtils();

  // const _effortsContext = useEffortsContext();

  const { toast } = useToast();

  const isEditing = formData !== undefined && "id" in formData;
  const isDuplicating = formData !== undefined && !("id" in formData);
  // const _effort = _effortsUtils.getEffortById(formData?.id);

  // const isHome = _effortsContext.currentEffort === undefined;
  // const isProject = _effortUtils.isProject(_effortsContext.currentEffort);

  // formData  parentId
  // --------home--------
  // undefined undefined
  // --------proj--------
  // 361       undefined
  // 361       undefined
  // --------task--------
  // undefined 361
  // 362       361
  // 362       361
  // --------sub--------
  // undefined 362
  // 363       362
  // console.log(formData, parentId);

  const isProject = isEmpty(parentId);
  const isTask = parentId && _effortUtils.isProject(parentId);

  // const isHome = parentId === undefined && formData === undefined;
  // const isProject =
  //   (parentId === undefined && formData === undefined) ||
  //   Boolean(formData && _effortUtils.isProject(formData.id));
  // const isTask =
  //   (formData === undefined && _effortUtils.isProject(parentId)) ||
  //   Boolean(formData && _effortUtils.isTask(formData.id));

  React.useEffect(() => {
    getResources({
      effortId: isProject ? undefined : parentId,
      fieldNames: [
        "tags",
        "types",
        "states",
        "prefixes",
        "ticketsAvailable",
        "priorities",
        "departments",
      ],
    });
  }, []);

  let _errorContent = undefined;
  if (states?.length === 0) {
    _errorContent = `You need to add a "State" ${isProject ? "" : "to your project"
      } before you can create tasks!`;
  } else if (priorities?.length === 0) {
    _errorContent = `You need to add a "Priority" ${isProject ? "" : "to your project"
      } before you can create tasks!`;
  } else if (ticketsAvailable?.length === 0) {
    _errorContent = `You need to add a "Ticket" type ${isProject ? "" : "to your project"
      } before you can create tasks!`;
  } else if (departments?.length === 0) {
    _errorContent = `You need to add a "Department" ${isProject ? "" : "to your project"
      } before you can create subtasks!`;
  }

  if (_errorContent) {
    _modalContext.dismiss();
    toast(_errorContent, {
      variant: "destructive",
    });
    return null;
  }

  return (
    <DialogContent
      size="lg"
      title={
        isEditing
          ? title
            ? `Editing "${title}"`
            : "Editing"
          : isProject
            ? "Creating New Project"
            : isTask
              ? "Adding New Task" + (title ? ` In "${title}"` : "")
              : "Adding New SubTask" + (title ? ` In "${title}"` : "")
      }
    >
      <Form
        allowInitSubmit={isDuplicating}
        data={
          isEditing || isDuplicating
            ? {
              ..._effortViewUtils.preproccessFormData(formData),
            }
            : {
              parentId,
              departmentId: isProject ? listFirst(departments)?.id : null,
              stateId: listFirst(states)?.id,
              priority: listFirst(priorities)?.id,
              // prefixId: listFirst( _prefixsContext.prefixs),
              tagList: [],
              typeList: [],
              stateList: [],
              prefixList: [],
              priorities: [],
              ticketsAvailable: [],
              ticketsCount: [],
              avatarUrl: null,
              isActive: true,
              ..._effortViewUtils.preproccessFormData(),
              creatorId: _currentUserContext.user?.id,
            }
        }
        onSubmit={async (d) => {
          let _d = {
            ...d,

            tags: tags?.filter((e) => d.tagList?.includes(e.id)) ?? [],
            types: types?.filter((e) => d.typeList?.includes(e.id)) ?? [],
            states: states?.filter((e) => d.stateList?.includes(e.id)) ?? [],
            prefixes:
              prefixes?.filter((e) => d.prefixList?.includes(e.id)) ?? [],
            priorities:
              priorities?.filter((e) => d.priorities?.includes(e.id)) ?? [],
            ticketsAvailable:
              ticketsAvailable?.filter((e) =>
                d.ticketsAvailable?.includes(e.id)
              ) ?? [],
            ticketsCount:
              d.ticketsCount?.map((eachCount: TicketCount) => ({
                ...eachCount,
                ticket: ticketsAvailable?.find(
                  (a) => a.id === eachCount.ticketId
                ),
              })) ?? [],

            department: departments?.find((e) => e.id === d.departmentId),
            state: states?.find((e) => e.id === d.stateId),
            priority: priorities?.find((e) => e.id === d.priorityId),
            prefix: prefixes?.find((e) => e.id === d.prefixId),
          };

          return await onSubmit(_d);
        }}
      >
        <div className="row m-0 p-0">
          <div className="col-12 lg:col-6 lg:col-6 px-2">
            <Input
              name="title"
              required
              autoFocus
              validations={[
                validation.notSpecificCharsUsed([
                  "\\",
                  "/",
                  ":",
                  "*",
                  '"',
                  "<",
                  ">",
                  "|",
                  "?",
                  "=",
                  "#",
                  "'",
                ]),
              ]}
            />

            {/* <Switch name="isActive" label="Active" /> */}

            <Select
              name="stateId"
              label="State"
              required
              options={objList2Options(states)}
            />

            {isProject && (
              <div className="flex-1">
                <Select
                  name="departmentId"
                  label="Department"
                  required
                  options={objList2Options(departments)}
                />
              </div>
            )}

            <Select
              name="priorityId"
              label="Priority"
              required
              options={objList2Options(priorities)}
            />

            <DatePickerInput
              name="dateDue"
              label="Deadline"
              fromDate={formData ? undefined : dateGetToday()}
            />
          </div>

          <div className="col-12 lg:col-6 px-2">
            <Separator className="lg:hidden" />

            {isProject ? (
              <>
                <Select
                  name="stateList"
                  label="Available State Options"
                  multiple
                  options={objList2Options(states)}
                />

                <Select
                  name="prefixList"
                  label="Available Prefix Options"
                  multiple
                  options={objList2Options(prefixes)}
                />

                <Select
                  name="ticketsAvailable"
                  label="Available Ticket Options"
                  multiple
                  options={objList2Options(ticketsAvailable)}
                />

                <Select
                  name="priorities"
                  label="Available Priority Options"
                  multiple
                  options={objList2Options(priorities)}
                />

                <Select
                  name="tagList"
                  label="Available tag Options"
                  multiple
                  options={objList2Options(tags)}
                />

                <Select
                  name="typeList"
                  label="Available type Options"
                  multiple
                  options={objList2Options(types)}
                />
              </>
            ) : (
              <>
                <TicketsCountInputField
                  name="ticketsCount"
                  label="Tickets Count"
                  tickets={ticketsAvailable}
                />

                <Select
                  name="prefixId"
                  label="Prefix"
                  options={objList2Options(prefixes)}
                />

                <Select
                  name="tagList"
                  label="Tags"
                  multiple
                  options={objList2Options(tags)}
                />

                <Select
                  name="typeList"
                  label="Types"
                  multiple
                  options={objList2Options(types)}
                />
              </>
            )}
          </div>
        </div>

        <Separator className="hidden lg:block bg-muted mb-4" />

        <div className="px-2">
          <TextEditor name="description" label="Description" isAdvance />
        </div>

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
