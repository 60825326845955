import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import Priority, { PriorityFilter, PrioritySortTypes } from "models/Priority";

import usePriorityApi from "hooks/api/UsePriorityApi";
import { listFlatten, listUnique } from "services/UtilServices";

import { useMainContext } from "./MainProvider";
import { useEffortsContext } from "./EffortsProvider";
import { useCurrentUserContext } from "./CurrentUserProvider";
import { useToast } from "components/ui/use-toast";

interface PrioritiesContextProps {
  priorities: Priority[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  get: (filter: PriorityFilter) => Promise<void>;
  getAll: () => Promise<Dict | undefined>;
  filter: PriorityFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const PrioritiesContext = React.createContext({} as PrioritiesContextProps);
PrioritiesContext.displayName = "PrioritiesContext";

function PrioritiesProvider({ children }: { children: React.ReactNode }) {
  const DEFAULT_FILTER = {
    pageNumber: -1,
    ascOrder: false,
    sortBy: PrioritySortTypes.ADDWHEN,
  };
  const itemsRef = React.useRef<Priority[] | undefined>(undefined);
  const globalFilterRef = React.useRef<PriorityFilter>({ ...DEFAULT_FILTER });

  const [_items, _setPriorities] = React.useState<Priority[]>();
  const [_filter, _setFilter] = React.useState<PriorityFilter>(
    globalFilterRef.current
  );
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const { toast } = useToast();
  const priorityApi = usePriorityApi();
  const _effortUtils = useEffortUtils();

  const _mainContext = useMainContext();
  const _currentUserContext = useCurrentUserContext();
  const _effortsContext = useEffortsContext();

  // console.log(items, globalFilter,_items, _filter, hasNext)

  // React.useEffect(() => {
  //   setPriorities(undefined);
  //   setFilter({
  //     pageNumber: -1,
  //     ascOrder: false,
  //     sortBy: PrioritySortTypes.ADDWHEN,
  //   });
  //   _setFilter(globalFilter);
  // setHasNext(true);
  // setTotalNumber(undefined);
  //   return () => {
  //     setPriorities(undefined);
  //     setFilter({
  //       pageNumber: -1,
  //       ascOrder: false,
  //       sortBy: PrioritySortTypes.ADDWHEN,
  //     });
  //     _setFilter(globalFilter);
  // setHasNext(true);
  // setTotalNumber(undefined);
  //   }
  // }, []);

  const setPriorities = (o?: Priority[]) => {
    itemsRef.current = o;
    _setPriorities(o);
  };

  const setFilter = (o: PriorityFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const getAll = async () => {
    await get({
      ..._filter,
      pageNumber: 0,
      numberInPage: 999,
    });
  };

  const get = async (filter: PriorityFilter) => {
    if (!_currentUserContext.isSudo()) {
      return;
    }

    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setPriorities(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setPriorities(undefined);
      setTotalNumber(undefined);
      return;
    }

    try {
      let { items, totalNumber } = await priorityApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setPriorities([...(itemsRef.current ?? []), ...(items as Priority[])]);
    } catch (e) {
      toast((e as AxiosError).message, {
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setPriorities([]);
      }
    }
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData = await priorityApi.create(formData);

      setPriorities([formData as Priority, ...(itemsRef.current ?? [])]);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      await priorityApi.update(formData);

      setPriorities(
        itemsRef.current?.map((e) =>
          e.id !== formData.id
            ? e
            : ({
                ...formData,
              } as Priority)
        )
      );

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) => ({
          ...eachEffort,
          priority:
            eachEffort.priority?.id === formData.id
              ? (formData as Priority)
              : eachEffort.priority,
          priorities: eachEffort.priorities?.map((e) =>
            e.id !== formData.id ? e : (formData as Priority)
          ),
        }))
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Priority[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await priorityApi.remove(ids);

      setPriorities(itemsRef.current?.filter((e) => !ids.includes(e.id)));
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <PrioritiesContext.Provider
      value={
        {
          priorities: itemsRef.current,
          create,
          update,
          removeMany,

          get,
          getAll,
          filter: _filter,
          hasNext,
          totalNumber,
        } as PrioritiesContextProps
      }
    >
      {children}
    </PrioritiesContext.Provider>
  );
}

export function usePrioritiesContext() {
  const _context = React.useContext(PrioritiesContext);

  if (!_context) {
    throw new Error("cannot use PrioritiesContext outside of its provider.");
  }

  return _context;
}

export { PrioritiesContext, PrioritiesProvider };
export type { PrioritiesContextProps };
