import React from "react";

import useUserUtils from "hooks/utils/UseUserUtils";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import Link from "../../common/Link";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import { LuLogOut } from "react-icons/lu";
import { Button } from "components/ui/button";
import { PROFILE_PAGE_TABS } from "pages/ProfilePage";
import { Separator } from "components/ui/separator";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import { UserRoles } from "models/User";
import { DialogContent } from "components/ui/dialog";
import { useModalContext } from "providers/ModalProvider";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";

function ProfileModal({ initTab = "" }: { initTab?: string }) {
  return (
    <DialogContent
      title={"Profile"}
      className="lg:aspect-golden-h"
      size={"lg"}
      onBgClickClosable
    >
      <Tabs defaultValue={initTab}>
        <TabsList className="rounded sticky top-0 mb-2">
          {PROFILE_PAGE_TABS.map((eachTab) => (
            <TabsTrigger key={"eachTab" + eachTab.url} value={eachTab.url}>
              {eachTab.text}
            </TabsTrigger>
          ))}
        </TabsList>

        {/* <div className="font-bold text-2xl">Me</div> */}

        {PROFILE_PAGE_TABS.map((eachTab) => (
          <TabsContent key={"eachTab" + eachTab.url} value={eachTab.url}>
            {eachTab.content}
          </TabsContent>
        ))}
      </Tabs>
    </DialogContent>
  );
}

export default function ProfileSection({
  needName = true,
}: {
  needName?: boolean;
}) {
  const _currentUserContext = useCurrentUserContext();
  const _modalContext = useModalContext();
  const _userUtils = useUserUtils();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"light"}
          className=" border-0 flex items-center ms-1 rounded-full gap-1"
        >
          {needName && (
            <span className="mb-0 hidden lg:inline">
              {_userUtils.getFullName(_currentUserContext.user!)}
            </span>
          )}
          <ProfileAvatar needHover={false} user={_currentUserContext.user} />
        </Button>
      </PopoverTrigger>

      <PopoverContent align="end" className="w-72">
        <PopoverClose asChild>
          <Button
            variant={"light"}
            className=" w-full flex flex-col gap-1"
            onClick={() => _modalContext.open(<ProfileModal />)}
          >
            <ProfileAvatar
              size={100}
              needHover={false}
              user={_currentUserContext.user}
            />

            <span className="font-bold text-lg">
              {_userUtils.getFullName(_currentUserContext.user!)}
            </span>
            <span className="text-muted-foreground">
              {UserRoles.options[_currentUserContext.user!.role]}
            </span>
          </Button>
        </PopoverClose>

        {PROFILE_PAGE_TABS.filter((e) => e.url !== "").map((eachItem, i) => (
          <React.Fragment key={"eachProfileDropDownItem" + i}>
            <Separator className="m-0 p-0 bg-muted" />
            <PopoverClose asChild>
              <Button
                variant={"light"}
                className=" justify-start ps-5 h-12 w-full"
                onClick={() =>
                  _modalContext.open(<ProfileModal initTab={eachItem.url} />)
                }
              >
                {eachItem.icon}
                {eachItem.text}
              </Button>
            </PopoverClose>
          </React.Fragment>
        ))}

        <Separator className="m-0 p-0 bg-muted" />

        <Button
          variant={"light"}
          onClick={() => _currentUserContext.logout()}
          className=" justify-start ps-5 h-12 w-full"
        >
          <LuLogOut /> Logout
        </Button>
      </PopoverContent>
    </Popover>
  );
}
