import React from "react";

import Dict from "models/Dict";

import { isBright } from "services/UtilServices";

import {
  CurrentUserContext,
  useCurrentUserContext,
} from "./CurrentUserProvider";
import { ApiVariables } from "hooks/api/UseApiServices";
import LocalStorageServices from "services/localServices/LocalStorageServices";

interface ThemeContextProps {
  themeColor: string;
  textColor: string;
  bgImage: string;
  isThemeColorBright: boolean;

  setThemeColor: (d: string) => Promise<Dict>;
  setBgImage: (d: string | null) => Promise<Dict>;

  darkModeState: TDarkModes;
  setDarkModeState: (d: TDarkModes) => void;
  isDark: boolean;
}

const ThemeContext = React.createContext({
  // isLoggedIn: () => ThemeLocalServices.isLoggedIn()
} as ThemeContextProps);
ThemeContext.displayName = "ThemeContext";

export class ThemeUtils {
  static INITIAL_BG_IMAGES = [
    "bg1.jpg",
    "bg2.jpg",
    "bg3.jpg",
    "bg4.jpg",
    "bg5.jpg",
    "bg6.jpg",
    "bg7.jpg",
    "bg8.jpg",
    "bg9.jpg",
    "bg10.jpg",
  ];

  static addBaseUrl(url?: string) {
    return !url
      ? url
      : ThemeUtils.INITIAL_BG_IMAGES.includes(url)
      ? ApiVariables.FILE_URL + url
      : ApiVariables.PUBLIC_FILE_URL + url;
  }
}

export type TDarkModes = "dark" | "light" | "auto";
const DARK_MODESW = ["dark", "light", "auto"];

function ThemeProvider({ children }: { children: React.ReactNode }) {
  const _currentUserContext = useCurrentUserContext();

  const [darkModeState, _setDarkModeState] = React.useState<TDarkModes>("auto");
  const [isDark, setIsDark] = React.useState(
    darkModeState === "auto"
      ? window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
      : darkModeState === "dark"
  );

  React.useEffect(() => {
    LocalStorageServices.get("DARK_MODE").then((r) =>
      setDarkModeState(r === null || !DARK_MODESW.includes(r) ? "auto" : r)
    );

    const callback = (event: MediaQueryListEvent) => setIsDark(event.matches);

    if (darkModeState === "auto") {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", callback);
    }

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", callback);
    };
  }, []);

  React.useEffect(() => {
    if (isDark) {
      document.getElementsByTagName("html")[0].classList.add("dark");
    } else {
      document.getElementsByTagName("html")[0].classList.remove("dark");
    }
  }, [isDark]);

  const _themeColor = _currentUserContext.user?.themeColor ?? "#607d8b";

  const setThemeColor = async (themeColor: string) => {
    return await _currentUserContext.updateInfo({
      ..._currentUserContext.user,
      themeColor,
    });
  };

  const setBgImage = async (bgImage: string | null) => {
    return await _currentUserContext.updateInfo({
      ..._currentUserContext.user,
      bgImage,
    });
  };

  const setDarkModeState = (value: TDarkModes) => {
    setIsDark(
      value === "auto"
        ? window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches
        : value === "dark"
    );
    _setDarkModeState(value);
    LocalStorageServices.set("DARK_MODE", value);
  };

  return (
    <ThemeContext.Provider
      value={
        {
          themeColor: _themeColor,
          bgImage: _currentUserContext.user?.bgImage,

          textColor: isBright(_themeColor) ? "text-dark" : "text-light",
          isThemeColorBright: isBright(_themeColor),

          setThemeColor,
          setBgImage,

          darkModeState,
          setDarkModeState,
          isDark,
        } as ThemeContextProps
      }
    >
      {children}
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  const _context = React.useContext(ThemeContext);

  if (!_context) {
    throw new Error("cannot use ThemeContext outside of its provider.");
  }

  return _context;
}

export { ThemeContext, ThemeProvider };
