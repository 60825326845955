import React from "react";
import { stringify } from "flatted";

import {
  objGetParameterCaseInsensitive,
  isEmpty,
  objKeysToLowerCase,
  objRemoveKeys,
} from "services/UtilServices";

import Input from "./Input";
import Select from "./Select";
import Switch from "./Switch";
import ColorPicker from "./ColorPicker";
import Dict from "../../../models/Dict";
import SubmitButton from "./SubmitButton";
import SelectFileButton from "./SelectFileButton";
import SelectImageButton from "./SelectImageButton";
import Validation, { ValidationType } from "./UseValidation";

interface FormValidationsType {
  [n: string]: ValidationType[];
}

export interface FormContextInterface {
  data: Dict;
  setData: (d: Dict) => void;
  removeData: (d: string[]) => void;

  localErrors: Dict;
  setLocalErrors: (fieldName: string, err: string | undefined | null) => void;

  submitErrors?: Dict | null;

  getError: () => string | null;
  getFieldError: (fieldName: string) => string | null;
  canSubmit: boolean;
  requestSubmit: () => void;
  // isFieldRequired: (fieldName: string) => boolean;
}

const FormContext = React.createContext({} as FormContextInterface);
FormContext.displayName = "FormContext";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >,
    "onSubmit"
  > {
  data?: Dict;
  // validations?: { [key: string]: ValidationType[] };
  onSubmit?: (formData: Dict) => Promise<boolean | void | Dict | null> | void;
  onChange?: (formData: Dict) => void;
  children: React.ReactNode;
  legend?: string;
  allowInitSubmit?: boolean;
  allowReSubmitAfterDataChange?: boolean;
}

// var validations: {[x: string]: Validation[]} = {};

const Form = ({
  data = {},
  // validations={},
  onSubmit = (newData: Dict) => {},
  onChange,
  children,
  legend,
  allowInitSubmit = false,
  allowReSubmitAfterDataChange = true,
  ...rest
}: Props) => {
  const initData = () => {
    var _newData: Dict = {};

    React.Children.forEach(children, (eachField: any) => {
      if (
        eachField !== null &&
        eachField !== undefined &&
        eachField.props !== undefined &&
        eachField.props.name !== undefined
      ) {
        _newData[eachField.props.name] = eachField.props.value;
      }
    });

    return {
      ..._newData,
      ...data,
    };
  };

  // const initValidations = () => {
  //   var _newValidations: Dict = {};

  //   React.Children.forEach(children, (eachField: any) => {
  //     if(
  //       eachField !== null &&
  //       eachField !== undefined &&
  //       eachField.props !== undefined &&
  //       eachField.props.validations !== undefined
  //     ) {
  //       _newValidations[eachField.props.name] = eachField.props.validations
  //     }
  //   });

  //   return _newValidations;
  // };

  const ref = React.useRef<HTMLFormElement>(null);
  const isMounted = React.useRef(false);
  const dataGlobal = React.useRef<Dict>(initData());

  const [_localErrors, _setLocalErrors] = React.useState<Dict>({});
  const [_submitErrors, _setSubmitErrors] = React.useState<
    Dict | null | undefined
  >(null);
  const [_data, _setData] = React.useState<Dict>(initData);
  const [_validations, _setValidations] = React.useState<FormValidationsType>(
    {}
  );

  const canSubmit =
    _submitErrors === null &&
    Object.keys(_localErrors).length === 0 &&
    (allowInitSubmit || stringify(initData()) !== stringify(_data));

  // React.useEffect(() => {

  //   console.log("ehsan", data)
  //   if((stringify(initData()) !== stringify(_data)))
  //   {
  //     _setData(initData());
  //   }

  // }, [data]);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };

    // _setData(data);
    // validations = {};

    // React.Children.forEach(children, (eachField: any) => {
    //   if(eachField.props.validations !== undefined)
    //     validations[eachField.props.name] = eachField.props.validations
    // });

    // _setData(initData());
  }, [children]); //, data

  const setLocalErrors = (
    fieldName: string,
    err: string | undefined | null
  ) => {
    if (err) {
      _setLocalErrors({
        ..._localErrors,
        [fieldName]: err,
      });
    } else if (_localErrors && fieldName in _localErrors) {
      let l = { ..._localErrors };
      delete l[fieldName];
      _setLocalErrors(l);
    }
  };

  const requestSubmit = () => {
    if (ref.current) {
      ref.current.requestSubmit();
    }
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (canSubmit) {
      _setSubmitErrors(undefined);

      const _errors = await onSubmit(_data);

      console.log("_errors", _errors, typeof _errors);

      if (!isMounted.current) return;

      if (_errors === undefined) {
        _setSubmitErrors(null);
      } else if (_errors === null) {
        _setSubmitErrors({});
      } else if (Object.getPrototypeOf(_errors) === Object.prototype) {
        // typeof _errors === "object"
        _setSubmitErrors(_errors as Dict);
      } else {
        _setSubmitErrors(_errors ? { "": [""] } : {});
      }
    }
  };

  const setData = (d: Dict) => {
    dataGlobal.current = {
      ...dataGlobal.current,
      ...d,
    };

    _setData(dataGlobal.current);
    onChange?.(dataGlobal.current);

    if (_submitErrors !== null && allowReSubmitAfterDataChange) {
      _setSubmitErrors(null);
    }
  };

  const removeData = (keys: string[]) => {
    dataGlobal.current = objRemoveKeys(dataGlobal.current, keys);

    _setData(dataGlobal.current);
    onChange?.(dataGlobal.current);
    _setLocalErrors((prev) => objRemoveKeys(prev, keys));
    _setValidations((prev) => objRemoveKeys(prev, keys));

    if (_submitErrors !== null && allowReSubmitAfterDataChange) {
      _setSubmitErrors(null);
    }
  };

  const getError = () => {
    let _result = null;

    const namesToValidate = Object.keys(_validations);
    for (const fieldName of namesToValidate) {
      _result = getFieldError(fieldName);

      if (_result !== null) break;
    }

    return _result;
  };

  const getFieldError = (fieldName: string) => {
    let _result = fieldName in _data ? null : "";

    if (fieldName in _data && fieldName in _validations) {
      for (const eachValidation of _validations[fieldName]) {
        if (!eachValidation.isValid(_data[fieldName])) {
          _result = eachValidation.text;
          break;
        }
      }
    }

    if (
      fieldName in _data &&
      _submitErrors !== null &&
      _submitErrors !== undefined &&
      objGetParameterCaseInsensitive(_submitErrors, fieldName)
    ) {
      _result = objGetParameterCaseInsensitive(_submitErrors, fieldName)[0];
    }

    return _result;
  };

  return (
    <FormContext.Provider
      value={{
        data: { ..._data },
        setData,
        removeData,

        localErrors: _localErrors,
        setLocalErrors,

        submitErrors: _submitErrors,

        getError,
        getFieldError,
        canSubmit,
        requestSubmit,
        // isFieldRequired,
      }}
    >
      <form ref={ref} onSubmit={handleSubmit} {...rest}>
        {legend && <legend className="border-b mb-4">{legend}</legend>}

        {children}
      </form>
    </FormContext.Provider>
  );
};

function useFormContext() {
  const _context = React.useContext(FormContext);

  if (!_context) {
    throw new Error("cannot use FormContext outside of its provider.");
  }

  return _context;
}

export {
  FormContext,
  useFormContext,
  Form,
  Input,
  Switch,
  Validation,
  Select,
  SubmitButton,
  SelectFileButton,
  SelectImageButton,
  ColorPicker,
};
