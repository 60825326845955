
import Dict from 'models/Dict';
import { UserFilter } from 'models/User';

import { dataMapper, dateAdd, getNotEmptyFields, getOnlyFields } from 'services/UtilServices';
import useApiServices from './UseApiServices';
import { BASE_URL as EFFORT_BASE_URL } from './UseEffortApi';


export const BASE_URL = "users/";

export default function useUserApi() {

  const apiServices = useApiServices();

  const get = async (filter: UserFilter) => {
    let response = await apiServices.send({
      url: BASE_URL + "filter",
      method: "post",
      expirationDurationMinutes: 2,
      body: getNotEmptyFields({
        ...filter,
        phone: filter.phone ? String(filter.phone) : filter.phone,
        dateAddTo: dateAdd({ date: filter.dateAddTo, days: 1 }),
      }),
    });

    return {
      ...response,
      items: response.result?.map((e: Dict) => ({
        ...e,
        ...dataMapper(e, [
          { "userId": "id" },
        ])
      })) ?? []
    };
  }


  const create = async (user: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "new",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: getNotEmptyFields({
        ...getOnlyFields(user, [
          "avatarUrl",
          "email",
          "firstName",
          "lastName",
          "officeId",
          "password",
          // "phone",
          "role",
          "userName",
        ]),
        phone: user.phone ? String(user.phone) : user.phone,

        "newEffort": true,
        "effortUpdate": true,
        "newComment": true,
        "commentUpdate": true,
      }),
    });

    return response;
  }


  const update = async (user: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: getNotEmptyFields({
        ...getOnlyFields(user, [
          "id",
          "avatarUrl",
          "email",
          "firstName",
          "lastName",
          "officeId",
          "password",
          // "phone",
          "role",
          "userName",
        ])!,

        phone: user.phone ? String(user.phone) : user.phone,
      }),
    });

    return response;
  }


  const remove = async (ids: string[]) => {
    await apiServices.send({
      url: BASE_URL + "delete",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: { ids }
    });
  }

  return {
    get,
    create,
    update,
    remove,
  };
}

