
import Activity, { ActivityFilter } from 'models/Activity';
import Dict from 'models/Dict';
import useApiServices from './UseApiServices';
import { dateAdd, dateGetOnlyDate, getNotEmptyFields, getOnlyFields } from 'services/UtilServices';
import { BASE_URL as EFFORT_BASE_URL } from './UseEffortApi';
import useTextEditorUtils from 'hooks/UseTextEditorUtils';
import Chart from 'models/Chart';

export const BASE_URL = "activities/";

export default function useActivityApi() {

  const apiServices = useApiServices();
  const textEditorUtils = useTextEditorUtils();

  const get = async (filter: ActivityFilter) => {
    let response = await apiServices.send({
      url: BASE_URL + "filter",
      method: "post",
      expirationDurationMinutes: 2,
      body: getNotEmptyFields({
        ...filter,
        dateAddFrom: dateGetOnlyDate(filter.dateAddFrom),
        dateDoneFrom: dateGetOnlyDate(filter.dateDoneFrom),
        dateAddTo: dateGetOnlyDate(filter.dateAddTo), // dateAdd({ date: filter.dateAddTo, days: 1 }),
        dateDoneTo: dateGetOnlyDate(filter.dateDoneTo), // dateAdd({ date: filter.dateDoneTo, days: 1 }),
      }),
    });

    return {
      ...response,
      items: response.result?.map((eachActivity: Activity) => ({
        ...eachActivity,
        description: textEditorUtils.server2Dict(eachActivity.description),
        ticketsCount: eachActivity.ticketsCount
          .map(e => ({
            ...e,
            ticketId: e.ticket.id
          }))
      })) ?? []
    };
  }

  const getChart = async (chart: Chart, filter?: Partial<ActivityFilter>) => {
    let response = await apiServices.send({
      url: BASE_URL + "chart",
      method: "post",
      expirationDurationMinutes: 2,
      body: getNotEmptyFields({
        ...filter,
        ...filter ? {
          dateAddFrom: dateGetOnlyDate(filter.dateAddFrom),
          dateDoneFrom: dateGetOnlyDate(filter.dateDoneFrom),
          dateAddTo: dateGetOnlyDate(filter.dateAddTo), // dateAdd({ date: filter.dateAddTo, days: 1 }),
          dateDoneTo: dateGetOnlyDate(filter.dateDoneTo), // dateAdd({ date: filter.dateDoneTo, days: 1 }),
        } : {},

        ...chart
      }),
    });

    return response;
  }


  const create = async (activity: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "new",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: {
        ...getNotEmptyFields(
          {
            ...getOnlyFields(activity, [
              "userId",
              "effortId",
              "typeId",
              "dateDone",
              { "ticketsCount": ["ticketId", "count", "countApproved"] },
              "duration",
              "isApproved",
            ]),
          }
        ),
        description: JSON.stringify(activity.description),
      }
    });

    return response.id;
  }


  const update = async (activity: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: {
        ...getNotEmptyFields(getOnlyFields(activity, [
          "id",
          "userId",
          "effortId",
          "typeId",
          "dateDone",
          { "ticketsCount": ["ticketId", "count", "countApproved"] },
          "duration",
          "isApproved",
        ])),
        description: JSON.stringify(activity.description),
      }
    });

    return response;
  }


  const remove = async (ids: string[]) => {
    await apiServices.send({
      url: BASE_URL + "delete",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: { ids }
    });
  }

  const bulkApprove = async (data: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update/approve",
      method: "post",
      body: getOnlyFields(data, ["activityIds", "isApproved"])
    });

    return response;
  }


  return {
    get,
    getChart,
    create,
    update,
    remove,
    bulkApprove,
  };
}

