import React from "react";
import { useNavigate } from "react-router";
import { Device } from "@capacitor/device";

import CurrentUserLocalServices from "services/localServices/CurrentUserLocalServices";
import { LuDownload } from "react-icons/lu";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { Button } from "components/ui/button";
import AppLogo from "components/pages/AppLogo";

function DepricatedPage() {
  const navigate = useNavigate();
  const [isAndroid, setIsAndroid] = React.useState<boolean | undefined>();
  // const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    // CurrentUserLocalServices.isLoggedIn()
    // .then((_result) => {
    //     if(_result) {
    //         setIsLoggedIn(_result);
    //     }
    // });

    Device.getInfo().then((info) => {
      console.log(info);
      setIsAndroid(info.platform === "android");
    });
  }, []);

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center p-4">
      <div className="flex items-center flex-col mb-5">
        <AppLogo width={100} height={100} className="object-contain" />

        <div className="mt-3 text-center">
          <h2>PerTask</h2>
          <span>A New Method To Manage Your Tasks</span>
        </div>
      </div>

      <span>Old Version.</span>

      {isAndroid === undefined ? null : isAndroid === true ? (
        <Button
          asChild
          variant={"light"}
          className="border flex items-center justify-end p-3 bg-white my-3 "
        >
          <a target="_blank" href="https://api.prjview.com/File/prjview.apk">
            <span className="h6 mb-0">Download new version.</span>
            <LuDownload />
          </a>
        </Button>
      ) : (
        <span className="my-3 ">To fix this bug call your it-support.</span>
      )}

      <Button
        variant={"light"}
        onClick={async () => {
          await CurrentUserLocalServices.clear();
          navigate("/login", { replace: true });
        }}
        className="border justify-start p-3 bg-white"
      >
        <span className="h6 mb-0">Login Again</span>
        <FaArrowRightToBracket />
      </Button>
    </div>
  );
}

export default DepricatedPage;
