import { z } from "zod";




export const officeFormSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  manager: z.string(),
  color: z.string(),
  location: z.string(),
});
export type OfficeFormType = z.infer<typeof officeFormSchema>;












export type OfficeSortType = "name" | "manager" | "location" | "dateAdd";


export const OfficeSortTypes: { [key: string]: OfficeSortType } = {
  name: "name",
  manager: "manager",
  dateAdd: "dateAdd",
  location: "location",
};

export interface OfficeFilter {
  "pageNumber": number,
  "sortBy": OfficeSortType,
  "ascOrder": boolean,
  numberInPage?: number,

  name?: string,
  manager?: string;
  color?: string,
  location?: string;

  dateAddFrom?: string,
  dateAddTo?: string,
}


export const officeSchema = z.object({
  id: z.string(),
  name: z.string(),
  manager: z.string(),
  color: z.string(),
  location: z.string(),
  dateAdd: z.string(),
});

type Office = z.infer<typeof officeSchema>;
export default Office;

// export default interface Office {
//   id: string,
//   name: string,
//   manager: string;
//   color: string,
//   location: string;
//   dateAdd: string;
// }

