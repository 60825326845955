import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import Ticket, { TicketFilter, TicketSortTypes } from "models/Ticket";

import useTicketApi from "hooks/api/UseTicketApi";
import { listFlatten, listUnique } from "services/UtilServices";

import { MainContext, useMainContext } from "./MainProvider";
import { EffortsContext, useEffortsContext } from "./EffortsProvider";
import {
  CurrentUserContext,
  useCurrentUserContext,
} from "./CurrentUserProvider";
import { useToast } from "components/ui/use-toast";

interface TicketsContextProps {
  tickets: Ticket[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  get: (filter: TicketFilter) => Promise<void>;
  getAll: () => Promise<Dict | undefined>;
  filter: TicketFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const TicketsContext = React.createContext({} as TicketsContextProps);
TicketsContext.displayName = "TicketsContext";

function TicketsProvider({ children }: { children: React.ReactNode }) {
  const DEFAULT_FILTER = {
    pageNumber: -1,
    ascOrder: false,
    sortBy: TicketSortTypes.ADDWHEN,
  };

  const itemsRef = React.useRef<Ticket[] | undefined>(undefined);
  const globalFilterRef = React.useRef<TicketFilter>({ ...DEFAULT_FILTER });

  const [_items, _setTickets] = React.useState<Ticket[]>();
  const [_filter, _setFilter] = React.useState<TicketFilter>(
    globalFilterRef.current
  );
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const { toast } = useToast();
  const ticketApi = useTicketApi();
  const _effortUtils = useEffortUtils();

  const _mainContext = useMainContext();
  const _currentUserContext = useCurrentUserContext();
  const _effortsContext = useEffortsContext();

  // console.log(items, globalFilter,_items, _filter, hasNext)

  // React.useEffect(() => {
  //   setTickets(undefined);
  //   setFilter({
  //     pageNumber: -1,
  //     ascOrder: false,
  //     sortBy: TicketSortTypes.ADDWHEN,
  //   });
  //   _setFilter(globalFilter);
  // setHasNext(true);
  // setTotalNumber(undefined);
  //   return () => {
  //     setTickets(undefined);
  //     setFilter({
  //       pageNumber: -1,
  //       ascOrder: false,
  //       sortBy: TicketSortTypes.ADDWHEN,
  //     });
  //     _setFilter(globalFilter);
  // setHasNext(true);
  // setTotalNumber(undefined);
  //   }
  // }, []);

  const setTickets = (o?: Ticket[]) => {
    itemsRef.current = o;
    _setTickets(o);
  };

  const setFilter = (o: TicketFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const getAll = async () => {
    await get({
      ..._filter,
      pageNumber: 0,
      numberInPage: 999,
    });
  };

  const get = async (filter: TicketFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setTickets(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setTickets(undefined);
      setTotalNumber(undefined);
      return;
    }
    try {
      let { items, totalNumber } = await ticketApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setTickets([...(itemsRef.current ?? []), ...(items as Ticket[])]);
    } catch (e) {
      toast((e as AxiosError).message, {
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setTickets([]);
      }
    }
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData = await ticketApi.create(formData);

      setTickets([formData as Ticket, ...(itemsRef.current ?? [])]);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      await ticketApi.update(formData);

      setTickets(
        itemsRef.current?.map((e) =>
          e.id !== formData.id
            ? e
            : ({
                ...formData,
              } as Ticket)
        )
      );

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) => ({
          ...eachEffort,
          ticketsAvailable: eachEffort.ticketsAvailable?.map((e) =>
            e.id !== formData.id ? e : (formData as Ticket)
          ),
          ticketsCount: eachEffort.ticketsCount?.map((e) =>
            e.ticketId !== formData.id
              ? e
              : {
                  ...e,
                  ticket: formData as Ticket,
                }
          ),
        }))
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Ticket[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await ticketApi.remove(ids);

      setTickets(itemsRef.current?.filter((e) => !ids.includes(e.id)));
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <TicketsContext.Provider
      value={
        {
          tickets: itemsRef.current,
          create,
          update,
          removeMany,

          get,
          getAll,
          filter: _filter,
          hasNext,
          totalNumber,
        } as TicketsContextProps
      }
    >
      {children}
    </TicketsContext.Provider>
  );
}

export function useTicketsContext() {
  const _context = React.useContext(TicketsContext);

  if (!_context) {
    throw new Error("cannot use TicketsContext outside of its provider.");
  }

  return _context;
}

export { TicketsContext, TicketsProvider };
export type { TicketsContextProps };
