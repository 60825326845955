
import { z } from "zod";


export type TicketSortType = "dateadd" | "name";


export const TicketSortTypes: { [key: string]: TicketSortType } = {
  dateadd: "dateadd",
  name: "name",
  // COLOR: "COLOR",
};


export interface TicketFilter {
  "pageNumber": number,
  "sortBy": TicketSortType,
  "ascOrder": boolean,
  numberInPage?: number,

  name?: string,
  color?: string,

  dateAddFrom?: Date,
  dateAddTo?: Date,
}


export const ticketSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string(),
  dateAdd: z.string(),
});

type Ticket = z.infer<typeof ticketSchema>;
export default Ticket;


// export default interface Ticket {
//     id: number,
//     name: string,
//     color: string,
//     dateAdd: string,
// }