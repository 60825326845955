import React from "react";

import Effort from "models/Effort";

import EmptyListIndicator from "components/common/EmptyListIndicator";
import Cheeps from "components/common/Cheeps";
import SectionBase from "./SectionBase";

export default function TicketsAvailableSection({
  effort,
}: {
  effort: Effort;
}) {
  return (
    <SectionBase
      effort={effort}
      title="Available Tickets"
      fieldName="Available tickets"
    >
      {effort.ticketsAvailable?.map((eachTicket, i) => (
        <Cheeps key={"eachTicket" + i} borderColor={eachTicket.color}>
          {eachTicket.name}
        </Cheeps>
      ))}
    </SectionBase>
  );
}
