import React from "react";

import { FaPlus } from "react-icons/fa6";
import { Button } from "components/ui/button";
import { cn } from "services/UtilServices";
import { LuPlus } from "react-icons/lu";

export function NewButtonMini({ onClick = () => { } }) {
  return (
    <Button
      variant={"light"}
      size="sm"
      onClick={onClick}
      className="ms-2 border p-0.5"
    >
      <LuPlus className="!text-base" />
    </Button>
  );
}

export default function NewButton({
  onClick = () => { },
  className = "",
  needText = true,
}) {
  return (
    <Button
      variant={"light"}
      className={cn("px-2", className)}
      onClick={onClick}
    >
      <LuPlus />
      {needText && <span className="hidden lg:inline">New</span>}
    </Button>
  );
}
