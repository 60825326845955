import React from "react";
import { useNavigate } from "react-router-dom";

import { cn, strSplitCammelCase } from "services/UtilServices";

import { useModalContext } from "providers/ModalProvider";
import { SideMenuProvider } from "providers/SideMenuProvider";

import Link from "../../common/Link";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { SearchModalBody } from "./SearchSection";
import ProfileSection from "./ProfileSection";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import NotificationButton from "./NotificationSection";

import { FaArrowLeft, FaMagnifyingGlass } from "react-icons/fa6";
import { DialogContent } from "components/ui/dialog";
import { Button } from "components/ui/button";
import MobileHamburgerButton from "./MobileHamburgerButton";
import Directionality from "components/common/Directionality";
import { useMainLayoutContext } from "layouts/MainLayout";

function MobileMainHeader({ children, title, back }: MainHeaderProps) {
  const _modalContext = useModalContext();
  const _currentUserContext = useCurrentUserContext();

  const navigate = useNavigate();

  return (
    <div className="flex md:hidden items-center relative -mx-3">
      {back && (
        <Button
          variant={"light"}
          className=" relative  "
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
        </Button>
      )}

      <MobileHamburgerButton />

      {children}

      <div className="flex-1 min-w-0 flex items-center gap-1">
        {typeof title === "string" ? (
          <Directionality
            className={cn("font-bold capitalize truncate inline-block ")}
          >
            {strSplitCammelCase(title)}
          </Directionality>
        ) : (
          title
        )}
      </div>

      <NotificationButton />

      <Button
        variant={"light"}
        className=" relative"
        onClick={() =>
          _modalContext.open(
            <DialogContent onBgClickClosable>
              <div className=" -m-4 h-[95vh]">
                <SearchModalBody onDismiss={() => _modalContext.dismiss()} />
              </div>
            </DialogContent>
          )
        }
      >
        <FaMagnifyingGlass />
      </Button>

      <Link
        href="/profile/"
        variant={"light"}
        className=" mx-2 p-0 rounded-full relative "
      >
        <ProfileAvatar user={_currentUserContext.user} needHover={false} />
      </Link>
    </div>
  );
}

function LaptopMainHeader({
  children,
  title,
  showUserName = true,
  back,
}: MainHeaderProps) {
  const navigate = useNavigate();

  if (!title) {
    title = children;
  }

  return (
    <div className="hidden md:flex h-16  items-center px-3 py-2 ">
      {/* {back && (
        <Button
          variant={"light"}
          className=" relative  "
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft />
        </Button>
      )} */}

      <div className="flex-1 capitalize">
        {typeof title === "string" ? <b>{strSplitCammelCase(title)}</b> : title}
      </div>
      {/* <Link
          href="/"
          variant={"outline-light"}
          className="border-0 p-0 bg-transparent "
        >
          <AppLogo className="hover:brightness-100 hover:invert-0 h-7 " />
        </Link> */}

      {/* <div className="mx-auto w-full px-4 max-w-lg h-7 ">
          <SearchSection />
        </div> */}

      {typeof title !== "string" && (
        <div className="border-s h-3/4 bg-danger mx-3"></div>
      )}

      <NotificationButton />

      <ProfileSection needName={showUserName} />
    </div>
  );
}

export type MainHeaderProps = {
  children?: React.ReactNode;
  title?: React.ReactNode;
  back?: boolean;
  showUserName?: boolean;
};

export default function MainHeaderBody({ ...props }: MainHeaderProps) {
  return (
    <SideMenuProvider>
      <header
        className={cn(
          "w-full top-0 right-0 left-0 z-100 border-b bg-background z-20 relative"
        )}
        // style={{
        //   background: _themeContext.themeColor,
        // }}
      >
        {/* <div className="px-3 py-2 "> */}
        {/* {children ? (
            <div className="flex lg:hidden items-center relative h-10 -my-3">
              <div className="absolute left-0 right-0 bottom-0 top-0 text-center flex items-center justify-center">
                <span className="mb-0">
                  {typeof title === "string" ? (
                    <CollapseText text={title} maxLength={15} />
                  ) : (
                    title
                  )}
                </span>
              </div>

              {children}
            </div>
          ) : ( */}
        {/* )} */}

        <MobileMainHeader {...props} />
        <LaptopMainHeader {...props} />
        {/* </div> */}
      </header>
    </SideMenuProvider>
  );
}

export function MainHeader({ ...props }: MainHeaderProps) {
  const mainLayout = useMainLayoutContext();

  React.useEffect(() => {
    mainLayout.setHeader(props);
  }, [props]);

  return <></>;
}
