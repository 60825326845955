import React from "react";
import { cn, isFarsi } from "services/UtilServices";

export default function Directionality({
  children,
  className,
  ...props
}: {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLSpanElement>) {
  const _isFarsi = typeof children === "string" && isFarsi(children);

  return (
    <span
      dir={_isFarsi ? "rtl" : undefined}
      className={cn({ "inline-block": _isFarsi }, className)}
      {...props}
    >
      {children}
    </span>
  );
}
