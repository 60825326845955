import React from "react";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import {
  Form,
  Select,
  SubmitButton,
  useFormContext,
} from "components/common/Forms/Form";
import {
  dateGetOnlyDate,
  dateGetToday,
  isEmpty,
  objList2Options,
} from "services/UtilServices";
import TicketsCountInputField, {
  ticketDecimalIsValid,
  ticketIsRequired,
} from "components/pages/EffortsSection/TicketsCountInputField";
import TicketCount from "models/TicketCount";
import DatePickerInput from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import { useMainContext } from "providers/MainProvider";
import TextEditor from "components/common/Forms/TextEditor";
import { Button } from "components/ui/button";

function ApproveSubmitButton({ formData }: { formData: Dict }) {
  const _formContext = useFormContext();

  const _isDisabled =
    !_formContext.canSubmit || !isEmpty(_formContext.submitErrors);

  const onClick = () => {
    _formContext.setData({
      isApproved: !formData.isApproved,
    });
    setInterval(() => _formContext.requestSubmit(), 100);
  };

  return (
    <Button
      variant={"primary"}
      onClick={onClick}
      disabled={
        _formContext.submitErrors === undefined ? undefined : _isDisabled
      }
    >
      {formData.isApproved ? "Not Approve" : "Approve"} & Save
    </Button>
  );
}

export default function ActivityApproveModal({
  formData,
  onSubmit,
}: {
  formData: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  const _mainContext = useMainContext();
  const _effortUtils = useEffortUtils();

  let _project = _effortUtils.getParentProject(formData.effortId);
  const _replaceApprovedTickets = formData?.ticketsCount.every(
    (e: TicketCount) => e.countApproved === 0
  );

  const _onSubmit = async (d: Dict) => {
    const _d = { ...d };

    _d.type = _project?.types?.find((e) => e.id === _d.typeId);

    _d.ticketsCount = _project?.ticketsAvailable?.map((eachTicket) => ({
      ticketId: eachTicket.id,
      ticket: eachTicket,

      count:
        _d.ticketsCount?.find((a: TicketCount) => eachTicket.id === a.ticketId)
          ?.count ?? 0,

      countApproved:
        _d.ticketsApproved?.find(
          (a: TicketCount) => eachTicket.id === a.ticketId
        )?.countApproved ?? 0,
    }));

    return await onSubmit(_d);
  };

  return (
    <DialogContent title="Activities">
      <Form
        allowInitSubmit
        onSubmit={_onSubmit}
        data={{
          ...formData,
          dateDone: dateGetOnlyDate(formData.dateDone),
          // typeId: _project?.types?.some((e) => e.id === formData.typeId) ?
          //             formData.typeId :
          //             listFirst( _project?.types)?.id,

          ticketsApproved: _replaceApprovedTickets
            ? formData.ticketsCount.map((e: TicketCount) => ({
                ...e,
                countApproved: e.count,
              }))
            : [...formData.ticketsCount],
        }}
      >
        <Select
          name="effortId"
          label="Task"
          required
          disabled
          options={objList2Options(
            _mainContext.efforts?.filter((e) => e.parentId),
            "title"
          )}
        />

        <Select
          name="typeId"
          label="Type"
          required
          disabled
          options={objList2Options(_project?.types)}
        />

        <DatePickerInput
          name="dateDone"
          label="Date"
          required
          disabled
          toDate={dateGetToday()}
        />

        <TextEditor name="description" label="Description" disabled />

        <div className="flex flex-wrap gap-x-5">
          <div className="flex-auto">
            <TicketsCountInputField
              name="ticketsCount"
              label="Booking"
              required
              disabled
              tickets={_project?.ticketsAvailable}
              validations={[ticketIsRequired(), ticketDecimalIsValid()]}
            />
          </div>

          <div className="flex-auto">
            <TicketsCountInputField
              name="ticketsApproved"
              label="Approved Booking"
              tickets={_project?.ticketsAvailable}
              ticketCountFieldName="countApproved"
              validations={[ticketDecimalIsValid("countApproved")]}
            />
          </div>
        </div>

        <DialogFooter>
          <SubmitButton />
          <ApproveSubmitButton formData={formData} />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
