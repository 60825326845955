import Dict from "models/Dict";

import {
  ColorPicker,
  Form,
  Input,
  SubmitButton,
} from "components/common/Forms/Form";
import { DialogContent, DialogFooter } from "components/ui/dialog";

export default function PriorityCreateUpdateModal({
  formData,
  onSubmit,
}: {
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  return (
    <DialogContent title="Priorities">
      <Form
        data={
          formData !== undefined && "id" in formData
            ? {
                ...formData,
              }
            : {}
        }
        onSubmit={onSubmit}
      >
        <Input name="name" maxLength={256} required autoFocus />
        <ColorPicker name="color" required />
        <Input name="weight" type="number" required />

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
