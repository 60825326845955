
import Attachment, { AttachmentFilter } from 'models/Attachment';
import Dict from 'models/Dict';
import { getOnlyFields } from 'services/UtilServices';
import useApiServices, { ApiVariables } from './UseApiServices';
import { BASE_URL as EFFORT_BASE_URL } from './UseEffortApi';
import useDownloadServices from 'hooks/UseDownloadServices';

export const BASE_URL = "attachments/";

export default function useAttachmentApi() {

  const apiServices = useApiServices();
  const downloadServices = useDownloadServices();

  const create = async (attachment: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "new",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: getOnlyFields(attachment, ["effortId", "name", "url"])
    });


    return response.id;
  }


  const remove = async (ids: string[]) => {
    await apiServices.send({
      url: BASE_URL + "delete",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: { ids }
    });
  }


  const download = async (attachment: { url: string; name?: string }) => {

    let response = await apiServices.send({
      url: (attachment.url.startsWith("http") ? "" : ApiVariables.SECURE_FILE_URL) + attachment.url,
      method: "get",
      responseType: "blob",
      expirationDurationMinutes: 60 * 24
    });

    downloadServices.downloadByBlob({
      blob: response.blob,
      filename: attachment.name ?? response.filename,
    });

    return response;
  }


  return {
    create,
    remove,
    download,
  }

}

