import React, { useContext } from "react";
import {
  SketchPicker,
  CompactPicker,
  SwatchesPicker,
  CirclePicker,
} from "react-color";

import { useModalContext } from "providers/ModalProvider";

import { useFormContext } from "./Form";
import { cn, listRandomItem } from "services/UtilServices";
import { DialogContent } from "components/ui/dialog";
import { Label } from "components/ui/label";
import useValidation, { ValidationType } from "./UseValidation";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";

export const COLORS = [
  "#bdbdbd",
  "#11296b",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
];

interface Props {
  name: string;
  label?: string;
  value?: boolean;
  validations?: ValidationType[];
  wrapperClassName?: string;
  [rest: string]: any;
}

export default function ColorPicker({
  name,
  label,
  value,
  validations,
  wrapperClassName = "",
  required,
  ...rest
}: Props) {
  const [isVisible, setIsVisible] = React.useState(false);
  const _modalContext = useModalContext();
  const _formContext = useFormContext();
  const validation = useValidation();

  React.useEffect(() => {
    console.log("ehsan");
    if (value !== undefined) {
      _formContext.setData({ [name]: value });
    } else if (
      required &&
      (!(name in _formContext.data) ||
        _formContext.data[name] === undefined ||
        _formContext.data[name] === null)
    ) {
      _formContext.setData({ [name]: listRandomItem(COLORS) });
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    _formContext.setData({ [name]: event.target.checked });
    // _setValue(event.target.checked);
  };

  const isFieldRequired = () => {
    const _isRequiredType = validation.isRequired().type;

    return (
      rest.required ||
      (validations !== undefined &&
        validations!.some((each) => each.type === _isRequiredType))
    );
  };

  const error =
    _formContext.data[name] === undefined
      ? null
      : _formContext.getFieldError(name);

  // const onClick = () => {
  //   _modalContext.open(
  //     <DialogContent fullScreen={false} onBgClickClosable={true} size={"sm"}>
  //       <div className="-m-4">
  //         <SwatchesPicker
  //           color={_formContext.data[name]}
  //           onChangeComplete={(d) => {
  //             _formContext.setData({ [name]: d.hex });
  //             _modalContext.dismiss();
  //           }}
  //         />
  //       </div>
  //     </DialogContent>
  //   );
  // };

  return (
    // <Popover>
    //   <PopoverTrigger asChild>
    <div className={cn("w-full block mb-3 relative ", wrapperClassName)}>
      <Label className="capitalize ">
        {label ?? name}
        {isFieldRequired() && <span className="text-destructive">*</span>}
      </Label>

      <div className="flex items-center mt-2">
        <CirclePicker
          width="100%"
          colors={COLORS}
          circleSize={20}
          color={_formContext.data[name]}
          onChangeComplete={(d) => {
            _formContext.setData({ [name]: d.hex });
          }}
        />
      </div>

      {error && (
        <div className="invalid-feedback mt-2">
          <span>{error}</span>
        </div>
      )}
    </div>
    //   </PopoverTrigger>
    //   <PopoverContent align="start">
    //     <CirclePicker
    //       color={formContext.data[name]}
    //       onChangeComplete={(d) => {
    //         formContext.setData({ [name]: d.hex });
    //         _modalContext.dismiss();
    //       }}
    //     />
    //   </PopoverContent>
    // </Popover>
  );
}
