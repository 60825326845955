import React from "react";

export default function useEffectStrictMode(
  callback?: () => any,
  deps: React.DependencyList = [],
  returnCallback?: () => any
) {

  const init = React.useRef(true); //process.env.NODE_ENV === "production"
  const retInit = React.useRef(true); //process.env.NODE_ENV === "production"

  React.useEffect(() => {
    if (init.current) {
      callback?.();
    } else {
      init.current = true;
    }

    return () => {
      if (retInit.current) {
        returnCallback?.();
      } else {
        retInit.current = true;
      }
    };
  }, deps);
}
