import React from "react";

import useUserUtils from "hooks/utils/UseUserUtils";
import Member, { MemberAccessTypes } from "models/Member";
import ProfileAvatar, {
  ProfileAvatarProps,
} from "components/pages/profile/account/ProfileAvatar";
import { cn } from "services/UtilServices";
import UserProfileHoverCard from "components/pages/admin/UsersSection/UserProfileHoverCard";

export type MemberAvatarProps = {
  member?: Member;
} & ProfileAvatarProps;

export default function MemberAvatar({
  member,
  needHover = true,
  ...props
}: MemberAvatarProps) {
  const Icon = member ? MemberAccessTypes.icons[member.accessType] : "";
  const _userUtils = useUserUtils();

  const _avatar = (
    <ProfileAvatar
      needHover={false}
      user={member?.user}
      title={
        _userUtils.getFullName(member?.user) +
        (member?.accessType !== undefined
          ? ` (${MemberAccessTypes.options[member.accessType]})`
          : "")
      }
      {...props}
    />
  );

  return (
    <>
      {member &&
        (needHover ? (
          <UserProfileHoverCard
            user={member.user}
            member={member}
            align={props.align}
            side={props.side}
          >
            {_avatar}
          </UserProfileHoverCard>
        ) : (
          _avatar
        ))}
    </>
  );
}
