import { NetworkImage } from "components/common/NetworkImage";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import Effort from "models/Effort";
import { cn, isEmpty, listFirst } from "services/UtilServices";


export default function EffortBanner({ effort, className }: { effort?: Effort, className?: string }) {
    const textEditorUtils = useTextEditorUtils();
    const imgs = textEditorUtils.getImages(effort?.description);

    return (
        <>
            {!isEmpty(imgs) && (
                <NetworkImage
                    secure
                    src={listFirst(imgs)?.url}
                    className={cn("", className)}
                />
            )}
        </>
    );
}