import React from "react";

import Dict from "models/Dict";
import { TagFilter } from "models/Tag";

import { Form, Input, SubmitButton } from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useTagUtils from "hooks/utils/UseTagUtils";

export default function TagFilterModal({
  filter,
  onSubmit,
}: {
  filter: TagFilter;
  onSubmit: (data: Dict) => void;
}) {
  const tagUtils = useTagUtils();

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        <Input name="name" label="Tag Name" maxLength={256} />

        <DatePickerInputRange name="dateAdd" label="Date Add" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={tagUtils.isFilterActive({ filter })}
            onClick={() => onSubmit({ pageNumber: -1 })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
