import React from "react";

import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import useUserUtils from "hooks/utils/UseUserUtils";
import Member, { MemberAccessTypes } from "models/Member";

import DeleteButton from "components/common/DeleteButton";
import MemberAvatar from "./MemberAvatar";
import { LuUserPlus, LuX } from "react-icons/lu";
import { Button } from "components/ui/button";
import useMemberUtils from "hooks/utils/UseMemberUtils";
import SectionBase from "../SettingSection/SectionBase";

function EachItem({
  member,
  onClick,
  onRemove,
}: {
  member: Member;
  onClick?: () => void;
  onRemove?: () => void;
}) {
  const _userUtils = useUserUtils();
  const Icon = MemberAccessTypes.icons[member.accessType];

  return (
    <Button
      asChild
      variant="light"
      onClick={
        onClick
          ? (ev) => {
              ev.stopPropagation();
              onClick();
            }
          : undefined
      }
      className="rounded-full border hover:border-foreground/50 p-0 bg-muted "
    >
      <div>
        <MemberAvatar member={member} size={40} needHover={false} />

        <div className="py-1 flex-1 text-start">
          {_userUtils.getFullName(member.user)}
          <div className="text-xs text-muted-foreground ">
            <Icon className="!text-sm" />{" "}
            {MemberAccessTypes.options[member.accessType]} Access
          </div>
        </div>

        {onRemove ? (
          <DeleteButton
            size="sm"
            onConfirm={onRemove}
            className="rounded-full aspect-square "
          >
            <LuX />
          </DeleteButton>
        ) : (
          <div className="ps-4"></div>
        )}
      </div>
    </Button>
  );
}

export default function MembersSection({ effort }: { effort: Effort }) {
  const _effortUtils = useEffortUtils();
  const _memberUtils = useMemberUtils(effort);

  return (
    <SectionBase
      effort={effort}
      editable={_memberUtils.canCreate()}
      icon={<LuUserPlus className="!text-base" />}
      onClick={() => _memberUtils.openCreateUpdateModal()}
      title={_effortUtils.isProject(effort) ? "Members" : "Assignees"}
    >
      {effort.members
        ?.filter((e) => e.isDeleted === false)
        .map((eachMember, i) => (
          <EachItem
            key={"eachAttachment" + i}
            member={eachMember}
            onClick={
              _memberUtils.canEdit(eachMember)
                ? () => _memberUtils.openCreateUpdateModal(eachMember)
                : undefined
            }
            onRemove={
              _memberUtils.canDelete([eachMember])
                ? () => _memberUtils.removeMany([eachMember])
                : undefined
            }
          />
        ))}
    </SectionBase>
  );
}
