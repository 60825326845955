import React from "react";

import MainSection from "components/layouts/MainSection";

function Error403Page() {
  return (
    <MainSection title="Error 403!" back>
      <div className="h-full flex flex-col items-center justify-center text-foreground">
        <h1>403</h1>
        <h6>Access Denied!</h6>
      </div>
    </MainSection>
  );
}

export default Error403Page;
