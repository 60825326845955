import React from "react";

import { ModalProvider } from "providers/ModalProvider";
import { ActivitysProvider } from "providers/ActivitysProvider";

import { useActivitysPageBody } from "components/pages/EachEffort/ActivitysSection/ActivitysSection";
import ItemsListMainSection from "components/common/ItemsListSection/ItemsListMainSection";
import Activity from "models/Activity";

function PageBody() {
  const props = useActivitysPageBody();
  return <ItemsListMainSection<Activity> {...props} />;
}

export default function ActivityReportPage() {
  return (
    <ActivitysProvider>
      <ModalProvider>
        <PageBody />
      </ModalProvider>
    </ActivitysProvider>
  );
}
