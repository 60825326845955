import React from "react";

import Dict from "models/Dict";
import {
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { AuditLogActionTypes, AuditLogFilter } from "models/AuditLog";
import { useUsersContext } from "providers/UsersProvider";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useUserUtils from "hooks/utils/UseUserUtils";
import useAuditLogUtils from "hooks/utils/UseAuditLogUtils";

export default function AuditLogFilterModal({
  filter,
  onSubmit,
  pageType = "profile",
}: {
  filter: AuditLogFilter;
  onSubmit: (data: Dict) => void;
  pageType?: "profile" | "admin";
}) {
  const _usersContext = useUsersContext();
  const _userUtils = useUserUtils();
  const auditLogUtils = useAuditLogUtils();

  let _users = _usersContext.users;

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        {pageType === "admin" && (
          <Select
            name="userIds"
            label="Users"
            multiple
            options={_users?.map((e) => ({
              value: e.id,
              label: _userUtils.getFullName(e),
            }))}
          />
        )}

        <Select
          name="actionType"
          label="Action Type"
          options={AuditLogActionTypes.toLabelValues()}
          value=""
        />

        <DatePickerInputRange name="dateAdd" label="Date Add" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={auditLogUtils.isFilterActive({
              filter,
              pageType: pageType,
            })}
            onClick={() => onSubmit({ pageNumber: -1 })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
