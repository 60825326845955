import { StateFilter } from "models/State";
import { hasOtherKeysExcept } from "services/UtilServices";



export default function useStateUtils() {
  const isFilterActive = ({ filter }: { filter: StateFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage"]);
  }

  return {
    isFilterActive
  }
}

