import React from "react";

import Dict from "../../../../models/Dict";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import { Form, Input, SubmitButton } from "../../../common/Forms/Form";
import { AxiosError } from "axios";
import { isEmpty } from "services/UtilServices";
import useCurrentUserApi from "hooks/api/UseCurrentUserApi";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import { Button } from "components/ui/button";

export default function EmailEditModal({ onSubmit }: { onSubmit: () => void }) {
  const _currentUserContext = useCurrentUserContext();
  const currentUserApi = useCurrentUserApi();

  const [data, setData] = React.useState<Dict>({
    email: _currentUserContext.user!.email ?? "",
  });

  const onEnterSubmit = async (d: Dict) => {
    let _errors = {};

    try {
      await currentUserApi.sendOtp(d.email);

      setData({
        email: d.email,
        otp: "",
      });
    } catch (e) {
      _errors = e as AxiosError;
    }

    return _errors;
  };

  const onOtpEnterSubmit = async (d: Dict) => {
    let _errors = await _currentUserContext.updateEmail(d);

    if (isEmpty(_errors)) {
      onSubmit();
    }

    return _errors;
  };

  return (
    <DialogContent title={"Update Email"} size={"sm"}>
      <Form
        data={data}
        onSubmit={onOtpEnterSubmit}
        // onSubmit={"otp" in data ? onOtpEnterSubmit : onEnterSubmit}
      >
        <Input
          name="email"
          label="email"
          type="email"
          required
          readOnly={"otp" in data}
          placeholder="example@email.com"
        />

        {"otp" in data && (
          <Input
            name="otp"
            label="Verification Code"
            required
            maxLength="5"
            description={
              <span className="block text-sm">
                Enter the Code which we've sent to your email.
              </span>
            }
          />
        )}

        <div className="flex justify-center items-center">
          {"otp" in data && (
            <Button
              variant={"light"}
              className=" border me-2"
              onClick={() => {
                setData({
                  email: data.email,
                });
              }}
            >
              Change Email
            </Button>
          )}

          <DialogFooter>
            <SubmitButton />
          </DialogFooter>
        </div>
      </Form>
    </DialogContent>
  );
}
