import { useThemeContext } from "providers/ThemeProvider";

export default function LoginLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const _themeContext = useThemeContext();

  return (
    <main
      className="flex justify-center items-center bg-cover bg-center bg-no-repeat min-h-screen bg-background"
      //   style={{
      //     backgroundImage: `linear-gradient(to right, #${
      //       _themeContext.isDark ? "000000" : "ffffff"
      //     }fe, #${
      //       _themeContext.isDark ? "000000" : "ffffff"
      //     }dd), url('/images/login-bg.jpg')`,
      //   }}
    >
      {children}
    </main>
  );
}
