import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import Effort from "models/Effort";
import {
  LuListTree,
  LuMessagesSquare,
  LuPaperclip,
  LuTimer,
  LuUsers,
} from "react-icons/lu";
import { strSplitCammelCase } from "services/UtilServices";

function EachItem({
  count,
  icon,
  index,
}: {
  count?: number;
  icon: React.ReactNode;
  index: string;
}) {
  return (
    <>
      {Boolean(count && count > 0) && (
        <div
          className="inline-flex items-center"
          title={strSplitCammelCase(index)}
        >
          {icon}
          <div className="pt-1">{count}</div>
        </div>
      )}
    </>
  );
}

export default function EffortCardLittleDetails({
  effort,
  needs,
  iconClassName,
}: {
  effort: Effort;
  needs?: { [k: string]: boolean };
  iconClassName?: string;
}) {
  const _effortUtils = useEffortUtils();

  const _needs = Object.entries({
    effortActivityRecordsCount: true,
    subTasksCount: true,
    comments: true,
    attachments: true,
    ...needs,
  })
    .filter(([k, v]) => v)
    .map(([k, v]) => k);

  const items = [
    {
      index: "effortActivityRecordsCount",
      icon: <LuTimer className={iconClassName} />,
      count: effort.effortActivityRecordsCount,
    },
    {
      index: "subTasksCount",
      icon: <LuListTree className={iconClassName} />,
      count: _effortUtils.getSubEfforts(effort.id)?.length,
    },
    {
      index: "comments",
      icon: <LuMessagesSquare className={iconClassName} />,
      count: effort.comments?.length,
    },
    {
      index: "attachments",
      icon: <LuPaperclip className={iconClassName} />,
      count: effort.attachments?.length,
    },
    {
      index: "members",
      icon: <LuUsers className={iconClassName} />,
      count: effort.members?.length,
    },
  ].filter((e) => _needs?.includes(e.index));

  return (
    <>
      {items.some((e) => e.count) && (
        <div className="flex flex-wrap gap-2 ">
          {items.map((eachItem, i) => (
            <EachItem key={"eachLittleItem" + i} {...eachItem} />
          ))}
        </div>
      )}
    </>
  );
}
