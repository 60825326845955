import React from "react";

import Ticket, { TicketFilter, TicketSortType } from "models/Ticket";
import Dict from "models/Dict";

import { cn, dateGetTime, isEmpty } from "services/UtilServices";

import { useTicketsContext, TicketsProvider } from "providers/TicketsProvider";
import { useModalContext } from "providers/ModalProvider";

import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import TicketCreateUpdateModal from "./TicketCreateUpdateModal";
import TicketFilterModal from "./TicketFilterModal";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import NewButton from "components/common/AdminSection/NewButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import LocalDateTime from "components/common/LocalDateTime";
import useTicketUtils from "hooks/utils/UseTicketUtils";
import ItemsListMainSection from "components/common/ItemsListSection/ItemsListMainSection";

function EachTicketItem({ ticket, index }: { ticket: Ticket; index: number }) {
  return (
    <div className="flex items-center ">
      <div>
        <h6 className="mb-0 "> {index + 1} </h6>
      </div>

      <div
        className={cn(
          "rounded-full aspect-square mx-auto my-auto ms-2 me-2 mb-1 w-5 h-5 "
        )}
        style={{
          background: ticket.color,
        }}
      />

      <div>
        <h6 className="mb-0 me-2">{ticket.name}</h6>
      </div>
    </div>
  );
}

function TicketsPageBody() {
  const _modalContext = useModalContext();
  const _ticketsContext = useTicketsContext();
  const ticketUtils = useTicketUtils();

  const onRemove = async (datas: Ticket[]) => {
    _ticketsContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _ticketsContext.update(data)
        : await _ticketsContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <TicketCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    _ticketsContext.get({
      ...formData,
      pageNumber: -1,
    } as TicketFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <TicketFilterModal
        filter={_ticketsContext.filter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  return (
    <ItemsListMainSection<Ticket>
      title="Tickets"
      data={_ticketsContext.tickets}
      // ellipsisResponsiveBreakPoint=""

      fields={[
        // {
        //   value: "",
        //   label: "#",
        //   className: "text-start whitespace-nowrap",
        //   width: "70px",
        //   isSortable: false,
        //   getUiContent: (e, i) => i + 1,
        // },

        {
          value: "name",
          label: "name",
          width: "auto",
          isTitleField: true,
          // isSortable: false,
          className: "whitespace-nowrap",
        },

        {
          value: "color",
          label: "color",
          width: "10%",
          isSortable: false,
          isSearchable: false,
          getUiContent: (e, i) => (
            <div
              className={cn("rounded-full aspect-square w-7 h-7 ")}
              style={{
                background: e.color,
              }}
            />
          ),
        },

        {
          value: "dateAdd",
          label: "Created on",
          width: "auto",
          // isSortable: false,
          getValue: (e) => dateGetTime(e.dateAdd),
          getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
          className: "whitespace-nowrap",
        },
      ]}
      onLoadMore={
        !_ticketsContext.hasNext
          ? undefined
          : async () => {
              await _ticketsContext.get({
                ..._ticketsContext.filter,
                pageNumber: _ticketsContext.filter.pageNumber + 1,
              });
            }
      }
      onSortField={(v) => {
        _ticketsContext.get({
          ..._ticketsContext.filter,
          sortBy: v as TicketSortType,
          pageNumber: -1,
        });
      }}
      onSortDir={(v) => {
        _ticketsContext.get({
          ..._ticketsContext.filter,
          ascOrder: v === 1,
          pageNumber: -1,
        });
      }}
      initialSelectedSortFieldValue="dateAdd"
      buildCardTitleRow={(v, i) => <EachTicketItem ticket={v} index={i} />}
      onItemEdit={(u) => openCreateUpdateModal}
      onItemClick={(u) => openCreateUpdateModal}
      onItemDelete={() => _ticketsContext.removeMany}
      actionsSection={
        <>
          <FilterButton
            count={_ticketsContext.totalNumber}
            isActive={ticketUtils.isFilterActive({
              filter: _ticketsContext.filter,
            })}
            onClick={openFilterModal}
          />

          <NewButton onClick={() => openCreateUpdateModal()} />
        </>
      }
    />
  );
}

export default function TicketsPage() {
  return (
    <TicketsProvider>
      <TicketsPageBody />
    </TicketsProvider>
  );
}
