import Chart, { ChartData, ChartTypes } from "models/Chart";
import Dict from "models/Dict"
import { groupBy, listFirst, listUnique } from "services/UtilServices";



export default function useChartUtils(chart: Chart) {


    const getTitle = (name: string) => {
        return name.substring(0, 15) + (name.length > 15 ? "..." : "");
    };

    const getXAxis = (data?: ChartData | null) => ({
        categories: data?.labels?.map(getTitle) ?? [],
    })

    const getLabel = (data?: ChartData | null) => data?.labels?.map(getTitle) ?? [];

    const getColorsFromLabel = (data?: ChartData | null) => data?.labelList?.map(e => e.color) ?? [];

    const getColors = (data?: ChartData | null) => {
        let result: string[] = [];


        const groupedValues = groupBy({
            callbackFunc: (e) => e?.id ?? "",
            list: data?.labelList?.map((l) => l.valueList).flat() ?? [],
        });

        result = (
            groupedValues
                ? Object.values(groupedValues)
                    .map((v) => listFirst(v)?.color)
                    .filter(e => e)
                : []
        ) as string[];

        return result;
    }


    const getPieSeries = (data?: ChartData | null) => data?.labelList?.map((e) => e.sum)



    const getLineSeries = (data?: ChartData | null) => {
        let result: { name?: string, data: number[] }[] = [];

        if (data) {

            const uniqueValues = listUnique(
                data!.labelList?.map((l) => l.valueList)
                    .flat()
                    .filter(e => e)
                    .map(e => e!)
            );

            result = uniqueValues?.map(eachVal => ({
                name: getTitle(eachVal.title),
                data: data?.labelList?.map(e => e.valueList?.find(a => a.id === eachVal.id)?.sum ?? 0) ?? []
            })) ?? [];


            if (chart.stackBy) {

            }
        }

        return result;
    }


    const apexChartOptions: Dict = {
        [ChartTypes.PIE]: {
            chart: { type: "pie", },
            getColors: getColorsFromLabel,
            getSeries: getPieSeries,
            getXAxis: getXAxis,
            getLabel: getLabel,
        },
        [ChartTypes.DONUT]: {
            chart: { type: "donut", },
            getColors: getColorsFromLabel,
            getSeries: getPieSeries,
            getXAxis: getXAxis,
            getLabel: getLabel,
        },

        [ChartTypes.LINE]: {
            chart: { type: "line", },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
        [ChartTypes.SMOOTH_LINE]: {
            chart: { type: "line", }, stroke: { curve: "smooth", },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
        [ChartTypes.STACKED_LINE]: {
            chart: { type: "line", stacked: true },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
        [ChartTypes.P100_STACKED_LINE]: {
            chart: { type: "line", stackType: "100%", stacked: true },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },

        [ChartTypes.BAR]: {
            chart: { type: "bar", },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
        [ChartTypes.STACKED_BAR]: {
            chart: { type: "bar", stacked: true },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
        [ChartTypes.P100_STACKED_BAR]: {
            chart: { type: "bar", stackType: "100%", stacked: true },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },

        [ChartTypes.HORIZONTAL_BAR]: {
            chart: { type: "bar", }, plotOptions: { horizontal: true },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
        [ChartTypes.HORIZONTAL_STACKED]: {
            chart: { type: "bar", stacked: true }, plotOptions: { horizontal: true },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
        [ChartTypes.P100_HORIZONTAL_STACKED_BAR]: {
            chart: { type: "bar", stackType: "100%", stacked: true }, plotOptions: { horizontal: true },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },

        [ChartTypes.AREA]: {
            chart: { type: "area", },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
        [ChartTypes.STACKED_AREA]: {
            chart: { type: "area", stacked: true },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
        [ChartTypes.P100_STACKED_AREA]: {
            chart: { type: "area", stackType: "100%", stacked: true },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },

        [ChartTypes.BUBBLE]: {
            chart: { type: "bubble", },
            getColors: getColors,
            getSeries: getLineSeries,
            getXAxis: getXAxis,
            getLabel: getLabel
        },
    }

    return apexChartOptions[chart.type ?? ChartTypes.BAR];
}