import React from "react";

import Dict from "models/Dict";

import { Form, Select, SubmitButton } from "components/common/Forms/Form";
import SelectButtonGroup from "components/common/Forms/SelectButtonGroup";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import { Separator } from "components/ui/separator";
import useUserUtils from "hooks/utils/UseUserUtils";

export default function MemberNotificationModal({
  formData,
  onSubmit,
}: {
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  const _userUtils = useUserUtils();

  const notificationOptions = [
    { label: "Receive", value: true },
    { label: "Don't Receive", value: false },
    { label: "Use User Profile Setting", value: null },
  ];

  return (
    <DialogContent title="Notification Settings">
      <Form
        data={{
          newEffort: null,
          effortUpdate: null,
          newComment: null,
          commentUpdate: null,
          ...formData,
        }}
        onSubmit={onSubmit}
      >
        <Select
          name="userId"
          label="User"
          required
          disabled={true}
          options={[
            {
              value: formData?.user.id,
              label: _userUtils.getFullName(formData?.user),
            },
          ]}
        />

        <SelectButtonGroup
          name="newEffort"
          label="Project/Task Creation"
          options={notificationOptions}
          description={
            "Send Email When new task or subtask in this project has been created"
          }
        />
        <Separator />

        <SelectButtonGroup
          name="effortUpdate"
          label="Project/Task Update"
          options={notificationOptions}
          description={
            "Send Email When  task or subtask in this project has been updatePassword"
          }
        />
        <Separator />

        <SelectButtonGroup
          name="newComment"
          label="New Comment"
          options={notificationOptions}
          description={
            "Send Email When a new comment in this project has been created"
          }
        />
        <Separator />

        <SelectButtonGroup
          name="commentUpdate"
          label="Comment Update"
          options={notificationOptions}
          description={
            "Send Email When a new comment in this project has been updated"
          }
        />

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
