import React from "react";

import Dict from "models/Dict";
import Activity, { ActivitySortType } from "models/Activity";

import {
  cn,
  convert2Date,
  getOnlyFields,
  isEmpty,
} from "services/UtilServices";

import { useMainContext } from "providers/MainProvider";

import { useModalContext } from "providers/ModalProvider";
import {
  useActivitysContext,
  ActivitysProvider,
} from "providers/ActivitysProvider";

import ExportModal from "./ExportModal";
import Cheeps from "components/common/Cheeps";
import NewButton from "components/common/AdminSection/NewButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import ItemsListSection, {
  PropTypes,
} from "components/common/ItemsListSection/ItemsListSection";
import LocalDateTime from "components/common/LocalDateTime";
import TicketsCheepsSection from "components/pages/TicketsCheepsSection";

import { Button } from "components/ui/button";
import { GrUserAdmin, GrUserExpert } from "react-icons/gr";
import { LuCheck, LuCopy, LuDownload, LuShirt } from "react-icons/lu";
import useActivityUtils, {
  useActivityViewUtils,
} from "hooks/utils/UseActivityUtils";
import EffortBanner from "components/pages/EffortsSection/EffortBanner";
import Directionality from "components/common/Directionality";
import { useEffortsContext } from "providers/EffortsProvider";
import { CollapsableTextEditorValue } from "components/common/Forms/TextEditor";
import useUserUtils from "hooks/utils/UseUserUtils";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import CollapseText from "components/common/CollapseText";

function EachActivityTitleRow({
  activity,
  index,
}: {
  activity: Activity;
  index: number;
}) {
  const _userUtils = useUserUtils();
  const _mainContext = useMainContext();
  const _effortsContext = useEffortsContext();

  const _effort = _mainContext.efforts?.find((e) => e.id === activity.effortId);

  return (
    <div className="w-full">
      {activity.isApproved && (
        <>
          <div className="absolute -right-3 -top-3 -left-3 rounded-t bg-green-400/20 p-2 text-xs text-green-500 ">
            <div className="flex items-center gap-1">
              <LuCheck className=" w-4" /> Approved
              <div className="flex-1"></div>
              {!isEmpty(activity.approverList) && (
                <LocalDateTime
                  date={activity.approverList[0]?.dateCreated}
                  className="text-xs"
                />
              )}
            </div>
            {!isEmpty(activity.approverList) && (
              <div className="flex items-center gap-1 mt-1">
                <GrUserAdmin className="!text-base w-4" />
                By
                <span className="hover:underline">
                  <ProfileAvatar
                    side="left"
                    needName
                    needAvatar={false}
                    user={activity.approverList[0]?.user}
                  />
                </span>
              </div>
            )}
          </div>

          <div
            className={isEmpty(activity.approverList) ? "py-4" : "py-7"}
          ></div>
        </>
      )}

      {_effort && _effortsContext.currentEffort?.id !== _effort.id && (
        <div className="flex items-center gap-1 mb-2  font-normal">
          <EffortBanner
            effort={_effort}
            className="object-cover aspect-square border rounded h-7 w-7"
          />

          <span className="text-start whitespace-normal capitalize break-words">
            {_effort.prefix && (
              <span className="text-xs">{_effort.prefix?.name} - </span>
            )}

            <Directionality className="capitalize text-sm">
              {_effort.title}
            </Directionality>
          </span>
        </div>
      )}
      <div className="flex items-center w-full gap-1 font-normal text-xs">
        <span>{activity.id} - </span>
        <ProfileAvatar size={30} user={activity.user} />
        <div className="">
          <span>{_userUtils.getFullName(activity.user)}</span>
          {convert2Date(activity.dateAdd)?.toLocaleDateString("en-GB", {
            month: "numeric",
            day: "numeric",
            year: "2-digit",
          }) !==
            convert2Date(activity.dateDone)?.toLocaleDateString("en-GB", {
              month: "numeric",
              day: "numeric",
              year: "2-digit",
            }) && (
              <div className=" whitespace-nowrap">
                Created On: <LocalDateTime date={activity.dateAdd} />
              </div>
            )}
        </div>

        <div className="flex-1"></div>
        <LocalDateTime date={activity.dateDone} />
      </div>
    </div>
  );
}

function EachActivityItem({
  activity,
  index,
}: {
  activity: Activity;
  index: number;
}) {
  const _anyCountApproved = activity.ticketsCount.some((e) => e.countApproved);
  const _isCountApproved = activity.ticketsCount.every(
    (e) => e.count === e.countApproved
  );

  return (
    <div className="w-full mt-1">
      <div className="flex justify-between">
        <Cheeps borderColor={activity.type.color}>{activity.type.name}</Cheeps>
      </div>

      <div className="my-2">
        {_isCountApproved ? (
          <TicketsCheepsSection
            fieldName={"count"}
            ticketCounts={activity.ticketsCount}
            wrap
            needIcon
            icon={
              <>
                <LuShirt />
                <GrUserAdmin />
              </>
            }
          />
        ) : (
          <TicketsCheepsSection
            fieldName={"count"}
            ticketCounts={activity.ticketsCount}
            hideOverritens={_anyCountApproved}
            wrap
            needIcon
          />
        )}
      </div>

      {!_isCountApproved && (
        <TicketsCheepsSection
          fieldName={"countApproved"}
          ticketCounts={activity.ticketsCount}
          wrap
          icon={<GrUserAdmin />}
        />
      )}

      {/* <Separator className="my-2" /> */}

      <CollapsableTextEditorValue
        value={activity.description}
        className="pt-3 border-t mt-3"
      />
    </div>
  );
}

export function useActivitysPageBody({
  alwaysMini,
}: { alwaysMini?: boolean } = {}) {
  const textEditorUtils = useTextEditorUtils();
  const _mainContext = useMainContext();
  const _modalContext = useModalContext();
  const _activitysContext = useActivitysContext();
  const _effortsContext = useEffortsContext();
  const _activityUtils = useActivityUtils();
  const _activityViewUtils = useActivityViewUtils(
    _effortsContext.currentEffort?.id
  );

  // const _isHome = _effortsContext.currentEffort === undefined;
  // const _isProject = _effortsContext.currentEffort?.parentId === null;
  // const _currentuserContext = useCurrentUserContext();

  return {
    title: "Activities",
    data: _activitysContext.activitys,
    tableBreakPointSize: alwaysMini ? "never" : undefined,
    ellipsisResponsiveBreakPoint: alwaysMini ? "always" : undefined,
    fields: [
      {
        value: "",
        label: "#",
        className: "text-start whitespace-nowrap",
        width: "70px",
        isSortable: false,
        getUiContent: (e, i) => i + 1,
      },
      {
        value: "id",
        label: "Id",
        className: "text-start whitespace-nowrap",
        width: "70px",
        isTitleField: true,
        // isSortable: false,
        // getUiContent: (e, i) => i+1,
      },
      {
        value: "user",
        label: "user",
        width: "auto",
        // isSortable: false,
        getUiContent: (e, i) => <ProfileAvatar user={e.user} />,
        className: "whitespace-nowrap",
      },

      // ...(_isProject
      //   ? ([
      {
        value: "effortId",
        label: "task",
        width: "auto",
        isSortable: false,
        className: "text-start whitespace-nowrap",
        getUiContent: (eachItem, i) => {
          const _effort = _mainContext.efforts?.find(
            (e) => e.id === eachItem.effortId
          );

          return (
            <Directionality className={cn("capitalize ")}>
              {_effort?.title}
            </Directionality>
          );
        },
      },
      // ] as FieldType<Activity>[])
      // : []),

      {
        value: "type",
        label: "type",
        width: "auto",
        // isSortable: false,
        getUiContent: (e, i) => (
          <Cheeps borderColor={e.type.color}>{e.type.name}</Cheeps>
        ),
      },
      {
        value: "ticketsCount",
        label: "Activity",
        width: "30%",
        isSortable: false,
        getUiContent: (p) => (
          <TicketsCheepsSection ticketCounts={p.ticketsCount} />
        ),
      },
      {
        value: "ticketApproved",
        label: "Activity Approved",
        width: "auto",
        isSortable: false,
        getUiContent: (p) => (
          <TicketsCheepsSection
            ticketCounts={p.ticketsCount}
            fieldName="countApproved"
          />
        ),
      },
      {
        value: "dateAdd",
        label: "dateAdd",
        width: "auto",
        // isSortable: false,
        getUiContent: (e, i) => convert2Date(e.dateAdd)?.toLocaleDateString(),
        className: "whitespace-nowrap",
      },

      {
        value: "dateDone",
        label: "dateDone",
        width: "auto",
        // isSortable: false,
        getUiContent: (e, i) => <LocalDateTime date={e.dateDone} />,
        className: "whitespace-nowrap",
      },

      {
        value: "isApproved",
        label: "Approved",
        width: "auto",
        isSortable: true,
        getUiContent: (p) => (p.isApproved ? <LuCheck /> : null),
        // getValue: (p) => p.isApproved == true ? 1 : 0
      },
      {
        value: "description",
        label: "Description",
        width: "200px",
        // isSortable: false,
        className: "truncate",
        getUiContent: (e) => (
          <CollapseText text={textEditorUtils.getInnerText(e.description)} />
        ),
        getValue: (e) => textEditorUtils.getInnerText(e.description),
        //<TextEditorValue value={e.description} />,
      },
    ],
    onLoadMore: !_activitysContext.hasNext
      ? undefined
      : async () => {
        await _activitysContext.get({
          ..._activitysContext.filter,
          pageNumber: _activitysContext.filter.pageNumber + 1,
        });
      },

    selectedActionsSection: (items) => [
      {
        icon: <LuDownload />,
        text: "Export",
        onClick: () => {
          _modalContext.open(
            <ExportModal
              selectedItems={items}
              effortId={_activitysContext.effortId}
              filter={_activitysContext.filter}
            />
          );
        },
      },

      // ...(_activityUtils.canDelete(items)
      //   ? [
      //     {
      //       icon: <LuCheck />,
      //       text: "Approve",
      //       onClick: () =>
      //         deleteButton.tryRemove({
      //           message: (
      //             <>
      //               You're about to delete these activities{" "}
      //               {items.map((e, i) => (
      //                 <span>
      //                   {i === 0 ? "" : ", "}"
      //                   <b className="capitalize">{e.id}</b>"
      //                 </span>
      //               ))}
      //               .
      //             </>
      //           ),
      //           onConfirm: async () =>
      //             await _effortViewUtils.onRemove(items),
      //         }),
      //     },
      //   ]
      //   : []),

      // ...(_effortAuthUtils.canDelete(items)
      //   ? [
      //     {
      //       icon: <LuTrash2 />,
      //       text: "Delete",
      //       onClick: () =>
      //         deleteButton.tryRemove({
      //           message: (
      //             <>
      //               You're about to delete these tasks{" "}
      //               {items.map((e, i) => (
      //                 <span>
      //                   {i === 0 ? "" : ", "}"
      //                   <b className="capitalize">{e.title}</b>"
      //                 </span>
      //               ))}
      //               .
      //             </>
      //           ),
      //           onConfirm: async () =>
      //             await _effortViewUtils.onRemove(items),
      //         }),
      //     },
      //   ]
      //   : []),
    ],

    onSortField: (v) => {
      _activitysContext.get({
        ..._activitysContext.filter,
        sortBy: v as ActivitySortType,
        pageNumber: -1,
      });
    },
    onSortDir: (v) => {
      _activitysContext.get({
        ..._activitysContext.filter,
        ascOrder: v === 1,
        pageNumber: -1,
      });
    },
    initialSelectedSortFieldValue: "dateDone",
    buildCardTitleRow: (v, i) => (
      <EachActivityTitleRow activity={v} index={i} />
    ),
    buildCardBodyItem: (v, i) => <EachActivityItem activity={v} index={i} />,
    onSearch: null,
    onItemEdit: (item) =>
      _activityUtils.canEdit(item)
        ? _activityViewUtils.openCreateUpdateModal
        : undefined,

    onItemClick: (item) =>
      _activityUtils.canUpdateApproved(item)
        ? _activityViewUtils.openApproveModal(item)
        : _activityUtils.canEdit(item)
          ? _activityViewUtils.openCreateUpdateModal
          : undefined,

    onItemDelete: () =>
      _activityUtils.canDelete() ? _activitysContext.removeMany : undefined,
    getItemActions: (item) => [
      ...(_activityUtils.canCreate(_effortsContext.currentEffort)
        ? [
          {
            text: "Duplicate",
            icon: <LuCopy />,
            onClick: () =>
              _activityViewUtils.openCreateUpdateModal(
                getOnlyFields(item, [
                  "userId",
                  "effortId",
                  "typeId",
                  "description",
                  { ticketsCount: ["ticketId", "ticket", "count"] },
                ]) as { effortId: number } & Dict
              ),
          },
        ]
        : []),

      ...(_activityUtils.canUpdateApproved(item)
        ? [
          {
            text: "Edit Approve",
            icon: <GrUserAdmin />,
            onClick: () => _activityViewUtils.openApproveModal(item),
          },
          ...(item.isApproved
            ? []
            : [
              {
                text: "Approve & Save",
                icon: <GrUserExpert />,
                onClick: () => _activityViewUtils.approveSave(item),
              },
            ]),
        ]
        : []),
    ],

    actionsSection: (
      <>
        {_activitysContext.activitys !== undefined &&
          _activitysContext.activitys!.length > 0 && (
            <Button
              variant={"light"}
              className="px-2"
              onClick={() =>
                _modalContext.open(
                  <ExportModal
                    filter={_activitysContext.filter}
                    effortId={_activitysContext.effortId}
                  />
                )
              }
            >
              <LuDownload />
              <span className={cn("hidden ", { "lg:inline": !alwaysMini })}>
                Export
              </span>
            </Button>
          )}

        <FilterButton
          needText={!alwaysMini}
          className="border-0 px-2"
          count={_activitysContext.totalNumber}
          isActive={_activityUtils.isFilterActive({
            filter: _activitysContext.filter,
          })}
          onClick={_activityViewUtils.openFilterModal}
        />

        {_activityUtils.canCreate(_effortsContext.currentEffort) && (
          <NewButton
            needText={!alwaysMini}
            className="border-0 px-2"
            onClick={() =>
              _activityViewUtils.openCreateUpdateModal({
                effortId: _effortsContext.currentEffort!.id,
              })
            }
          />
        )}
      </>
    ),
  } as PropTypes<Activity>;
}

export function ActivitysPageBody({
  alwaysMini = true,
}: {
  alwaysMini?: boolean;
}) {
  const props = useActivitysPageBody({ alwaysMini });
  return <ItemsListSection<Activity> {...props} />;
}

export default function ActivitysPage() {
  return (
    <ActivitysProvider>
      {/* <ModalProvider> */}
      <ActivitysPageBody alwaysMini={true} />
      {/* </ModalProvider> */}
    </ActivitysProvider>
  );
}
