import React from "react";

import { useFormContext } from "./Form";

import Skeleton from "../Skeleton";

import { FaFaceFrownOpen, FaFaceSmileWink } from "react-icons/fa6";
import { Button, ButtonProps } from "components/ui/button";
import { cn, isEmpty } from "services/UtilServices";

interface Props extends ButtonProps {
  children?: React.ReactNode;
  // loadingChildren?: React.ReactNode;
  failedChildren?: React.ReactNode;
  successChildren?: React.ReactNode;
  center?: boolean;
  disabled?: boolean;
  [rest: string]: any;
}

export default function SubmitButton({
  children = "Save",
  // <LoadingSpinner color="white" className="py-2 px-3" />
  // loadingChildren = <Skeleton className="absolute inset-0" />,
  failedChildren = (
    <span>
      <FaFaceFrownOpen /> Failed!
    </span>
  ),
  successChildren = (
    <span>
      <FaFaceSmileWink /> Success!
    </span>
  ),
  center = true,
  disabled = false,
  variant = "primary",
  className,
  ...rest
}: Props) {
  const _formContext = useFormContext();
  const _isDisabled =
    disabled || !_formContext.canSubmit || !isEmpty(_formContext.submitErrors);

  return (
    <div className="text-center">
      <Button
        variant={variant}
        {...rest}
        type="submit"
        className={cn(
          "",
          {
            "animate-pulse pointer-events-none":
              _formContext.submitErrors === undefined,
          },
          className
        )}
        disabled={
          _formContext.submitErrors === undefined ? undefined : _isDisabled
        }
      >
        {children}
        {/* {_formContext.submitErrors === undefined && loadingChildren} */}

        {/* {_formContext.submitErrors === null ||
      _formContext.submitErrors === undefined
        ? children
        : Object.keys(_formContext.submitErrors).length === 0
        ? successChildren
        : Object.keys(_formContext.submitErrors).length > 0
        ? failedChildren
        : children} */}
      </Button>
    </div>
  );
}
