import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import Office, { OfficeFilter, OfficeSortTypes } from "models/Office";

import useOfficeApi from "hooks/api/UseOfficeApi";
import { useToast } from "components/ui/use-toast";

interface OfficesContextProps {
  offices: Office[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  get: (filter: OfficeFilter) => Promise<void>;
  getAll: () => Promise<Dict | undefined>;
  filter: OfficeFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const OfficesContext = React.createContext({} as OfficesContextProps);
OfficesContext.displayName = "OfficesContext";

function OfficesProvider({ children }: { children: React.ReactNode }) {
  const DEFAULT_FILTER = {
    pageNumber: -1,
    ascOrder: false,
    sortBy: OfficeSortTypes.ADDWHEN,
  };
  const itemsRef = React.useRef<Office[] | undefined>(undefined);
  const globalFilterRef = React.useRef<OfficeFilter>({ ...DEFAULT_FILTER });

  const [_items, _setOffices] = React.useState<Office[] | undefined>(
    itemsRef.current
  );
  const [_filter, _setFilter] = React.useState<OfficeFilter>(
    globalFilterRef.current
  );
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const { toast } = useToast();
  const officeApi = useOfficeApi();

  // const _mainContext = useMainContext();
  // const _currentUserContext = useCurrentUserContext();
  // const _effortsContext = React.useContext(EffortsContext);

  React.useEffect(() => {
    setOffices(undefined);
    setFilter({
      ...DEFAULT_FILTER,
    });
    setHasNext(true);
    setTotalNumber(undefined);
    return () => {
      setOffices(undefined);
      setFilter({
        ...DEFAULT_FILTER,
      });
      setHasNext(true);
      setTotalNumber(undefined);
    };
  }, []);

  const setOffices = (o?: Office[]) => {
    itemsRef.current = o;
    _setOffices(o);
  };

  const setFilter = (o: OfficeFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const getAll = async () => {
    return await get({
      ...DEFAULT_FILTER,
      pageNumber: 0,
      numberInPage: 999,
    });
  };

  const get = async (filter: OfficeFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setOffices(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setOffices(undefined);
      setTotalNumber(undefined);
      return;
    }

    try {
      let { items, totalNumber } = await officeApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setOffices([...(itemsRef.current ?? []), ...(items as Office[])]);
    } catch (e) {
      toast((e as AxiosError).message, {
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setOffices([]);
      }
    }
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData = await officeApi.create(formData);

      setOffices([formData as Office, ...(itemsRef.current ?? [])]);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      await officeApi.update(formData);

      setOffices(
        itemsRef.current?.map((e) =>
          e.id !== formData.id
            ? e
            : ({
                ...formData,
              } as Office)
        )
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Office[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await officeApi.remove(ids);

      setOffices(itemsRef.current?.filter((e) => !ids.includes(e.id)));
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <OfficesContext.Provider
      value={
        {
          offices: itemsRef.current,
          create,
          update,
          removeMany,

          get,
          getAll,
          filter: _filter,
          hasNext,
          totalNumber,
        } as OfficesContextProps
      }
    >
      {children}
    </OfficesContext.Provider>
  );
}

export function useOfficesContext() {
  const _context = React.useContext(OfficesContext);

  if (!_context) {
    throw new Error("cannot use OfficesContext outside of its provider.");
  }

  return _context;
}

export { OfficesContext, OfficesProvider };
export type { OfficesContextProps };
