import useAuthUtils from "hooks/UseAuthUtils";
import Attachment from "models/Attachment";
import { MemberAccessTypes } from "models/Member";
import { useCurrentUserContext } from "providers/CurrentUserProvider";







export default function useAttachmentUtils(effortId?: number | null) {
  const _authUtils = useAuthUtils();
  const _currentUserContext = useCurrentUserContext();

  const canCreate = () => {
    let accessType = _authUtils!.authorize(effortId);
    return accessType >= MemberAccessTypes.Create;
  }

  const canDelete = (item: Attachment) => {
    let accessType = _authUtils!.authorize(effortId);
    return accessType >= MemberAccessTypes.Edit ||
      (
        accessType > MemberAccessTypes.Read &&
        _currentUserContext.user?.id === item.userId
      )
  }

  return {
    canCreate,
    canDelete
  }
}
