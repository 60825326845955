import React from "react";

import LoadingSpinner from "./LoadingSpinner";
import useOnScreen from "./UseOnScreen";
import { cn } from "services/UtilServices";
import { Slot } from "@radix-ui/react-slot";

export default function OnLoadMoreIndicator({
  children,
  onLoadMore,
  className,
  asChild,
  ...props
}: {
  onLoadMore: (d: number) => Promise<void> | void;
  asChild?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>) {
  const timerIdRef = React.useRef<undefined | NodeJS.Timeout>(undefined);
  const hasRespondedRef = React.useRef(true);
  const indexRef = React.useRef(0);
  const loadingElement = React.useRef<HTMLSpanElement>(null);

  const isOnViewRef = React.useRef(false);
  isOnViewRef.current = useOnScreen(loadingElement);

  const onLoadMoreRef = React.useRef(onLoadMore);
  onLoadMoreRef.current = onLoadMore;

  const Comp = asChild ? Slot : "span";

  var myFnc = async function () {
    if (hasRespondedRef.current && timerIdRef.current !== undefined) {
      if (isOnViewRef.current) {
        hasRespondedRef.current = false;
        indexRef.current += 1;
        await onLoadMoreRef.current(indexRef.current);
        hasRespondedRef.current = true;
      } else {
        clearInterval(timerIdRef.current);
        timerIdRef.current = undefined;
      }
    }
  };

  React.useEffect(() => {
    return () => {
      clearInterval(timerIdRef.current);
      timerIdRef.current = undefined;
    };
  }, []);

  React.useEffect(() => {
    if (isOnViewRef.current && timerIdRef.current === undefined) {
      timerIdRef.current = setInterval(myFnc, 2000);
      myFnc();
    }
  }, [isOnViewRef.current]);

  return (
    <Comp
      ref={loadingElement}
      {...props}
      className={cn("min-h-12 min-w-12 block ", className)}
    >
      {children ?? <LoadingSpinner />}
    </Comp>
  );
}
