import React from "react";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

import { cn, isEmpty, isFarsi, objRemoveKeys } from "services/UtilServices";

import FormFieldBase, { FormFieldBaseInputProps } from "./FormFieldBase";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { Input as ShadCnInput } from "components/ui/input";

interface Props extends FormFieldBaseInputProps {
  type?: string;
  textarea?: boolean;
  showPasswordBtn?: boolean;
}

export default function Input(props: Props) {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  return (
    <FormFieldBase {...props}>
      {(baseProps) => {
        const _value = (baseProps.value ?? "") as string;
        const isPassword = props.type === "password";
        const showPasswordBtn =
          isPassword && props.showPasswordBtn && !isEmpty(_value);

        const _props = {
          ...(objRemoveKeys(props, ["textarea", "showPasswordBtn"])! as Props),
          id: props.name,
          name: props.name,
          value: _value,
          dir: isFarsi(_value) && !isPassword ? "rtl" : undefined,
          className: cn(
            "form-control ",
            {
              "pe-2": isPassword,
              "is-invalid": baseProps.error && baseProps.isFocused,
              "bg-none":
                baseProps.error && baseProps.isFocused && showPasswordBtn,
            },
            props.className
          ),
          onChange: (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            baseProps.setData({
              [props.name]:
                props.type === "number"
                  ? parseInt(event.target.value)
                  : event.target.value,
            });
          },
          disabled: props.disabled || baseProps.isLoading,
          required: baseProps.isRequired,
        };

        return (
          <div className="relative">
            {props.textarea ? (
              <Textarea {..._props} />
            ) : (
              <ShadCnInput
                {..._props}
                type={isPasswordVisible ? "text" : _props.type}
              />
            )}

            {showPasswordBtn && (
              <Button
                variant={"faded"}
                className="absolute right-0 bottom-0 text-foreground mix-blend-difference "
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? <VscEyeClosed /> : <VscEye />}
              </Button>
            )}
          </div>
        );
      }}
    </FormFieldBase>
  );
}
