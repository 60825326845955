import AppLogo from "components/pages/AppLogo";
import { Button } from "components/ui/button";
import { TDarkModes, useThemeContext } from "providers/ThemeProvider";
import { LuCheck } from "react-icons/lu";
import { cn } from "services/UtilServices";

function Thumbnail({ type }: { type: TDarkModes }) {
  return (
    <div className="relative overflow-hidden">
      <div
        className={cn(
          "  rounded overflow-hidden",
          type === "dark" ? "bg-dark" : "bg-white",
          {
            //   invert: type === "dark",
          }
        )}
      >
        <div className="h-5 "></div>
        <div className="flex h-12 ">
          <div className="w-7"></div>
          <div
            className={cn(
              "flex-1  border-t border-s rounded-tl  flex items-center justify-center",
              type === "dark"
                ? "bg-slate-800 border-slate-600"
                : "bg-slate-100 border-slate-200"
            )}
          >
            <span className={cn(type === "dark" ? "text-white" : "text-dark")}>
              PerTask
            </span>
          </div>
        </div>
      </div>

      {type === "auto" && (
        <div
          className={cn(
            "absolute -rotate-[20deg] top-1 h-20 w-[200%] backdrop-invert bg-dark overflow-hidden"
          )}
          style={{
            WebkitBackdropFilter: "invert(100%)",
          }}
        >
          <div className="absolute rotate-[20deg] -top-9 h-20 w-1/2">
            <Thumbnail type="dark" />
          </div>
        </div>
      )}

      <AppLogo mini className={cn("h-5 p-1 absolute top-0 left-0 ")} />
    </div>
  );
}

function EachItem({ type }: { type: TDarkModes }) {
  const _themeContext = useThemeContext();
  const _isSelected = _themeContext.darkModeState === type;

  return (
    <Button
      variant={_isSelected ? "primary" : "light"}
      className={cn(
        "flex-1 whitespace-normal flex flex-col items-start justify-start border"
      )}
      onClick={() => {
        _themeContext.setDarkModeState(type);
      }}
    >
      <div className="flex items-center w-full">
        <span className="capitalize">{type}</span>
        <div className="flex-1"></div>
        {_isSelected && <LuCheck />}
      </div>
      <div className={cn("border rounded w-full overflow-hidden")}>
        <Thumbnail type={type} />
      </div>
    </Button>
  );
}

export default function SelectDarkMode() {
  const _themeContext = useThemeContext();

  return (
    <div className="mb-4">
      <h6 className="mb-3 text-foreground">Dark Mode</h6>

      <div className="flex items-center gap-1">
        <EachItem type="auto" />
        <EachItem type="dark" />
        <EachItem type="light" />
      </div>
    </div>
  );
}
