import { Button } from "components/ui/button";
import React from "react";

import { FaChevronLeft } from "react-icons/fa6";

const CollapseText = ({
  text = "",
  maxLength = 100,
  needExtraButton = false,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return maxLength > text.length ? (
    <span>{text}</span>
  ) : (
    <>
      <span title={text}>
        {text.substring(0, maxLength - 1)}
        {isVisible ? "" : "..."}
        {isVisible ? text.substring(maxLength) : ""}
      </span>

      {needExtraButton ? (
        <>
          <br />
          <Button
            variant={"link"}
            className="mt-2"
            onClick={() => setIsVisible(!isVisible)}
          >
            <span>{isVisible ? "بستن" : "بیشتر"}</span>
            <small>
              <FaChevronLeft className="me-1" />
            </small>
          </Button>
        </>
      ) : null}
    </>
  );
};

export default CollapseText;
