import { hasOtherKeysExcept } from "services/UtilServices";
import Activity from "./Activity";
import Comment from "./Comment";
import Dict from "./Dict";
import Member from "./Member";
import Office, { officeSchema } from "./Office";
import UserLog, { userLogSchema } from "./UserLog";
import AuditLog, { auditLogSchema } from "./AuditLog";
import Device, { deviceSchema } from "./Device";
import { z } from "zod";



export type UserSortType = "firstname" |
  "lastname" |
  "username" |
  "email" |
  "phone" |
  "dateadd" |
  "dateupdate" |
  "office" |
  "role"


export const UserSortTypes: { [key: string]: UserSortType } = {

  firstname: "firstname",
  lastname: "lastname",
  username: "username",
  email: "email",
  phone: "phone",
  dateadd: "dateadd",
  dateupdate: "dateupdate",
  office: "office",
  role: "role",

  // OFFICEID: "OFFICEID",

  // FIRSTNAME: "FIRSTNAME",
  // LASTNAME: "LASTNAME",
  // PHONE: "PHONE",
  // EMAIL: "EMAIL",

  // USERNAME: "USERNAME",

  // CREATEDON: "CREATEDON",
  // DATEUPDATE: "DATEUPDATE",

  // DATEFROM: "DATEFROM",
  // DATETO: "DATETO",


  // ROLE: "ROLE",

  // NEWEFFORT: "NEWEFFORT",
  // EFFORTUPDATE: "EFFORTUPDATE",
  // NEWCOMMENT: "NEWCOMMENT",
  // COMMENTUPDATE: "COMMENTUPDATE",


  // MEMBERS: "MEMBERS",
  // COMMENTS: "COMMENTS",
  // ACTIVITIES: "ACTIVITIES",
  // USERLOG: "USERLOG",
};

export interface UserFilter {
  "pageNumber": number,
  "sortBy": UserSortType,
  "ascOrder": boolean,
  numberInPage?: number,

  role?: string,
  userName?: string,

  officeId?: string,
  firstName?: string,
  lastName?: string,
  phone?: string,
  email?: string,

  dateAddFrom?: Date,
  dateAddTo?: Date,
}


export class UserRoles {
  static ADMIN = "ADMIN";
  static MANAGER = "MANAGER";
  static USER = "USER";

  static options: Dict = {
    "ADMIN": "Admin",
    "MANAGER": "Manager",
    "USER": "User",
  };

  static toLabelValues = () =>
    Object.entries(UserRoles.options).map(
      ([key, val]) => ({
        value: key,
        label: val,
      })
    );
}

export const roleEnum = z.enum(["ADMIN", "MANAGER", "USER"]);
export const userSchema = z.object({
  id: z.string(),
  userName: z.string(),
  role: roleEnum,
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  avatarUrl: z.string().optional(),
  dateAdd: z.string(),

  officeId: z.string(),
  office: officeSchema.optional(),
});

export const currentUserSchema = userSchema.extend({
  newEffort: z.boolean(),
  effortUpdate: z.boolean(),
  newComment: z.boolean(),
  commentUpdate: z.boolean(),
  bgImage: z.string().optional(),
  themeColor: z.string().optional(),
  notificationCount: z.number(),
  // logs: userLogSchema.array(),
  auditLogs: auditLogSchema.array().optional(),
  devices: deviceSchema.array().optional(),
});
export type CurrentUser = z.infer<typeof currentUserSchema>;

type User = z.infer<typeof userSchema>;
export default User;

// export default interface User {
//   id: string,
//   userName: string,
//   role: "ADMIN" | "MANAGER" | "USER",

//   officeId: string,
//   firstName?: string,
//   lastName?: string,
//   phone?: string,
//   email?: string,
//   avatarUrl?: string,

//   dateAdd: string,
//   // dateUpdate?: Date,



//   newEffort: boolean;
//   effortUpdate: boolean;
//   newComment: boolean;
//   commentUpdate: boolean;

//   office?: Office;
//   logs: UserLog[],

//   bgImage?: string,
//   themeColor?: string,

//   auditLogs?: AuditLog[],
//   devices?: Device[],

// }



