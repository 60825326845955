import User, { userSchema } from "./User";
import Dict from "./Dict";
import { z } from "zod";



export type UserLogSortType = "username"| "dateAdd"| "action" | "type" | "actionType";

// export type UserLogActionType =  [
//   { value: "Add", label: "New Task Has Been Aded" },
//   { value: "Update", label: "State Has Been Changed" },
//   { value: "2", label: "New Comment" },
//   { value: "3", label: "Edit Comment" },
// ];


// export const ActionTypeObject =  [
//       { value: "0", label: "New Task Has Been Aded" },
//       { value: "1", label: "State Has Been Changed" },
//       { value: "2", label: "New Comment" },
//       { value: "3", label: "Edit Comment" },
// ];


export class UserLogEntityNames {
  static Comment = "Comment";  
  static Member = "Member";  
  static Effort = "Effort";
  static State = "State";
  static Ticket = "Ticket";
  static Priority = "Priority";
  static Activity = "Activity";
  static Type = "Type";
  static Prefix = "Prefix";
  static Attachment = "Attachment";
  static Department = "Department";

  static options: Dict = {
    "Comment": "Comment",  
    "Member": "Member",  
    "Effort": "Effort",
    "State": "State",
    "Ticket": "Ticket",
    "Priority" : "Priority",
    "Activity" : "Activity",
    "Type" : "Type",
    "Attachment" : "Attachment",
    "Department": "Department",
  };

  static toLabelValues = () => 
    Object.entries(UserLogEntityNames.options).map(
      ([key, val]) => ({ 
        value: key,
        label: val, 
      })
    );
}

export class UserLogActionTypes {
  static Deleted = "Deleted";  
  static Updated = "Updated";  
  static Added = "Added";

  static options: Dict = {
    "Deleted": "Delete",  
    "Updated": "Update",  
    "Added": "Add",

  };

  static toLabelValues = () => 
    Object.entries(UserLogActionTypes.options).map(
      ([key, val]) => ({ 
        value: key,
        label: val, 
      })
    );
}





export const UserLogSortTypes: { [key: string]: UserLogSortType } = {
  username:"username",
  dateAdd:"dateAdd",
  action:"action",
  type: "type",
  actionType:"actionType",
};

export interface UserLogFilter {
  "pageNumber": number,
  "sortBy": UserLogSortType,
  "ascOrder": boolean,
  "numberInPage"?: number,

  userIds?: string[],
  effortIds?: number[],
  description?: string,

  actionTypes?: string[],
  entityNames?: string[],

  "dateAddFrom"?: string,
  "dateAddTo"?: string
}

export const actoinTypeEnum = z.enum(["Deleted", "Updated", "Added"]);
export const entityNameEnum = z.enum([ "Comment", "Member", "Effort", "State", "Ticket", "Priority", "Activity", "Type", "Prefix", "Attachment", "Department"]);
export const userLogSchema = z.object({
  id: z.string(),
  description:  z.string(),
  dateAdd: z.string(),
  isNotification: z.boolean().optional(),
  actionType: actoinTypeEnum,
  entityName: entityNameEnum,
  
  effortId: z.number(),
  userId: z.string(),
}).extend({
  user: z.lazy(() => userSchema),
});

type UserLog = z.infer<typeof userLogSchema>;
export default UserLog;



// export default interface UserLog {

//   id: string,
  
//   userId: string,
//   user: User,

//   effortId: number,

//   action: "Deleted"|"Updated"|"Added" ,

//   actionType: string,
//   type: "COMMENT"|"MEMBER"|"EFFORT"|"STATE"|"TICKET",

//   dateAdd: string,

//   isNew?: boolean,
// }

