import React from "react";
import { useNavigate, useParams } from "react-router";

import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { EffortsProvider, useEffortsContext } from "providers/EffortsProvider";
import MainSection from "components/layouts/MainSection";
import Skeleton from "components/common/Skeleton";
import EffortHeaderSection from "components/pages/EachEffort/EffortHeaderSection";
import {
  EffortsListSectionBody,
  EffortsSectionProvider,
} from "components/pages/EffortsSection/EffortsSection";
import SettingSection from "components/pages/EachEffort/SettingSection/SettingSection";
import { TabContents } from "components/common/Tabs";
import EffortSidePanel, {
  useSidePanelSections,
} from "components/pages/EachEffort/EffortSidePanel";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import MainHeaderBody from "components/layouts/MainHeader/MainHeader";
import { useEffortInfoLoader } from "components/pages/EffortsSection/EffortInfoLoader";

export function EachEffortPageSkeleton() {
  return (
    <div className="p-3">
      <div className="flex rounded border p-3 mb-3">
        <Skeleton className="w-1/2 lg:w-1/4 h-5" />
      </div>

      <div className="flex rounded border p-3">
        <Skeleton className="w-1/2 lg:w-1/4 h-7" />
        <div className="flex-1"></div>
        <Skeleton className=" w-24 h-7" />
      </div>

      <div className="flex items-center py-2 gap-2">
        <Skeleton className=" w-24 h-7" />
        <div className="rounded-full border p-3 w-[15%]"></div>
        <div className="flex-1"></div>
        <Skeleton count={4} className=" w-20 h-10" />
      </div>

      <div className="row">
        <div className="col-12 md:col-6 lg:col-3 p-2">
          <Skeleton className="w-full h-72" />
        </div>
        <div className="col-12 md:col-6 lg:col-3 p-2">
          <Skeleton className="w-full h-72" />
        </div>
        <div className="col-12 md:col-6 lg:col-3 p-2">
          <Skeleton className="w-full h-72" />
        </div>
        <div className="col-12 md:col-6 lg:col-3 p-2">
          <Skeleton className="w-full h-72" />
        </div>
      </div>
    </div>
  );
}

export function EachEffortPageBody() {
  const _effortsContext = useEffortsContext();
  const navigate = useNavigate();
  const mediaQuery = useMediaQuery();
  const _effortUtils = useEffortUtils();
  const SECTIONS = useSidePanelSections(_effortsContext.currentEffort, true);

  const params = useParams();

  let effortUrl = _effortUtils.makeUrl(_effortsContext.currentEffort?.id);
  let possibleInitilialTabIndex = [params.task, params.subtask, params.index];

  let _tabIndexes = SECTIONS.map((e) => e.url);
  if (
    _effortsContext.currentEffort === undefined ||
    _effortsContext.currentEffort === null ||
    _effortsContext.currentEffort?.level >= 2
  ) {
    _tabIndexes = _tabIndexes.filter((e) => e !== "");
  }

  let _initIndex = _tabIndexes.find((eachTabIndex) =>
    possibleInitilialTabIndex.includes(eachTabIndex)
  );
  if (!_initIndex) {
    _initIndex =
      _effortsContext.currentEffort !== undefined &&
      _effortsContext.currentEffort !== null &&
      _effortsContext.currentEffort?.level < 1
        ? ""
        : "setting";
  }

  if (_effortsContext.currentEffort === null) {
    navigate("/404");
    return null;
  }

  return (
    <>
      {_effortsContext.currentEffort === undefined ? (
        <>
          <MainHeaderBody back title={"Loading..."} showUserName={false} />
          <EachEffortPageSkeleton />
        </>
      ) : (
        <>
          <MainHeaderBody back showUserName={false}>
            <EffortHeaderSection effort={_effortsContext.currentEffort} />
          </MainHeaderBody>

          <div className="flex flex-1 ">
            <MainSection>
              {mediaQuery <= MediaQuery2Num.md ? (
                <TabContents items={SECTIONS} activeUrl={_initIndex} />
              ) : _effortsContext.currentEffort.level >= 2 ? (
                <SettingSection effort={_effortsContext.currentEffort} />
              ) : (
                <EffortsListSectionBody
                  effortId={_effortsContext.currentEffort.id}
                />
              )}
            </MainSection>

            <EffortSidePanel />
          </div>
        </>
      )}
    </>
  );
}

export default function EachEffortPage() {
  // const params = useParams();
  const _effortUtils = useEffortUtils();
  const navigate = useNavigate();
  const _effortInfoLoader = useEffortInfoLoader();
  let _effort = _effortUtils.getEffortByUrl();

  const _project = _effortUtils.getParentProject(_effort?.id);

  React.useEffect(() => {
    if (!_effort) {
      navigate("/404");
    } else {
      _effortInfoLoader.load([_effort]);
    }
  }, [_effort?.id]);

  return (
    <EffortsProvider effortId={_project?.id}>
      <EffortsSectionProvider projectId={_project?.id}>
        {/* <AttachmentsProvider> */}
        <EachEffortPageBody />
        {/* </AttachmentsProvider> */}
      </EffortsSectionProvider>
    </EffortsProvider>
  );
}
