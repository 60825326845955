import Activity from "models/Activity";
import { ModalProvider } from "providers/ModalProvider";
import { ActivitysProvider } from "providers/ActivitysProvider";
import { useActivitysPageBody } from "components/pages/EachEffort/ActivitysSection/ActivitysSection";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import ItemsListProvider, {
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";
import MainHeaderBody from "components/layouts/MainHeader/MainHeader";
import MainSection from "components/layouts/MainSection";
import { ItemsListSectionBody } from "components/common/ItemsListSection/ItemsListSection";
import { ActivityChartSection } from "../components/pages/activities/ActivityChartSection";
import ItemListSearchbarSection from "components/common/ItemsListSection/ItemsListSearchbarSection";
import React from "react";
import { StickyHorizontalScrollbar } from "components/common/StickyHorizontalScrollbar";

function PageBodyBody() {
  const _itemsListContext = useItemsListContext();
  const { onSearch } = _itemsListContext;
  const scrollRef = React.useRef<HTMLDivElement>(null);

  let size = useMediaQuery();

  return (
    <>
      {size > MediaQuery2Num.md ? (
        <MainHeaderBody>
          <ItemListSearchbarSection
            isSearchable={onSearch !== null}
            // tableModeBreakPoint={tableBreakPointSize}
            {..._itemsListContext}
          />
        </MainHeaderBody>
      ) : (
        <ItemListSearchbarSection
          isSearchable={onSearch !== null}
          // tableModeBreakPoint={tableBreakPointSize}
          {..._itemsListContext}
        />
      )}

      <MainSection className="pe-2 overflow-x-clip">
        <ActivityChartSection />

        <div
          ref={scrollRef}
          className="overflow-x-auto scrollbar-hide w-full mt-2"
        >
          <ItemsListSectionBody
            needSearch={false}
            // tableBreakPointSize={tableBreakPointSize}
          />
        </div>

        <StickyHorizontalScrollbar scrollRef={scrollRef} className="pb-0" />
      </MainSection>
    </>
  );
}

function PageBody() {
  const props = useActivitysPageBody();

  return (
    <ItemsListProvider<Activity> {...props}>
      <PageBodyBody />
    </ItemsListProvider>
  );
}

export default function ActivitiesPage() {
  return (
    <ActivitysProvider>
      <ModalProvider>
        <PageBody />
      </ModalProvider>
    </ActivitysProvider>
  );
}
