import * as React from "react";

import { ModalProvider } from "providers/ModalProvider";

import MainFooter from "components/layouts/MainFooter";
import { BackButtonHandler } from "components/common/BackButtonHandler";
import { DesktopSideMenu } from "../components/layouts/sideMenu/DesktopSideMenu";
import LocalStorageServices from "services/localServices/LocalStorageServices";
import { ThemeUtils, useThemeContext } from "providers/ThemeProvider";
import CurrentUserLocalServices from "services/localServices/CurrentUserLocalServices";
import { useNavigate } from "react-router-dom";
import MainHeaderBody, {
  MainHeaderProps,
} from "components/layouts/MainHeader/MainHeader";

interface MainLayoutContextProps {
  leftSideMenu: string | null;
  setLeftSideMenu: (l: string | null) => void;

  header: MainHeaderProps | null;
  setHeader: (d: MainHeaderProps | null) => void;
}

const MainLayoutContext = React.createContext({} as MainLayoutContextProps);
MainLayoutContext.displayName = "MainLayoutContext";

export function useMainLayoutContext() {
  const _context = React.useContext(MainLayoutContext);

  if (!_context) {
    throw new Error("cannot use MainLayoutContext outside of its provider.");
  }

  return _context;
}

export default function MainLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const _themeContext = useThemeContext();
  const [leftSideMenu, _setLeftSideMenu] = React.useState<string | null>(null);
  const [header, setHeader] = React.useState<MainHeaderProps | null>();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!CurrentUserLocalServices.isLoggedIn()) {
      navigate("/login/");
    }
  }, []);

  // React.useEffect(() => {
  //   LocalStorageServices.get("LEFT_SIDE_MENU").then((r) =>
  //     _setLeftSideMenu(r ?? null)
  //   );
  // }, []);

  const setLeftSideMenu = (value: string | null) => {
    _setLeftSideMenu(value);
    // LocalStorageServices.set("LEFT_SIDE_MENU", value);
  };

  return (
    <MainLayoutContext.Provider
      value={
        {
          leftSideMenu,
          setLeftSideMenu,

          header,
          setHeader,
        } as MainLayoutContextProps
      }
    >
      <BackButtonHandler>
        <ModalProvider>
          <div
            style={{
              backgroundImage: _themeContext.bgImage
                ? `linear-gradient(to top, ${
                    _themeContext.isDark ? "#000000aa" : "#ffffffaa"
                  }, ${_themeContext.isDark ? "#000000aa" : "#ffffffaa"}), 
                                    url('${ThemeUtils.addBaseUrl(
                                      _themeContext.bgImage
                                    )}')`
                : undefined,
            }}
            className="h-full w-full flex bg-cover bg-center bg-no-repeat bg-muted "
          >
            <DesktopSideMenu />

            <div className="flex-1 flex flex-col">
              {/* {header !== null && <MainHeaderBody {...header} />} */}

              {children}

              <MainFooter />
            </div>
          </div>
        </ModalProvider>
      </BackButtonHandler>
    </MainLayoutContext.Provider>
  );
}
