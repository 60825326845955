import { effortSchema } from "./Effort";
import { z } from "zod";



export type StateSortType = "dateadd" | "name";


export const StateSortTypes: { [key: string]: StateSortType } = {
  dateadd: "dateadd",
  name: "name",
  // COLOR: "COLOR",
};


export interface StateFilter {
  "pageNumber": number,
  "sortBy": StateSortType,
  "ascOrder": boolean,
  numberInPage?: number,

  name?: string,
  color?: string,
  dateAddFrom?: Date,
  dateAddTo?: Date,

}


export const stateSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string(),
  dateAdd: z.string(),
});

type State = z.infer<typeof stateSchema>;
export default State;


// export default interface State {
//   id: number,
//   name: string,

//   color: string,
//   dateAdd: string,

//   efforts: Effort[],
// }
