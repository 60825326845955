import React from "react";

import Dict from "models/Dict";
import Member from "models/Member";
import useMemberApi from "hooks/api/UseMemberApi";
import { AxiosError } from "axios";
import { useCurrentUserContext } from "./CurrentUserProvider";
import { useMainContext } from "./MainProvider";
import { useNavigate } from "react-router";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { isEmpty, listFirst } from "services/UtilServices";

interface MembersContextProps {
  // members: Member[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  createAndDelete: (props: {
    createFormData?: Dict;
    itemsToDelete?: Member[];
  }) => Promise<Dict>;
}

const MembersContext = React.createContext({} as MembersContextProps);
MembersContext.displayName = "MembersContext";

function MembersProvider({ children }: { children: React.ReactNode }) {
  const _currentUserContext = useCurrentUserContext();
  const _mainContext = useMainContext();
  // const _effortsContext = useEffortsContext();
  // const _effortsContext = useEffortsContext();

  const navigate = useNavigate();
  const memberApi = useMemberApi();
  const _effortUtils = useEffortUtils();

  const createAndDelete = async ({
    createFormData,
    itemsToDelete,
  }: {
    createFormData?: Dict;
    itemsToDelete?: Member[];
  }) => {
    let _errors = {};

    try {
      if (createFormData) {
        createFormData.id = await memberApi.create(createFormData);
        createFormData.isDeleted = false;
      }

      let _ids = itemsToDelete?.map((e) => e.id);
      if (!isEmpty(itemsToDelete)) {
        await memberApi.remove(_ids!);
      }

      _mainContext.setEfforts((prev) => {
        let _prev = [...(prev ?? [])];

        if (createFormData) {
          _prev = _prev?.map((e) =>
            e.id !== createFormData.effortId
              ? e
              : {
                  ...e,
                  members: [...(e?.members ?? []), createFormData as Member],
                }
          );
        }

        if (!isEmpty(itemsToDelete)) {
          _prev = _prev?.map((eachEffort) => ({
            ...eachEffort,
            members: eachEffort?.members?.map((e) =>
              !_ids!.includes(e.id)
                ? e
                : ({
                    ...e,
                    isDeleted: true,
                  } as Member)
            ),
          }));
        }

        return _prev;
      });
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      // formData.effortId = _effortsContext.currentEffort!.id;
      formData.id = await memberApi.create(formData);
      formData.isDeleted = false;

      _mainContext.setEfforts((prev) =>
        prev?.map((e) =>
          e.id !== formData.effortId
            ? e
            : {
                ...e,
                members: [...(e?.members ?? []), formData as Member],
              }
        )
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      await memberApi.update(formData);

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) =>
          eachEffort.id !== formData.effortId
            ? eachEffort
            : {
                ...eachEffort,
                members: eachEffort.members?.map((e) =>
                  e.id !== formData.id ? e : ({ ...formData } as Member)
                ),
              }
        )
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Member[]) => {
    let _errors = {};

    try {
      let _ids = itemsToDelete.map((e) => e.id);
      await memberApi.remove(_ids);

      let _effort = _mainContext.efforts?.find(
        (a) => a.id === listFirst(itemsToDelete)?.effortId
      );

      if (
        itemsToDelete.length === 1 &&
        !_currentUserContext.isSudo() &&
        itemsToDelete.some((e) => e.userId === _currentUserContext.user?.id) &&
        _effort?.parentId === null
      ) {
        let _allIdsToDelete = _effortUtils
          .getSubEffortsDeep({
            id: _effort!.id,
          })
          .map((e) => e.id);

        _mainContext.setEfforts((prev) =>
          prev?.filter((e) => !_allIdsToDelete.includes(e.id))
        );

        navigate("/");
      } else {
        _mainContext.setEfforts((prev) =>
          prev?.map((eachEffort) => ({
            ...eachEffort,
            members: eachEffort?.members?.map((e) =>
              !_ids.includes(e.id)
                ? e
                : ({
                    ...e,
                    isDeleted: true,
                  } as Member)
            ),
          }))
        );
      }
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <MembersContext.Provider
      value={
        {
          // members: _effortsContext.currentEffort?.members,
          create,
          update,
          removeMany,

          createAndDelete,
        } as MembersContextProps
      }
    >
      {children}
    </MembersContext.Provider>
  );
}

export function useMembersContext() {
  const _context = React.useContext(MembersContext);

  if (!_context) {
    throw new Error("cannot use MembersContext outside of its provider.");
  }

  return _context;
}

export { MembersContext, MembersProvider };
export type { MembersContextProps };
