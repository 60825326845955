import React from "react";
import { stringify } from "flatted";
import { ReactSortable, Sortable } from "react-sortablejs";

import Effort from "models/Effort";

import LocalStorageServices from "services/localServices/LocalStorageServices";

import {
  ItemType,
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";
import Skeleton from "components/common/Skeleton";
import {
  cn,
  isEmpty,
  objSafeGet,
  randomInt,
  sortedStringify,
} from "services/UtilServices";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import Dict from "models/Dict";
import { EffortsProvider } from "providers/EffortsProvider";
import { TrelloSectionTypeItemType } from "../GroupBySelectSection";
import { EffortGroupType } from "hooks/utils/UseEffortUtils";
import EachTableSection from "./EachTableSection";
import ItemsListTableSection from "components/common/ItemsListSection/ItemsListTableSection";

export default function TablesSection({
  cols,
  onUpdate,
  basedOnType,
  isColDisabled,
  colClick,
  colActions,
}: {
  cols?: EffortGroupType[];
  basedOnType: TrelloSectionTypeItemType;
  colActions?: (col: EffortGroupType) => ItemType[];
  colClick?: (col: EffortGroupType) => void;
  isColDisabled: (
    eachCol: EffortGroupType,
    draggingEffortId?: number
  ) => boolean | undefined;
  onUpdate: (d: {
    effortId: number;
    srcColId: string;
    desColId: string;
    isCloning?: boolean;
  }) => Promise<Dict>;
}) {
  const _itemsListContext = useItemsListContext();

  const [_cols, _setCols] = React.useState<EffortGroupType[] | undefined>();
  const [_draggingEffortId, _setDraggingEffortId] = React.useState<number>();
  const [_isCloning, _setIsCloning] = React.useState(false);

  const setCols = (s?: EffortGroupType[]) => {
    _setCols(s);
    LocalStorageServices.set(
      "COLS_ORDER" + basedOnType.label,
      s?.map((e) => e.id) ?? []
    );
  };

  const cloneCols = (c: EffortGroupType[]) => {
    return [
      ...c!.map((e) => ({
        ...e,
        efforts: [...(e.effortIds ?? [])],
      })),
    ];
  };

  React.useEffect(() => {
    const _onKeyDown = (ev: KeyboardEvent) => _setIsCloning(ev.ctrlKey);
    const _onKeyUp = () => _setIsCloning(false);

    if (basedOnType.multiple) {
      window.addEventListener("keydown", _onKeyDown);
      window.addEventListener("keyup", _onKeyUp);
    }

    return () => {
      window.removeEventListener("keydown", _onKeyDown);
      window.removeEventListener("keyup", _onKeyUp);
    };
  }, [basedOnType.multiple]);

  React.useEffect(() => {
    _setCols(undefined);
    if (isEmpty(cols)) return;

    LocalStorageServices.get("COLS_ORDER" + basedOnType.label).then((r) => {
      if (r === null) r = [];

      let prevOrdered = (r as string[])
        .map((e) => cols?.find((a) => a.id === e))
        .filter((e) => e !== undefined) as EffortGroupType[];

      let notOrderedYet = cols?.filter(
        (a) => !(r as string[]).some((e) => a.id === e)
      );

      let newCols = [...prevOrdered, ...(notOrderedYet ?? [])];

      setCols(newCols);
    });
  }, [
    sortedStringify(cols?.map((e) => e.id)),
    sortedStringify(cols?.map((e) => e.effortIds)),
  ]);

  const onColDragEnd = (ev: Sortable.SortableEvent) => {
    if (!objSafeGet(ev, "originalEvent", "cancelable")) return;

    const dest = { id: ev.to.id, index: ev.newIndex! };
    const src = { id: ev.from.id, index: ev.oldIndex! };
    const draggableId = ev.item.id;

    const copiedItems = [..._cols!];
    const [removed] = copiedItems.splice(src.index, 1);
    copiedItems.splice(dest.index, 0, removed);
    setCols(copiedItems);
  };

  const onCardRemove = (effortId: number, colId: string) => {
    const _rollbackCols = cloneCols(_cols!);
    setCols(
      _cols!.map((eachCol) =>
        eachCol.id !== colId
          ? eachCol
          : {
              ...eachCol,
              effortIds: eachCol.effortIds?.filter((e) => e !== effortId),
            }
      )
    );

    onUpdate({
      effortId,
      desColId: "-1",
      srcColId: colId,
    }).then((_errors) => {
      if (!isEmpty(_errors)) {
        setCols(_rollbackCols);
      }
    });
  };

  const onCardDragEnd = (ev: Sortable.SortableEvent) => {
    if (!objSafeGet(ev, "originalEvent", "cancelable")) {
      _setDraggingEffortId(undefined);
      return;
    }

    const dest = { id: ev.to.id, index: ev.newIndex! };
    const src = { id: ev.from.id, index: ev.oldIndex! };
    const draggableId = ev.item.id;

    let effortId = parseInt(draggableId);
    let targetEffort = (_itemsListContext.data as Effort[])!.find(
      (e) => e.id === effortId
    )!;
    let desColId = dest.id;
    let srcColId = src.id;

    const _rollbackCols = cloneCols(_cols!);
    setCols(
      _cols!.map((eachCol) => {
        if (eachCol.id === srcColId && !_isCloning) {
          const copiedItems = [...eachCol.effortIds!];
          copiedItems.splice(src.index, 1);
          eachCol.effortIds = copiedItems;
        }

        if (eachCol.id === desColId) {
          let _isAlreadyInCol = eachCol.effortIds?.some(
            (e) => e === targetEffort.id
          );
          if (!_isAlreadyInCol) {
            const copiedItems = [...eachCol.effortIds!];
            copiedItems.splice(dest.index, 0, targetEffort.id);
            eachCol.effortIds = copiedItems;
          }
        }

        return eachCol;
      })
    );

    _setDraggingEffortId(undefined);

    if (dest.id !== src.id) {
      onUpdate({
        effortId,
        desColId,
        srcColId,
        isCloning: _isCloning,
      }).then((_errors) => {
        if (!isEmpty(_errors)) {
          setCols(_rollbackCols);
        }
      });
    }
  };

  return (
    <div className="flex-1 ">
      {cols === undefined ? (
        <div className="flex flex-col ">
          {Array(3)
            .fill(null)
            .map((e, i) => (
              <div
                key={"eachLoadingItem" + i}
                className="mx-1 mt-2 min-w-[300px]"
              >
                <Skeleton className="my-3 w-1/4 h-7" />
                <Skeleton
                  count={randomInt(5, 1)}
                  className="w-full mb-2 h-28"
                />
              </div>
            ))}
        </div>
      ) : cols.length === 0 ? (
        <EmptyListIndicator />
      ) : (
        <ItemsListTableSection>
          <ReactSortable
            id="board"
            group="board"
            disabled={!basedOnType.colsSortable}
            ghostClass="opacity-25" //invisible
            list={_cols ?? []}
            setList={() => {}}
            onEnd={onColDragEnd}
            handle={".section-handle"}
            // style={{
            //   height: `calc(100vh - ${ref.current?.offsetTop}px - 5px)`,
            // }}
            className={cn(
              "w-full caption-bottom text-sm rounded border-collapse border-spacing-0 table"
            )}
          >
            {/* <div className="flex flex-col gap-5"> */}
            {/* <Table asDiv> */}
            {basedOnType.label === "effort"
              ? _cols?.map((eachCol, i) => (
                  <EffortsProvider
                    key={"eachCol" + eachCol.id}
                    effortId={parseInt(eachCol.id)}
                  >
                    {/* <ModalProvider> */}
                    <EachTableSection
                      col={eachCol}
                      index={i}
                      colClick={colClick}
                      colActions={colActions}
                      basedOnType={basedOnType}
                      onDragEnd={onCardDragEnd}
                      disabled={isColDisabled(eachCol, _draggingEffortId)}
                      onDragStart={(ev) =>
                        _setDraggingEffortId(parseInt(ev.item.id))
                      }
                      onCardRemove={(ef) => onCardRemove(ef.id, eachCol.id)}
                    />
                    {/* </ModalProvider> */}
                  </EffortsProvider>
                ))
              : _cols?.map((eachCol, i) => (
                  <EachTableSection
                    key={"eachCol" + eachCol.id}
                    col={eachCol}
                    index={i}
                    colClick={colClick}
                    colActions={colActions}
                    basedOnType={basedOnType}
                    onDragEnd={onCardDragEnd}
                    disabled={isColDisabled(eachCol, _draggingEffortId)}
                    onDragStart={(ev) =>
                      _setDraggingEffortId(parseInt(ev.item.id))
                    }
                    onCardRemove={(ef) => onCardRemove(ef.id, eachCol.id)}
                  />
                ))}
            {/* </div> */}
            {/* </Table> */}
          </ReactSortable>
        </ItemsListTableSection>
      )}
    </div>
  );
}
