
export const RESPONSIVE_INLINE = {
    never: "",
    xs: "xs:inline",
    sm: "sm:inline",
    md: "md:inline",
    lg: "lg:inline",
    xl: "xl:inline",
    "2xl": "2xl:inline",
    0: "xs:inline",
    1: "sm:inline",
    2: "md:inline",
    3: "lg:inline",
    4: "xl:inline",
    5: "2xl:inline",
    always: "inline"
};
export const RESPONSIVE_HIDDEN = {
    never: "",
    xs: "xs:hidden",
    sm: "sm:hidden",
    md: "md:hidden",
    lg: "lg:hidden",
    xl: "xl:hidden",
    "2xl": "2xl:hidden",
    0: "xs:hidden",
    1: "sm:hidden",
    2: "md:hidden",
    3: "lg:hidden",
    4: "xl:hidden",
    5: "2xl:hidden",
    always: "hidden"
};
export const RESPONSIVE_FLEX = {
    never: "",
    xs: "xs:flex",
    sm: "sm:flex",
    md: "md:flex",
    lg: "lg:flex",
    xl: "xl:flex",
    "2xl": "2xl:flex",
    0: "xs:flex",
    1: "sm:flex",
    2: "md:flex",
    3: "lg:flex",
    4: "xl:flex",
    5: "2xl:flex",
    always: "flex"
};
export type RESPONSIVE_KEYS = keyof typeof RESPONSIVE_INLINE;
