
import Dict from 'models/Dict';
import { MemberFilter } from 'models/Member';
import { dataMapper, getOnlyFields, getNotEmptyFields } from 'services/UtilServices';
import useApiServices from './UseApiServices';
import { BASE_URL as EFFORT_BASE_URL } from './UseEffortApi';

export const BASE_URL = "memberships/";

export default function useMemberApi() {

  const apiServices = useApiServices();


  // const get = async (filter: MemberFilter)  => {
  //   let response = await apiServices.send({
  //     url: BASE_URL + "AllMemberShips",
  //     // method: "GET",
  //     body: filter,
  //   });

  //   return response;
  // }


  const create = async (member: Dict) => {
    let response = await apiServices.send({
      url: BASE_URL + "new",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: {
        ...dataMapper(member, [
          "userId",
          "effortId",
          "accessType",
          "memberType",
          "newEffort",
          "effortUpdate",
          "newComment",
          "commentUpdate",
        ]),

        "isAssign": true,
        "weight": 0,
      },
    });

    return response.id;
  }


  const update = async (member: Dict) => {
    let response = await apiServices.send({
      url: BASE_URL + "update",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: dataMapper(member, [
        "id",
        "accessType",
        "memberType",
        "newEffort",
        "effortUpdate",
        "newComment",
        "commentUpdate",
      ])
    });

    return response;
  }


  const remove = async (ids: string[]) => {
    await apiServices.send({
      url: BASE_URL + "delete",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: { ids }
    });
  }

  return {
    // get,
    create,
    update,
    remove,
  };
}

