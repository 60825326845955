import React from "react";

import Dict from "models/Dict";
import { OfficeFilter } from "models/Office";

import { Form, Input, SubmitButton } from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useOfficeUtils from "hooks/utils/UseOfficeUtils";

export default function OfficeFilterModal({
  filter,
  onSubmit,
}: {
  filter: OfficeFilter;
  onSubmit: (data: Dict) => void;
}) {
  const officeUtils = useOfficeUtils();

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        <div className="flex gap-2">
          <div className="flex-1">
            <Input name="name" label="name" maxLength={256} />
          </div>
          <div className="flex-1">
            <Input name="location" label="location" maxLength={256} />
          </div>
        </div>

        <Input name="manager" label="Manager" maxLength={256} />

        <DatePickerInputRange name="dateAdd" label="Date Add" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={officeUtils.isFilterActive({ filter })}
            onClick={() => onSubmit({ pageNumber: -1 })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
