import React from "react";

import { cn } from "services/UtilServices";

import { useModalContext } from "providers/ModalProvider";

import { LuBell } from "react-icons/lu";
import { DialogContent } from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Badge } from "components/ui/badge";
import UserLogsSection from "components/pages/UserLogsSection/UserLogsSection";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import { useNotificationsContext } from "providers/NotificationsProvider";

export default function NotificationButton() {
  const { totalCount } = useNotificationsContext();
  const _modalContext = useModalContext();
  const mediaQuery = useMediaQuery();

  const onClick = () => {
    _modalContext.open(
      <DialogContent title={"   "}>
        <UserLogsSection type="Notifications" />
      </DialogContent>
    );
  };

  return (
    <>
      {totalCount > 0 &&
        (mediaQuery <= MediaQuery2Num.sm ? (
          <Button
            onClick={onClick}
            variant={"light"}
            className={cn(" border-0 relative px-1", {
              "ms-2": totalCount > 9,
              "ms-3": totalCount > 99,
            })}
          >
            <LuBell />
            <Badge className=" absolute right-5 top-0 ">{totalCount}</Badge>
          </Button>
        ) : (
          <Popover>
            <PopoverTrigger asChild>
              <Button
                // onClick={onClick}
                variant={"light"}
                className={cn(" border-0 relative px-1", {
                  "ms-2": totalCount > 9,
                  "ms-3": totalCount > 99,
                })}
              >
                <LuBell />
                <Badge className=" absolute right-5 top-0 ">{totalCount}</Badge>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              className=" w-96 max-h-[400px] overflow-y-auto "
              align="end"
            >
              <UserLogsSection type="Notifications" />
            </PopoverContent>
          </Popover>
        ))}
    </>
  );
}
