import React from "react";

import LocalStorageServices from "services/localServices/LocalStorageServices";

import MenuButton from "components/ui/menu-button";
import { FaCaretDown, FaChartLine, FaTrello } from "react-icons/fa6";
import { Button } from "components/ui/button";
import { cn } from "services/UtilServices";
import { LuTableProperties, LuGrid } from "react-icons/lu";
import { useEffortsContext } from "providers/EffortsProvider";

export const ITEMS = [
  { text: "board", icon: <FaTrello /> },
  { text: "table", icon: <LuTableProperties /> },
  // { text: "grid", icon: <LuGrid /> },
  // { text: "chart", icon: <FaChartLine /> },
];

export function ViewTypeRow() {
  const _context = useEffortsContext();

  const selectedItem = ITEMS.find((e) => e.text === _context.viewType);

  const onSelect = (text: string) => {
    _context.setViewType(text);
    LocalStorageServices.set("EFFORT_SECTION_VIEW_TYPE", text);
  };

  return (
    <div className="flex items-center ">
      {ITEMS.map((eachItem) => (
        <Button
          key={"eachViewType" + eachItem.text}
          variant={"light"}
          onClick={() => onSelect(eachItem.text)}
          className={cn("p-3 pb-2 border-b-4 border-transparent", {
            "rounded-b-none border-foreground box-border":
              selectedItem === eachItem,
          })}
        >
          {eachItem.icon} {eachItem.text}
        </Button>
      ))}
    </div>
  );
}

export default function ViewTypeSelectButton() {
  const _context = useEffortsContext();

  const selectedItem = ITEMS.find((e) => e.text === _context.viewType);

  const onSelect = (text: string) => {
    _context.setViewType(text);
    LocalStorageServices.set("EFFORT_SECTION_VIEW_TYPE", text);
  };

  return (
    <>
      {selectedItem !== undefined && (
        <MenuButton
          ellipsisResponsiveBreakPoint={"always"}
          ellipsisClassName="border opacity-100"
          children={
            <>
              <FaCaretDown className="!text-base" />
              {selectedItem.icon}
              <span className="hidden lg:inline capitalize">
                {selectedItem.text}
              </span>
            </>
          }
          items={ITEMS.filter((e) => e !== selectedItem).map((eachItem) => ({
            icon: eachItem.icon,
            text: eachItem.text,
            onClick: () => onSelect(eachItem.text),
          }))}
        />
      )}
    </>
  );
}
