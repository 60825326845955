import React from "react";
import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { cn, dateGetToday } from "services/UtilServices";

import LocalDateTime from "components/common/LocalDateTime";

export default function DateDueCheeps({ effort }: { effort: Effort }) {
  const _effortUtils = useEffortUtils();

  return (
    <span
      className={cn("block rounded px-1 py-0.5 text-xs", {
        "bg-destructive text-light": _effortUtils.isMatch(effort, {
          dateDueTo: dateGetToday(),
        }),
      })}
    >
      <LocalDateTime date={effort.dateDue} />
    </span>
  );
}
