import React from "react";

import Dict from "models/Dict";
import { ActivityFilter } from "models/Activity";

import { objList2Options } from "services/UtilServices";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import {
  Switch,
  Form,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useUserUtils from "hooks/utils/UseUserUtils";
import useActivityUtils from "hooks/utils/UseActivityUtils";
import { useMainContext } from "providers/MainProvider";
import { useEffortResources } from "hooks/utils/UseEffortResources";

export default function ActivityFilterModal({
  filter,
  onSubmit,
  effortId,
}: {
  filter: ActivityFilter;
  onSubmit: (data: Dict) => void;
  effortId?: number | null;
}) {
  const _mainContext = useMainContext();
  const _currentuserContext = useCurrentUserContext();
  const _userUtils = useUserUtils();
  const activityUtils = useActivityUtils();

  const {
    resourceData: { users, types },
    getResources,
  } = useEffortResources();

  React.useEffect(() => {
    getResources({
      effortId,
      fieldNames: ["users", "types"],
    });
  }, [effortId]);

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        {!effortId && (
          <Select
            multiple
            label="Task"
            name="effortIdList"
            options={objList2Options(_mainContext.efforts, "title")}
          />
        )}

        <Select
          multiple
          label="User"
          name="userIdList"
          options={users?.map((e) => ({
            value: e.id,
            label: _userUtils.getFullName(e),
          }))}
        />

        <Select
          multiple
          label="Type"
          name="typeIdList"
          options={objList2Options(types)}
        />

        {/* {_currentuserContext.user!.role !== UserRoles.USER && ( */}
        <>
          <Switch name="isApproved" label="Approved Activities" />
        </>
        {/* )} */}

        {/* <CheckBox name="isApproved" label="Approved Activities" /> */}

        <DatePickerInputRange name="dateAdd" label="Activity added" />

        <DatePickerInputRange name="dateDone" label="Activity Done" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={activityUtils.isFilterActive({ effortId, filter })}
            onClick={() =>
              onSubmit({
                pageNumber: -1,
                ascOrder: filter.ascOrder,
                sortBy: filter.sortBy,
                ...(effortId ? { effortIdList: filter.effortIdList } : {}),
              })
            }
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
