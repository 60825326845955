import React from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { cn } from "services/UtilServices";

export default function LoadingSpinner({
  color = "black",
  className = "",
  size = 7,
}) {
  return (
    <div
      className={cn(
        "flex items-center justify-center w-full h-full ",
        className
      )}
    >
      <SyncLoader size={size} color={color} />
    </div>
  );
}
