import GalleryView from "components/pages/galleryView/GalleryView";
import React from "react";

export type GalleryItemType = { url: string; name?: string };
export interface GalleryViewProps {
  items: GalleryItemType[];
  initUrl: string;
}

interface GalleryViewContextProps {
  open: (props: GalleryViewProps) => void;
  dismiss: () => void;
}

const GalleryViewContext = React.createContext({} as GalleryViewContextProps);
GalleryViewContext.displayName = "GalleryViewContext";

function GalleryViewProvider({ children }: { children: React.ReactNode }) {
  const [galleryProps, setGalleryProps] =
    React.useState<GalleryViewProps | null>(null);

  const open = (props: GalleryViewProps) => {
    if (props.items.length > 0) {
      setGalleryProps(props);
    }
  };

  const dismiss = () => {
    setGalleryProps(null);
  };

  return (
    <GalleryViewContext.Provider
      value={
        {
          open,
          dismiss,
        } as GalleryViewContextProps
      }
    >
      {children}

      {galleryProps && <GalleryView {...galleryProps} />}
    </GalleryViewContext.Provider>
  );
}

export function useGalleryViewContext() {
  const _context = React.useContext(GalleryViewContext);

  if (!_context) {
    throw new Error("cannot use GalleryViewContext outside of its provider.");
  }

  return _context;
}

export { GalleryViewContext, GalleryViewProvider };
