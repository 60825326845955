import React from "react";

import {
  GalleryItemType,
  useGalleryViewContext,
} from "providers/GalleryViewProvider";
import useAttachmentApi from "hooks/api/UseAttachmentApi";
import { LuDownload, LuX } from "react-icons/lu";
import { Button } from "components/ui/button";

export default function HeaderSection({ item }: { item: GalleryItemType }) {
  const _galleryViewContext = useGalleryViewContext();
  const attachmentApi = useAttachmentApi();

  const onDownload = async () => {
    try {
      await attachmentApi.download(item);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="text-light z-1000 bg-[#00000055] pointer-events-auto ">
      <div className="flex items-center  w-full mx-auto px-4 lg:w-3/4 py-3 ">
        <Button
          variant={"outline-light"}
          className="rounded-full p-3"
          onClick={() => _galleryViewContext.dismiss()}
        >
          <LuX size={25} strokeWidth={1.5} />
        </Button>

        <Button
          onClick={onDownload}
          variant={"outline-light"}
          className="rounded-full p-3"
        >
          <LuDownload size={25} strokeWidth={1.5} />
        </Button>

        <div className="flex-1 text-center">
          <span>{item.name}</span>
        </div>
      </div>
    </div>
  );
}
