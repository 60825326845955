import React from "react";

import Effort from "models/Effort";

import { useMainContext } from "providers/MainProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";

import SideMenuCollapse from "./SideMenuCollapse";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import { useThemeContext } from "providers/ThemeProvider";

import { PiHeadset } from "react-icons/pi";
import { AiOutlineHome } from "react-icons/ai";
import {
  LuBell,
  LuLineChart,
  LuListTree,
  LuMoon,
  LuSun,
  LuTimer,
  LuUserCog,
} from "react-icons/lu";
import { cn, isEmpty, listFirst, listSum } from "services/UtilServices";
import { Separator } from "components/ui/separator";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import EffortBanner from "components/pages/EffortsSection/EffortBanner";
import { EachPanelItem } from "./EachPanelItem";
import { useMainLayoutContext } from "layouts/MainLayout";
import { useAdminPageSection } from "pages/AdminPage";
import Link from "components/common/Link";
import AppLogo from "components/pages/AppLogo";
import SearchComponent from "components/common/SearchComponent";
import { useReportPageSection } from "pages/ReportPage";
import Directionality from "components/common/Directionality";
import CollapseText from "components/common/CollapseText";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

function EffortItemSection({
  efforts,
  effortId,
}: {
  efforts: Effort[];
  effortId: number;
}) {
  const _currentUserContext = useCurrentUserContext();
  const _effortUtils = useEffortUtils();
  const effort = efforts.find((e) => e.id === effortId)!;

  const subEffortsDeep = _effortUtils.getSubEffortsDeep({
    id: effort.id,
  });

  return (
    <SideMenuCollapse
      title={
        <Directionality
          title={effort.title}
          className={cn("truncate inline-block ", {
            " line-through opacity-50": !effort.isActive,
          })}
        >
          <CollapseText text={effort.title} />
        </Directionality>
      }
      icon={
        <EffortBanner
          effort={effort}
          className="object-cover rounded border w-7 h-7"
        />
      }
      url={_effortUtils.makeUrl(effortId)}
      isProject={_effortUtils.isProject(effort)}
      matchUrls={
        subEffortsDeep
          .map((e) => _effortUtils.makeUrl(e.id))
          .filter((e) => e) as string[]
      }
      notifCount={
        effort.notificationCount
        // listSum(subEffortsDeep, (e) => e.notificationCount) ?? 0
        // _currentUserContext.user!.logs?.filter((e) =>
        //   subEffortsDeep.some((a) => a.id === e.effortId)
        // ).length
      }
    >
      {efforts
        .filter((e) => e.parentId === effort.id)
        .sort((a, b) => (a.dateAdd < b.dateAdd ? 1 : -1))
        .map((eachEffort) => (
          <EffortItemSection
            key={"eachEffort" + eachEffort.id}
            efforts={efforts}
            effortId={eachEffort.id}
          />
        ))}
    </SideMenuCollapse>
  );
}

function ProjectsSection() {
  const _mainContext = useMainContext();
  const _effortUtils = useEffortUtils();

  const [_searched, setSearched] = React.useState<string>("");

  return (
    <SectionBase title="Projects">
      <SearchComponent
        placeholder="Search All Tasks"
        onSearch={(v) => {
          setSearched(v);
        }}
      />
      <div className="py-1"></div>
      <EmptyListIndicator wrapperClassName="h-full">
        {(isEmpty(_searched)
          ? _mainContext.efforts?.filter((e) => _effortUtils.isProject(e))
          : _mainContext.efforts?.filter((e) =>
            e.title.toLowerCase().includes(_searched.toLowerCase())
          )
        )
          ?.sort((a, b) => (a.dateAdd < b.dateAdd ? 1 : -1))
          .map((e) => (
            <EffortItemSection
              key={"eachEffort" + e.id}
              efforts={_mainContext.efforts!}
              effortId={e.id}
            />
          ))}
      </EmptyListIndicator>
    </SectionBase>
  );
}

function AdminSection() {
  const SECTIONS = useAdminPageSection();

  return (
    <SectionBase title="Admin Panel">
      {SECTIONS.map((eachSection) => (
        <Link
          key={"eachSectoin" + eachSection.url}
          to={"/admin/" + eachSection.url}
          exact={eachSection.exact}
          variant={"light"}
          activeVariant="primary"
          className={cn(" px-2 py-3 w-full justify-start")}
        >
          {eachSection.text}
        </Link>
      ))}
    </SectionBase>
  );
}

function ReportsSection() {
  const SECTIONS = useReportPageSection();

  return (
    <SectionBase title="Reports">
      {SECTIONS.map((eachSection) => (
        <Link
          key={"eachSectoin" + eachSection.url}
          to={"/reports/" + eachSection.url}
          // exact={eachSection.exact}
          variant={"light"}
          activeVariant="primary"
          className={cn(" px-2 py-3 w-full justify-start")}
        >
          {eachSection.text}
        </Link>
      ))}
    </SectionBase>
  );
}

function SectionBase({
  title,
  children,
}: {
  title?: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <>
      <div className="text-xl h-16 flex items-center px-1 font-bold">
        {title}
      </div>
      {children}
    </>
  );
}

export function DesktopSideMenu({ children }: { children?: React.ReactNode }) {
  const _currentUserContext = useCurrentUserContext();
  const _mainLayoutContext = useMainLayoutContext();
  const _themeContext = useThemeContext();
  const mediaQuery = useMediaQuery();

  const PANELS = [
    ...(_currentUserContext.isSudo()
      ? [
        {
          url: "admin",
          text: "Admin",
          icon: <LuUserCog className="text-2xl" />,
          content: <AdminSection />,
          // onClick: () => navigate("/admin/"),
        },
      ]
      : []),
    {
      url: "projects",
      text: "Projects",
      icon: <LuListTree className="text-2xl" />,
      content: <ProjectsSection />,
    },
    // {
    //   url: "Reports",
    //   text: "Reports",
    //   icon: <LuLineChart className="text-2xl" />,
    //   content: <ReportsSection />,
    // },
  ];

  const content = PANELS.find(
    (e) => e.url === _mainLayoutContext.leftSideMenu
  )?.content;

  React.useEffect(() => {
    if (!_mainLayoutContext.leftSideMenu && mediaQuery <= MediaQuery2Num.xl) {
      _mainLayoutContext.setLeftSideMenu(null);
    }
  }, [mediaQuery]);

  return (
    <div className={cn("md:block hidden  h-full bg-background ")}>
      {_mainLayoutContext.leftSideMenu && mediaQuery <= MediaQuery2Num.xl && (
        <div
          onClick={() => _mainLayoutContext.setLeftSideMenu(null)}
          className="fixed inset-0 xl:hidden bg-background/50 z-30 backdrop-blur-sm"
        ></div>
      )}

      <div className={cn("flex  h-full border-e z-40 relative")}>
        <div className="flex flex-col h-full p-1 w-16 pb-2">
          <AppLogo mini className="h-16 p-1" />

          <EachPanelItem
            to="/"
            exact
            isMini={true}
            icon={<AiOutlineHome className="text-2xl" />}
            text="Home"
          />

          <EachPanelItem
            to="/activities"
            icon={<LuTimer className="text-2xl" />}
            isMini={true}
            text="Activities"
          />

          {PANELS.map((eachPanel) => (
            <EachPanelItem
              key={"eachItem" + eachPanel.url}
              type="BUTTON"
              isMini={true}
              icon={eachPanel.icon}
              text={eachPanel.text}
              isActive={_mainLayoutContext.leftSideMenu === eachPanel.url}
              onClick={() => {
                _mainLayoutContext.setLeftSideMenu(
                  _mainLayoutContext.leftSideMenu === eachPanel.url
                    ? null
                    : eachPanel.url
                );
              }}
            />
          ))}

          {/* 
          {_currentUserContext.isSudo() && (
            <EachPanelItem
              to="/admin"
              isMini={true}
              icon={<LuUserCog className="text-2xl" />}
              text="Admin"
              isActive={_mainLayoutContext.leftSideMenu === "ADMIN"}
              onClick={() => _mainLayoutContext.setLeftSideMenu("ADMIN")}
            />
          )}

          <EachPanelItem
            type="BUTTON"
            isMini={true}
            icon={<LuListTree className="text-2xl" />}
            text="Tree"
            isActive={_mainLayoutContext.leftSideMenu === "PROJECTS"}
            onClick={() => _mainLayoutContext.setLeftSideMenu("PROJECTS")}
          /> */}

          <div className="flex-1" />

          <EachPanelItem
            type="BUTTON"
            isMini={true}
            text="DarkOrLight"
            icon={
              _themeContext.isDark ? (
                <LuSun className="text-2xl" />
              ) : (
                <LuMoon className="text-2xl" />
              )
            }
            onClick={() =>
              _themeContext.setDarkModeState(
                _themeContext.isDark ? "light" : "dark"
              )
            }
          />

          <EachPanelItem
            to="/about"
            icon={<PiHeadset className="text-2xl" />}
            text="Support"
            isMini={true}
          />
        </div>

        <div className="relative ">
          <div
            className={cn(
              "overflow-y-auto bg-background  scrollbar-hide h-full border-s transition-all ",
              " duration-100 py-2 max-xl:shadow-[rgba(0,0,15,0.2)_2px_0px_4px_0px] max-xl:absolute max-xl:border-e ",
              content ? " px-2 w-80" : "opacity-0"
            )}
          >
            {content}

            <div className="py-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function MobileSideMenu({
  children,
  isMini = false,
}: {
  children?: React.ReactNode;
  isMini?: boolean;
}) {
  const _currentUserContext = useCurrentUserContext();

  return (
    <div className="flex flex-col h-screen ">
      {_currentUserContext.isSudo() && (
        <EachPanelItem
          to="/admin"
          className="ps-3"
          activeClassName="!ps-2"
          isMini={isMini}
          icon={<LuUserCog className="text-2xl" />}
          text="Admin"
        />
      )}

      <EachPanelItem
        to="/"
        exact
        className="ps-3"
        activeClassName="!ps-2"
        isMini={isMini}
        icon={<AiOutlineHome className="text-2xl" />}
        text="Home"
      />
      {/* 
      <SheetClose>
        <EachPanelItem
          className="px-3"
          to="/news"
          exact
          isMini={isMini}
          icon={<LuBell />}
          text="News"
          notifCount={_currentUserContext.user?.logs?.length}
        />
      </SheetClose> */}

      <Separator className="mb-2 mt-0" />

      <ProjectsSection />

      <div className="mt-auto py-4">
        <EachPanelItem
          to="/about"
          className="ps-3"
          activeClassName="!ps-2"
          icon={<PiHeadset className="text-2xl" />}
          text="Support"
          isMini={isMini}
        />
      </div>

      <div className="py-3"></div>
    </div>
  );
}
