
import Dict from 'models/Dict';
import Login from 'models/views/Login';
import { dataMapper, getOnlyFields } from 'services/UtilServices';
import useApiServices from './UseApiServices';
import RefreshRequest from 'models/views/RefreshRequest';
import ApiToken, { ApiTokenUtils } from 'models/views/ApiToken';
import { BASE_URL as EFFORT_BASE_URL } from './UseEffortApi';
import { AuditLogFilter } from 'models/AuditLog';

export const BASE_URL = "accounts/";

export default function useCurrentUserApi() {

  const apiServices = useApiServices();


  const login = async (credentials: Login) => {
    const _response = await apiServices.send({
      url: BASE_URL + "login/email",
      method: "post",
      body: {
        // "userName": credentials.userName,
        "email": credentials.email,
        "password": credentials.password
      }
    });

    return _response;
  }


  const logout = async (token?: ApiToken) => {
    if (
      token &&
      ApiTokenUtils.isAccessTokenValid(token) &&
      ApiTokenUtils.isRefreshTokenValid(token)
    ) {
      const _response = await apiServices.send({
        url: BASE_URL + "logout",
        method: "post",
        token
      });
    }
  }

  const get = async () => {
    const _response = await apiServices.send({
      url: BASE_URL + "profile",
      method: "get",
      expirationDurationMinutes: 2,
    });

    return {
      ...dataMapper(_response, [
        { "userId": "id" },
        "userName",
        "role",

        "officeId",
        "firstName",
        "lastName",
        "phone",
        "email",
        "avatarUrl",
        "bgImage",
        "themeColor",

        "newEffort",
        "effortUpdate",
        "newComment",
        "commentUpdate",
        "notificationCount",
        // "logs",

        // { "dateAdd": "createdOn" },
      ]),

    };
  }

  // const getLoginLogs = async () => {
  //   const _response = await apiServices.send({
  //                       url: BASE_URL + "/logs/logins",
  //                       method: "get",
  //                   });
  //   return {
  //     _response,
  //     loginLogs: _response.logins ?? [],
  //   };
  // }



  const update = async (user: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update/profile",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: getOnlyFields(user, [
        "themeColor",
        "bgImage",
        "avatarUrl",
        "userName",
        "firstName",
        "lastName",
        "newEffort",
        "effortUpdate",
        "newComment",
        "commentUpdate",
        "email",
        "phone"
      ])
    });

    return response;
  }

  const updatePassword = async (user: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update/password",
      method: "post",
      body: dataMapper(user, [
        { "password": "newPassword" },
        { "oldPassword": "password" },
      ])
    });


    return response;
  }

  const updatePhone = async (user: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update/profile",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: getOnlyFields(
        user,
        ["otp", "phone"]
      )
    });


    return response;
  }

  const updateEmail = async (user: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update/profile",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: getOnlyFields(
        user,
        ["otp", "email"]
      )
    });

    return response;
  }

  const sendOtp = async (target: string) => {

    let response = await apiServices.send({
      url: BASE_URL + "sendOtp",
      body: target
    });

    return response;
  }

  const sendForgotPasswordOTP = async (email: string) => {
    const _response = await apiServices.send({
      url: BASE_URL + "otp/email/send",
      // method: "put",
      body: {
        "email": email,
      },
    });

    return _response;
  }

  const validatePasswordOTP = async ({ email = "", otp = "" }) => {
    const _response = await apiServices.send({
      url: BASE_URL + "otp/email/verify",
      // method: "put",
      body: {
        "email": email,
        "otp": otp,
      },
    });

    return _response;
  }

  const resetPassword = async ({ email = "", otp = "", password = "" }: { email?: string | null, otp?: string | null, password?: string | null }) => {
    const _response = await apiServices.send({
      url: BASE_URL + "otp/email/reset",
      // method: "put",
      body: {
        "email": email,
        "otp": otp,
        "newPassword": password,
      },
    });

    return _response;
  }

  return {
    login,
    logout,
    get,
    update,
    updatePassword,
    updatePhone,
    updateEmail,
    sendOtp,
    sendForgotPasswordOTP,
    validatePasswordOTP,
    resetPassword,
  }

}

