
import Effort from 'models/Effort';
import useEffortApi from './UseEffortApi';

export default function useMainApi() {

  const effortApi = useEffortApi();

  const all = async () => {

    let response = await effortApi.get({
      "pageNumber": 0,
      "ascOrder": true,
      numberInPage: 9999,
      includes: true,
    });

    return {
      items: response
      // .map((eachEffort: Effort) => {

      //   eachEffort.comments = eachEffort.comments?.map(e => {
      //                           e.id = e.id.toString();
      //                           e.parentId = e.parentId?.toString();
      //                           return e;
      //                         }) ?? [];

      //   return eachEffort;
      // }),
    };
  }

  return {
    all
  }

}

