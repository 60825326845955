import React from "react";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import { useFormContext } from "./Form";
import Validation from "./UseValidation";
import FormFieldBase, { FormFieldBaseInputProps } from "./FormFieldBase";
import { cn, getItem, listFirst } from "services/UtilServices";
import { useThemeContext } from "providers/ThemeProvider";
import { Button } from "components/ui/button";

export interface OptionType {
  name?: string;
  value: string | number | null | boolean;
  label: string | React.ReactNode;
  description?: string | React.ReactNode;
}

interface Props extends FormFieldBaseInputProps {
  options?: OptionType[];
  multiple?: boolean;
}

export default function SelectButtonGroup(props: Props) {
  const _themeContext = useThemeContext();
  const _formContext = useFormContext();

  const { name, value, options = [], multiple, required } = props;

  React.useEffect(() => {
    if (value !== undefined && !(name in _formContext.data)) {
      _formContext.setData({ [name]: value });
    } else if (
      required &&
      !multiple &&
      options.length > 0 &&
      (!(name in _formContext.data) || _formContext.data[name] === undefined)
    ) {
      _formContext.setData({
        [name]: options[0].value,
      });
    }
  }, [value]);

  const handleChange = (option: OptionType) => {
    _formContext.setData({
      [name]: multiple
        ? [...(_formContext.data[name] ?? []), option.value]
        : option.value,
    });
  };

  let _selectedOptions: OptionType[] = [];
  if (multiple) {
    _selectedOptions = options.filter((e) =>
      _formContext.data[name]?.includes(e.value)
    );
  } else {
    _selectedOptions = options.filter(
      (e) => e.value === _formContext.data[name]
    );
  }

  // React.useEffect(() => {

  //   if(props.value !== undefined && !(props.name in _formContext.data)) {
  //     _formContext.setData({ [props.name]: props.value });
  //   }

  // }, [props.value]);

  // const selectedOption = props.options.find(e =>
  //                         _formContext.data[props.name] === e.value
  //                       );

  return (
    <FormFieldBase
      {...props}
      description={
        multiple ||
        _selectedOptions.length === 0 ||
        listFirst(_selectedOptions)?.description === undefined
          ? props.description
          : listFirst(_selectedOptions)?.description
      }
    >
      {(baseProps) => (
        <div className="flex items-center gap-1 ">
          {props.options?.map((e, index) => (
            <Button
              key={"selectGroupButton" + index}
              onClick={() => handleChange(e)}
              disabled={props.disabled || baseProps.isLoading}
              className={cn(" border ", props.itemClassName)}
              variant={_selectedOptions?.includes(e) ? "primary" : "light"}
            >
              {e.label}
            </Button>
          ))}
        </div>
      )}
    </FormFieldBase>
  );
}
