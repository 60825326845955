import Device, {
  CLIENT_INFOS,
  DEVICE_INFOS,
  DeviceFilter,
} from "models/Device";
import { hasOtherKeysExcept } from "services/UtilServices";

export default function useDeviceUtils() {
  const isFilterActive = ({ filter }: { filter: DeviceFilter }) => {
    return hasOtherKeysExcept(filter, [
      "pageNumber",
      "ascOrder",
      "sortBy",
      "numberInPage",
    ]);
  };

  const getDeviceInfo = ({ device }: { device: Device }) => {
    const _deviceInfoKey = Object.keys(DEVICE_INFOS).find((k) =>
      device.device.toLowerCase().includes(k)
    );
    return _deviceInfoKey
      ? DEVICE_INFOS[_deviceInfoKey as keyof typeof DEVICE_INFOS]
      : undefined;
  };

  const getClientInfo = ({ device }: { device: Device }) => {
    const _clientInfoKey = Object.keys(CLIENT_INFOS).find((k) =>
      device.client.toLowerCase().includes(k)
    );
    return _clientInfoKey
      ? CLIENT_INFOS[_clientInfoKey as keyof typeof CLIENT_INFOS]
      : undefined;
  };

  return {
    isFilterActive,
    getDeviceInfo,
    getClientInfo,
  };
}
