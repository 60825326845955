import axios, { AxiosError, AxiosRequestConfig } from "axios";

import Dict from "../../models/Dict";
import ApiToken from "models/views/ApiToken";

import ImgCompressServices from "services/ImgCompressServices";
import { getOnlyFields, isDateUntil, listLast } from "services/UtilServices";
import { CurrentUserStatics } from "providers/CurrentUserProvider";
import LocalStorageServices from "services/localServices/LocalStorageServices";
import { useToast } from "components/ui/use-toast";
import useApiTokenUtils from "hooks/utils/UseApiTokenUtils";

interface SendProps {
  body?: Dict | FormData | string;
  url?: string;
  method?:
    | "get"
    | "post"
    | "put"
    | "delete"
    | "GET"
    | "POST"
    | "PUT"
    | "DELETE";
  onUploadProgress?: (v: number) => void;
  responseType?: "json" | "blob";
  token?: ApiToken;
  expirationDurationMinutes?: number;
  inValidateUrls?: string[];
  inValidateBaseUrlByDefault?: boolean;
}

interface HistoryItem {
  response: Dict;
  requestedOn: Date;
}

interface SendFileProps {
  file: File | FileList | undefined | null | string;
  onUploadProgress?: ((v: number) => void) | undefined;
  size?: number | undefined;
  fieldName?: string | undefined;
  effortId?: number | undefined;
}

export class ApiVariables {
  static BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // static BASE_URL = "https://apipertaskdev.perxact.de/" // "https://apipertask.perxact.de/"
  // process.env.NODE_ENV === "development" ?
  // "https://apipertaskdev.perxact.de/" :
  // "https://apipertask.perxact.de/"
  static API_URL = ApiVariables.BASE_URL;
  static FILE_URL = ApiVariables.BASE_URL + "files/";
  static PUBLIC_FILE_URL = ApiVariables.BASE_URL + "public/";
  static SECURE_FILE_URL = ApiVariables.BASE_URL + "files/download?fileUrl=";

  static TOKEN: ApiToken | null = null; //LocalStorageServices.get('TOKEN'); // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYWRtaW4iLCJqdGkiOiIwNWY5NmQ2Yi1kOTY2LTQyYWQtYjUzNC0zZjRjMDQwZDQ0OGQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOlsiQWRtaW4iLCJVc2VyIl0sImV4cCI6MTY5ODY2NDcwMCwiaXNzIjoiKiIsImF1ZCI6IioifQ.hfGiRkeTfddi08asJuTjV_xbgVVeKEvrxUafVEU2SbY";
  static REQUESTS: SendProps[] = [];
  static IS_REFRESHING_TOKEN = false;
  static CACHE: { [key: string]: HistoryItem } = {};
}

export default function useApiServices() {
  const { toast } = useToast();
  const apiTokenUtils = useApiTokenUtils();

  const sendFile = async ({
    file,
    onUploadProgress,
    size,
    fieldName = "",
    effortId,
  }: SendFileProps) => {
    if (file === undefined || file === null || typeof file === "string") {
      return file;
    }

    file = file instanceof FileList ? file.item(0)! : file;

    if (file.size > 5000000) {
      throw { [fieldName]: ["File size can not be more than 5M!"] };
    }

    if (size && file.type.startsWith("image")) {
      //["jpeg", "jpg", "png"].some(e => (file as File).type.includes(e))
      file = (await ImgCompressServices.compress(file, size))[0];
    }

    const _formData = new FormData();
    _formData.append("File", file);
    if (effortId) {
      _formData.append("EntityName", "effort");
      _formData.append("EntityId", effortId.toString());
    }

    const { id, update } = toast(`Uploading "${file.name}"...`);
    const _response = await send({
      url: "Files/Upload",
      body: _formData,
      expirationDurationMinutes: 0,
      onUploadProgress: (e) => {
        console.log(e);
        update({
          id,
          description: `Uploading "${(file as File).name}" (${e * 100}%)`,
        });
      },
    });
    toast("Successfully Uploaded.");

    return _response;
  };

  const removeFile = async (fileNames: string | string[]) => {
    await send({
      url: "file/delete",
      method: "delete",
      body: fileNames,
    });
  };

  const tokenValidation = async () => {
    if (ApiVariables.TOKEN === null) {
      ApiVariables.TOKEN = await LocalStorageServices.get("TOKEN");
    }

    while (ApiVariables.IS_REFRESHING_TOKEN) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    if (ApiVariables.TOKEN) {
      if (!apiTokenUtils.isRefreshTokenValid(ApiVariables.TOKEN)) {
        console.log(
          "ehsan refresh expireeeeeeeeeeeeeeeeeeeeeeeeeee",
          ApiVariables.TOKEN
        );
        console.log(
          "accessTokenExpiryDate: ",
          new Date(ApiVariables.TOKEN.accessTokenExpiryDate)
        );
        console.log(
          "refreshTokenExpiryDate: ",
          new Date(ApiVariables.TOKEN.refreshTokenExpiryDate)
        );
        console.log("system: ", new Date());
        CurrentUserStatics.logout?.();
        return;
      } else if (!apiTokenUtils.isAccessTokenValid(ApiVariables.TOKEN)) {
        console.log(
          "ehsan access expireeeeeeeeeeeeeeeeeeeeeeeeeee",
          ApiVariables.TOKEN
        );
        console.log(
          "accessTokenExpiryDate: ",
          new Date(ApiVariables.TOKEN.accessTokenExpiryDate)
        );
        console.log(
          "refreshTokenExpiryDate: ",
          new Date(ApiVariables.TOKEN.refreshTokenExpiryDate)
        );
        console.log("system: ", new Date());

        ApiVariables.IS_REFRESHING_TOKEN = true;
        try {
          ApiVariables.TOKEN = await _send({
            url: "accounts/refresh",
            method: "post",
            body: getOnlyFields(ApiVariables.TOKEN, [
              "accessToken",
              "refreshToken",
            ]),
          });
          await LocalStorageServices.set("TOKEN", ApiVariables.TOKEN);
        } catch (e) {
          ApiVariables.IS_REFRESHING_TOKEN = false;
          CurrentUserStatics.logout?.();
          return;
        }
        ApiVariables.IS_REFRESHING_TOKEN = false;
      }
    }
  };

  const handleInValidateUrls = async (props: SendProps) => {
    const _currentBaseUrl = props.url?.substring(0, props.url?.indexOf("/"));
    const _inValidateUrls = [...(props.inValidateUrls ?? [])];

    if (_currentBaseUrl && props.inValidateBaseUrlByDefault !== false) {
      _inValidateUrls.push(_currentBaseUrl);
    }

    _inValidateUrls.forEach((eachUrl) => {
      let inValidKey = Object.keys(ApiVariables.CACHE).find((e) =>
        (JSON.parse(e) as SendProps).url?.startsWith(eachUrl)
      );

      if (inValidKey) {
        delete ApiVariables.CACHE[inValidKey];
      }
    });
  };

  const sendIfNotInCache = async (props: SendProps) => {
    let _propsKey = JSON.stringify(props);
    if (_propsKey in ApiVariables.CACHE) {
      let _prevReq = ApiVariables.CACHE[_propsKey];
      if (
        isDateUntil({
          date: _prevReq.requestedOn,
          minutes: props.expirationDurationMinutes,
        })
      ) {
        return _prevReq.response;
      } else {
        delete ApiVariables.CACHE[_propsKey];
      }
    }

    const _response = await _send(props);

    handleInValidateUrls(props);

    ApiVariables.CACHE[_propsKey] = {
      response: _response,
      requestedOn: new Date(),
    };

    return _response;
  };

  const send = async (props: SendProps) => {
    await tokenValidation();
    return await sendIfNotInCache(props);
  };

  const _send = async ({
    body,
    url = "",
    method = "post",
    onUploadProgress,
    responseType,
    token,
  }: SendProps) => {
    let _response: Dict = {};

    // const config: AxiosRequestConfig<FormData> = {
    //     headers: {
    //         'content-type': body instanceof FormData ?
    //             'multipart/form-data' : "application/json",
    //     },

    //     onUploadProgress: onUploadProgress ?
    //         (progressEvent) => {
    //             onUploadProgress(
    //                 Math.round( progressEvent.loaded / progressEvent.total )
    //             )
    //     } :

    //         undefined
    // }

    let headers: Dict = {
      "Content-Type":
        body instanceof FormData ? "multipart/form-data" : "application/json",
    };

    if (ApiVariables.TOKEN !== null) {
      headers["Authorization"] = "Bearer " + ApiVariables.TOKEN.accessToken;
    } else if (token) {
      headers["Authorization"] = "Bearer " + token.accessToken;
    }

    // console.log("ApiVariables.TOKEN", ApiVariables.TOKEN, await LocalStorageServices.get('TOKEN'))

    console.log("requesttttttttttttttttttttttt", {
      headers,
      url: (url.startsWith("http") ? "" : ApiVariables.API_URL) + url,
      method,
      data: body,
    });

    // try {

    _response = await axios({
      headers,
      url: (url.startsWith("http") ? "" : ApiVariables.API_URL) + url,
      method,
      data: body,
      responseType,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(
              Math.round(progressEvent.loaded / progressEvent.total)
            );
          }
        : undefined,
    }).catch((error) => {
      console.log("erorrrrrrrrrrrrrrrrrrrr", error);

      if (error.response?.status === 500) {
        toast("Server Error. Try again later.", {
          variant: "destructive",
        });
      } else if (
        error.response?.status === 401 ||
        error.response?.status === 403
      ) {
        toast("Unauthorized.", {
          variant: "destructive",
        });
      } else if (
        error.response?.status === 0 ||
        error.code === "ECONNABORTED"
      ) {
        toast("Internet Connection Problem.", {
          variant: "destructive",
        });
      }

      return Promise.reject(error.response?.data?.errors ?? { "": [""] });
    });

    console.log("responseeeeeeeeeeeeeeeeeeee", _response);

    // }
    // catch(e) {
    //     console.log("erorrrrrrrrrrrrrrrrrrrr", e);

    //     ToastServices.error(
    //         (e as Dict).response.data.message ??
    //         "Server Error!"
    //     );

    //     throw new Error((e as Dict).response.data.message );
    // }

    let filename: string | undefined = listLast(url.split("/"));
    let disposition = _response.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1])
        filename = matches[1].replace(/['"]/g, "");
    }

    return _response.data instanceof Blob
      ? {
          blob: _response.data,
          filename,
        }
      : _response.data;
  };

  return {
    sendFile,
    send,
  };
}
