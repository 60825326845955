
import { z } from "zod";



export class ApiTokenUtils {

    static isAccessTokenValid(token: ApiToken) {
        return new Date(token.accessTokenExpiryDate) > new Date();
    }

    static isRefreshTokenValid(token: ApiToken) {
        return new Date(token.refreshTokenExpiryDate) > new Date();
    }

}




const apiTokenSchema = z.object({
    accessToken: z.string(),
    accessTokenExpiryDate: z.string(),
    refreshToken: z.string(),
    refreshTokenExpiryDate: z.string(),
    loginKey: z.string(),
});
type ApiToken = z.infer<typeof apiTokenSchema>;
export default ApiToken;

// export default interface ApiToken {
//     accessToken: string,
//     accessTokenExpiryDate: string,
//     refreshToken: string,
//     refreshTokenExpiryDate: string,
//     loginKey: string,
// }