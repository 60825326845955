import Compress from "compress.js";

export default class ImgCompressServices {

    static async compress(files: File | FileList | (File | FileList)[], size?: number) {
        if(!Array.isArray(files)) {
            files = [files];
        }

        let result: File[] = [];

        const resizedImages = await new Compress().compress(
            files.map(e => e instanceof FileList ? e[0] : e), 
            {
                // size: 2, // the max size in MB, defaults to 2MB
                quality: 1, // the quality of the image, max is 1,
                maxWidth: size, // the max width of the output image, defaults to 1920px
                maxHeight: size, // the max height of the output image, defaults to 1920px
                resize: true // defaults to true, set false if you do not want to resize the image width and height
            }
        );

        resizedImages.forEach((eachImg, index) => {
            // if(eachImg.initialWidthInPx <= size && eachImg.initialHeightInPx <= size) {
            //     result.push(files[index]);
            // }
            // else {
                const base64str = eachImg.data;
                const imgExt = eachImg.ext;

                result.push(
                    Compress.convertBase64ToFile(base64str, imgExt)
                );
            // }
        });

        return result;
    }

}