import React from "react";

import Dict from "models/Dict";

import { isBright, isDateUntil } from "services/UtilServices";

import { useCurrentUserContext } from "./CurrentUserProvider";
import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { MemberAccessTypes } from "models/Member";
import User from "models/User";
import useUserUtils from "hooks/utils/UseUserUtils";
import useAuthUtils from "hooks/UseAuthUtils";
import { useMainContext } from "./MainProvider";

interface AccessContextProps {
  effort: {
    canCreate: (parentId?: number | null) => boolean;
    canEdit: (items: Effort[]) => boolean;
    canDelete: (items: Effort[]) => boolean;
  };
}

const AccessContext = React.createContext({
  // isLoggedIn: () => AccessLocalServices.isLoggedIn()
} as AccessContextProps);
AccessContext.displayName = "AccessContext";

function AccessProvider({ children }: { children: React.ReactNode }) {
  const _currentUserContext = useCurrentUserContext();
  const _mainContext = useMainContext();
  const _effortUtils = useEffortUtils();
  const _authUtils = useAuthUtils();
  const _userUtils = useUserUtils();

  const effort = {
    canCreate: (parentId?: number | null) => {
      let accessType = _authUtils.authorize(parentId);

      return accessType >= MemberAccessTypes.Create;
    },

    canEdit: (items: Effort[]) => {
      let accessType = Math.min(
        ...items.map((e) => _authUtils.authorize(e.id))
      );

      return (
        accessType >= MemberAccessTypes.Edit ||
        (accessType >= MemberAccessTypes.Create &&
          items.every((e) => !_effortUtils.isProject(e)) &&
          items.every((e) => _currentUserContext.user!.id === e.creatorId))
      );
    },

    canDelete: (items: Effort[]) => {
      let accessType = Math.min(
        ...items.map((e) => _authUtils.authorize(e.id))
      );

      return (
        _userUtils.isSudo(_currentUserContext.user!) ||
        (accessType >= MemberAccessTypes.Delete &&
          items.every((e) => !_effortUtils.isProject(e))) ||
        (accessType >= MemberAccessTypes.Edit &&
          items.every((e) => !_effortUtils.isProject(e)) &&
          items.every((e) => _currentUserContext.user!.id === e.creatorId)) ||
        (accessType >= MemberAccessTypes.Create &&
          items.every((e) => !_effortUtils.isProject(e)) &&
          items.every((e) => _currentUserContext.user!.id === e.creatorId) &&
          items.every((e) => isDateUntil({ date: e.dateAdd, minutes: 2 })))
      );
    },
  };

  return (
    <AccessContext.Provider
      value={
        {
          effort,
        } as AccessContextProps
      }
    >
      {children}
    </AccessContext.Provider>
  );
}

export function useAccessContext() {
  const _context = React.useContext(AccessContext);

  if (!_context) {
    throw new Error("cannot use AccessContext outside of its provider.");
  }

  return _context;
}

export { AccessContext, AccessProvider };
