import React from "react";
import Dict from "models/Dict";
import useOnScreen from "./UseOnScreen";
import { cn } from "services/UtilServices";

export default function Skeleton({
  count = 1,
  className = "w-7 h-5",
  style,
  onScreen,
}: {
  style?: Dict;
  count?: number;
  className?: string;
  onScreen?: (d: boolean) => void;
}) {
  const ref = React.useRef(null);
  const isInview = useOnScreen(ref);

  onScreen?.(isInview);

  return (
    <>
      {Array(count)
        .fill(null)
        .map((e, i) => (
          <span
            ref={ref}
            key={"eachSkelet" + i}
            className={cn(
              "block rounded skeleton border animate-pulse bg-slate-200 dark:bg-slate-700 ",
              className
            )}
            style={style}
          />
        ))}
    </>
  );
}
