import { ApiVariables } from "./api/UseApiServices";


export default function useDownloadServices() {

    const downloadByBlob = ({ blob, filename }: { blob: Blob, filename?: string }) => {
        // if (typeof window.navigator.msSaveBlob !== 'undefined') {
        //     // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
        //     window.navigator.msSaveBlob(blob, filename);
        // } else {
        var URL = window.URL || window.webkitURL;
        var downloadUrl = URL.createObjectURL(blob);

        if (filename) {
            // use HTML5 a[download] attribute to specify filename
            var a = document.createElement("a");
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
                window.location.href = downloadUrl;
            } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
            }
        } else {
            window.location.href = downloadUrl;
        }

        setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
        // }
    }

    const downloadByUrl = (url: string) => {
        var link = document.createElement("a");
        link.setAttribute("href",
            url.startsWith(ApiVariables.FILE_URL) ? url : ApiVariables.FILE_URL + url
        );
        // link.setAttribute("download", 'ibvm export.csv');
        link.style.visibility = 'hidden';
        link.target = "_blank";
        link.download = ""

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return {
        downloadByBlob,
        downloadByUrl,
    }
}

