import * as React from "react";

//@ts-ignore
import emptyImageSrc from "images/empty.png";
import { cn } from "services/UtilServices";

function EmptyListIndicator({
  children,
  text,
  wrapperClassName = "",
}: {
  children?: React.ReactNode;
  text?: string;
  wrapperClassName?: string;
}) {
  return (
    <>
      {children === undefined || React.Children.count(children) === 0 ? (
        <div
          className={cn(
            "flex justify-center items-center   flex-col text-foreground",
            wrapperClassName
          )}
        >
          <img
            src={emptyImageSrc}
            className="mx-auto dark:invert dark:opacity-60 opacity-20"
          />
          <span className="mt-2 text-foreground/50 ">
            {text ?? "Not Found."}
          </span>
        </div>
      ) : (
        children
      )}
    </>
  );
}

export default EmptyListIndicator;
