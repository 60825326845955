import React from "react";

import Dict from "models/Dict";

import { useModalContext } from "providers/ModalProvider";

import { Form, Select, SubmitButton } from "components/common/Forms/Form";
import { objList2Options } from "services/UtilServices";
import { useToast } from "components/ui/use-toast";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import { useEffortResources } from "hooks/utils/UseEffortResources";

export default function EffortStateUpdateModal({
  effortIds,
  onSubmit,
  parentId,
}: {
  effortIds: number[];
  onSubmit: (data: Dict) => Promise<Dict>;
  parentId?: number | null;
}) {
  const _modalContext = useModalContext();
  const { toast } = useToast();

  const {
    resourceData: { states },
    getResources,
  } = useEffortResources();

  React.useEffect(() => {
    getResources({
      effortId: parentId,
      fieldNames: ["states"],
    });
  }, [parentId]);

  let _errorContent = undefined;
  if (states?.length === 0) {
    _errorContent = `You need to add a State ${
      parentId === null ? "" : "to your project"
    } before you can create tasks!`;
  }

  if (_errorContent) {
    _modalContext.dismiss();
    toast(_errorContent, {
      variant: "destructive",
    });
    return null;
  }

  return (
    <DialogContent title={"Change State"} size={"sm"} fullScreen={false}>
      <Form
        data={{ effortIds }}
        onSubmit={async (d) => {
          d.states = states?.filter((e) => d.stateList?.includes(e.id)) ?? [];
          d.state = states?.find((e) => e.id === d.stateId);

          return await onSubmit(d);
        }}
      >
        <Select
          name="stateId"
          label="State"
          required
          options={objList2Options(states)}
        />
        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
