import React from "react";

import useUserUtils from "hooks/utils/UseUserUtils";
import Member, { MemberAccessTypes } from "models/Member";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import { cn } from "services/UtilServices";
import User, { UserRoles } from "models/User";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "components/ui/hover-card";

export default function UserProfileHoverCard({
  user,
  member,
  children,
  align,
  side,
}: {
  user?: User;
  member?: Member;
  children?: React.ReactNode;
  align?: "center" | "start" | "end";
  side?: "top" | "right" | "bottom" | "left";
}) {
  const Icon = member ? MemberAccessTypes.icons[member.accessType] : "";
  const _userUtils = useUserUtils();

  return (
    <>
      {member || user ? (
        <HoverCard>
          <HoverCardTrigger>{children}</HoverCardTrigger>
          <HoverCardContent align={align} side={side}>
            <div className={cn("flex items-center gap-2 ")}>
              <ProfileAvatar size={50} user={user} needHover={false} />

              <div className="">
                <span className="font-bold text-lg">
                  {_userUtils.getFullName(user)}
                </span>
                {user?.role && (
                  <div className="text-xs text-muted-foreground">
                    {UserRoles.options[user.role]}
                  </div>
                )}

                {member && (
                  <div className="text-xs text-muted-foreground">
                    <Icon className="!text-sm" />{" "}
                    {MemberAccessTypes.options[member.accessType]} Access
                  </div>
                )}
              </div>
            </div>
          </HoverCardContent>
        </HoverCard>
      ) : (
        children
      )}
    </>
  );
}
