import React from "react";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import { EachPanelItem } from "components/layouts/sideMenu/EachPanelItem";
import { useEffortUtils, useEffortViewUtils } from "hooks/utils/UseEffortUtils";
import Effort from "models/Effort";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { EffortsProvider, useEffortsContext } from "providers/EffortsProvider";

import {
  LuClipboard,
  LuSlidersHorizontal,
  LuMessagesSquare,
  LuTimer,
  LuHistory,
  LuUserPlus,
} from "react-icons/lu";
import { cn } from "services/UtilServices";
import ActivitysSection from "./ActivitysSection/ActivitysSection";
import CommentsSection from "./CommentsSection/CommentsSection";
import SettingSection from "./SettingSection/SettingSection";
import EffortsSection from "../EffortsSection/EffortsSection";
import UserLogsSection from "../UserLogsSection/UserLogsSection";
import { Button } from "components/ui/button";
import { useUsersContext } from "providers/UsersProvider";
import ProfileAvatar from "../profile/account/ProfileAvatar";
import useMemberUtils from "hooks/utils/UseMemberUtils";
import { Separator } from "components/ui/separator";
import UserProfileHoverCard from "../admin/UsersSection/UserProfileHoverCard";
import LocalStorageServices from "services/localServices/LocalStorageServices";
import TaskSection from "./SettingSection/TaskSection";
import Resizable, { ResizableHandle } from "components/common/Resizable";

export function useSidePanelSections(
  effort?: Effort | null,
  includeSubTasks = false,
  includeSetting = true
) {
  const _currentUserContext = useCurrentUserContext();
  // effort!.level < 2

  return [
    ...(effort
      ? [
        ...(includeSubTasks
          ? [
            {
              url: "",
              exact: true,
              icon: <LuClipboard />,
              text: effort!.level === 0 ? "Tasks" : "Sub Tasks",
              content: <EffortsSection effortId={effort.id} />,
            },
          ]
          : []),

        ...(includeSetting
          ? [
            {
              url: "setting",
              text: "Setting",
              icon: <LuSlidersHorizontal />,
              content: <SettingSection effort={effort} />,
            },
          ]
          : []),
        {
          url: "comments",
          text: "Comments",
          icon: <LuMessagesSquare />,
          content: <CommentsSection effortId={effort.id} />,
        },
      ]
      : []),

    {
      url: "activitys",
      text: "Activities",
      icon: <LuTimer />,
      content: <ActivitysSection />,
    },
    {
      url: "logs",
      text: "Logs",
      icon: <LuHistory />,
      count: effort?.notificationCount,
      // count: effort
      //   ? _currentUserContext.user?.logs?.filter(
      //       (e) => e.effortId === effort.id
      //     ).length
      //   : undefined,
      content: <UserLogsSection onExitClear effortId={effort?.id} />,
    },
  ];
}

export default function EffortSidePanel() {
  const effortUtils = useEffortUtils();

  const effort = effortUtils.getEffortByUrl();

  const mediaQuery = useMediaQuery();
  const effortViewUtils = useEffortViewUtils(effort?.id);
  const effortsContext = useEffortsContext();
  const _usersContext = useUsersContext();
  const _memberUtils = useMemberUtils(effort);

  const [section, _setSection] = React.useState<string | null>(null);

  const _project = effortUtils.getParentProject(effort?.id);
  const _users = effort
    ? _project?.members?.filter((e) => !e.isDeleted).map((e) => e.user)
    : _usersContext.users;

  React.useEffect(() => {
    LocalStorageServices.get("EFFORT_SIDE_MENU").then((r) =>
      setSection(r ?? null)
    );
  }, []);

  React.useEffect(() => {
    if (!effort) {
      _usersContext.getAll();
    }
  }, [effort?.id]);

  React.useEffect(() => {
    if (mediaQuery <= MediaQuery2Num.xl && section) {
      setSection(null);
    }
  }, [mediaQuery]);

  const setSection = (value: string | null) => {
    _setSection(value);
    LocalStorageServices.set("EFFORT_SIDE_MENU", value);
  };

  const SECTOINS = useSidePanelSections(
    effort,
    false,
    (effort?.level ?? 10) === 0
  );
  const content = SECTOINS.find((e) => e.url === section)?.content;

  return (
    <EffortsProvider effortId={effort?.id}>
      {mediaQuery <= MediaQuery2Num.xl && section && (
        <div
          onClick={() => setSection(null)}
          className="fixed inset-0 xl:hidden z-10 bg-background/50 backdrop-blur-sm"
        ></div>
      )}

      {effort?.parentId && (
        <div className="relative z-20">
          <Resizable
            id="EFFORT_SECTION_"
            className={cn(
              "overflow-y-auto h-full bg-background border-s scrollbar-hide right-0",
              " duration-0  px-3 py-2 max-xl:shadow-[rgba(0,0,15,0.1)_-2px_0px_4px_0px] max-xl:absolute ",
              effort ? "" : "opacity-0"
            )}
          >
            <TaskSection effort={effort} />
          </Resizable>
        </div>
      )}

      {content && (
        <div className="relative z-20">
          <Resizable
            id="EFFORT_SECTION_SIZE"
            className={cn(
              "overflow-y-auto h-full bg-background border-s scrollbar-hide right-0",
              " duration-0  px-3 py-2 max-xl:shadow-[rgba(0,0,15,0.1)_-2px_0px_4px_0px] max-xl:absolute ",
              content ? "" : "opacity-0"
            )}
          >
            {content}
          </Resizable>
        </div>
      )}

      <div className=" p-1 py-2 items-center w-16 flex flex-col border-s bg-background z-20">
        {SECTOINS.map((eachItem) => (
          <EachPanelItem
            key={"eachItem" + eachItem.url}
            type="BUTTON"
            isMini={true}
            icon={eachItem.icon}
            text={eachItem.text}
            notifCount={eachItem.count}
            isActive={eachItem.url === section}
            activeClassName="rounded-s rounded-e-none "
            className="border-e-4 border-s-0 p-1 pe-0"
            onClick={() =>
              setSection(eachItem.url === section ? null : eachItem.url)
            }
          />
        ))}

        <Separator className="my-2" />

        <div className="flex-1 overflow-y-auto scrollbar-hide">
          <div className="flex flex-col px-2  ">
            {effort && _memberUtils.canCreate() && (
              <Button
                variant={"faded"}
                title="Add New Member"
                className="rounded-full aspect-square border mb-2"
                onClick={(ev) => {
                  _memberUtils.openCreateUpdateModal();
                }}
              >
                <LuUserPlus />
              </Button>
            )}

            {_users?.map((eachUser, i) => (
              <UserProfileHoverCard
                key={"eachMember" + i}
                side="left"
                user={eachUser}
                member={_project?.members?.find(
                  (e) => e.userId === eachUser.id
                )}
              >
                <Button
                  onClick={() =>
                    effortViewUtils.onFilterSubmit({
                      ...effortsContext.filter,
                      memberIds: effortsContext.filter.memberIds?.some(
                        (a) => a === eachUser.id
                      )
                        ? effortsContext.filter.memberIds.filter(
                          (a) => a !== eachUser.id
                        )
                        : [
                          ...(effortsContext.filter.memberIds ?? []),
                          eachUser.id,
                        ],
                    })
                  }
                  className={cn("rounded-full aspect-square p-0.5 m-0", {
                    "bg-primary": effortsContext.filter.memberIds?.some(
                      (a) => a === eachUser.id
                    ),
                  })}
                >
                  <ProfileAvatar needHover={false} user={eachUser} />
                </Button>
              </UserProfileHoverCard>
            ))}
          </div>
        </div>
      </div>
    </EffortsProvider>
  );
}
