import React from "react";

import LocalStorageServices from "services/localServices/LocalStorageServices";

import {
  FieldType,
  useItemsListContext,
  SortDirType,
} from "./ItemsListProvider";
import { useModalContext } from "providers/ModalProvider";

import ItemsListToolsSection, {
  ItemsListContextMenu,
} from "./ItemsListToolsSection";
import OnLoadMoreIndicator from "../OnLoadMoreIndicator";
import EmptyListIndicator from "../EmptyListIndicator";
import Skeleton from "../Skeleton";
import { ReactSortable, Sortable } from "react-sortablejs";

import { FaCaretDown, FaCaretUp, FaEllipsisVertical } from "react-icons/fa6";
import { cn, strSplitCammelCase } from "services/UtilServices";
import { Button } from "components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { LuCheck } from "react-icons/lu";
import Sticky from "../Sticky";
import useMediaQuery, { MediaQuery2Num } from "../useMediaQuery";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";

function EachFieldSelectionModalItem({
  isSelected = false,
  label = "",
  onClick,
}: {
  isSelected?: boolean;
  label?: string;
  onClick?: () => void;
}) {
  return (
    <div>
      <Button
        asChild
        variant={"light"}
        onClick={onClick}
        className={cn("w-full py-2 flex items-center", {
          "opacity-50": onClick === undefined,
        })}
      >
        <div>
          {label}
          <div className="flex-1"></div>
          {isSelected && <LuCheck className="ms-auto" />}
          {/* {isSelected ? (
            <FaCircleCheck className="ms-auto text-primary" />
          ) : (
            <FaCircle className="ms-auto text-secondary-foreground" />
          )} */}
        </div>
      </Button>
    </div>
  );
}

function FieldSelectionModal() {
  const { setSortableFields, sortableFields } =
    useItemsListTableSectionContext();

  const onDragEnd = (result: Sortable.SortableEvent) => {
    const dest = { id: result.to.id, index: result.newIndex! };
    const src = { id: result.from.id, index: result.oldIndex! };
    const draggableId = result.item.id;

    const copiedItems = [...sortableFields];
    const [removed] = copiedItems.splice(src.index, 1);
    copiedItems.splice(dest.index, 0, removed);
    setSortableFields(copiedItems);
  };

  return (
    <PopoverContent>
      <ReactSortable
        id="board"
        group="board"
        ghostClass="invisible"
        list={sortableFields.map((e) => ({ ...e, id: e.label })) ?? []}
        setList={() => {}}
        onEnd={onDragEnd}
      >
        {sortableFields.map((eachField, i) => (
          <EachFieldSelectionModalItem
            key={"colItem" + eachField.label}
            label={eachField.label}
            isSelected={
              eachField.isActive === true || eachField.isActive === undefined
            }
            onClick={
              eachField.isTitleField
                ? undefined
                : () =>
                    setSortableFields(
                      sortableFields.map((e) =>
                        e.label !== eachField.label
                          ? e
                          : {
                              ...eachField,
                              isActive: !(
                                eachField.isActive === true ||
                                eachField.isActive === undefined
                              ),
                            }
                      )
                    )
            }
          />
        ))}
      </ReactSortable>
    </PopoverContent>
  );
}

export function ItemsListTableHeaderCell({
  eachField,
  className,
  asDiv,
}: {
  eachField: FieldType<any>;
  className?: string;
  asDiv?: boolean;
}) {
  const ref = React.useRef<HTMLTableCellElement>(null);
  const mediaQuery = useMediaQuery();

  const {
    onSortDir,
    setSelectedSortDir,
    selectedSortDir,
    onSortField,
    setSelectedSortFieldValue,
    selectedSortFieldValue,
  } = useItemsListContext();

  return (
    <Sticky
      side="left"
      disabled={!eachField.isTitleField || mediaQuery <= MediaQuery2Num.md}
      className={cn(
        "bg-muted p-1 data-[stick=true]:shadow-md ", //data-[stick=true]:ps-3
        {
          "md:sticky -left-1 z-[1]":
            eachField.isTitleField && mediaQuery > MediaQuery2Num.md,
        }
      )}
    >
      <TableHead
        asDiv={asDiv}
        ref={ref}
        // className={cn(
        //   "bg-muted p-1 data-[stick=true]:shadow-md",
        //   {
        //     "md:sticky left-0 z-[1]":
        //       eachField.isTitleField &&
        //       mediaQuery > MediaQuery2Num.md,
        //   }
        // )}
        style={{
          width: eachField.width,
        }}
        className={className}
      >
        <div className="flex">
          {eachField.isTitleField && (
            <PopoverTrigger asChild>
              <Button variant={"faded"} className="ps-1 pe-0">
                <FaEllipsisVertical />
              </Button>
            </PopoverTrigger>
          )}

          <Button
            variant={"light"}
            disabled={eachField.isSortable === false}
            className={cn(
              " flex-1 justify-start " //hover:bg-transparent hover:text-secondary py-2 px-0
              // { "": eachField.width === "auto" }
            )}
            onClick={
              eachField.isSortable === false
                ? undefined
                : () => {
                    if (selectedSortFieldValue === eachField.value) {
                      onSortDir?.(-selectedSortDir as SortDirType);
                      setSelectedSortDir(-selectedSortDir as SortDirType);
                    } else {
                      onSortField?.(eachField.value);
                      setSelectedSortFieldValue(eachField.value);
                    }
                  }
            }
          >
            {selectedSortFieldValue === eachField.value &&
              (selectedSortDir === 1 ? (
                <FaCaretUp className="!text-base" />
              ) : (
                <FaCaretDown className="!text-base" />
              ))}

            <span>{strSplitCammelCase(eachField.label)}</span>
          </Button>
        </div>
      </TableHead>
    </Sticky>
  );
}

export function ItemsListTableHeader({
  title,
  preppend,
}: {
  title?: React.ReactNode;
  preppend?: React.ReactNode;
}) {
  const { sortableFields } = useItemsListTableSectionContext();

  return (
    <TableHeader className="sticky -top-1 z-[2]">
      <TableRow>
        {preppend}

        {sortableFields!
          .filter((e) => e.isActive === true || e.isActive === undefined)
          .map((eachField, eachFieldIndex) => (
            <ItemsListTableHeaderCell
              key={"sortCol" + eachFieldIndex}
              eachField={eachField}
            />
          ))}
      </TableRow>
    </TableHeader>
  );
}

export function ItemsListTableCell({
  eachItem,
  eachField,
  eachItemIndex,
  asDiv,
}: {
  eachItem: any;
  eachField: FieldType<any>;
  eachItemIndex: number;
  asDiv?: boolean;
}) {
  const mediaQuery = useMediaQuery();

  const { title, selecteds, isSelected, titleFieldName } =
    useItemsListContext();

  return (
    <ItemsListContextMenu
      asChild
      item={eachItem}
      deleteMessage={
        titleFieldName ? (
          <span>
            You're about to delete "<b>{eachItem[titleFieldName]}</b>" in{" "}
            <b>"{title}"</b>!
          </span>
        ) : undefined
      }
    >
      <Sticky
        side="left"
        disabled={!eachField.isTitleField || mediaQuery <= MediaQuery2Num.md}
        className={cn(
          " group data-[stick=true]:shadow-md  ", //data-[stick=true]:ps-6
          eachField.className,
          {
            // "rounded-s border-e-0": eachFieldIndex === 0,
            // "border-s-0 border-e-0": eachFieldIndex > 0,
            // "flex-1": eachField.width === "auto",
            "md:sticky -left-1 z-[1]":
              eachField.isTitleField && mediaQuery > MediaQuery2Num.md,
          },
          isSelected(eachItem)
            ? " bg-primary text-primary-foreground "
            : " bg-card group-hover/row:bg-slate-100 group-hover/row:dark:bg-slate-800 group-hover/row:hover:bg-slate-200 group-hover/row:hover:dark:bg-slate-700"
        )}
      >
        <TableCell
          asDiv={asDiv}
          // className={cn(
          //   " group data-[stick=true]:shadow-md ", //break-all p-2 py-1 border
          //   eachField.className,
          //   {
          //     // "rounded-s border-e-0": eachFieldIndex === 0,
          //     // "border-s-0 border-e-0": eachFieldIndex > 0,
          //     // "flex-1": eachField.width === "auto",
          //     "md:sticky -left-1 z-[1]":
          //       eachField.isTitleField &&
          //       mediaQuery > MediaQuery2Num.md,
          //   },
          //   isSelected(eachItem)
          //     ? " bg-primary text-primary-foreground "
          //     : " bg-card group-hover/row:bg-slate-100 group-hover/row:dark:bg-slate-800 group-hover/row:hover:bg-slate-200 group-hover/row:hover:dark:bg-slate-700"
          // )}
          style={{
            width: eachField.width,
          }}
          onClick={
            eachField.onClick
              ? () => {
                  if (isSelected(eachItem)) {
                    // setSelecteds(selecteds.filter((e) => e !== eachItem));
                    // onItemSelect?.(eachItem)?.(eachItem);
                  } else if (selecteds.length > 0) {
                    // setSelecteds([...selecteds, eachItem]);
                    // onItemSelect?.(eachItem)?.(eachItem);
                  } else {
                    eachField.onClick!(eachItem, eachItemIndex);
                  }
                }
              : undefined
          }
        >
          <div className="inline-flex items-center">
            {eachField.isTitleField && (
              <ItemsListToolsSection
                eachItem={eachItem}
                ellipsisResponsiveBreakPoint={"always"}
                ellipsisClassName="visible opacity-20 group-hover/row:opacity-100 -ml-3"
                deleteMessage={
                  titleFieldName ? (
                    <span>
                      You're about to delete "<b>{eachItem[titleFieldName]}</b>"
                      in <b>"{title}"</b>!
                    </span>
                  ) : undefined
                }
              />
            )}
            {eachField.getUiContent
              ? eachField.getUiContent(eachItem, eachItemIndex)
              : eachItem[eachField.value]}
          </div>
        </TableCell>
      </Sticky>
    </ItemsListContextMenu>
  );
}

export function ItemsListTableRow({
  eachItem,
  eachItemIndex,
  preppend,
  asDiv,
  id,
  className,
}: {
  eachItem: any;
  eachItemIndex: number;
  preppend?: React.ReactNode;
  asDiv?: boolean;
  id?: string;
  className?: string;
}) {
  const {
    selecteds,
    isSelected,
    onItemSelect,
    toggleSelecteds,
    onItemClick,
    itemClassName,
  } = useItemsListContext();

  const { sortableFields } = useItemsListTableSectionContext();

  return (
    <TableRow
      id={id}
      asDiv={asDiv}
      key={"itemsListSectionDesktop" + eachItemIndex}
      className={cn(
        " group/tools ",
        itemClassName?.(eachItem, eachItemIndex),
        isSelected(eachItem) ? " bg-primary " : " bg-card ",
        { "cursor-pointer": onItemClick || selecteds.length > 0 },
        className
      )}
      onClick={() => {
        if (isSelected(eachItem)) {
          toggleSelecteds(eachItem);
          onItemSelect?.(eachItem)?.(eachItem);
        } else if (selecteds.length > 0) {
          toggleSelecteds(eachItem);
          onItemSelect?.(eachItem)?.(eachItem);
        } else {
          onItemClick?.(eachItem)?.(eachItem);
        }
      }}
    >
      {preppend}

      {sortableFields!
        .filter((e) => e.isActive === true || e.isActive === undefined)
        .map((eachField, eachFieldIndex) => (
          <ItemsListTableCell
            asDiv={asDiv}
            key={"col" + eachField.label}
            eachField={eachField}
            eachItem={eachItem}
            eachItemIndex={eachItemIndex}
          />
        ))}
    </TableRow>
  );
}

interface ItemsListTableSectionContextProps {
  sortableFields: FieldType<any>[];
  setSortableFields: (l: FieldType<any>[]) => void;
}

const ItemsListTableSectionContext = React.createContext(
  {} as ItemsListTableSectionContextProps
);
ItemsListTableSectionContext.displayName = "ItemsListTableSectionContext";

export function useItemsListTableSectionContext() {
  const _context = React.useContext(ItemsListTableSectionContext);

  if (!_context) {
    throw new Error(
      "cannot use ItemsListTableSectionContext outside of its provider."
    );
  }

  return _context;
}

export function ItemsListTableSectionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { title, sortableFields } = useItemsListContext();

  const [_sortableFields, _setSortableFields] = React.useState<
    FieldType<any>[]
  >([]);

  React.useEffect(() => {
    LocalStorageServices.get("TABLE_FIELDS_" + title).then((r) => {
      if (r === null) {
        _setSortableFields(sortableFields);
      } else {
        let prevOrdered = (r as FieldType<any>[])
          .map((e) => {
            let eachField = sortableFields.find((a) => a.label === e.label);

            if (eachField !== undefined) eachField.isActive = e.isActive;

            return eachField;
          })
          .filter((e) => e !== undefined) as FieldType<any>[];

        let notOrderedYet = sortableFields.filter(
          (a) => !(r as FieldType<any>[]).some((e) => a.label === e.label)
        );

        _setSortableFields([...prevOrdered, ...notOrderedYet]);
      }
    });
  }, []);

  const setSortableFields = (d: FieldType<any>[]) => {
    _setSortableFields(d);
    LocalStorageServices.set(
      "TABLE_FIELDS_" + title,
      d.map((e) => ({ label: e.label, isActive: e.isActive }))
    );
  };

  return (
    <ItemsListTableSectionContext.Provider
      value={
        {
          sortableFields: _sortableFields,
          setSortableFields,
        } as ItemsListTableSectionContextProps
      }
    >
      {children}
    </ItemsListTableSectionContext.Provider>
  );
}

export default function ItemsListTableSection({
  onLoadMoreIndicator,
  children,
}: {
  onLoadMoreIndicator?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const { data, title, onLoadMore } = useItemsListContext();

  return (
    <>
      {data !== undefined && (
        <Popover>
          <ItemsListTableSectionProvider>
            {children ?? (
              <Table>
                <ItemsListTableHeader />

                <TableBody>
                  {data?.map((eachItem, eachItemIndex) => (
                    <ItemsListTableRow
                      key={"eachRow" + eachItemIndex}
                      eachItem={eachItem}
                      eachItemIndex={eachItemIndex}
                    />
                  ))}
                </TableBody>
              </Table>
            )}

            <FieldSelectionModal />
          </ItemsListTableSectionProvider>
        </Popover>
      )}

      {onLoadMore &&
        (onLoadMoreIndicator ?? (
          <OnLoadMoreIndicator onLoadMore={onLoadMore}>
            <Skeleton count={5} className="w-full mb-1 h-10" />
          </OnLoadMoreIndicator>
        ))}

      {!onLoadMore && data?.length === 0 && (
        <EmptyListIndicator text={"No " + title + " Found!"} />
      )}
    </>
  );
}
