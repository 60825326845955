import React from "react";
import Chart from "react-apexcharts";

import Dict from "models/Dict";
import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import useUserUtils from "hooks/utils/UseUserUtils";

import {
  isEmpty,
  randomHexColor,
  sortedStringify,
  strCapitalizefirstLetter,
} from "services/UtilServices";

import { useUsersContext } from "providers/UsersProvider";
import { Form, Select } from "components/common/Forms/Form";
import { useTagsContext, TagsProvider } from "providers/TagsProvider";
import { useTypesContext, TypesProvider } from "providers/TypesProvider";
import { useStatesContext, StatesProvider } from "providers/StatesProvider";
import { useTicketsContext, TicketsProvider } from "providers/TicketsProvider";
import { useItemsListContext } from "components/common/ItemsListSection/ItemsListProvider";

import EmptyListIndicator from "components/common/EmptyListIndicator";
import { usePrioritiesContext } from "providers/PrioritiesProvider";
import Skeleton from "components/common/Skeleton";
import { useThemeContext } from "providers/ThemeProvider";
import {
  EffortResource,
  useEffortResources,
} from "hooks/utils/UseEffortResources";
import { useEffortsContext } from "providers/EffortsProvider";

function DonutChart({ effortId }: { effortId?: number }) {
  const _itemsListContext = useItemsListContext();
  const _effortUtils = useEffortUtils();
  const _userUtils = useUserUtils();
  const _themeContext = useThemeContext();
  const _effortsContext = useEffortsContext();

  const _efforts = _itemsListContext.data as Effort[];

  const basedOnTypes: {
    value: keyof EffortResource;
    label: string;
  }[] = [
      { value: "states", label: "state" },
      { value: "priorities", label: "priority" },
      { value: "types", label: "type" },
      { value: "tags", label: "tag" },
      { value: "ticketsAvailable", label: "ticket" },
      { value: "users", label: "user" },
    ];
  const [_basedOnType, _setBasedOnType] =
    React.useState<keyof EffortResource>("states");
  const { resourceData, getResources } = useEffortResources();

  let _data: Dict[] | undefined;
  let _series: number[] | undefined;
  let _colors: string[] | undefined;
  if (_basedOnType === "states") {
    _data = resourceData.states;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { stateIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "priorities") {
    _data = resourceData.priorities;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { priorityIds: [eachItem.id] })?.length ??
        0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "types") {
    _data = resourceData.types;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { typeIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "tags") {
    _data = resourceData.tags;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { tagIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "ticketsAvailable") {
    _data = resourceData.ticketsAvailable;
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { ticketIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  } else if (_basedOnType === "users") {
    _data = resourceData.users?.map((e) => ({
      id: e.id,
      name: _userUtils.getFullName(e),
      color: randomHexColor(),
    }));
    _series = _data?.map(
      (eachItem) =>
        _effortUtils.filter(_efforts, { memberIds: [eachItem.id] })?.length ?? 0
    );
    _colors = _data?.map((e) => e.color);
  }

  React.useEffect(() => {
    getResources({
      effortId: _effortsContext.currentEffort?.id,
      fieldNames: [_basedOnType],
    });
  }, [_basedOnType]);

  return (
    <div className="h-96">
      <div className="bg-card rounded border p-4 h-full">
        <div className="flex items-center">
          <h6 className="mb-0 me-2 text-foreground">Based On: </h6>

          <Form
            data={{ basedOnType: _basedOnType }}
            onChange={(d) => _setBasedOnType(d.basedOnType)}
          >
            <Select
              name="basedOnType"
              options={basedOnTypes}
              needLabel={false}
              needMarginBottom={false}
              required
            />
          </Form>
        </div>

        {_data === undefined ? (
          <DonutChartSkeleton />
        ) : _efforts.length === 0 || _data.length === 0 ? (
          <EmptyListIndicator text="No Data" />
        ) : (
          <Chart
            type="donut"
            height="90%"
            series={_series}
            options={{
              theme: {
                mode: _themeContext.isDark ? "dark" : undefined,
              },
              chart: {
                //   foreColor: _themeContext.isDark ? "#ffffff" : undefined,
                background: "#ffffff00",
              },
              legend: {
                position: "right",
                // offsetY: 40,
                formatter: function (val, opts) {
                  return strCapitalizefirstLetter(
                    _data![opts.seriesIndex].name
                  )!;
                },
              },
              // tooltip: {
              //   theme: _themeContext.isDark ? "dark" : undefined,
              // },
              labels: _data!.map((e) => strCapitalizefirstLetter(e.name)!),
              colors: _colors,
              responsive: [
                {
                  breakpoint: 1440,
                  options: {
                    legend: {
                      position: "bottom",
                      // offsetX: -10,
                      offsetY: 0,
                    },
                  },
                },
              ],
            }}
          />
        )}
      </div>
    </div>
  );
}

function DonutChartSkeleton() {
  return (
    <div className="flex flex-col items-center p-3">
      <Skeleton className="rounded-full aspect-square mx-auto w-3/4 max-w-[200px]" />
      <div className="mt-3 flex flex-wrap gap-2 items-center justify-center">
        <Skeleton className=" w-24 h-5" count={5} />
      </div>
    </div>
  );
}

function TicketsBarChartSkeleton() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="flex items-end gap-1 ">
        <Skeleton className="w-10 h-10" />
        <Skeleton className="w-10 h-20" />
        <Skeleton className="w-10 h-5" />
        <Skeleton className="w-10 h-12" />
        <Skeleton className="w-10 h-14" />
        <Skeleton className="w-10 h-12" />
        <Skeleton className="w-10 h-5" />
      </div>
    </div>
  );
}

function TicketsBarChart({ effortId }: { effortId?: number }) {
  let _colors: string[] | undefined;

  const _themeContext = useThemeContext();
  const _itemsListContext = useItemsListContext();
  const { resourceData, getResources } = useEffortResources();

  const _efforts = (_itemsListContext.data as Effort[]) ?? [];

  React.useEffect(() => {
    getResources({
      effortId,
      fieldNames: ["ticketsAvailable"],
    });
  }, []);

  _colors = resourceData.ticketsAvailable?.map((e) => e.color);

  return (
    <div className="h-96">
      <div className="bg-card rounded border p-4 h-full overflow-x-auto">
        {resourceData.ticketsAvailable === undefined ? (
          <TicketsBarChartSkeleton />
        ) : _efforts.length === 0 ||
          resourceData.ticketsAvailable.length === 0 ? (
          <EmptyListIndicator text="No Data" />
        ) : (
          <Chart
            type="bar"
            height="100%"
            options={{
              theme: {
                mode: _themeContext.isDark ? "dark" : undefined,
              },
              // tooltip: {
              //   theme: _themeContext.isDark ? "dark" : undefined,
              // },
              chart: {
                // foreColor: _themeContext.isDark ? "#ffffff" : undefined,
                background: "#ffffff00",
                type: "bar",
                stacked: true,
                toolbar: {
                  show: true,
                },
                zoom: {
                  enabled: true,
                },
              },
              colors: _colors,
              responsive: [
                {
                  breakpoint: 1440,
                  options: {
                    legend: {
                      position: "bottom",
                      // offsetX: -10,
                      offsetY: 0,
                    },
                  },
                },
              ],
              plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    total: {
                      enabled: true,
                      style: {
                        fontSize: "13px",
                        fontWeight: 900,
                        color: _themeContext.isDark ? "white" : undefined,
                      },
                    },
                  },
                },
              },
              xaxis: {
                type: "category",
                categories: _efforts?.map(
                  (e: Effort) =>
                    e.title.substring(0, 15) +
                    (e.title.length > 15 ? "..." : "")
                ),
              },
              legend: {
                position: "right",
                offsetY: 40,
              },
              fill: {
                opacity: 1,
              },
            }}
            series={resourceData.ticketsAvailable?.map((eachTicket) => ({
              name: eachTicket.name,
              data: _efforts?.map(
                (e) =>
                  e.ticketsCount?.find((a) => a.ticket.id === eachTicket.id)
                    ?.count ?? 0
              ),
            }))}
          />
        )}
      </div>
    </div>
  );
}

export default function EffortsListChartSection({
  effortId,
}: {
  effortId?: number;
}) {
  return (
    <div className="grid grid-rows-2 lg:grid-cols-2 gap-2 ">
      <DonutChart effortId={effortId} />
      <TicketsBarChart effortId={effortId} />
    </div>
  );
}
