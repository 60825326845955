import React from "react";

import Dict from "models/Dict";
import Activity, { ActivityFilter, ActivitySortTypes } from "models/Activity";
import { useCurrentUserContext } from "./CurrentUserProvider";
import { AxiosError } from "axios";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { useMainContext } from "./MainProvider";
import TicketCount from "models/TicketCount";
import useTicketCountUtils from "hooks/utils/UseTicketCountUtils";
import { groupBy, listFlatten } from "services/UtilServices";
import useActivityApi from "hooks/api/UseActivityApi";
import useExportApi from "hooks/api/UseExportApi";
import useDownloadServices from "hooks/UseDownloadServices";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import { useEffortsContext } from "./EffortsProvider";

interface ActivitysContextProps {
  effortId: number;

  activitys: Activity[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;
  activityBulkApprove: (items: Dict[]) => Promise<Dict>;

  get: (filter: ActivityFilter) => Promise<void>;
  filter: ActivityFilter;
  hasNext: boolean;
  totalNumber?: number;
}

const ActivitysContext = React.createContext({} as ActivitysContextProps);
ActivitysContext.displayName = "ActivitysContext";

function ActivitysProvider({ children }: { children: React.ReactNode }) {
  const _currentuserContext = useCurrentUserContext();
  const _effortsContext = useEffortsContext();
  const _mainContext = useMainContext();

  const activityApi = useActivityApi();
  const _effortUtils = useEffortUtils();
  const textEditorUtils = useTextEditorUtils();

  let effortIds: number[] = [];
  if (_effortsContext.currentEffort?.parentId) {
    effortIds = [_effortsContext.currentEffort.id];
  } else {
    effortIds =
      _effortUtils
        .getSubEffortsDeep({
          id: _effortsContext.currentEffort?.id,
        })
        ?.map((e) => e.id) ?? [];
  }

  const DEFAULT_FILTER = {
    pageNumber: -1,
    ascOrder: false,
    sortBy: ActivitySortTypes.ADDWHEN,
    effortIdList: effortIds,
    userIdList: [_currentuserContext.user!.id],
  } satisfies ActivityFilter;
  const itemsRef = React.useRef<Activity[] | undefined>(undefined);
  const globalFilterRef = React.useRef<ActivityFilter>({ ...DEFAULT_FILTER });
  const ticketCountUtils = useTicketCountUtils();

  const [_items, _setActivitys] = React.useState<Activity[]>();
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [_filter, _setFilter] = React.useState<ActivityFilter>({
    ...globalFilterRef.current,
  });
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const _project = _effortUtils.getParentProject(
    _effortsContext.currentEffort?.id
  );

  React.useEffect(() => {
    setActivitys(undefined);
    setFilter({ ...DEFAULT_FILTER });
    setHasNext(true);
    setTotalNumber(undefined);
    return () => {
      setActivitys(undefined);
      setFilter({ ...DEFAULT_FILTER });
      setHasNext(true);
      setTotalNumber(undefined);
    };
  }, [_effortsContext.currentEffort?.id]);

  const setActivitys = (o?: Activity[]) => {
    itemsRef.current = o;
    _setActivitys(o);
  };

  const setFilter = (o: ActivityFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const get = async (filter: ActivityFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setActivitys(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setActivitys(undefined);
      setTotalNumber(undefined);
      return;
    }

    try {
      let { items, totalNumber } = await activityApi.get(filter);

      setHasNext(items?.length >= 20);

      items = items.filter(
        (eachRes: Dict) => !itemsRef.current?.some((e) => e.id === eachRes.id)
      );

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      setActivitys([...(itemsRef.current ?? []), ...(items as Activity[])]);
    } catch (e) {
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setActivitys([]);
      }
    }
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      formData.userId = _currentuserContext.user!.id;
      // formData.effortId = _effortsContext.currentEffort?.id;

      formData.description = await textEditorUtils.uploadImages({
        value: formData.description,
        fieldName: "description",
        effortId: formData.effortId,
      });

      formData.id = await activityApi.create(formData);

      formData.user = _currentuserContext.user;
      formData.dateAdd = new Date().toISOString();

      setActivitys([formData as Activity, ...(itemsRef.current ?? [])]);

      _mainContext.setEfforts((prev) =>
        prev?.map((eachPrev) => {
          let _new = { ...eachPrev };

          if (eachPrev.id === formData.effortId) {
            _new.effortActivityRecordsCount =
              (_new.effortActivityRecordsCount ?? 0) + 1;
          }

          if (
            eachPrev.id === formData.effortId ||
            _project?.id === eachPrev.id
          ) {
            _new.activitySum = ticketCountUtils.add(
              ...(_new.activitySum ?? []),
              ...(formData!.ticketsCount ?? ([] as TicketCount[]))
            );
          }

          return _new;
        })
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      formData.description = await textEditorUtils.uploadImages({
        value: formData.description,
        fieldName: "description",
        effortId: formData.effortId,
      });

      await activityApi.update(formData);

      const _oldActivity = itemsRef.current?.find((e) => e.id === formData.id);

      setActivitys(
        itemsRef.current?.map((e) =>
          e.id !== formData.id
            ? e
            : ({
                ...formData,
              } as Activity)
        )
      );

      _mainContext.setEfforts((prev) =>
        prev?.map((eachPrev) => {
          let _new = { ...eachPrev };

          if (
            eachPrev.id === formData.effortId ||
            _project?.id === eachPrev.id
          ) {
            _new.activitySum = ticketCountUtils.add(
              ...(ticketCountUtils.subtract(
                _new.activitySum,
                ...(_oldActivity!.ticketsCount ?? [])
              ) ?? []),
              ...(formData!.ticketsCount ?? ([] as TicketCount[]))
            );
          }

          return _new;
        })
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Activity[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await activityApi.remove(ids);

      setActivitys(itemsRef.current?.filter((e) => !ids.includes(e.id)));

      const _itemsToDeleteGroupByEffortId = groupBy({
        list: itemsToDelete,
        callbackFunc: (e) => e.effortId,
      })!;

      const _itemsToDeleteGroupByProjectId = groupBy({
        list: itemsToDelete,
        callbackFunc: (e) => _effortUtils.getParentProject(e.effortId)!.id,
      })!;

      _mainContext.setEfforts((prev) =>
        prev?.map((eachPrev) => {
          let _new = { ...eachPrev };

          if (eachPrev.id in _itemsToDeleteGroupByEffortId) {
            _new.effortActivityRecordsCount =
              (_new.effortActivityRecordsCount ?? 0) -
              _itemsToDeleteGroupByEffortId[eachPrev.id].length;

            _new.activitySum =
              ticketCountUtils.subtract(
                _new.activitySum,
                ...(ticketCountUtils.add(
                  ...listFlatten(
                    _itemsToDeleteGroupByEffortId[eachPrev.id].map(
                      (a) => a.ticketsCount
                    )
                  )!
                ) ?? [])
              ) ?? [];
          } else if (eachPrev.id in _itemsToDeleteGroupByProjectId) {
            _new.effortActivityRecordsCount =
              (_new.effortActivityRecordsCount ?? 0) -
              _itemsToDeleteGroupByProjectId[eachPrev.id].length;

            _new.activitySum =
              ticketCountUtils.subtract(
                _new.activitySum,
                ...(ticketCountUtils.add(
                  ...listFlatten(
                    _itemsToDeleteGroupByProjectId[eachPrev.id].map(
                      (a) => a.ticketsCount
                    )
                  )!
                ) ?? [])
              ) ?? [];
          }

          return _new;
        })
      );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const activityBulkApprove = async (itemsToApprove: Activity[]) => {
    let _errors = {};

    try {
      const ids = itemsToApprove.map((e) => e.id);

      await activityApi.bulkApprove(ids);

      // setActivitys(itemsRef.current?.filter((e) => !ids.includes(e.id)));

      // const _itemsToDeleteGroupByEffortId = groupBy({
      //   list: itemsToApprove,
      //   callbackFunc: (e) => e.effortId,
      // })!;

      // _mainContext.setEfforts((prev) =>
      //   prev?.map((eachPrev) => {
      //     let _new = { ...eachPrev };

      //     if (eachPrev.id in _itemsToApproveGroupByProjectId) {
      //       _new.effortActivityRecordsCount =
      //         (_new.effortActivityRecordsCount ?? 0) -
      //         _itemsToApproveGroupByProjectId[eachPrev.id].length;

      //       _new.activitySum =
      //         ticketCountUtils.subtract(
      //           _new.activitySum,
      //           ...(ticketCountUtils.add(
      //             ...listFlatten(
      //               _itemsToApproveGroupByProjectId[eachPrev.id].map(
      //                 (a) => a.ticketsCount
      //               )
      //             )!
      //           ) ?? [])
      //         ) ?? [];
      //     } else if (eachPrev.id in _itemsToApproveGroupByProjectId) {
      //       _new.effortActivityRecordsCount =
      //         (_new.effortActivityRecordsCount ?? 0) -
      //         _itemsToApproveGroupByProjectId[eachPrev.id].length;

      //       _new.activitySum =
      //         ticketCountUtils.subtract(
      //           _new.activitySum,
      //           ...(ticketCountUtils.add(
      //             ...listFlatten(
      //               _itemsToApproveGroupByProjectId[eachPrev.id].map(
      //                 (a) => a.ticketsCount
      //               )
      //             )!
      //           ) ?? [])
      //         ) ?? [];
      //     }

      //     return _new;
      //   })
      // );
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <ActivitysContext.Provider
      value={
        {
          effortId: _effortsContext.currentEffort?.id,

          activitys: itemsRef.current,
          create,
          update,
          removeMany,
          activityBulkApprove,

          get,
          filter: _filter,
          hasNext,
          totalNumber,
        } as ActivitysContextProps
      }
    >
      {children}
    </ActivitysContext.Provider>
  );
}

export function useActivitysContext() {
  const _context = React.useContext(ActivitysContext);

  if (!_context) {
    throw new Error("cannot use ActivitysContext outside of its provider.");
  }

  return _context;
}

export { ActivitysContext, ActivitysProvider };
export type { ActivitysContextProps };
