import { Clipboard } from "@capacitor/clipboard";
import { useToast } from "components/ui/use-toast";

export default function useClipboard() {
  const { toast } = useToast();

  const write = (
    content: string,
    props?: {
      messageSuccess?: string;
      messageError?: string;
    }
  ) => {
    const { messageSuccess = "Link Copied", messageError = "Not Copied!" } =
      props ?? {};

    try {
      Clipboard.write({ string: content })
        .then(() => {
          toast(messageSuccess);
        })
        .catch(() => {
          toast(messageError, {
            variant: "destructive",
          });
        });
    } catch (e) {
      toast(messageError, {
        variant: "destructive",
      });
    }
  };

  return {
    write,
  };
}
