import { ticketSchema } from "./Ticket";
import { z } from "zod";


export const ticketCountSchema = z.object({
  // id: z.number(),
  count: z.number(),
  countApproved: z.number(),
  ticketId: z.number(),
  ticket: ticketSchema,
});

type TicketCount = z.infer<typeof ticketCountSchema>;
export default TicketCount;


// export default interface TicketCount {
//   // id: number,
//   count: number,
//   countApproved: number,
//   ticketId: number
//   ticket: Ticket,
// }