import { dateAdd, getNotEmptyFields } from 'services/UtilServices';
import useApiServices from './UseApiServices';
import { AuditLogFilter } from 'models/AuditLog';

export const BASE_URL = "accounts/";

export default function useAuditLogApi() {

  const apiServices = useApiServices();

  const get = async (filter: AuditLogFilter) => {
    let response = await apiServices.send({
      url: BASE_URL + "logs/audit",
      method: "post",
      expirationDurationMinutes: 2,
      body: getNotEmptyFields({
        ...filter,
        actionType: filter.actionType === "" ? null : filter.actionType,
        dateTo: dateAdd({ date: filter.dateTo, days: 1 }),
      }),
    });

    return {
      ...response,
      items: response.auditLogs ?? []
    };
  }

  const getAll = async (filter: AuditLogFilter) => {
    let response = await apiServices.send({
      url: BASE_URL + "logs/audit",
      method: "post",
      body: {
        ...filter,
        // pageNumber : filter?.pageNumber || -1 , //-1 means no pagination 
        actionType: filter.actionType === "" ? null : filter.actionType,
        // userIds: null
        userIds: [filter.userIds] && null

      },
    });

    return response.auditLogs ?? [];
  }

  return {
    get,
    getAll,
  };
}

