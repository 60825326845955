import React from "react";

import Dict from "models/Dict";
import { UserRoles } from "models/User";
import { MemberAccessTypes, MemberTypes } from "models/Member";

import { getItem, listFirst } from "services/UtilServices";

import { useUsersContext } from "providers/UsersProvider";

import { Form, Select, SubmitButton } from "components/common/Forms/Form";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import SelectButtonGroup from "components/common/Forms/SelectButtonGroup";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useUserUtils from "hooks/utils/UseUserUtils";
import { useEffortResources } from "hooks/utils/UseEffortResources";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

export default function MemberCreateUpdateModal({
  formData,
  onSubmit,
  accessType,
  usersAlreadyMember,
  effortId,
}: {
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
  accessType: number;
  effortId: number;
  usersAlreadyMember?: string[];
}) {
  const _userUtils = useUserUtils();
  const _effortUtils = useEffortUtils();
  const _currentUserContext = useCurrentUserContext();
  const { resourceData, getResources } = useEffortResources();

  const isEditing = formData !== undefined && "id" in formData;
  const isMemberOfCurrentUser =
    formData?.userId === _currentUserContext.user?.id &&
    !_currentUserContext.isSudo();
  const isProject = _effortUtils.isProject(effortId);

  let _users = resourceData.users;
  if (!(isEditing || isMemberOfCurrentUser)) {
    _users = _users?.filter((e) => !usersAlreadyMember?.includes(e.id));
  }

  React.useEffect(() => {
    getResources({
      effortId: isProject ? undefined : effortId, //isEditing &&
      fieldNames: ["users"],
    });
  }, []);

  return (
    <DialogContent title="Members">
      <Form
        allowInitSubmit={!isEditing}
        data={
          isEditing
            ? {
                ...formData,
              }
            : {
                effortId,
                userId: listFirst(_users)?.id,
                accessType: MemberAccessTypes.Read,
                memberType: MemberTypes.User,
                newEffort: null,
                effortUpdate: null,
                newComment: null,
                commentUpdate: null,
              }
        }
        onSubmit={async (d) => {
          if (!("user" in d)) {
            d.user = _users?.find((e) => e.id === d.userId);
          }

          return await onSubmit(d);
        }}
      >
        <Select
          name="userId"
          label="User"
          required
          disabled={isEditing || isMemberOfCurrentUser}
          options={
            isEditing || isMemberOfCurrentUser
              ? [
                  {
                    value: formData?.user.id,
                    label: _userUtils.getFullName(formData?.user),
                  },
                ]
              : _users?.map((e) => ({
                  value: e.id,
                  label: _userUtils.getFullName(e),
                }))
          }
        />

        <SelectButtonGroup
          name="memberType"
          options={MemberTypes.toLabelValues()}
          disabled={isMemberOfCurrentUser}
          required
        />

        <SelectButtonGroup
          name="accessType"
          options={MemberAccessTypes.toLabelValues().filter(
            (e) => e.value <= accessType
          )}
          disabled={isMemberOfCurrentUser}
          required
        />

        <DialogFooter>
          <SubmitButton
            disabled={
              isEditing || isMemberOfCurrentUser
                ? undefined
                : _users === undefined
            }
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
