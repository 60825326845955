import React from "react";

import { NetworkImage } from "components/common/NetworkImage";
import { GalleryItemType } from "providers/GalleryViewProvider";
import { cn } from "services/UtilServices";

export default function FilesRowSection({
  items,
  index,
  setIndex,
  scrollerContainer,
  tileSize,
}: {
  items: GalleryItemType[];
  index: number;
  setIndex: (d: number) => void;
  scrollerContainer: React.RefObject<HTMLDivElement>;
  tileSize: number;
}) {
  return (
    <div
      ref={scrollerContainer}
      className="w-full z-1000 flex justify-center overflow-x-auto scrollbar-hide bg-[#00000055] pointer-events-auto"
    >
      {items.map((eachItem, i) => (
        <div
          key={"eachThumbnail" + i}
          style={{
            width: tileSize,
          }}
          className={cn(
            "bg-dark border-e border-t cursor-pointer relative aspect-square ",
            {
              "outline -outline-offset-4 outline-4 outline-[#ffc107]":
                index === i,
            }
          )}
        >
          <NetworkImage
            secure
            src={eachItem.url}
            onClick={() => setIndex(i)}
            className="cursor-pointer object-cover h-full w-full"
          />
        </div>
      ))}

      {/* {
                (
                    _mainContext.isConnected && 
                    _postsContext.pageInfo.hasNext
                ) &&
                    <div className="mx-3">
                        <OnLoadMoreIndicator 
                            onLoadMore={async () => 
                                await _postsContext.get({
                                    ..._postsContext.filter,
                                    pageNumber: _postsContext.filter.pageNumber + 1
                                })
                            } 
                        />
                    </div> 
            } */}
    </div>
  );
}
