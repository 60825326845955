import Effort from "models/Effort";

import SectionBase from "./SectionBase";
import Cheeps from "components/common/Cheeps";

export default function PrioritiesSection({ effort }: { effort: Effort }) {
  return (
    <SectionBase effort={effort} fieldName="priorities" title="Priorities">
      {effort.priorities?.map((eachPriority, i) => (
        <Cheeps key={"eachPriority" + i} borderColor={eachPriority.color}>
          {eachPriority.name}
        </Cheeps>
      ))}
    </SectionBase>
  );
}
