import React from "react";

import Dict from "models/Dict";
import { RESPONSIVE_KEYS } from "models/views/responsive";

export type SortDirType = 1 | -1;

export type ItemType =
  | {
      icon: React.ReactNode;
      text: string;
      onClick?: () => void;
      multiple?: boolean;
    }
  | React.ReactElement;

export interface FieldType<T extends Dict> {
  value: string;
  label: string;
  width?: string;
  className?: string;
  isActive?: boolean;
  isSortable?: boolean;
  isSearchable?: boolean;
  isTitleField?: boolean;
  isPositionFixed?: boolean;
  getValue?: (e: T) => string | number | Date;
  getUiContent?: (e: T, index: number) => string | number | React.ReactNode;
  onClick?: (e: T, index: number) => void;
}

export interface ItemsListProviderInputProps<T extends Dict> {
  data?: T[];
  title?: string;
  fields: FieldType<T>[];
  sortableFieldValues?: string[];
  ellipsisResponsiveBreakPoint?: RESPONSIVE_KEYS;
  btnTextResponsiveBreakPoint?: RESPONSIVE_KEYS;
  initialSelectedSortFieldValue?: string;
  initialSelectedSortDir?: SortDirType;
  actionsSection?: React.ReactNode;
  bottomActionSection?: React.ReactNode;
  onLoadMore?: (i: number) => void;
  onSearch?: null | ((v: string) => void);
  onSortDir?: (v: SortDirType) => void;
  onSortField?: (v: string) => void;

  itemClassName?: (v: T, i: number) => string;
  itemStyle?: (v: T, i: number) => Dict;
  getItemActions?: (e: T) => ItemType[];
  selectedActionsSection?: (e: T[]) => ItemType[];
  buildCardBodyItem?: (v: T, i: number) => React.ReactNode;
  buildCardTitleRow?: (v: T, i: number) => React.ReactNode;

  onItemClick?: (item: T) => undefined | ((item: T) => void);
  onItemEdit?: (item: T) => undefined | ((item: T) => void);
  onItemDelete?: (items: T[]) => undefined | ((item: T[]) => void | Dict);
  onItemSelect?: (item: T) => undefined | ((item: T) => void);
}

export interface ItemsListContextProps<T extends Dict> {
  titleFieldName: keyof T;

  selecteds: T[];
  isSelected: (v: T) => boolean;
  toggleSelecteds: (v: T) => void;

  selectedSortDir: SortDirType;
  setSelectedSortDir: (v: SortDirType) => void;

  selectedSortFieldValue: string;
  setSelectedSortFieldValue: (v: string) => void;

  searchValue: string;
  setSearchValue: (v: string) => void;

  data?: T[];
  fields: FieldType<T>[];
  sortableFields: FieldType<T>[];
  sortableFieldValues: string[];
  title: string;
  actionsSection: React.ReactNode;
  bottomActionSection: React.ReactNode;
  itemClassName?: (v: T, i: number) => string;
  itemStyle?: (v: T, i: number) => Dict;
  ellipsisResponsiveBreakPoint: RESPONSIVE_KEYS;
  btnTextResponsiveBreakPoint: RESPONSIVE_KEYS;
  initialSelectedSortFieldValue: string;
  initialSelectedSortDir: SortDirType;
  buildCardBodyItem?: (v: T, i: number) => React.ReactNode;
  buildCardTitleRow?: (v: T, i: number) => React.ReactNode;
  onSearch?: null | ((v: string) => void);
  onLoadMore?: (i: number) => void;
  onSortDir: (v: SortDirType) => void;
  onSortField: (v: string) => void;

  onItemClick: (item: T) => undefined | ((item: T) => void);
  onItemEdit: (item: T) => undefined | ((item: T) => void);
  onItemDelete: (items: T[]) => undefined | ((item: T[]) => void | Dict);
  onItemSelect: (item: T) => undefined | ((item: T) => void);

  getItemActions: (e: T) => ItemType[];
  selectedActionsSection?: (e: T[]) => ItemType[];
}

const ItemsListContext = React.createContext({} as ItemsListContextProps<any>);
ItemsListContext.displayName = "ItemsListContext";

export { ItemsListContext };

export default function ItemsListProvider<T extends Dict>(
  props: React.PropsWithChildren<ItemsListProviderInputProps<T>>
) {
  props = {
    ...props,

    // itemClassName: props.itemClassName ?? "",
    // ellipsisResponsiveBreakPoint: props.ellipsisResponsiveBreakPoint ?? "lg",
    // btnTextResponsiveBreakPoint: props.btnTextResponsiveBreakPoint ?? "xl",
    // onSearch: props.onSearch ?? (()=>{}),
    // onSortDir: props.onSortDir,
    // onSortField: props.onSortField,
    initialSelectedSortDir: props.initialSelectedSortDir ?? -1,
    onItemClick: props.onItemClick ?? (() => undefined),
    onItemEdit: props.onItemEdit ?? (() => undefined),
    onItemDelete: props.onItemDelete ?? (() => undefined),
    onItemSelect: props.onItemSelect ?? (() => undefined),
  };

  const [selecteds, _setSelecteds] = React.useState<T[]>([]);
  const [selectedSortDir, setSelectedSortDir] = React.useState<SortDirType>(
    props.initialSelectedSortDir!
  );
  const [selectedSortFieldValue, setSelectedSortFieldValue] =
    React.useState<string>(
      (
        props.fields.find(
          (e) => e.value === props.initialSelectedSortFieldValue
        ) ?? props.fields[0]
      ).value
    );
  const [searchValue, setSearchValue] = React.useState<string>("");

  var sortableFields = props.fields;
  if (props.sortableFieldValues) {
    sortableFields = props.fields.filter((e) =>
      props.sortableFieldValues!.includes(e.value)
    );
  }

  const titleFieldName =
    sortableFields.find((e) => e.isTitleField)?.value ?? "id";

  const getItemFieldValue = (eachItem: T, fieldName: string) => {
    let _result = eachItem[fieldName];

    let _getValueFunc = sortableFields?.find(
      (e) => e.value === fieldName && e.getValue !== undefined
    )?.getValue;

    if (_getValueFunc) {
      _result = _getValueFunc(eachItem);
    }

    return _result;
  };

  const toggleSelecteds = (item: T | "all" | "clear") => {
    _setSelecteds((prev) => {
      let _result = [] as T[];

      if (item === "all") {
        _result = props.data ?? [];
      } else if (item === "clear") {
        _result = [];
      } else if (prev.some((a) => a[titleFieldName] === item[titleFieldName])) {
        _result = prev.filter(
          (e) => e[titleFieldName] !== item[titleFieldName]
        );
        props.onItemSelect?.(item)?.(item);
      } else {
        _result = [...prev, item];
        props.onItemSelect?.(item)?.(item);
      }

      return _result;
    });
  };

  const isSelected = (item: T) => {
    return selecteds.some((a) => a[titleFieldName] === item[titleFieldName]);
  };

  let data = props.data?.filter((eachItem) =>
    searchValue === ""
      ? true
      : props.fields
          .filter((e) => e.isSearchable !== false)
          .some((eachSearchField) =>
            typeof getItemFieldValue(eachItem, eachSearchField.value) ===
            "string"
              ? getItemFieldValue(eachItem, eachSearchField.value)
                  .toLowerCase()
                  .includes(searchValue.toLocaleLowerCase())
              : getItemFieldValue(eachItem, eachSearchField.value) ===
                searchValue
          )
  );
  if (props.onSortDir === undefined && props.onSortField === undefined) {
    data = data?.sort(
      (a: T, b: T) =>
        selectedSortDir *
        (typeof getItemFieldValue(a, selectedSortFieldValue) === "string"
          ? getItemFieldValue(a, selectedSortFieldValue).localeCompare(
              getItemFieldValue(b, selectedSortFieldValue)
            )
          : getItemFieldValue(a, selectedSortFieldValue) -
            getItemFieldValue(b, selectedSortFieldValue))
    );
  }

  return (
    <ItemsListContext.Provider
      value={
        {
          ...props,
          titleFieldName,

          selecteds,
          toggleSelecteds,
          isSelected,

          selectedSortDir,
          setSelectedSortDir,

          selectedSortFieldValue,
          setSelectedSortFieldValue,

          searchValue,
          setSearchValue,

          data,
          sortableFields,
        } as ItemsListContextProps<T>
      }
    >
      {props.children}
    </ItemsListContext.Provider>
  );
}

export function useItemsListContext() {
  const _context = React.useContext(ItemsListContext);

  if (!_context) {
    throw new Error("cannot use ItemsListContext outside of its provider.");
  }

  return _context;
}
