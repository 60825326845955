import React from "react";
import { stringify } from "flatted";
import { ReactSortable, Sortable } from "react-sortablejs";

import Effort from "models/Effort";

import LocalStorageServices from "services/localServices/LocalStorageServices";

import {
  ItemType,
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";
import Skeleton from "components/common/Skeleton";
import EachColumn from "./EachColumn";
import {
  cn,
  isEmpty,
  objSafeGet,
  randomInt,
  sortedStringify,
} from "services/UtilServices";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import Dict from "models/Dict";
import { EffortsProvider } from "providers/EffortsProvider";
import { TrelloSectionTypeItemType } from "../GroupBySelectSection";
import { EffortGroupType } from "hooks/utils/UseEffortUtils";

export default function BoardSection({
  cols,
  onUpdate,
  basedOnType,
  isColDisabled,
  colClick,
  colActions,
}: {
  cols?: EffortGroupType[];
  basedOnType: TrelloSectionTypeItemType;
  colActions?: (col: EffortGroupType) => ItemType[];
  colClick?: (col: EffortGroupType) => void;
  isColDisabled: (
    eachCol: EffortGroupType,
    draggingEffortId?: number
  ) => boolean | undefined;
  onUpdate: (d: {
    effortId: number;
    srcColId: string;
    desColId: string | null;
    isCloning?: boolean;
  }) => Promise<Dict>;
}) {
  const _itemsListContext = useItemsListContext();

  const [_cols, _setCols] = React.useState<EffortGroupType[] | undefined>();
  const [_draggingEffortId, _setDraggingEffortId] = React.useState<number>();
  const [_isCloning, _setIsCloning] = React.useState(false);

  const setCols = (s?: EffortGroupType[]) => {
    _setCols(s);
    LocalStorageServices.set(
      "COLS_ORDER" + basedOnType.label,
      s?.map((e) => e.id) ?? []
    );
  };

  const cloneCols = (c: EffortGroupType[]) => {
    return [
      ...c!.map((e) => ({
        ...e,
        efforts: [...(e.effortIds ?? [])],
      })),
    ];
  };

  React.useEffect(() => {
    const _onKeyDown = (ev: KeyboardEvent) => _setIsCloning(ev.ctrlKey);
    const _onKeyUp = () => _setIsCloning(false);

    if (basedOnType.multiple) {
      window.addEventListener("keydown", _onKeyDown);
      window.addEventListener("keyup", _onKeyUp);
    }

    return () => {
      window.removeEventListener("keydown", _onKeyDown);
      window.removeEventListener("keyup", _onKeyUp);
    };
  }, [basedOnType.multiple]);

  React.useEffect(() => {
    // _setCols(undefined);
    if (isEmpty(cols)) return;

    LocalStorageServices.get("COLS_ORDER" + basedOnType.label).then((r) => {
      if (r === null) r = [];

      let prevOrdered = (r as string[])
        .map((e) => cols?.find((a) => a.id === e))
        .filter((e) => e !== undefined) as EffortGroupType[];

      let notOrderedYet = cols?.filter(
        (a) => !(r as string[]).some((e) => a.id === e)
      );

      let newCols = [...prevOrdered, ...(notOrderedYet ?? [])];

      setCols(newCols);
    });
  }, [
    sortedStringify(cols?.map((e) => e.id)),
    sortedStringify(cols?.map((e) => e.effortIds)),
  ]);

  const onColDragEnd = (ev: Sortable.SortableEvent) => {
    if (!objSafeGet(ev, "originalEvent", "cancelable")) return;

    const dest = { id: ev.to.id, index: ev.newIndex! };
    const src = { id: ev.from.id, index: ev.oldIndex! };
    const draggableId = ev.item.id;

    const copiedItems = [..._cols!];
    const [removed] = copiedItems.splice(src.index, 1);
    copiedItems.splice(dest.index, 0, removed);
    setCols(copiedItems);
  };

  const onCardRemove = (effortId: number, colId: string) => {
    const _rollbackCols = cloneCols(_cols!);
    setCols(
      _cols!.map((eachCol) =>
        eachCol.id !== colId
          ? eachCol
          : {
              ...eachCol,
              effortIds: eachCol.effortIds?.filter((e) => e !== effortId),
            }
      )
    );

    onUpdate({
      effortId,
      desColId: null,
      srcColId: colId,
    }).then((_errors) => {
      if (!isEmpty(_errors)) {
        setCols(_rollbackCols);
      }
    });
  };

  const onCardDragEnd = (ev: Sortable.SortableEvent) => {
    if (!objSafeGet(ev, "originalEvent", "cancelable")) {
      _setDraggingEffortId(undefined);
      return;
    }

    const dest = { id: ev.to.id, index: ev.newIndex! };
    const src = { id: ev.from.id, index: ev.oldIndex! };
    const draggableId = ev.item.id;

    let effortId = parseInt(draggableId);
    let targetEffort = (_itemsListContext.data as Effort[])!.find(
      (e) => e.id === effortId
    )!;
    let desColId = dest.id;
    let srcColId = src.id;

    const _rollbackCols = cloneCols(_cols!);
    setCols(
      _cols!.map((eachCol) => {
        if (eachCol.id === srcColId && !_isCloning) {
          const copiedItems = [...eachCol.effortIds!];
          copiedItems.splice(src.index, 1);
          eachCol.effortIds = copiedItems;
        }

        if (eachCol.id === desColId) {
          let _isAlreadyInCol = eachCol.effortIds?.some(
            (e) => e === targetEffort.id
          );
          if (!_isAlreadyInCol) {
            const copiedItems = [...eachCol.effortIds!];
            copiedItems.splice(dest.index, 0, targetEffort.id);
            eachCol.effortIds = copiedItems;
          }
        }

        return eachCol;
      })
    );

    _setDraggingEffortId(undefined);

    if (dest.id !== src.id) {
      onUpdate({
        effortId,
        desColId,
        srcColId,
        isCloning: _isCloning,
      }).then((_errors) => {
        if (!isEmpty(_errors)) {
          setCols(_rollbackCols);
        }
      });
    }
  };

  return (
    <>
      {cols === undefined ? (
        <div className="flex  ">
          {Array(3)
            .fill(null)
            .map((e, i) => (
              <div
                key={"eachLoadingItem" + i}
                className="mx-1 mt-2 min-w-[300px]"
              >
                <Skeleton className="mx-auto my-3 w-1/4 h-7" />
                <Skeleton
                  count={randomInt(5, 1)}
                  className="w-full mb-2 h-28"
                />
              </div>
            ))}
        </div>
      ) : cols.length === 0 ? (
        <EmptyListIndicator />
      ) : (
        // <BeautifulDnDDragDropContext onDragEnd={onColDragEnd}>
        //   <BeautifulDnDDroppable
        //     className="flex h-full"
        //     droppableProps={{ droppableId: "board", direction: "horizontal" }}
        //   >

        <ReactSortable
          id="board"
          group="board"
          disabled={!basedOnType.colsSortable}
          ghostClass="opacity-25" //invisible
          list={_cols ?? []}
          setList={() => {}}
          onEnd={onColDragEnd}
          // style={{
          //   height: `calc(100vh - ${ref.current?.offsetTop}px - 5px)`,
          // }}
          className={cn("flex h-full")}
        >
          {basedOnType.label === "effort"
            ? _cols?.map((eachCol, i) => (
                <EffortsProvider
                  key={"eachCol" + eachCol.id}
                  effortId={parseInt(eachCol.id)}
                >
                  {/* <MembersProvider>
                    <ModalProvider> */}
                  {/* <BeautifulDnDDraggable
                      asChild
                      draggableProps={{
                        draggableId: eachCol.id,
                        index: i,
                      }}
                    > */}
                  <EachColumn
                    col={eachCol}
                    colClick={colClick}
                    colActions={colActions}
                    basedOnType={basedOnType}
                    onDragEnd={onCardDragEnd}
                    disabled={isColDisabled(eachCol, _draggingEffortId)}
                    onDragStart={(ev) =>
                      _setDraggingEffortId(parseInt(ev.item.id))
                    }
                    onCardRemove={(ef) => onCardRemove(ef.id, eachCol.id)}
                  />
                  {/* </BeautifulDnDDraggable> */}
                  {/* </ModalProvider>
                  </MembersProvider> */}
                </EffortsProvider>
              ))
            : _cols?.map((eachCol, i) => (
                // <BeautifulDnDDraggable
                //   asChild
                //   key={"eachCol" + eachCol.id}
                //   draggableProps={{
                //     draggableId: eachCol.id,
                //     index: i,
                //   }}
                // >
                <EachColumn
                  key={"eachCol" + eachCol.id}
                  col={eachCol}
                  colClick={colClick}
                  colActions={colActions}
                  basedOnType={basedOnType}
                  onDragEnd={onCardDragEnd}
                  disabled={isColDisabled(eachCol, _draggingEffortId)}
                  onDragStart={(ev) =>
                    _setDraggingEffortId(parseInt(ev.item.id))
                  }
                  onCardRemove={(ef) => onCardRemove(ef.id, eachCol.id)}
                />
                // </BeautifulDnDDraggable>
              ))}
          {/* </BeautifulDnDDroppable>
        </BeautifulDnDDragDropContext> */}
        </ReactSortable>
      )}
    </>
  );
}
