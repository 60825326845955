import * as React from "react";
import { FaXmark } from "react-icons/fa6";
import { useSwipeable } from "react-swipeable";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";

import Dict from "../models/Dict";
import {
  addBackButtonEventListener,
  removeBackButtonEventListener,
} from "../components/common/BackButtonHandler";
import { cn, getItem } from "services/UtilServices";

const SIZES = {
  xs: "max-w-xs",
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-xl",
  "2xl": "max-w-2xl",
  "3xl": "max-w-3xl",
  "4xl": "max-w-4xl",
  "5xl": "max-w-5xl",
  "6xl": "max-w-6xl",
  "7xl": "max-w-7xl",
  full: "max-w-full",
};

interface ModalProps {
  id?: string;
  title?: React.ReactNode;
  size?: keyof typeof SIZES;
  dir?: "ltr" | "rtl";
  fullScreen?: boolean;
  needCloseBtn?: boolean;
  onDismiss?: () => void;
}

var initialAbsY = 0;
const trershold = 50;

const EachModal = ({
  children,
  dismiss,
}: {
  dismiss: () => void;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  // const [_disabled, _setDisabled] = React.useState(false);
  // const [_height, _setHeight] = React.useState(0);
  // const handlers = useSwipeable({
  //   onSwiping: (onSwipeEvent) => {
  //     // console.log(onSwipeEvent.deltaY, initialAbsY)

  //     if (_disabled === false) {
  //       if (initialAbsY === 0) {
  //         initialAbsY = onSwipeEvent.deltaY;
  //       }
  //       // else if (onSwipeEvent.deltaY > initialAbsY)
  //       // {
  //       _setHeight(
  //         Math.min(Math.max(onSwipeEvent.deltaY - initialAbsY, 0), trershold)
  //       );
  //       // }
  //     } else if (initialAbsY !== 0) {
  //       initialAbsY = 0;
  //       _setHeight(0);
  //     }
  //   },

  //   onSwiped: (onSwipeEvent) => {
  //     if (onSwipeEvent.dir === "Down" && _height >= trershold) {
  //       dismiss();
  //     } else if (_height > 0) {
  //       _setHeight(0);
  //       _setDisabled(false);
  //     }

  //     initialAbsY = 0;
  //   },
  // });

  // const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
  //   _setDisabled(event.currentTarget.scrollTop > 0);
  // };

  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 100);

    // return () => {
    //   if (dismiss) {
    //     dismiss();
    //   }
    // };
  }, []);

  return (
    <div
      data-state={isOpen ? "open" : "closed"}
      className={cn(
        "group/dialog transition-opacity duration-100",
        "data-[state=closed]:opacity-0 fixed inset-0 z-50"
      )}
    >
      {children}
    </div>
    // <Dialog
    //   modal={false}
    //   open={isOpen}
    //   onOpenChange={(d) => {
    //     if (!d) {
    //       setIsOpen(false);
    //       setTimeout(() => {
    //         dismiss();
    //       }, 200);
    //     }
    //   }}
    // >
    //   {children}
    // </Dialog>

    // <div
    //   className={cn(
    //     "z-1000 fixed top-0 bottom-0 end-0 start-0 overflow-y-auto scrollbar-hide ",
    //     "dir-" + props.dir,
    //     { invisible: isHidden },
    //     animated ? "opacity-100" : "opacity-0"
    //   )}
    //   onMouseDown={() => {
    //     dismiss();
    //     // if(props.onDismiss) {
    //     //   props.onDismiss();
    //     // }
    //   }}
    //   onScroll={handleScroll}
    //   {...handlers}
    // >
    //   <div
    //     onClick={(e) => e.stopPropagation()}
    //     onMouseDown={(e) => e.stopPropagation()}
    //     onMouseUp={(e) => e.stopPropagation()}
    //     className={cn(
    //       animated ? "" : " modal-animated",
    //       isHidden ? "duration-0" : "duration-100",
    //       `modal-dialog transition-all modal-${props.size} shadow `,
    //       props.fullScreen ? " modal-dialog-fullscreen mx-auto" : " mt-5"
    //     )}
    //   >
    //     <div
    //       className={`modal-content border-0 top-[${_height}px]
    //       opacity-[${(trershold * 5 - _height) / (trershold * 5)}] `}
    //     >
    //       {/* {
    //             props.title &&
    //               <Modal.Header className="items-center ">
    //                 <h5 className="mb-0">{ props.title }</h5>
    //                 <Button
    //                   variant={"light"}
    //                   size={"lg"}
    //                   className="flex items-center ml-[-10px]"

    //                   onClick={() => {
    //                     dismiss();
    //                     // if(props.onDismiss) {
    //                     //   props.onDismiss();
    //                     // }
    //                   }}
    //                 >
    //                   <FaXmark />
    //                 </Button>
    //               </Modal.Header>
    //           }

    //           <Modal.Body className="rounded">
    //             { props.children }
    //           </Modal.Body> */}
    //     </div>
    //   </div>
    // </div>
  );
};

interface ModalContextProps {
  open: (item: React.ReactElement) => void;
  dismiss: (id?: string) => void;
  dismissAll: (count?: number) => void;
}

const ModalContext = React.createContext({} as ModalContextProps);
ModalContext.displayName = "ModalContext";

function ModalProvider({ children }: { children: React.ReactNode }) {
  var [_modals, setModals] = React.useState<React.ReactElement[]>([]);

  const open = (item: React.ReactElement) => {
    if (_modals.length === 0) {
      addBackButtonEventListener(dismiss);
    }

    setModals((prev) => [...prev, item]);
  };

  const dismissAll = (count?: number) => {
    setModals((prev) => [
      ...(count === undefined ? [] : prev.slice(0, -count)),
    ]);
  };

  const dismiss = (id = "") => {
    setModals((prev) => {
      let _targetModalToRemove: React.ReactNode | undefined = undefined;

      if (id === "") {
        _targetModalToRemove = prev[prev.length - 1];
      } else if (typeof id === "string") {
        _targetModalToRemove = prev
          .slice()
          .reverse()
          .find(
            (e) =>
              // e &&
              // typeof e !== "string" &&
              // typeof e !== "number" &&
              // typeof e !== "boolean" &&
              "props" in e && "id" in e.props && e?.props.id === id
          );
      }

      const _result = prev.filter((e) => e !== _targetModalToRemove);

      if (_result.length === 0) {
        removeBackButtonEventListener();
      }

      return _result;
    });
  };

  return (
    <ModalContext.Provider
      value={
        {
          open,
          dismiss,
          dismissAll,
        } as ModalContextProps
      }
    >
      {children}

      {_modals.map((e, index) => (
        <EachModal key={"modal" + index} dismiss={() => dismiss()}>
          {e}
        </EachModal>
      ))}
    </ModalContext.Provider>
  );
}

export function useModalContext() {
  const _context = React.useContext(ModalContext);

  if (!_context) {
    throw new Error("cannot use ModalContext outside of its provider.");
  }

  return _context;
}

export { ModalContext, ModalProvider };
export type { ModalContextProps };
