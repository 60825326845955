import React from "react";

import { ItemType, useItemsListContext } from "./ItemsListProvider";

import MenuButton, { useMenuButton } from "components/ui/menu-button";

import { LuCheck } from "react-icons/lu";
import { cn, isEmpty } from "services/UtilServices";
import { RESPONSIVE_KEYS } from "models/views/responsive";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  MyContextMenuProps,
} from "components/ui/context-menu";
import { ContextMenuTriggerProps } from "@radix-ui/react-context-menu";

export function ItemsListContextMenu({
  item,
  otherItems,
  deleteMessage,
  ...props
}: {
  item: any;
  otherItems?: ItemType[] | undefined;
} & React.PropsWithoutRef<ContextMenuTriggerProps> &
  Omit<MyContextMenuProps, "onSelect" | "onEdit" | "onDelete">) {
  const menuButtonItems = useMenuButton();

  const {
    selecteds,
    onItemSelect,
    toggleSelecteds,
    onItemEdit,
    onItemDelete,
    getItemActions,
    selectedActionsSection,
  } = useItemsListContext();

  const _items = menuButtonItems
    .getItems({
      deleteMessage,
      onSelect: !(
        onItemSelect(item) ||
        selectedActionsSection ||
        onItemDelete([item, ...selecteds])
      )
        ? undefined
        : () => {
            toggleSelecteds(item);
            onItemSelect(item)?.(item);
          },
      onEdit:
        onItemEdit(item) === undefined
          ? undefined
          : () => onItemEdit(item)?.(item),
      onDelete:
        onItemDelete([item]) === undefined
          ? undefined
          : () => onItemDelete([item])?.([item]),
      items: [
        ...(getItemActions === undefined ? [] : getItemActions(item)),
        ...(otherItems ?? []),
      ],
    })
    .filter(
      (e) =>
        ("onClick" in e && e.onClick !== undefined) || React.isValidElement(e)
    );

  return (
    <>
      {isEmpty(_items) ? (
        props.children
      ) : (
        <ContextMenu>
          <ContextMenuTrigger {...props} />
          <ContextMenuContent>
            {_items?.map((eachMenuItem, i) =>
              React.isValidElement(eachMenuItem) ? (
                eachMenuItem
              ) : (
                <ContextMenuItem
                  key={"eachContextItem" + i}
                  onClick={eachMenuItem.onClick}
                >
                  {eachMenuItem.icon} {eachMenuItem.text}
                </ContextMenuItem>
              )
            )}
          </ContextMenuContent>
        </ContextMenu>
      )}
    </>
  );
}

export default function ItemsListToolsSection({
  eachItem,
  ellipsisResponsiveBreakPoint = "lg",
  btnTextResponsiveBreakPoint = "xl",
  deleteMessage,
  otherItems,
  ellipsisClassName,
}: {
  eachItem: any;
  ellipsisResponsiveBreakPoint?: RESPONSIVE_KEYS;
  btnTextResponsiveBreakPoint?: RESPONSIVE_KEYS;
  deleteMessage?: React.ReactNode;
  ellipsisClassName?: string;
  otherItems?: ItemType[] | undefined;
}) {
  const {
    selecteds,
    isSelected,
    onItemSelect,
    toggleSelecteds,
    onItemEdit,
    onItemDelete,
    getItemActions,
    selectedActionsSection,
  } = useItemsListContext();

  return (
    <div>
      {selecteds.length > 0 ? (
        <div
          className={cn(
            "rounded-full aspect-square border border-muted-foreground ms-auto flex items-center justify-center mx-2 w-5 h-5 my-1 p-0.5",
            {
              "bg-primary": isSelected(eachItem),
            }
          )}
        >
          {isSelected(eachItem) && (
            <LuCheck
              className={cn(
                "",
                isSelected(eachItem)
                  ? "text-primary-foreground"
                  : "text-foreground"
              )}
            />
          )}
        </div>
      ) : (
        <div
          className={cn(`left-0 top-0 bottom-0 md:invisible `, {
            "group-hover:visible group-hover/tools:visible":
              selecteds.length <= 0,
          })}
        >
          <MenuButton
            stopPropagation
            deleteMessage={deleteMessage}
            ellipsisResponsiveBreakPoint={ellipsisResponsiveBreakPoint}
            btnTextResponsiveBreakPoint={btnTextResponsiveBreakPoint}
            ellipsisClassName={ellipsisClassName}
            onSelect={
              !(
                onItemSelect(eachItem) ||
                selectedActionsSection ||
                onItemDelete([eachItem, ...selecteds])
              )
                ? undefined
                : () => {
                    toggleSelecteds(eachItem);
                    onItemSelect?.(eachItem)?.(eachItem);
                  }
            }
            onEdit={
              onItemEdit(eachItem) === undefined
                ? undefined
                : () => onItemEdit(eachItem)?.(eachItem)
            }
            onDelete={
              onItemDelete([eachItem]) === undefined
                ? undefined
                : () => onItemDelete([eachItem])?.([eachItem])
            }
            items={[
              ...(getItemActions === undefined ? [] : getItemActions(eachItem)),
              ...(otherItems ?? []),
            ]}
          />
        </div>
      )}
    </div>
  );
}
