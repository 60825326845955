import { Button } from "components/ui/button";
import React from "react";

export default function FilterModalResetButton({
  isFilterActive = false,
  onClick = () => {},
}) {
  return (
    <>
      {isFilterActive && (
        <Button
          variant={"light"}
          onClick={onClick}
          className="me-2 block relative"
        >
          Reset
        </Button>
      )}
    </>
  );
}
