import React from "react";

import { isEmpty } from "services/UtilServices";

import { useModalContext } from "providers/ModalProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import Effort, { EffortFilter } from "models/Effort";
import { DialogContent } from "components/ui/dialog";
import LoadingButton from "components/common/LoadingButton";
import { useToast } from "components/ui/use-toast";
import useExportApi from "hooks/api/UseExportApi";
import useDownloadServices from "hooks/UseDownloadServices";
import { AxiosError } from "axios";

export default function ExportModal({
  filter,
  selectedItems,
}: {
  filter?: Omit<EffortFilter, "numberInPage" | "ascOrder" | "pageNumber">;
  selectedItems?: Effort[];
}) {
  const _effortsContext = useEffortsContext();
  const exportApi = useExportApi();
  const _modalContext = useModalContext();
  const downloadServices = useDownloadServices();
  const { toast } = useToast();

  const onExport = async (type: "xlsx" | "pdf") => {
    let _errors = {};

    try {
      let response = await exportApi.getEffortReport(type, {
        ...(filter ?? {
          ..._effortsContext.filter,
          parentId: _effortsContext.currentEffort?.id ?? 0,
          effortIds: selectedItems?.map((e) => e.id),
        }),
        numberInPage: 999,
        ascOrder: false,
        pageNumber: 0,
      });

      downloadServices.downloadByBlob({
        blob: response.blob,
        filename: response.filename,
      });

      toast("Exported.");
      setTimeout(() => _modalContext.dismiss(), 2000);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;

    // let _errors = await _effortsContext.exportReport(type, {
    //   ...(filter ?? {
    //     ..._effortsContext.filter,
    //     parentId: _effortsContext.currentEffort?.id ?? 0,
    //     effortIds: selectedItems?.map((e) => e.id),
    //   }),
    //   numberInPage: 999,
    //   ascOrder: false,
    //   pageNumber: 0,
    // });

    // if (isEmpty(_errors)) {
    //   toast("Exported.");
    //   setTimeout(() => _modalContext.dismiss(), 2000);
    // }
    // // else {
    // //   toast()
    // // }

    // return _errors;
  };

  return (
    <DialogContent
      title={"Export"}
      size={"sm"}
      fullScreen={false}
      onBgClickClosable
    >
      <span className="mb-3 block text-foreground">
        Now you can select in which format would you like to export.
      </span>

      <LoadingButton
        variant={"primary"}
        className="w-full p-3 mb-2 "
        onClick={async () => await onExport("xlsx")}
      >
        <img
          src={"/images/excel-logo.png"}
          width="30px"
          alt="excel-icon"
          className="inline"
        />
        <span>Excel</span>
      </LoadingButton>

      {/* <Form allowInitSubmit
        onSubmit={() => onExport("pdf")}
      >
        <SubmitButton 
          variant="light"
          className="border flex items-center w-full p-3 justify-center bg-transparent"
        >
            <img
                src={"/images/pdf-logo.png"}
                width="30px"
                alt="pdf-icon"
                className="me-2"
            />
            <span>PDF</span>
        </SubmitButton>
      </Form> */}
    </DialogContent>
  );
}
