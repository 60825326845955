import React from "react";
import { useFormContext } from "./Form";

import { FaCamera } from "react-icons/fa6";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";

export function useSelectFile() {
  const inputRef = React.useRef<HTMLInputElement>(
    document.createElement("input")
  );

  const select = (
    onChange: (files: File[] | File) => void,
    props: {
      multiple?: boolean;
    }
  ) => {
    const { multiple = false } = props;

    inputRef.current.type = "file";
    inputRef.current.multiple = multiple;

    inputRef.current.onchange = (event: Event) => {
      const _target = event.target as HTMLInputElement;
      if (_target?.files !== null) {
        onChange(multiple ? Array.from(_target!.files) : _target.files[0]);
      }
    };

    inputRef.current.click();
  };

  return {
    select,
  };
}

interface Props {
  children: React.ReactNode;
  name: string;
  label?: string;
  maxCount?: number;
  multiple?: boolean;
  [rest: string]: any;
}

const SelectFileButton = ({
  children,
  name = "avatar",
  multiple = false,
  maxCount = 10,
  ...rest
}: Props) => {
  const formContext = useFormContext();
  const upload = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const target = event.target as HTMLInputElement;
    if (event.target.files !== null) {
      formContext.setData({
        [name]: multiple
          ? formContext.data[name].concat(event.target.files).slice(0, maxCount)
          : event.target.files[0],
      });
    }
  };

  return (
    <div>
      <Button
        size={"sm"}
        variant={"outline"}
        onClick={() => {
          upload.current.click();
        }}
        className=" ms-2 bg-light absolute bottom-0 right-0 "
        {...rest}
      >
        {children ?? <FaCamera />}
      </Button>

      <Input
        id="myInput"
        type="file"
        ref={upload}
        className="hidden"
        multiple={multiple}
        onChange={onChangeFile}
      />
    </div>
  );
};

export default SelectFileButton;
