import { hasOtherKeysExcept } from "services/UtilServices";
import { z } from "zod";


export type PrefixSortType = "name" | "dateadd"


export const PrefixSortTypes: { [key: string]: PrefixSortType } = {
  name: "name",
  dateadd: "dateadd",
};


export interface PrefixFilter {
  "pageNumber": number,
  "sortBy": PrefixSortType,
  "ascOrder": boolean,
  numberInPage?: number,

  "name"?: string,
  "dateAddFrom"?: string,
  "dateAddTo"?: string,
}


export const prefixSchema = z.object({
  id: z.number(),
  name: z.string(),
  dateAdd: z.string(),
});

type Prefix = z.infer<typeof prefixSchema>;
export default Prefix;

// export default interface Prefix {
//   id: number,
//   name: string,
//   dateAdd: string,
// }

