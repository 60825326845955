import { PriorityFilter } from "models/Priority";
import { hasOtherKeysExcept } from "services/UtilServices";


export default function usePriorityUtils() {
  const isFilterActive = ({ filter }: { filter: PriorityFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage"]);
  }

  return {
    isFilterActive
  }
}
