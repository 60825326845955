import React from "react";

import Effort from "models/Effort";

import SectionBase from "./SectionBase";
import Cheeps from "components/common/Cheeps";

export default function PrefixsSection({ effort }: { effort: Effort }) {
  return (
    <SectionBase effort={effort} title="Prefixes" fieldName="prefixes">
      {effort.prefixes?.map((eachPrefix, i) => (
        <Cheeps key={"eachType" + i}>
          {eachPrefix.name}
        </Cheeps>
      ))}
    </SectionBase>
  );
}
