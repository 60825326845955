import React from "react";

import Effort from "models/Effort";

import { useModalContext, ModalProvider } from "providers/ModalProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import ItemsListProvider, {
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";

import ExportModal from "./ExportModal";
import NewButton from "components/common/AdminSection/NewButton";
import EffortsListTrelloSection from "./EffortsListTrelloSection/EffortsListTrelloSection";
import FilterButton from "components/common/AdminSection/FilterButton";
import EffortsListChartSection from "components/pages/EffortsSection/EffortsListChartSection";
import ItemListSearchbarSection from "components/common/ItemsListSection/ItemsListSearchbarSection";
import EmptyListIndicator from "components/common/EmptyListIndicator";

import { LuCopy, LuDownload, LuPencil, LuTrash2 } from "react-icons/lu";
import { Button } from "components/ui/button";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import {
  useEffortViewUtils,
  useEffortAuthUtils,
  useEffortUtils,
} from "hooks/utils/UseEffortUtils";
import { useEffortMenuButton } from "../EachEffort/EffortMenuButton";
import { useDeleteButton } from "components/common/DeleteButton";
import MainHeaderBody from "components/layouts/MainHeader/MainHeader";

export function EffortsListSectionHeader() {
  const _itemsListContext = useItemsListContext();
  const _effortsContext = useEffortsContext();

  return (
    <MainHeaderBody>
      <ItemListSearchbarSection
        hideEllipseBreakPoint={
          _effortsContext.viewType === "board" ? "never" : "md"
        }
        {..._itemsListContext}
      />
    </MainHeaderBody>
  );
}

export function EffortsListSectionBody({ effortId }: { effortId?: number }) {
  const { data, onLoadMore, title } = useItemsListContext();

  const { viewType } = useEffortsContext();

  const _isEmpty = !onLoadMore && data?.length === 0;

  return (
    <>
      {_isEmpty ? (
        <EmptyListIndicator
          wrapperClassName="mt-5"
          text={"No " + title + " Found!"}
        />
      ) : (
        <>
          {viewType === "board" || viewType === "table" ? (
            <EffortsListTrelloSection effortId={effortId} />
          ) : viewType === "chart" ? (
            <EffortsListChartSection effortId={effortId} />
          ) : null}
        </>
      )}
    </>
  );
}

export function EffortsSectionProvider({
  projectId,
  children,
}: {
  projectId?: number;
  children?: React.ReactNode;
}) {
  const _modalContext = useModalContext();
  const _effortsContext = useEffortsContext();
  const deleteButton = useDeleteButton();

  // const effortId = _effortsContext.currentEffort?.id;

  const _effortViewUtils = useEffortViewUtils(projectId);
  const _effortAuthUtils = useEffortAuthUtils();
  const _effortUtils = useEffortUtils();
  const effortMenuButton = useEffortMenuButton(projectId);

  const mediaQuery = useMediaQuery();

  const _isHome = _effortsContext.currentEffort === undefined;
  const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);

  return (
    <ModalProvider>
      <ItemsListProvider<Effort>
        title={_isHome ? "Projects" : _isProject ? "Tasks" : "Sub Tasks"}
        data={_effortsContext.efforts}
        btnTextResponsiveBreakPoint="never"
        initialSelectedSortFieldValue="dateAdd"
        fields={_effortViewUtils.getItemsListFields()}
        // onItemClick={(item) => () => navigate(item.url!)}
        selectedActionsSection={(items) => [
          {
            icon: <LuDownload />,
            text: "Export",
            onClick: () => {
              _modalContext.open(<ExportModal selectedItems={items} />);
            },
          },

          ...(_effortAuthUtils.canEdit(items)
            ? [
                {
                  icon: <LuPencil />,
                  text: "Change State",
                  onClick: () => _effortViewUtils.openUpdateStateModal(items),
                },
              ]
            : []),

          ...(_effortAuthUtils.canDelete(items)
            ? [
                {
                  icon: <LuTrash2 />,
                  text: "Delete",
                  onClick: () =>
                    deleteButton.tryRemove({
                      message: (
                        <>
                          You're about to delete these tasks{" "}
                          {items.map((e, i) => (
                            <span>
                              {i === 0 ? "" : ", "}"
                              <b className="capitalize">{e.title}</b>"
                            </span>
                          ))}
                          .
                        </>
                      ),
                      onConfirm: async () =>
                        await _effortViewUtils.onRemove(items),
                    }),
                },
              ]
            : []),
        ]}
        itemClassName={(item) =>
          item.isActive ? "" : "opacity-60 hover:opacity-100"
        }
        getItemActions={(item) => effortMenuButton.getItems(item)}
        actionsSection={
          <>
            {_effortsContext.efforts !== undefined &&
              _effortsContext.efforts?.length > 0 && (
                <Button
                  variant={"light"}
                  onClick={() => _modalContext.open(<ExportModal />)}
                >
                  <LuDownload />
                  {mediaQuery >= MediaQuery2Num["2xl"] && (
                    <span className="hidden lg:inline ">Export</span>
                  )}
                </Button>
              )}

            <FilterButton
              needText={mediaQuery >= MediaQuery2Num["2xl"]}
              count={_effortsContext.efforts?.length}
              isActive={_effortUtils.isFilterActive({
                effortId: projectId,
                filter: _effortsContext.filter,
              })}
              onClick={_effortViewUtils.openFilterModal}
            />

            {_effortAuthUtils.canCreate(projectId) && (
              <NewButton
                needText={mediaQuery >= MediaQuery2Num["2xl"]}
                onClick={() => _effortViewUtils.openCreateUpdateModal()}
              />
            )}
          </>
        }
      >
        {children}
      </ItemsListProvider>
    </ModalProvider>
  );
}

export default function EffortsSection({ effortId }: { effortId?: number }) {
  return (
    <EffortsSectionProvider projectId={effortId}>
      <EffortsListSectionBody effortId={effortId} />
    </EffortsSectionProvider>
  );
}
