import React from "react";

import State, { StateFilter, StateSortType } from "models/State";
import Dict from "models/Dict";

import { cn, dateGetTime, isEmpty } from "services/UtilServices";

import { useStatesContext, StatesProvider } from "providers/StatesProvider";
import { useModalContext } from "providers/ModalProvider";

import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import StateCreateUpdateModal from "./StateCreateUpdateModal";
import StateFilterModal from "./StateFilterModal";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import NewButton from "components/common/AdminSection/NewButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import LocalDateTime from "components/common/LocalDateTime";
import useStateUtils from "hooks/utils/UseStateUtils";
import ItemsListMainSection from "components/common/ItemsListSection/ItemsListMainSection";

function EachStateItem({ state, index }: { state: State; index: number }) {
  return (
    <div className="flex items-center ">
      <div>
        <h6 className="mb-0 "> {index + 1} </h6>
      </div>

      <div
        className={cn(
          "rounded-full aspect-square mx-auto my-auto ms-2 me-2 mb-1 w-5 h-5 "
        )}
        style={{
          background: state.color,
        }}
      />

      <div>
        <h6 className="mb-0 me-2">{state.name}</h6>
      </div>
    </div>
  );
}

function StatesPageBody() {
  const _modalContext = useModalContext();
  const _statesContext = useStatesContext();
  const stateUtils = useStateUtils();

  const onRemove = async (datas: State[]) => {
    _statesContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _statesContext.update(data)
        : await _statesContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <StateCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    _statesContext.get({
      ...formData,
      pageNumber: -1,
    } as StateFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <StateFilterModal
        filter={_statesContext.filter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  return (
    <ItemsListMainSection<State>
      title="States"
      data={_statesContext.states}
      // ellipsisResponsiveBreakPoint=""

      fields={[
        // {
        //   value: "",
        //   label: "#",
        //   className: "text-start whitespace-nowrap",
        //   width: "70px",
        //   isSortable: false,
        //   getUiContent: (e, i) => i + 1,
        // },

        {
          value: "name",
          label: "name",
          isTitleField: true,
          width: "auto",
          // isSortable: false,
          className: "whitespace-nowrap",
        },

        {
          value: "color",
          label: "color",
          width: "10%",
          isSortable: false,
          isSearchable: false,
          getUiContent: (e, i) => (
            <div
              className={cn("rounded-full aspect-square w-7 h-7 ")}
              style={{
                background: e.color,
              }}
            />
          ),
        },

        {
          value: "dateAdd",
          label: "Created on",
          width: "auto",
          // isSortable: false,
          getValue: (e) => dateGetTime(e.dateAdd),
          getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
          className: "whitespace-nowrap",
        },
      ]}
      onLoadMore={
        !_statesContext.hasNext
          ? undefined
          : async () => {
              await _statesContext.get({
                ..._statesContext.filter,
                pageNumber: _statesContext.filter.pageNumber + 1,
              });
            }
      }
      onSortField={(v) => {
        _statesContext.get({
          ..._statesContext.filter,
          sortBy: v as StateSortType,
          pageNumber: -1,
        });
      }}
      onSortDir={(v) => {
        _statesContext.get({
          ..._statesContext.filter,
          ascOrder: v === 1,
          pageNumber: -1,
        });
      }}
      initialSelectedSortFieldValue="dateAdd"
      buildCardTitleRow={(v, i) => <EachStateItem state={v} index={i} />}
      onItemEdit={(u) => openCreateUpdateModal}
      onItemClick={(u) => openCreateUpdateModal}
      onItemDelete={() => _statesContext.removeMany}
      actionsSection={
        <>
          <FilterButton
            count={_statesContext.totalNumber}
            isActive={stateUtils.isFilterActive({
              filter: _statesContext.filter,
            })}
            onClick={openFilterModal}
          />

          <NewButton onClick={() => openCreateUpdateModal()} />
        </>
      }
    />
  );
}

export default function StatesPage() {
  return (
    <StatesProvider>
      <StatesPageBody />
    </StatesProvider>
  );
}
