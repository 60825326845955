
import { TicketFilter } from "models/Ticket";
import { hasOtherKeysExcept } from "services/UtilServices";



export default function useTicketUtils() {
  const isFilterActive = ({ filter }: { filter: TicketFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage"]);
  }

  return {
    isFilterActive
  }
}
