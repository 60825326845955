import React from "react";

import MainSection from "components/layouts/MainSection";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import UserLogsSection from "components/pages/UserLogsSection/UserLogsSection";

function FeedPage() {
  return (
    <MainSection title="Feed">
      <UserLogsSection />
      <MobileFooterPadding />
    </MainSection>
  );
}

export default FeedPage;
