import React from "react";

import ZoomSection from "./ZoomSection";
import HeaderSection from "./HeaderSection";
import FilesRowSection from "./FilesRowSection";
import {
  useGalleryViewContext,
  GalleryViewProps,
} from "providers/GalleryViewProvider";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";
import { cn } from "services/UtilServices";

export default function GalleryView({ initUrl, items }: GalleryViewProps) {
  const [isZooming, setIsZooming] = React.useState(false);
  const [index, _setIndex] = React.useState<number>(-1);
  const _selectedItem = items[index];

  const media = useMediaQuery();
  var tileSize = 60;
  if (media >= MediaQuery2Num.lg) {
    tileSize = 100;
  } else if (media >= MediaQuery2Num.sm) {
    tileSize = 80;
  }

  const _galleryViewContext = useGalleryViewContext();

  const scrollerContainer = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    scrollerContainer.current?.scrollTo({
      behavior: "smooth",
      left: index * (tileSize + 1) - Math.floor(window.innerWidth / 2),
    });
  }, [index]);

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      _galleryViewContext.dismiss();
    } else if (e.key === "ArrowRight") {
      _setIndex((prev) => (prev < items.length - 1 ? prev + 1 : prev));
    } else if (e.key === "ArrowLeft") {
      _setIndex((prev) => (prev > 0 ? prev - 1 : prev));
    }
  };

  React.useEffect(() => {
    _setIndex(items.findIndex((e) => e.url === initUrl) ?? 0);

    document.addEventListener("keydown", onKeyPress, false);

    return () => {
      document.removeEventListener("keydown", onKeyPress, false);
    };
  }, [items, initUrl]);

  return (
    <div className="z-50 fixed inset-0 bg-dark transition-all duration-100">
      {_selectedItem && (
        <>
          <ZoomSection
            // key={_selectedItem}
            url={_selectedItem.url}
            isZooming={isZooming}
            setIsZooming={setIsZooming}
          />

          <div
            className={cn(
              "absolute inset-0 flex flex-col h-screen pointer-events-none ",
              {
                hidden: isZooming,
              }
            )}
          >
            <HeaderSection item={_selectedItem} />

            <div className="flex-1 "></div>

            <FilesRowSection
              items={items}
              index={index}
              setIndex={_setIndex}
              scrollerContainer={scrollerContainer}
              tileSize={tileSize}
            />
          </div>
        </>
      )}
    </div>
  );
}
