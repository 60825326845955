import Department from "./Department";
import { z } from "zod";


export type TypeSortType = "name" | "dateadd";


export const TypeSortTypes: { [key: string]: TypeSortType } = {
  // DEPARTMENT: "DEPARTMENT",
  name: "name",
  dateadd: "dateadd",
};

export interface TypeFilter {
  "pageNumber": number,
  "sortBy": TypeSortType,
  "ascOrder": boolean,
  numberInPage?: number,

  department?: Department,
  name?: string,
  color?: string,
  // activities?: Activity[],
  // efforts?: Effort[],
  dateAddFrom?: Date,
  dateAddTo?: Date,
}

export const typeSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string(),
  dateAdd: z.string(),
});

type Type = z.infer<typeof typeSchema>;
export default Type;
// export default interface Type {
//   id: number,
//   name: string,
//   color: string,

//   departmentId: string,
//   department: Department,

//   activities: Activity[],
//   efforts: Effort[],
//   dateAdd: string,

// }
