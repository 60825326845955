import React from "react";
import { ResizableBox } from "react-resizable";
import { cn } from "services/UtilServices";
import LocalStorageServices from "services/localServices/LocalStorageServices";

interface ResizableContextProps {
  init: () => void;
}

const ResizableContext = React.createContext({} as ResizableContextProps);
ResizableContext.displayName = "ResizableContext";

export function useResizableContext() {
  const _context = React.useContext(ResizableContext);

  if (!_context) {
    throw new Error("cannot use ResizableContext outside of its provider.");
  }

  return _context;
}

export function ResizableHandle() {
  // const _context = useResizableContext();

  return (
    <div
      // onMouseDown={(ev) => {
      //   // _context.init();
      //   ev.preventDefault();
      // }}
      className={cn(
        "hover:bg-blue-400 ps-1 absolute left-0 top-0 bottom-0",
        "transition-color duration-200 cursor-col-resize"
      )}
    />
  );
}

export type ResizableProps = {
  children: React.ReactNode;
  id: string;
} & React.HTMLAttributes<HTMLDivElement>;

var requestId: number | null;

export default function Resizable({
  id,
  style,
  children,
  className,
  ...props
}: ResizableProps) {
  const [width, _setWidth] = React.useState<number>(0);

  const setWidth = (v: number | string) => {
    LocalStorageServices.set(id, v);
  };

  React.useEffect(() => {
    LocalStorageServices.get(id).then((r) => _setWidth(r ?? 300));
  }, []);

  return (
    <ResizableBox
      axis="x"
      width={width}
      resizeHandles={["w"]}
      className={cn("max-w-lg min-w-[300px]", className)}
      onResizeStop={(ev, data) => setWidth(data.size.width)}
      minConstraints={[300, 0]}
      maxConstraints={[512, 0]}
      handle={
        <div
          onMouseDown={(ev) => ev.preventDefault()}
          className={cn(
            "hover:bg-blue-400 ps-1.5 absolute left-0 top-0 bottom-0",
            "transition-color duration-200 cursor-col-resize"
          )}
        />
      }
    >
      <>{children}</>
    </ResizableBox>
  );
}
