import React from "react";

import Dict from "models/Dict";

import { useModalContext } from "providers/ModalProvider";

import {
  Switch,
  ColorPicker,
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import { DialogContent, DialogFooter } from "components/ui/dialog";

export default function PrefixCreateUpdateModal({
  formData,
  onSubmit,
}: {
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  // const _modalContext = useModalContext();

  return (
    <DialogContent title="Prefixes">
      <Form
        data={
          formData !== undefined && "id" in formData
            ? {
                ...formData,
              }
            : {}
        }
        onSubmit={onSubmit}
      >
        <Input name="name" maxLength={256} required autoFocus />

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
