import React from "react";

import { ThemeContext, useThemeContext } from "./ThemeProvider";
import { cn } from "services/UtilServices";
import { RESPONSIVE_HIDDEN as RESPONSIVE_HIDDEN } from "models/views/responsive";
import { LuX } from "react-icons/lu";
import { Button } from "components/ui/button";

interface SideMenuProps {
  content: React.ReactNode;
  size?: "xl" | "lg" | "md" | "sm";
  dir?: "left" | "right";
  fullScreen?: boolean;
}

const SideMenu = ({
  props,
  dismiss,
  isDismissed = false,
}: {
  props: SideMenuProps;
  dismiss: () => void;
  isDismissed?: boolean;
}) => {
  const _themeContext = useThemeContext();

  return (
    <div
      onClick={dismiss}
      className={cn(
        "z-20  fixed bg-dark-transparent top-0 bottom-0 right-0 left-0",
        RESPONSIVE_HIDDEN[props.size ?? "md"]
      )}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={cn(
          "absolute top-0 bottom-0 shadow w-72  bg-muted ",
          props.dir === "left" ? "border-e" : "border-s",
          {
            "right-[-350px]": props.dir === "right" && isDismissed, //props.dir === "left" ? undefined : isDismissed ? -350 : 0,
            "left-[-350px]": props.dir === "left" && isDismissed, //props.dir === "left" ? (isDismissed ? -350 : 0) : undefined,
          }
        )}
        // style={{
        //   background: _themeContext.themeColor,
        // }}
      >
        <div className="overflow-y-auto scrollbar-hide">
          <div className="flex items-center px-3 pt-3 ">
            <Button
              onClick={dismiss}
              variant={"outline-light"}
              className={cn(" border-0 ", {
                "ms-auto": props.dir === "left",
              })}
            >
              <LuX />
            </Button>
          </div>

          {props.content}
        </div>
      </div>
    </div>
  );
};

interface SideMenuContextProps {
  show: ({ content, size, dir, fullScreen }: SideMenuProps) => void;
  dismiss: () => void;
}

const SideMenuContext = React.createContext({} as SideMenuContextProps);
SideMenuContext.displayName = "SideMenuContext";

interface Props {
  children: React.ReactNode;
}

function SideMenuProvider({ children }: Props) {
  const [sliderProps, setSliderProps] = React.useState<
    SideMenuProps | undefined
  >();
  const [isDismissed, setIsDismissed] = React.useState<boolean>(false);

  const show = ({
    content,
    size = "md",
    dir = "left",
    fullScreen = true,
  }: SideMenuProps) => {
    setIsDismissed(true);
    setSliderProps({
      content,
      size,
      dir,
      fullScreen,
    });
    setTimeout(() => {
      setIsDismissed(false);
    }, 50);
  };

  const dismiss = () => {
    setIsDismissed(true);
    setTimeout(() => {
      setSliderProps(undefined);
    }, 150);
  };

  return (
    <SideMenuContext.Provider
      value={
        {
          show,
          dismiss,
        } as SideMenuContextProps
      }
    >
      {children}

      {sliderProps === undefined ? null : (
        <SideMenu
          props={sliderProps}
          dismiss={dismiss}
          isDismissed={isDismissed}
        />
      )}
    </SideMenuContext.Provider>
  );
}

export function useSideMenuContext() {
  const _context = React.useContext(SideMenuContext);

  if (!_context) {
    throw new Error("cannot use SideMenuContext outside of its provider.");
  }

  return _context;
}

export { SideMenuContext, SideMenuProvider };
