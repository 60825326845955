import React from "react";

import Effort from "models/Effort";

import SectionBase from "./SectionBase";
import Cheeps from "components/common/Cheeps";

export default function TypesSection({ effort }: { effort: Effort }) {
  return (
    <SectionBase effort={effort} title="Types" fieldName="types">
      {effort.types?.map((eachType, i) => (
        <Cheeps key={"eachType" + i} borderColor={eachType.color}>
          {eachType.name}
        </Cheeps>
      ))}
    </SectionBase>
  );
}
