import useClipboard from "components/common/UseClipboard";
import MenuButton from "components/ui/menu-button";
import {
  useEffortUtils,
  useEffortAuthUtils,
  useEffortViewUtils,
} from "hooks/utils/UseEffortUtils";
import Effort from "models/Effort";
import { CgRename } from "react-icons/cg";
import {
  LuBell,
  LuCopy,
  LuDownload,
  LuPencil,
  LuSettings,
  LuShare2,
  LuTimer,
  LuTrash2,
} from "react-icons/lu";
import { useNavigate } from "react-router";
import { isEmpty, objRemoveKeys } from "services/UtilServices";
import useSingleFieldUpdate from "../EffortsSection/SingleFieldUpdateButton";
import { useModalContext } from "providers/ModalProvider";
import ExportModal from "../EffortsSection/ExportModal";
import { useDeleteButton } from "components/common/DeleteButton";
import useMemberUtils from "hooks/utils/UseMemberUtils";
import useActivityUtils, {
  useActivityViewUtils,
} from "hooks/utils/UseActivityUtils";
import { TbArchive, TbArchiveOff } from "react-icons/tb";

export function useEffortMenuButton(parentId?: number | null) {
  const _effortUtils = useEffortUtils();
  const _effortAuthUtils = useEffortAuthUtils();
  const _effortViewUtils = useEffortViewUtils(parentId);
  const singleFieldEdit = useSingleFieldUpdate();
  const clipnoard = useClipboard();
  const _modalContext = useModalContext();
  const deleteButton = useDeleteButton();
  const _memberUtils = useMemberUtils();
  const navigate = useNavigate();
  const activityViewUtils = useActivityViewUtils();
  const activityUtils = useActivityUtils();

  const getItems = (effort: Effort) => {
    return [
      ...(activityUtils.canCreate(effort)
        ? [
            {
              text: "New Activity",
              icon: <LuTimer />,
              onClick: () =>
                activityViewUtils.openCreateUpdateModal({
                  effortId: effort.id,
                }),
            },
          ]
        : []),

      {
        text: "Copy Link",
        icon: <LuShare2 />,
        onClick: () => {
          clipnoard.write(
            encodeURI(window.location.origin + _effortUtils.makeUrl(effort.id))
          );
        },
      },

      ...(_effortAuthUtils.canEdit([effort])
        ? [
            {
              text: "rename",
              icon: <CgRename />,
              onClick: () =>
                singleFieldEdit.onClick({
                  effort: effort,
                  fieldName: "title",
                }),
            },
            {
              text: effort.isActive ? "archive" : "not archive",
              icon: effort.isActive ? <TbArchive /> : <TbArchiveOff />,
              onClick: () =>
                _effortViewUtils.onCreateUpdateSubmit({
                  ...effort,
                  isActive: !effort.isActive,
                }),
            },
          ]
        : []),

      ...(_effortAuthUtils.canCreate(parentId)
        ? [
            {
              text: "Duplicate",
              icon: <LuCopy />,
              onClick: () =>
                _effortViewUtils.openCreateUpdateModal(
                  objRemoveKeys(effort, ["id"])
                ),
            },
          ]
        : []),

      ...(!isEmpty(_effortUtils.getSubEfforts(effort.id))
        ? [
            {
              text: "Export",
              icon: <LuDownload />,
              onClick: () =>
                _modalContext.open(
                  <ExportModal filter={{ parentId: effort.id }} />
                ),
            },
          ]
        : []),

      ...(_memberUtils.getCurrent(effort)
        ? [
            {
              text: "Notifications",
              icon: <LuBell />,
              onClick: () =>
                _memberUtils.openNotificationModal(
                  _memberUtils.getCurrent(effort)
                ),
            },
          ]
        : []),

      {
        icon: <LuPencil />,
        text: "Edit",
        onClick: _effortAuthUtils.canEdit([effort])
          ? () => _effortViewUtils.openCreateUpdateModal(effort)
          : undefined,
      },
      // {
      //   icon: <LuSettings />,
      //   text: "Settings",
      //   onClick: _effortAuthUtils.canEdit([effort])
      //     ? () => _effortViewUtils.openSettingModal(effort)
      //     : undefined,
      // },
      {
        icon: <LuTrash2 />,
        text: "Delete",
        onClick: _effortAuthUtils.canDelete([effort])
          ? () =>
              deleteButton.tryRemove({
                message: (
                  <>
                    You're about to delete "
                    <b className="capitalize">{effort.title}</b>".
                  </>
                ),
                onConfirm: async () => {
                  let nextUrl = parentId ? _effortUtils.makeUrl(parentId) : "/";
                  const _errors = await _effortViewUtils.onRemove([effort]);

                  if (isEmpty(_errors) && nextUrl) {
                    navigate(nextUrl);
                  }

                  return _errors;
                },
              })
          : undefined,
      },
    ];
  };

  return {
    getItems,
  };
}

export default function EffortMenuButton({
  effort,
  ellipsisClassName,
  children,
}: {
  effort: Effort;
  children?: React.ReactNode;
  ellipsisClassName?: string;
}) {
  const effortMenuButton = useEffortMenuButton(effort.parentId);

  return (
    <MenuButton
      ellipsisResponsiveBreakPoint={"always"}
      items={effortMenuButton.getItems(effort)}
      ellipsisClassName={ellipsisClassName}
      stopPropagation={false}
    >
      {children}
    </MenuButton>
  );
}
