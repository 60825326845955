import React from "react";

import Effort from "models/Effort";

import SectionBase from "./SectionBase";
import Cheeps from "components/common/Cheeps";

export default function TagsSection({ effort }: { effort: Effort }) {
  return (
    <SectionBase effort={effort} title="Tags" fieldName="tags">
      {effort.tags?.map((eachTag, i) => (
        <Cheeps key={"eachType" + i} borderColor={eachTag.color}>
          {eachTag.name}
        </Cheeps>
      ))}
    </SectionBase>
  );
}
