import React from "react";
import { useNavigate, useParams } from "react-router";

import { TabContents, TabItems } from "components/common/Tabs";

import MainSection from "components/layouts/MainSection";
import AccountSection from "components/pages/profile/account/AccountSection";
import SettingsSection from "components/pages/profile/SettingsSection";
import ThemeSection from "components/pages/profile/themeSection/ThemeSection";
import AuditLogSection from "components/pages/AuditLogSection/AuditLogSection";
import DeviceSection from "components/pages/profile/DeviceSection";
import { BsGear } from "react-icons/bs";
import { LuPalette, LuShield, LuUser } from "react-icons/lu";
import { RxMobile } from "react-icons/rx";

export const PROFILE_PAGE_TABS = [
  {
    icon: <LuUser />,
    exact: true,
    url: "",
    text: "Account",
    content: <AccountSection />,
  },
  {
    icon: <LuPalette />,
    url: "theme",
    text: "Theme",
    content: <ThemeSection />,
  },
  {
    icon: <BsGear />,
    url: "settings",
    text: "Notification Settings",
    content: <SettingsSection />,
  },
  {
    icon: <LuShield />,
    url: "auditlogs",
    text: "Audit Logs",
    content: <AuditLogSection />,
  },
  {
    icon: <RxMobile />,
    url: "devices",
    text: "Devices",
    content: <DeviceSection />,
  },
];

export default function ProfilePage() {
  const params = useParams();

  return (
    <MainSection title={"Profile"}>
      <div className="h-full ">
        <TabItems baseUrl="/profile/" items={PROFILE_PAGE_TABS} />

        <div className="p-2 md:p-3 ">
          <TabContents activeUrl={params.index} items={PROFILE_PAGE_TABS} />
        </div>
      </div>
    </MainSection>
  );
}
