import { UserLogFilter } from "models/UserLog";
import { hasOtherKeysExcept } from "services/UtilServices";



export default function useUserLogUtils() {
  const isFilterActive = ({ filter }: { filter: UserLogFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage", "effortIds"]);
  }

  return {
    isFilterActive
  }
}
