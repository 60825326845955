import React from "react";
import { default as ApexChart } from "react-apexcharts";

import Chart, { ChartData, ChartTypes } from "models/Chart";

import { useChartContext } from "providers/ChartProvider";
import { useThemeContext } from "providers/ThemeProvider";
import Skeleton from "components/common/Skeleton";
import EmptyListIndicator from "components/common/EmptyListIndicator";
import { ActivityFilter } from "models/Activity";
import useChartUtils from "hooks/utils/UseChartUtils";
import { isEmpty, sortedStringify } from "services/UtilServices";

function SkeletonSection({ chart }: { chart: Chart }) {
  return (
    <div className="flex items-center justify-center w-full h-full">
      {chart.type === ChartTypes.PIE ? (
        <Skeleton className=" w-48 h-48 rounded-full aspect-square" />
      ) : (
        <div className="flex items-end gap-1 ">
          <Skeleton className="w-10 h-10" />
          <Skeleton className="w-10 h-20" />
          <Skeleton className="w-10 h-5" />
          <Skeleton className="w-10 h-12" />
          <Skeleton className="w-10 h-14" />
          <Skeleton className="w-10 h-12" />
          <Skeleton className="w-10 h-5" />
        </div>
      )}
    </div>
  );
}

export default function ChartItem({
  chart,
  initFilter,
}: {
  chart: Chart;
  initFilter?: Partial<ActivityFilter>;
}) {
  const [data, setData] = React.useState<ChartData | null>();
  const { getData } = useChartContext();
  const { isDark } = useThemeContext();

  const _options = useChartUtils(chart);

  React.useEffect(() => {
    getData(chart, initFilter).then((res) => setData(res));
  }, [sortedStringify(chart), sortedStringify(initFilter)]);

  return (
    <div className="bg-card rounded border p-7 flex flex-col gap-4">
      <div className="flex items-center ">
        <h5 className="mb-0">{chart.title}</h5>
      </div>

      <div className="flex-1 min-h-[300px]">
        {data === undefined ? (
          <SkeletonSection chart={chart} />
        ) : data === null || isEmpty(data.labelList) ? (
          <EmptyListIndicator wrapperClassName="h-full" />
        ) : (
          <ApexChart
            key={JSON.stringify(chart)}
            type={_options.chart.type}
            series={_options.getSeries(data)}
            height={300}
            options={{
              labels: _options.getLabel?.(data),
              xaxis: _options.getXAxis?.(data),
              colors: _options.getColors(data),
              legend: {
                position: "bottom",
                offsetY: 0,
                // position: "right",
                // offsetY: 40,
              },
              fill: {
                opacity: 1,
              },
              theme: {
                mode: isDark ? "dark" : undefined,
              },
              stroke: {
                width: 2,
                ..._options.stroke,
              },
              markers: {
                size: 4,
              },
              // tooltip: {
              //   theme: isDark ? "dark" : undefined,
              // },
              chart: {
                // foreColor: isDark ? "#ffffff" : undefined,
                ..._options.chart,
                background: "#ffffff00",
                // stacked: true,
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: true,
                },
              },
              // responsive: [
              //   {
              //     breakpoint: 1440,
              //     options: {
              //       legend: {
              //         position: "bottom",
              //         offsetY: 0,
              //       },
              //     },
              //   },
              // ],
              plotOptions: {
                bar: {
                  ..._options.plotOptions,
                  dataLabels: {
                    total: {
                      enabled: true,
                      style: {
                        fontSize: "13px",
                        fontWeight: 900,
                        color: isDark ? "white" : undefined,
                      },
                    },
                  },
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
}
