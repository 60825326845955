import { hasOtherKeysExcept } from "services/UtilServices";
import {
  FaAndroid,
  FaApple,
  FaChrome,
  FaEdge,
  FaFirefox,
  FaOpera,
  FaSafari,
  FaUbuntu,
  FaWindows,
} from "react-icons/fa6";
import { z } from "zod";

export const CLIENT_INFOS = {
  chrome: {
    icon: <FaChrome />,
  },
  edge: {
    icon: <FaEdge />,
  },
  firefox: {
    icon: <FaFirefox />,
  },
  safari: {
    icon: <FaSafari />,
  },
  opera: {
    icon: <FaOpera />,
  },
};

export const DEVICE_INFOS = {
  windows: {
    icon: <FaWindows />,
  },
  ubuntu: {
    icon: <FaUbuntu />,
  },
  android: {
    icon: <FaAndroid />,
  },
  ios: {
    icon: <FaApple />,
  },
  macos: {
    icon: <FaOpera />,
  },
};

export type DeviceSortType = "name" | "dateadd";

export const DeviceSortTypes: { [key: string]: DeviceSortType } = {
  dateadd: "dateadd",
  name: "name",
};

export const deviceSortEnum = z.enum(["name", "dateadd"]);
export const DeviceFilterSchema = z.object({
  pageNumber: z.number(),
  sortBy: deviceSortEnum,
  ascOrder: z.boolean(),
  numberInPage: z.number().optional(),

  name: z.string().optional(),
  dateAddFrom: z.date().optional(),
  dateAddTo: z.date().optional(),
});
export type DeviceFilter = z.infer<typeof DeviceFilterSchema>;
// export interface DeviceFilter {
//   pageNumber: number;
//   sortBy: DeviceSortType;
//   ascOrder: boolean;
//   numberInPage?: number;

//   name?: string;
//   dateAddFrom?: Date;
//   dateAddTo?: Date;
// }

export const deviceSchema = z.object({
  userId: z.string(),
  loginDate: z.string(),
  loginKey: z.string(),
  loginProvider: z.string(),
  device: z.string(),
  client: z.string(),
  ipv4Address: z.string(),
});

type Device = z.infer<typeof deviceSchema>;
export default Device;

// export default interface Device {
//   userId: string;
//   loginDate: string;
//   loginKey: string;
//   loginProvider: string;
//   device: string;
//   client: string;
//   ipv4Address: string;
// }
