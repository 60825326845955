import React from "react";

import Type, { TypeFilter, TypeSortType } from "models/Type";
import Dict from "models/Dict";

import {
  cn,
  dateGetTime,
  hasOtherKeysExcept,
  isEmpty,
} from "services/UtilServices";

import { useModalContext } from "providers/ModalProvider";
import { useTypesContext, TypesProvider } from "providers/TypesProvider";

import TypeFilterModal from "./TypeFilterModal";
import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import TypeCreateUpdateModal from "./TypeCreateUpdateModal";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import NewButton from "components/common/AdminSection/NewButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import LocalDateTime from "components/common/LocalDateTime";
import useTypeUtils from "hooks/utils/UseTypeUtils";
import ItemsListMainSection from "components/common/ItemsListSection/ItemsListMainSection";

function EachTypeItem({ type, index }: { type: Type; index: number }) {
  return (
    <div className="flex items-center ">
      <div>
        <h6 className="mb-0 "> {index + 1} </h6>
      </div>

      <div
        className={cn(
          "rounded-full aspect-square mx-auto my-auto ms-2 me-2 mb-1 h-5 w-5 "
        )}
        style={{
          background: type.color,
        }}
      />

      <div>
        <h6 className="mb-0 me-2">{type.name}</h6>
      </div>
    </div>
  );
}

function TypesPageBody() {
  const _modalContext = useModalContext();
  const _typesContext = useTypesContext();
  const typeUtils = useTypeUtils();

  const onRemove = async (datas: Type[]) => {
    _typesContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _typesContext.update(data)
        : await _typesContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _modalContext.open(
      <TypeCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    _typesContext.get({
      ...formData,
      pageNumber: -1,
    } as TypeFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <TypeFilterModal
        filter={_typesContext.filter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  return (
    <ItemsListMainSection<Type>
      title="Types"
      data={_typesContext.types}
      // ellipsisResponsiveBreakPoint=""

      fields={[
        // {
        //   value: "",
        //   label: "#",
        //   className: "text-start whitespace-nowrap",
        //   width: "70px",
        //   isSortable: false,
        //   getUiContent: (e, i) => i + 1,
        // },

        {
          value: "name",
          label: "name",
          isTitleField: true,
          width: "auto",
          // isSortable: false,
          className: "whitespace-nowrap",
        },

        {
          value: "color",
          label: "color",
          width: "10%",
          isSortable: false,
          isSearchable: false,
          getUiContent: (e, i) => (
            <div
              className={cn("rounded-full aspect-square w-7 h-7 ")}
              style={{
                background: e.color,
              }}
            />
          ),
        },

        {
          value: "dateAdd",
          label: "Created on",
          width: "auto",
          // isSortable: false,
          getValue: (e) => dateGetTime(e.dateAdd),
          getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
          className: "whitespace-nowrap",
        },
      ]}
      onLoadMore={
        !_typesContext.hasNext
          ? undefined
          : async () => {
              await _typesContext.get({
                ..._typesContext.filter,
                pageNumber: _typesContext.filter.pageNumber + 1,
              });
            }
      }
      onSortField={(v) => {
        _typesContext.get({
          ..._typesContext.filter,
          sortBy: v as TypeSortType,
          pageNumber: -1,
        });
      }}
      onSortDir={(v) => {
        _typesContext.get({
          ..._typesContext.filter,
          ascOrder: v === 1,
          pageNumber: -1,
        });
      }}
      initialSelectedSortFieldValue="dateAdd"
      buildCardTitleRow={(v, i) => <EachTypeItem type={v} index={i} />}
      onItemEdit={(u) => openCreateUpdateModal}
      onItemClick={(u) => openCreateUpdateModal}
      onItemDelete={() => _typesContext.removeMany}
      actionsSection={
        <>
          <FilterButton
            count={_typesContext.totalNumber}
            isActive={typeUtils.isFilterActive({
              filter: _typesContext.filter,
            })}
            onClick={openFilterModal}
          />

          <NewButton onClick={() => openCreateUpdateModal()} />
        </>
      }
    />
  );
}

export default function TypesPage() {
  return (
    <TypesProvider>
      <TypesPageBody />
    </TypesProvider>
  );
}
