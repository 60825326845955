
import Department, { DepartmentFilter } from 'models/Department';
import Dict from 'models/Dict';
import { dataMapper } from 'services/UtilServices';
import useApiServices from './UseApiServices';
import { BASE_URL as EFFORT_BASE_URL } from './UseEffortApi';

export const BASE_URL = "accounts/";

export default function useDeviceApi() {

  const apiServices = useApiServices();

  const get = async () => {
    const _response = await apiServices.send({
      url: BASE_URL + "logs/logins",
      method: "get",
      expirationDurationMinutes: 2,
    });

    return _response.logins;
  }


  const logoutMany = async (loginKeyList: string[]) => {
    await apiServices.send({
      url: BASE_URL + "logout",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: { loginKeyList }
    });
  }

  return {
    get,
    logoutMany,
  };
}

