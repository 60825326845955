import React from "react";

import { useThemeContext } from "providers/ThemeProvider";

import FormFieldBase, { FormFieldBaseInputProps } from "./FormFieldBase";
import { cn } from "services/UtilServices";
import { Label } from "components/ui/label";

function SwitchBody({ isChecked }: { isChecked?: boolean | null }) {
  const _themeContext = useThemeContext();

  return (
    <div
      className={cn(
        "rounded-full flex items-center transition-all duration-100 border border-slate-300 h-7 w-12 ",
        isChecked ? "bg-primary" : "bg-muted"
      )}
    >
      <div
        className={cn(
          "rounded-full aspect-square border border-slate-700 dark:border-slate-300 shadow transition-all duration-100 bg-background w-6 h-6 ",
          {
            "mx-auto": isChecked === undefined || isChecked === null,
            "ms-auto": isChecked,
          }
        )}
      >
        <div className="rounded-full aspect-square border w-full h-full flex items-center justify-center">
          {/* {isChecked === undefined || isChecked === null ? (
            "" // <FaQuestion className="text-secondary-foreground !text-sm" />
          ) : isChecked ? (
            <LuCheck className="text-secondary-foreground !text-sm" />
          ) : (
            <LuX className="text-secondary-foreground !text-sm" />
          )} */}
        </div>
      </div>
    </div>
  );
}

interface Props extends FormFieldBaseInputProps {
  wrapperClassName?: string;
}

export default function Switch(props: Props) {
  return (
    <FormFieldBase {...props} needLabel={false}>
      {(baseProps) => {
        const isChecked = baseProps.value; // (baseProps.value ?? false) as boolean;

        return (
          <div className="flex items-center">
            <Label
              className={cn(
                "cursor-pointer inline-flex items-center flex-1 gap-2 me-2 ",
                props.wrapperClassName
              )}
              onClick={() => baseProps.setData({ [props.name]: !isChecked })}
            >
              <SwitchBody isChecked={isChecked} />
              <span className="capitalize">{baseProps.label}</span>
            </Label>

            {baseProps.nullableBtn}
          </div>
        );
      }}
    </FormFieldBase>
  );
}
