import React from "react";

import Dict from "models/Dict";
import { UserFilter, UserRoles } from "models/User";

import { objList2Options } from "services/UtilServices";

import {
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";
import { useOfficesContext } from "providers/OfficesProvider";
import FilterModalResetButton from "components/common/AdminSection/FilterModalResetButton";
import { DatePickerInputRange } from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useUserUtils from "hooks/utils/UseUserUtils";

export default function UserFilterModal({
  filter,
  onSubmit,
}: {
  filter: UserFilter;
  onSubmit: (data: Dict) => void;
}) {
  const _officesContext = useOfficesContext();
  const _userUtils = useUserUtils();

  return (
    <DialogContent title="Filter">
      <Form data={filter} onSubmit={onSubmit}>
        <div className="flex gap-2">
          <div className="flex-1">
            <Input name="userName" label="Username" maxLength={256} />
          </div>
          <div className="flex-1">
            <Input name="phone" type="number" />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="flex-1">
            <Input name="firstName" label="Firstname" maxLength={256} />
          </div>
          <div className="flex-1">
            <Input name="lastName" label="Lastname" maxLength={256} />
          </div>
        </div>

        <Input
          name="email"
          label="Email Address"
          type="email"
          placeholder="example@email.com"
          maxLength={256}
        />

        <div className="flex gap-2">
          <div className="flex-1">
            <Select
              name="officeId"
              label="Office"
              options={objList2Options(_officesContext.offices)}
            />
          </div>
          <div className="flex-1">
            <Select name="role" options={UserRoles.toLabelValues()} value="" />
          </div>
        </div>

        <DatePickerInputRange name="dateAdd" label="Date Add" />

        <DialogFooter>
          <SubmitButton className="mx-0" />

          <FilterModalResetButton
            isFilterActive={_userUtils.isFilterActive({ filter })}
            onClick={() => onSubmit({ pageNumber: -1 })}
          />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
