import { hasOtherKeysExcept } from "services/UtilServices";
import Department, { departmentSchema } from "./Department";
import Effort, { effortSchema } from "./Effort";
import { z } from "zod";


export type TagSortType = "name" | "dateadd";


export const TagSortTypes: { [key: string]: TagSortType } = {
  // DEPARTMENT: "DEPARTMENT",
  name: "name",
  // COLOR: "COLOR",
  dateadd: "dateadd",
};

export interface TagFilter {

  "pageNumber": number,
  "sortBy": TagSortType,
  "ascOrder": boolean,
  numberInPage?: number,

  departmentId?: string,
  name?: string,
  color?: string,

  dateAddFrom?: Date,
  dateAddTo?: Date,
}


export const tagSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string(),
  dateAdd: z.string(),
});

type Tag = z.infer<typeof tagSchema>;
export default Tag;


// export default interface Tag {
//   id: number,
//   name: string,
//   color: string,
//   department?: Department,
//   departmentId?: string,
//   efforts?: Effort[],
//   dateAdd: string,
// }

