import React from "react";

import User, { UserFilter, UserRoles, UserSortType } from "models/User";
import Dict from "models/Dict";

import { dateGetTime, isEmpty } from "services/UtilServices";

import { useUsersContext, UsersProvider } from "providers/UsersProvider";
import { useModalContext, ModalProvider } from "providers/ModalProvider";
import { useOfficesContext, OfficesProvider } from "providers/OfficesProvider";

import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import UserCreateUpdateModal from "./UserCreateUpdateModal";
import UserFilterModal from "./UserFilterModal";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import NewButton from "components/common/AdminSection/NewButton";
import FilterButton from "components/common/AdminSection/FilterButton";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import LocalDateTime from "components/common/LocalDateTime";
import useUserUtils from "hooks/utils/UseUserUtils";
import ItemsListMainSection from "components/common/ItemsListSection/ItemsListMainSection";

function EachUserItem({ user, index }: { user: User; index: number }) {
  const _userUtils = useUserUtils();

  return (
    <div className="flex items-center px-1">
      <h6 className="mb-0"> {index + 1} </h6>

      <div className="flex-1 ps-3">
        <div className="flex p-0 ">
          <ProfileAvatar user={user} needName />
          {/* <h6 className="mb-0 my-auto text-justify me-auto">{user.userName}</h6> */}
        </div>
      </div>
    </div>
  );
}

function UsersPageBody() {
  const _modalContext = useModalContext();
  const _usersContext = useUsersContext();
  const _officesContext = useOfficesContext();
  const _userUtils = useUserUtils();

  const onRemove = async (datas: User[]) => {
    _usersContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _usersContext.update(data)
        : await _usersContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _officesContext.getAll();

    _modalContext.open(
      <UserCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    _usersContext.get({
      ...formData,
      pageNumber: -1,
    } as UserFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _officesContext.getAll();

    _modalContext.open(
      <UserFilterModal
        filter={_usersContext.filter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  return (
    <ItemsListMainSection<User>
      title="Users"
      data={_usersContext.users}
      // ellipsisResponsiveBreakPoint=""

      fields={[
        // {
        //   value: "",
        //   label: "#",
        //   className: "text-start whitespace-nowrap",
        //   width: "70px",
        //   isSortable: false,
        //   getUiContent: (e, i) => i + 1,
        // },

        {
          value: "userName",
          label: "Username",
          isTitleField: true,
          width: "auto",
          className: "whitespace-nowrap",
          // isSortable: false,
        },

        {
          value: "avatarUrl",
          label: "avatar",
          width: "10%",
          isSortable: false,
          getUiContent: (e, i) => <ProfileAvatar user={e} />,
        },

        {
          value: "firstName",
          label: "Firstname",
          width: "auto",
          className: "whitespace-nowrap",
          // isSortable: false,
        },

        {
          value: "lastName",
          label: "Lastname",
          width: "auto",
          className: "whitespace-nowrap",
          // isSortable: false,
        },

        {
          value: "role",
          label: "Role",
          width: "auto",
          getUiContent: (e, i) => UserRoles.options[e.role],
          className: "whitespace-nowrap",
          // isSortable: false,
        },

        {
          value: "email",
          label: "email",
          width: "auto",
          className: "whitespace-nowrap",
          // isSortable: false,
        },

        {
          value: "office",
          label: "Office",
          width: "auto",
          // isSortable: false,
          getUiContent: (e, i) => e.office?.name,
          className: "whitespace-nowrap",
        },

        {
          value: "dateAdd",
          label: "Created On",
          width: "auto",
          // isSortable: false,
          getValue: (e) => dateGetTime(e.dateAdd),
          getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
          className: "whitespace-nowrap",
        },
      ]}
      onLoadMore={
        !_usersContext.hasNext
          ? undefined
          : async () => {
              await _usersContext.get({
                ..._usersContext.filter,
                pageNumber: _usersContext.filter.pageNumber + 1,
              });
            }
      }
      onSortField={(v) => {
        _usersContext.get({
          ..._usersContext.filter,
          sortBy: v as UserSortType,
          pageNumber: -1,
        });
      }}
      onSortDir={(v) => {
        _usersContext.get({
          ..._usersContext.filter,
          ascOrder: v === 1,
          pageNumber: -1,
        });
      }}
      initialSelectedSortFieldValue="dateAdd"
      buildCardTitleRow={(v, i) => <EachUserItem user={v} index={i} />}
      // buildCardBodyItem={(v, i) => <EachUserItem user={v} index={i} />}

      onItemEdit={(u) => openCreateUpdateModal}
      onItemClick={(u) => openCreateUpdateModal}
      onItemDelete={() => _usersContext.removeMany}
      actionsSection={
        <>
          <FilterButton
            count={_usersContext.totalNumber}
            isActive={_userUtils.isFilterActive({
              filter: _usersContext.filter,
            })}
            onClick={openFilterModal}
          />

          <NewButton onClick={() => openCreateUpdateModal()} />
        </>
      }
    />
  );
}

export default function UsersPage() {
  return (
    <OfficesProvider>
      <UsersProvider>
        <ModalProvider>
          <UsersPageBody />
        </ModalProvider>
      </UsersProvider>
    </OfficesProvider>
  );
}
