import React from "react";

import Department, {
  DepartmentFilter,
  DepartmentSortType,
} from "models/Department";
import Dict from "models/Dict";

import { dateGetTime, isEmpty } from "services/UtilServices";

import {
  useDepartmentsContext,
  DepartmentsProvider,
} from "providers/DepartmentsProvider";
import { useModalContext, ModalProvider } from "providers/ModalProvider";
import { useTagsContext, TagsProvider } from "providers/TagsProvider";
import { useTypesContext, TypesProvider } from "providers/TypesProvider";
import { useStatesContext, StatesProvider } from "providers/StatesProvider";

import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import DepartmentCreateUpdateModal from "./DepartmentCreateUpdateModal";
import DepartmentFilterModal from "./DepartmentFilterModal";
import Cheeps from "components/common/Cheeps";
import Truncate from "components/common/Truncate";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import FilterButton from "components/common/AdminSection/FilterButton";
import NewButton from "components/common/AdminSection/NewButton";
import LocalDateTime from "components/common/LocalDateTime";
import useDepartmentUtils from "hooks/utils/UseDepartmentUtils";
import ItemsListMainSection from "components/common/ItemsListSection/ItemsListMainSection";

function EachDepartmentItem({
  department,
  index,
}: {
  department: Department;
  index: number;
}) {
  return (
    <div className="flex items-center">
      <div>
        <h6 className="mb-0 "> {index + 1} </h6>
      </div>

      <div>
        <h6 className="mb-0 me-2 ms-4">{department.name}</h6>
      </div>
    </div>
  );
}

function DepartmentsPageBody() {
  const _modalContext = useModalContext();
  const _departmentsContext = useDepartmentsContext();
  const departmentUtils = useDepartmentUtils();

  const _tagsContext = useTagsContext();
  const _typesContext = useTypesContext();
  const _statesContext = useStatesContext();

  const onRemove = async (datas: Department[]) => {
    _departmentsContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _departmentsContext.update(data)
        : await _departmentsContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const openCreateUpdateModal = (formData?: Dict) => {
    _tagsContext.getAll();
    _typesContext.getAll();
    _statesContext.getAll();

    _modalContext.open(
      <DepartmentCreateUpdateModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const onFilterSubmit = (formData: Dict) => {
    _departmentsContext.get({
      ...formData,
      pageNumber: -1,
    } as DepartmentFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <DepartmentFilterModal
        filter={_departmentsContext.filter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  return (
    <ItemsListMainSection<Department>
      title="Departments"
      data={_departmentsContext.departments}
      // ellipsisResponsiveBreakPoint=""

      fields={[
        // {
        //   value: "",
        //   label: "#",
        //   className: "text-start whitespace-nowrap",
        //   width: "70px",
        //   isSortable: false,
        //   getUiContent: (e, i) => i + 1,
        // },

        {
          value: "name",
          label: "name",
          isTitleField: true,
          width: "auto",
          // isSortable: false,
          className: "whitespace-nowrap",
        },

        {
          value: "stats",
          label: "states",
          width: "auto",
          isSortable: false,
          getUiContent: (p) => (
            <Truncate>
              {p.states?.map((e, i) => (
                <Cheeps key={"eachState" + i} borderColor={e.color}>
                  {e.name}
                </Cheeps>
              ))}
            </Truncate>
          ),
          // getValue: (e) => e.tag.name,
        },

        {
          value: "tags",
          label: "tags",
          width: "auto",
          isSortable: false,
          getUiContent: (p) => (
            <Truncate>
              {p.tags?.map((e, i) => (
                <Cheeps key={"eachTag" + i} borderColor={e.color}>
                  {e.name}
                </Cheeps>
              ))}
            </Truncate>
          ),
          // getValue: (e) => e.tags.name,
        },

        {
          value: "types",
          label: "types",
          width: "auto",
          isSortable: false,
          getUiContent: (p) => (
            <Truncate>
              {p.types?.map((e, i) => (
                <Cheeps key={"eachType" + i} borderColor={e.color}>
                  {e.name}
                </Cheeps>
              ))}
            </Truncate>
          ),
          // getValue: (e) => e.tag.name,
        },

        {
          value: "dateAdd",
          label: "Created on",
          width: "auto",
          // isSortable: false,
          getValue: (e) => dateGetTime(e.dateAdd),
          getUiContent: (e, i) => <LocalDateTime date={e.dateAdd} />,
          className: "whitespace-nowrap",
        },
      ]}
      onLoadMore={
        !_departmentsContext.hasNext
          ? undefined
          : async () => {
              await _departmentsContext.get({
                ..._departmentsContext.filter,
                pageNumber: _departmentsContext.filter.pageNumber + 1,
              });
            }
      }
      onSortField={(v) => {
        _departmentsContext.get({
          ..._departmentsContext.filter,
          sortBy: v as DepartmentSortType,
          pageNumber: -1,
        });
      }}
      onSortDir={(v) => {
        _departmentsContext.get({
          ..._departmentsContext.filter,
          ascOrder: v === 1,
          pageNumber: -1,
        });
      }}
      initialSelectedSortFieldValue="dateAdd"
      buildCardTitleRow={(v, i) => (
        <EachDepartmentItem key={v.name + i} department={v} index={i} />
      )}
      onItemEdit={(u) => openCreateUpdateModal}
      onItemClick={(u) => openCreateUpdateModal}
      onItemDelete={() => _departmentsContext.removeMany}
      actionsSection={
        <>
          <FilterButton
            count={_departmentsContext.totalNumber}
            isActive={departmentUtils.isFilterActive({
              filter: _departmentsContext.filter,
            })}
            onClick={openFilterModal}
          />

          <NewButton onClick={() => openCreateUpdateModal()} />
        </>
      }
    />
  );
}

export default function DepartmentsPage() {
  return (
    <DepartmentsProvider>
      <TagsProvider>
        <StatesProvider>
          <TypesProvider>
            <ModalProvider>
              <DepartmentsPageBody />
            </ModalProvider>
          </TypesProvider>
        </StatesProvider>
      </TagsProvider>
    </DepartmentsProvider>
  );
}
