import { z } from "zod";
import { typeSchema } from "./Type";
import { userSchema } from "./User";
import { ticketCountSchema } from "./TicketCount";
import { textEditorTypeSchema } from "components/common/Forms/TextEditor";


export type ActivitySortType = "duration" | "dateadd" | "datedone" | "user" | "type"

export const ActivitySortTypes: { [key: string]: ActivitySortType } = {

  duration: "duration",
  dateadd: "dateadd",
  datedone: "datedone",
  user: "user",
  type: "type",

};

export const approverSchema = z.object({
  dateCreated: z.string(),
  isApproved: z.boolean(),
  user: userSchema,
});
export type ActivityApproverSchema = z.infer<typeof approverSchema>;



export interface ActivityFilter {
  effortIdList?: number[],
  typeIdList?: number[],
  userIdList?: string[],


  "pageNumber": number,
  "sortBy": ActivitySortType,
  "ascOrder": boolean,
  numberInPage?: number,

  "createdOnFrom"?: Date,
  "createdOnTo"?: Date,

  // "userId"?: number,
  // "typeId"?: number,

  "dateAddFrom"?: string,
  "dateAddTo"?: string,

  "dateDoneFrom"?: string,
  "dateDoneTo"?: string,

  "isApproved"?: boolean,
  // "ticketApproved"?: boolean,
  // "ticketIdList": number[],
  // "approverIdList": number[],
  // "description": string,
  // "recordStatus": number,
  // "durationFrom": number,
  // "durationTo": number,
  // "dateApprovedFrom":string,
  // "dateApprovedTo": string,

}



export const activitySchema = z.object({
  id: z.string(),
  effortId: z.number(),
  isApproved: z.boolean(),
  duration: z.string(),
  dateAdd: z.string(),
  dateDone: z.string(),
  description: textEditorTypeSchema.optional(),

  ticketsCount: ticketCountSchema.array(),

  userId: z.string(),
  user: userSchema,

  typeId: z.string(),
  type: typeSchema,
  approverList : approverSchema.array()
});

type Activity = z.infer<typeof activitySchema>;
export default Activity;

// export default interface Activity {
//   id: string,
//   effortId: number,

//   ticketsCount: TicketCount[],
//   // ticketApproved?: TicketCount[],

//   isApproved: boolean,
  
//   duration: string,
//   dateAdd: string,
//   dateDone: string,


//   userId: string,
//   user: User,

//   typeId: string,
//   type: Type,

//   description?: string,

// }

