import React from "react";

import Dict from "models/Dict";

import { useCurrentUserContext } from "providers/CurrentUserProvider";

import { Form, Input, SubmitButton } from "components/common/Forms/Form";
import { isEmpty } from "services/UtilServices";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useValidation from "components/common/Forms/UseValidation";

export default function PasswordEditModal({
  onSubmit,
}: {
  onSubmit: () => void;
}) {
  const _currentUserContext = useCurrentUserContext();
  const validation = useValidation();

  const _onSubmit = async (d: Dict) => {
    let _errors = await _currentUserContext.updatePassword(d);

    if (isEmpty(_errors)) {
      onSubmit();
    }

    return _errors;
  };

  return (
    <DialogContent title={"Update Password"} size={"sm"}>
      <Form onSubmit={_onSubmit}>
        <Input
          name="oldPassword"
          label="Old Password"
          type="password"
          required
        />
        <Input
          name="password"
          type="password"
          required
          validations={[validation.tooSimplePassword()]}
        />
        <Input
          name="repassword"
          type="password"
          required
          validations={[validation.tooSimplePassword()]}
        />

        <DialogFooter>
          <SubmitButton>Update</SubmitButton>
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
