import { hasOtherKeysExcept } from "services/UtilServices";
import Dict from "./Dict";
import { z } from "zod";
import { userSchema } from "./User";






export type AuditLogActionType = "Login" |
  "LogOut" |
  "TotalLogOut" |
  "WrongPassword" |
  "ChangePassword" |
  "ChangeEmail" |
  "ChangeUsername" |
  "TimedOut"





export type AuditLogSortType = "userId" |
  "loginProvider" |
  "providerKey" |
  "providerDisplayName" |
  "ipv4Address" |
  "client" |
  "device" |
  "refreshToken" |
  "identityActionType" |
  "dateCreated"


export const AuditLogSortType: { [key: string]: AuditLogSortType } = {

  userId: "userId",
  loginProvider: "loginProvider",
  providerKey: "providerKey",
  providerDisplayName: "providerDisplayName",
  ipv4Address: "ipv4Address",
  client: "client",
  device: "device",
  refreshToken: "refreshToken",
  identityActionType: "identityActionType",
  dateCreated: "dateCreated",

};

export interface AuditLogFilter {
  "pageNumber": number,
  "sortBy": AuditLogSortType,
  "ascOrder": boolean,
  numberInPage?: number,

  userIds?: string[] | null,
  actionType?: string,
  dateFrom?: string,
  dateTo?: string
}

export class AuditLogActionTypes {
  static Login = "Login";
  static LogOut = "LogOut";
  static TotalLogOut = "TotalLogOut";
  static WrongPassword = "WrongPassword";
  static ChangePassword = "ChangePassword";
  static ChangeEmail = "ChangeEmail";
  static ChangeUsername = "ChangeUsername";
  static TimedOut = "TimedOut";

  static options: Dict = {
    "Login": "Login",
    "LogOut": "LogOut",
    "TotalLogOut": "TotalLogOut",
    "WrongPassword": "WrongPassword",
    "ChangePassword": "ChangePassword",
    "ChangeEmail": "ChangeEmail",
    "ChangeUsername": "ChangeUsername",
    "TimedOut": "TimedOut",
  };

  static toLabelValues = () =>
    Object.entries(AuditLogActionTypes.options).map(
      ([key, val]) => ({
        value: key,
        label: val,
      })
    );
}



export const auditLogSchema = z.object({
  id: z.string(),
  loginProvider: z.string(),
  providerKey: z.string(),
  providerDisplayName: z.string(),
  ipv4Address: z.string(),
  client: z.string(),
  device: z.string(),
  refreshToken: z.string().optional(),
  identityActionType: z.string(),
  dateCreated: z.string(),

  userId: z.string(),
}).extend({
  user: z.lazy(() => userSchema),
});

type AuditLog = z.infer<typeof auditLogSchema>;
export default AuditLog;

// export default interface AuditLog {
//   id: string,
//   loginProvider: string,
//   providerKey: string,
//   providerDisplayName: string,
//   ipv4Address: string,
//   client: string,
//   device: string,
//   refreshToken?: string,
//   identityActionType: string,
//   dateCreated: string,

//   userId: string,
//   user: User,
// }



