import React from "react";
import { useNavigate } from "react-router";

import Effort from "models/Effort";
import { useEffortAuthUtils, useEffortUtils } from "hooks/utils/UseEffortUtils";

import TagsSection from "./TagsSection";
import TypesSection from "./TypesSection";
import StatesSection from "./StatesSection";
import PrefixsSection from "./PrefixsSection";
import TicketsAvailableSection from "./TicketsAvailableSection";
import Skeleton from "components/common/Skeleton";
import PrioritiesSection from "./PrioritiesSection";
import MembersSection from "../MembersSection/MembersSection";
import { MobileFooterPadding } from "components/layouts/MainFooter";
import { CollapsableTextEditorValue, TextEditorValue } from "components/common/Forms/TextEditor";
import AttachmentsSection from "../AttachmentsSection/AttachmentsSection";
import ProfileAvatar from "components/pages/profile/account/ProfileAvatar";
import useSingleFieldUpdate, {
  SingleFieldUpdatePen,
} from "components/pages/EffortsSection/SingleFieldUpdateButton";
import LocalDateTime from "components/common/LocalDateTime";
import TicketsCountSection from "./TicketsCountSection";
import EffortMenuButton from "../EffortMenuButton";
import { LuPencil } from "react-icons/lu";
import { Button } from "components/ui/button";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";

function SettingSectionSkeleton() {
  return (
    <div className="w-full">
      <div className="flex items-center gap-2 mb-2">
        <Skeleton className="rounded-full aspect-square w-9" />
        <Skeleton className="w-1/4 h-5" />
        <div className="flex-1"></div>
        <Skeleton className="w-10 h-5" />
      </div>

      <Skeleton count={6} className="w-full h-20 mb-1" />
    </div>
  );
}

export default function SettingSection({ effort }: { effort: Effort }) {
  const _effortUtils = useEffortUtils();
  const singleFieldUpdate = useSingleFieldUpdate();
  const mediaQuery = useMediaQuery();
  const _effortAuthUtils = useEffortAuthUtils();

  return (
    <div>
      {!effort.creator ? (
        <SettingSectionSkeleton />
      ) : (
        <div className="flex flex-col gap-2 ">
          <div className="flex items-center text-sm w-full text-foreground">
            {Boolean(mediaQuery > MediaQuery2Num.md && effort.level < 2) && (
              <EffortMenuButton effort={effort} />
            )}

            {effort.creator ? (
              <span>
                <ProfileAvatar user={effort.creator} needName />
              </span>
            ) : (
              <Skeleton />
            )}

            <div className="flex-1"></div>
            <LocalDateTime date={effort.dateAdd} className="text-xs" />
          </div>

          <div>
            {effort.description?.content || effort.description?.attachments ? (
              <div className="bg-card p-2 rounded border relative group ">
                <CollapsableTextEditorValue value={effort.description} />
                <SingleFieldUpdatePen
                  effort={effort}
                  fieldName="description"
                  variant={"faded"}
                  className="absolute right-0 top-0 bg-muted aspect-square rounded-full border"
                />
              </div>
            ) : (
              _effortAuthUtils.canEdit([effort]) && (
                <Button
                  variant={"faded"}
                  onClick={() =>
                    singleFieldUpdate.onClick({
                      effort,
                      fieldName: "description",
                    })
                  }
                  className="text-sm p-4 border bg-muted w-full"
                >
                  Add Description <LuPencil className="!text-base" />
                </Button>
              )
            )}
          </div>

          <TicketsCountSection effort={effort} />
          <MembersSection effort={effort} />
          <AttachmentsSection effort={effort} />

          {_effortUtils.isProject(effort) && (
            <>
              <StatesSection effort={effort} />
              <PrefixsSection effort={effort} />
              <PrioritiesSection effort={effort} />
              <TicketsAvailableSection effort={effort} />
            </>
          )}

          <TypesSection effort={effort} />
          <TagsSection effort={effort} />
        </div>
      )}

      <MobileFooterPadding />
    </div>
  );
}
