import React from "react";

import { cn, isBright } from "services/UtilServices";
import Skeleton from "./Skeleton";

export default function Cheeps({
  color,
  children,
  className,
  borderColor,
}: {
  children?: React.ReactNode;
  color?: string;
  className?: string;
  borderColor?: string;
}) {
  return children ? (
    <span
      className={cn(
        "text-xs whitespace-nowrap rounded-full px-2 py-1 border capitalize inline-flex items-center justify-center ",
        color
          ? isBright(color)
            ? "text-dark"
            : "text-white"
          : "bg-muted text-muted-foreground",
        className
      )}
      style={{
        background: color,
        border: borderColor ? `1px solid ${borderColor}` : undefined,
      }}
    >
      {children}
    </span>
  ) : (
    <Skeleton />
  );
}
