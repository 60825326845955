import React from "react";

import {
  CurrentUserContext,
  useCurrentUserContext,
} from "./CurrentUserProvider";

import useDeviceApi from "hooks/api/UseDeviceApi";
import Device from "models/Device";
import { AxiosError } from "axios";
import Dict from "models/Dict";

interface DevicesContextProps {
  devices: Device[] | undefined;
  get: () => Promise<void>;
  logoutMany: (items: Dict[]) => Promise<Dict>;
}

const DevicesContext = React.createContext({} as DevicesContextProps);
DevicesContext.displayName = "DevicesContext";

function DevicesProvider({ children }: { children: React.ReactNode }) {
  const deviceApi = useDeviceApi();

  const itemsRef = React.useRef<Device[] | undefined>(undefined);
  const [_items, _setDevices] = React.useState<Device[]>();

  React.useEffect(() => {
    // get();
  }, []);

  const setDevices = (o?: Device[]) => {
    itemsRef.current = o;
    _setDevices(o);
  };

  const get = async () => {
    // if(!_currentUserContext.isSudo())
    // {
    //   setDevices();
    //   return;
    // }

    try {
      let response = await deviceApi.get();

      setDevices([
        ...(itemsRef.current ?? []),
        ...((response as Device[]) ?? []),
      ]);
    } catch (e) {
      if (itemsRef.current === undefined) {
        setDevices([]);
      }
    }
  };

  const logoutMany = async (itemsToDelete: Device[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.loginKey);

      await deviceApi.logoutMany(ids);

      setDevices(itemsRef.current?.filter((e) => !ids.includes(e.loginKey)));
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  return (
    <DevicesContext.Provider
      value={
        {
          devices: itemsRef.current,

          get,
          logoutMany,
        } as DevicesContextProps
      }
    >
      {children}
    </DevicesContext.Provider>
  );
}

export function useDevicesContext() {
  const _context = React.useContext(DevicesContext);

  if (!_context) {
    throw new Error("cannot use DevicesContext outside of its provider.");
  }

  return _context;
}

export { DevicesContext, DevicesProvider };
export type { DevicesContextProps };
