import React from "react";
import { CirclePicker } from "react-color";

import useApiServices from "hooks/api/UseApiServices";

import { useThemeContext, ThemeUtils } from "providers/ThemeProvider";
import { useCurrentUserContext } from "providers/CurrentUserProvider";

import { MobileFooterPadding } from "components/layouts/MainFooter";
import { useToast } from "components/ui/use-toast";
import { Button } from "components/ui/button";
import { LuCheck, LuPlus } from "react-icons/lu";
import { COLORS } from "components/common/Forms/ColorPicker";
import DarkModeSection from "./DarkModeSection";

function SelectColor({ name }: { name: string }) {
  const _themeContext = useThemeContext();

  return (
    <div className="mb-4">
      <h6 className="mb-3 text-foreground">Theme Color</h6>

      <CirclePicker
        width="100%"
        color={_themeContext.themeColor}
        colors={COLORS}
        onChangeComplete={(d) => _themeContext.setThemeColor(d.hex)}
      />
    </div>
  );
}

function EachSelectBgImageTile({
  eachBgImage = "",
  onSelect = (d: string) => {},
  isSelected = false,
}) {
  const _themeContext = useThemeContext();
  const _overlayColor = _themeContext.isDark ? "#00000077" : "#ffffff77";

  return (
    <Button
      variant={"light"}
      onClick={() => onSelect(eachBgImage)}
      className=" rounded relative flex items-center justify-center border w-20 h-20 bg-cover bg-center bg-no-repeat bg-foreground/50"
      style={{
        backgroundImage: `linear-gradient(to top, ${
          isSelected
            ? _overlayColor + ", " + _overlayColor
            : "transparent, transparent"
        }), 
                          url('${ThemeUtils.addBaseUrl(eachBgImage)}')`,
      }}
    >
      {isSelected && <LuCheck />}
    </Button>
  );
}

function SelectBgImage({ name }: { name: string }) {
  const _themeContext = useThemeContext();

  let initialBgs = [...ThemeUtils.INITIAL_BG_IMAGES];
  if (_themeContext.bgImage && !initialBgs.includes(_themeContext.bgImage)) {
    initialBgs.push(_themeContext.bgImage);
  }

  const onSelect = (selectedBgImage: string | null) => {
    _themeContext.setBgImage(selectedBgImage);
    // _themeContext.updateInfo({
    //   ..._themeContext.user,
    //   bgImage: selectedBgImage
    // });
  };

  return (
    <div className="mb-4">
      <h6 className="mb-3 text-foreground">Background Image</h6>

      <div className="flex flex-wrap gap-1">
        <EachSelectBgImageTile
          eachBgImage={"null.jpg"}
          onSelect={() => onSelect(null)}
          // themeColor={_themeContext.themeColor}
          isSelected={
            _themeContext.bgImage === null ||
            _themeContext.bgImage === undefined
          }
        />

        {initialBgs.map((eachBgImage) => (
          <EachSelectBgImageTile
            key={"eachBgItem" + eachBgImage}
            eachBgImage={eachBgImage}
            onSelect={onSelect}
            // themeColor={_themeContext.themeColor}
            isSelected={_themeContext.bgImage === eachBgImage}
          />
        ))}

        {/* <SelectImageButtonStandalone
          onChange={async (files) => {
            try {
              let newBgImage = (await apiServices.sendFile({
                file: files[0],
                fieldName: "bgImage",
              })) as string | null;
              onSelect(newBgImage);
            } catch (e) {
              let _error = e as AxiosError;
              if (!isEmpty(_error)) {
                let _message = listFirst(
                  listFirst(Object.values(_error) as Dict[])
                );
                if (_message) {
                  toast({
                    variant: "destructive",
                    description: _message,
                  });
                }
              }
            }
          }}
          wrapperProps={{
            variant: "light",
            style: {
              width: 80,
              height: 80,
            },
            className:
              " rounded relative flex items-center justify-center border",
          }}
        >
          <LuPlus className=" text-foreground" />
        </SelectImageButtonStandalone> */}
      </div>
    </div>
  );
}

export default function ThemeSection() {
  const _currentUserContext = useCurrentUserContext();

  return (
    <section className="p-3 mx-auto max-w-[600px]">
      <div className="text-justify mb-3 text-foreground">
        In this section, you can change the theme color and background image of
        your app.
      </div>

      <SelectBgImage name="bgImage" />

      {/* <SelectColor name="themeColor" /> */}

      <DarkModeSection />

      <MobileFooterPadding />
    </section>
  );
}
