import Activity, { ActivityFilter } from "models/Activity";
import User, { CurrentUser, UserRoles } from "models/User";
import {
  isDateUntil,
  hasOtherKeysExcept,
  isEmpty,
  listFirst,
} from "services/UtilServices";
import useAuthUtils from "hooks/UseAuthUtils";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { MemberAccessTypes } from "models/Member";
import { useToast } from "components/ui/use-toast";
import Dict from "models/Dict";
import { useActivitysContext } from "providers/ActivitysProvider";
import { useModalContext } from "providers/ModalProvider";
import { useEffortUtils } from "./UseEffortUtils";
import ActivityCreateUpdateModal from "components/pages/EachEffort/ActivitysSection/ActivityCreateUpdateModal";
import ActivityFilterModal from "components/pages/EachEffort/ActivitysSection/ActivityFilterModal";
import Effort from "models/Effort";
import ActivityApproveModal from "components/pages/EachEffort/ActivitysSection/ActivityApproveModal";

export function useActivityViewUtils(effortId?: number) {
  const { toast } = useToast();
  const _effortUtils = useEffortUtils();
  const _modalContext = useModalContext();
  const _activitysContext = useActivitysContext();

  const onRemove = async (datas: Activity[]) => {
    _activitysContext.removeMany(datas);
  };

  const onSubmit = async (data: Dict) => {
    let _errors =
      "id" in data
        ? await _activitysContext.update(data)
        : await _activitysContext.create({ ...data });

    if (isEmpty(_errors)) {
      _modalContext.dismiss();
    }

    return _errors;
  };

  const approveSave = async (activity: Activity, isApproved = true) => {
    toast("Approving...");
    const _replaceApprovedTickets = activity.ticketsCount.every(
      (e) => e.countApproved === 0
    );

    const _errors = await onSubmit({
      ...activity,
      isApproved,
      ticketsCount: _replaceApprovedTickets
        ? activity.ticketsCount.map((eachCount) => ({
            ...eachCount,
            countApproved: eachCount.count,
          }))
        : activity.ticketsCount,
    });

    if (isEmpty(_errors)) {
      toast("Approved.");
    } else {
      let _message = listFirst(listFirst(Object.values(_errors) as Dict[]));
      if (_message) {
        toast("Approved.", { variant: "destructive" });
      }
    }

    return _errors;
  };

  const openApproveModal = (formData: { effortId: number } & Dict) => {
    _modalContext.open(
      <ActivityApproveModal formData={formData} onSubmit={onSubmit} />
    );
  };

  const openBulkApproveModal = (items: Activity[]) => {
    _activitysContext.activityBulkApprove(items);
  };

  const openCreateUpdateModal = (formData: { effortId: number } & Dict) => {
    let _project = _effortUtils.getParentProject(formData.effortId);

    // if (_project === undefined) {
    //   toast({
    //     variant: "destructive",
    //     description: "Project Not Found!",
    //   });
    // } else
    if (_project && _project.types?.length === 0) {
      toast(
        "You need to add a type to your project before you'd be able to create an activity!",
        {
          variant: "destructive",
        }
      );
    } else {
      _modalContext.open(
        <ActivityCreateUpdateModal formData={formData} onSubmit={onSubmit} />
      );
    }
  };

  const onFilterSubmit = (formData: Dict) => {
    _activitysContext.get({
      ...formData,
      pageNumber: -1,
    } as ActivityFilter);

    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _modalContext.open(
      <ActivityFilterModal
        filter={_activitysContext.filter}
        onSubmit={onFilterSubmit}
        effortId={effortId}
      />
    );
  };

  return {
    onRemove,
    onSubmit,
    openCreateUpdateModal,
    onFilterSubmit,
    openFilterModal,
    openApproveModal,
    approveSave,
  };
}

export default function useActivityUtils() {
  const _authUtils = useAuthUtils();
  const _currentUserContext = useCurrentUserContext();

  const canCreate = (effort?: Effort | null) => {
    let _accessType = _authUtils.authorize(effort?.id);
    return _accessType >= MemberAccessTypes.Create && effort?.parentId;
  };

  const canEdit = (item: Activity) => {
    let _accessType = _authUtils.authorize(item.effortId);

    return (
      _currentUserContext.user?.role !== UserRoles.USER ||
      (!item.isApproved && _accessType >= MemberAccessTypes.Edit) ||
      (!item.isApproved &&
        _accessType >= MemberAccessTypes.Create &&
        _currentUserContext.user?.id === item.userId &&
        isDateUntil({ date: item.dateAdd, days: 2 }))
    );
  };

  const canDelete = () => {
    return _currentUserContext.user?.role !== UserRoles.USER;
  };

  const canUpdateApproved = (item: Activity) => {
    let _accessType = _authUtils.authorize(item.effortId);

    return (
      _currentUserContext.user!.role !== UserRoles.USER ||
      (!item.isApproved &&
        _currentUserContext.user?.id !== item.userId &&
        _accessType >= MemberAccessTypes.Delete)
    );
  };

  const isFilterActive = ({
    effortId,
    filter,
  }: {
    effortId?: number | null;
    filter: ActivityFilter;
  }) => {
    return hasOtherKeysExcept(filter, [
      "pageNumber",
      "ascOrder",
      "sortBy",
      "numberInPage",
      ...(effortId ? ["effortIdList" as keyof ActivityFilter] : []),
    ]);
  };

  return {
    canCreate,
    canEdit,
    canDelete,
    isFilterActive,
    canUpdateApproved,
  };
}
