import React from "react";

import Effort from "models/Effort";

import SectionBase from "./SectionBase";
import TicketsCheepsSection from "components/pages/TicketsCheepsSection";

export default function TicketsCountSection({ effort }: { effort: Effort }) {
  return (
    <SectionBase
      effort={effort}
      title="Tickets"
      fieldName="tickets Count"
      editable={Boolean(effort.parentId)}
    >
      <TicketsCheepsSection ticketCounts={effort.ticketsCount} wrap />
    </SectionBase>
  );
}
