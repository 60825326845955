import React from "react";
import { cn, isEmpty, listFirst } from "services/UtilServices";
import { Button, ButtonProps } from "components/ui/button";
import { useToast } from "components/ui/use-toast";

export default function LoadingButton({
  children,
  onClick,
  variant = "light",
  className,
  ...rest
}: {
  onClick: () => Promise<any> | void;
} & ButtonProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { toast } = useToast();

  const _onClick = async () => {
    setIsLoading(true);

    const _res = await onClick();

    if (!isEmpty(_res)) {
      toast(listFirst(listFirst(_res)) ?? "Error", {
        variant: "destructive",
      });
    }

    setIsLoading(false);
  };

  return (
    <Button
      variant={variant}
      onClick={_onClick}
      className={cn(
        "relative ",
        { "animate-pulse pointer-events-none": isLoading },
        className
      )}
      {...rest}
      // disabled={isLoading}
    >
      {children}

      {/* {isLoading && <Skeleton className="absolute inset-0" />} */}
    </Button>
  );
}
