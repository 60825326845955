import React from "react";

import Dict from "models/Dict";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import {
  Form,
  Select,
  SubmitButton,
  useFormContext,
} from "components/common/Forms/Form";
import {
  dateGetOnlyDate,
  dateGetToday,
  objList2Options,
} from "services/UtilServices";
import TicketsCountInputField, {
  ticketDecimalIsValid,
  ticketIsRequired,
} from "components/pages/EffortsSection/TicketsCountInputField";
import TicketCount from "models/TicketCount";
import DatePickerInput from "components/common/Forms/DatePickerInput";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import { useMainContext } from "providers/MainProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import TextEditor from "components/common/Forms/TextEditor";

function FormBody() {
  const _formContext = useFormContext();
  const _effortUtils = useEffortUtils();

  let _project = _effortUtils.getParentProject(_formContext.data?.effortId);

  return (
    <>
      <Select
        name="typeId"
        label="Type"
        required
        options={objList2Options(_project?.types)}
      />

      <DatePickerInput
        name="dateDone"
        label="Date"
        required
        toDate={dateGetToday()}
      />

      <TextEditor name="description" label="Description" />

      <TicketsCountInputField
        name="ticketsCount"
        label="Booking"
        required
        tickets={_project?.ticketsAvailable}
        validations={[ticketIsRequired(), ticketDecimalIsValid()]}
      />
    </>
  );
}

export default function ActivityCreateUpdateModal({
  formData,
  onSubmit,
}: {
  formData?: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
}) {
  const _mainContext = useMainContext();
  const _effortUtils = useEffortUtils();
  const _effortsContext = useEffortsContext();

  const _isEditing = formData !== undefined && "id" in formData;

  return (
    <DialogContent title="Activities">
      <Form
        allowInitSubmit={_isEditing ? undefined : true}
        data={
          _isEditing
            ? {
              ...formData,
              dateDone: dateGetOnlyDate(formData.dateDone),
            }
            : {
              // typeId: listFirst(_project?.types)?.id,
              ...formData,
              dateDone: dateGetOnlyDate(dateGetToday()),
            }
        }
        onSubmit={async (d) => {
          const _d = { ...d };

          let _project = _effortUtils.getParentProject(_d.effortId);
          let _tickets = _project?.ticketsAvailable;

          _d.type = _project?.types?.find((e) => e.id === _d.typeId);

          _d.ticketsCount = _tickets?.map((eachTicket) => ({
            ticketId: eachTicket.id,
            ticket: eachTicket,

            count:
              _d.ticketsCount?.find(
                (a: TicketCount) => eachTicket.id === a.ticketId
              )?.count ?? 0,

            countApproved:
              _d.ticketsApproved?.find(
                (a: TicketCount) => eachTicket.id === a.ticketId
              )?.countApproved ?? 0,
          }));

          return await onSubmit(_d);
        }}
      >
        <Select
          name="effortId"
          label="Task"
          required
          options={objList2Options(
            _mainContext.efforts?.filter((e) => e.parentId),
            "title"
          )}
          disabled={
            (formData && "id" in formData) || _effortsContext.currentEffort
          }
        />

        <FormBody />

        <p>You can update this activity up until 2 days from now.</p>

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
