import OnLoadMoreIndicator from "components/common/OnLoadMoreIndicator";
import { useToast } from "components/ui/use-toast";
import useEffortApi from "hooks/api/UseEffortApi";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import Effort from "models/Effort";
import { useMainContext } from "providers/MainProvider";
import React from "react";
import { isEmpty, listUnique, sortedStringify } from "services/UtilServices";

export function useEffortInfoLoader() {
  const _effortUtils = useEffortUtils();
  const _effortApi = useEffortApi();
  const _mainContext = useMainContext();
  const { toast } = useToast();

  const load = async (efforts: Effort[]) => {
    if (isEmpty(efforts)) return efforts;
    let _results = [] as Effort[];

    for (const eachEffort of listUnique(efforts)!) {
      try {
        // if (eachEffort.creator) {
        //   _results.push(eachEffort);
        // } else {
        const response = await _effortApi.getById(eachEffort.id);
        response.level = _effortUtils.getParentEffortsDeep(
          response.id
        ).length;

        const _eachResult = {
          ...eachEffort,
          ...response,
        } as Effort;

        _results.push(_eachResult);
        // }
      } catch (_) {
        toast(`Failed to load "${eachEffort.title}" details.`, {
          variant: "destructive",
        });
        _results.push(eachEffort);
      }
    }

    _mainContext.setEfforts((prev) =>
      listUnique([..._results, ...(prev ?? [])])
    );
    return _results;
  };

  return {
    load,
  };
}

export function EffortsInfoLoader({
  children,
  efforts,
  skeleton,
}: {
  children?: React.ReactNode;
  efforts: Effort[];
  skeleton?: React.ReactNode;
}) {
  const _effortInfoLoader = useEffortInfoLoader();

  React.useEffect(() => {
    _effortInfoLoader.load(efforts);
  }, [sortedStringify(efforts.map((e) => e.id))]);

  return <>{efforts.every((e) => e.creator) ? children : skeleton}</>;
}

export default function EffortInfoLoaderOnView({
  children,
  effort,
  asChild,
  skeleton,
}: {
  children?: React.ReactNode;
  effort: Effort;
  asChild?: boolean;
  skeleton?: React.ReactNode;
}) {
  const _effortInfoLoader = useEffortInfoLoader();

  const loadInfo = async () => {
    await _effortInfoLoader.load([effort]);
  };

  return (
    <>
      {effort.creator ? (
        children
      ) : (
        <OnLoadMoreIndicator asChild={asChild} onLoadMore={loadInfo}>
          {skeleton}
        </OnLoadMoreIndicator>
      )}
    </>
  );
}

/* import OnLoadMoreIndicator from "components/common/OnLoadMoreIndicator";
import { useToast } from "components/ui/use-toast";
import useEffortApi from "hooks/api/UseEffortApi";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import Effort from "models/Effort";
import { useMainContext } from "providers/MainProvider";
import React from "react";
import { isEmpty } from "services/UtilServices";

export default function EffortInfoLoader({
  children,
  effort,
  asChild,
  skeleton,
}: {
  children?: React.ReactNode;
  effort: Effort;
  asChild?: boolean;
  skeleton?: React.ReactNode;
}) {
  const _effortUtils = useEffortUtils();
  const _effortApi = useEffortApi();
  const _mainContext = useMainContext();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = React.useState(false);
  const _efforsToGet = [
    effort,
    ..._effortUtils.getParentEffortsDeep(effort.id),
  ].filter((e) => !e.creator);

  const get = async (ef: Effort) => {
    let _result = null;

    try {
      const response = await _effortApi.getById(ef.id);
      response.level = _effortUtils.getParentEffortsDeep(response.id).length;

      _result = response as Effort;
    } catch (_) {
      toast(`Failed to load "${effort.title}" details.`, {
        variant: "destructive",
      });
    }

    return _result;
  };

  const loadInfo = async () => {
    setIsLoading(true);
    let _result: (Effort | null)[] = [];

    for (const eachEf of _efforsToGet) {
      _result.push(await get(eachEf));
    }

    console.log(_result);
    _mainContext.setEfforts(
      _mainContext.efforts?.map((eachEffort) =>
        _result.some((a) => a?.id === eachEffort.id)
          ? ({
              ..._result.find((a) => a?.id === eachEffort.id),
              ...eachEffort,
            } as Effort)
          : eachEffort
      )
    );

    setIsLoading(false);
  };

  return (
    <>
      {isEmpty(_efforsToGet) ? (
        children
      ) : (
        <OnLoadMoreIndicator asChild={asChild} onLoadMore={loadInfo}>
          {isLoading && skeleton ? skeleton : children}
        </OnLoadMoreIndicator>
      )}
    </>
  );
}
 */
