import React from "react";

import { useModalContext } from "providers/ModalProvider";

import {
  FaPen,
  FaTrashCan,
  FaCircleCheck,
  FaRegCircleCheck,
  FaEllipsisVertical,
} from "react-icons/fa6";
import { LuPencil, LuTrash2 } from "react-icons/lu";
import { cn } from "services/UtilServices";
import {
  RESPONSIVE_KEYS,
  RESPONSIVE_FLEX,
  RESPONSIVE_INLINE,
  RESPONSIVE_HIDDEN,
} from "models/views/responsive";
import LoadingSpinner from "components/common/LoadingSpinner";
import DeleteButton, {
  DeleteWarning,
  useDeleteButton,
} from "components/common/DeleteButton";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "./dropdown-menu";
import Dict from "models/Dict";
import { Button } from "./button";
import useMediaQuery, { MediaQuery2Num } from "components/common/useMediaQuery";

function EachButton({
  item,
  disabled,
  btnsClassName,
  btnTextResponsiveBreakPoint = "xl",
  stopPropagation = true,
}: {
  item: ItemType;
  disabled?: boolean;
  btnsClassName?: string;
  btnTextResponsiveBreakPoint?: RESPONSIVE_KEYS;
  stopPropagation?: boolean;
}) {
  const [isLoading, setIsLoading] = React.useState(false);

  const isElement = React.isValidElement(item);

  return (
    <Button
      variant={"faded"}
      disabled={disabled || isLoading}
      className={cn("px-2 ", btnsClassName)}
      onClick={
        isElement
          ? undefined
          : async (e) => {
              if (stopPropagation) {
                e.stopPropagation();
              }
              setIsLoading(true);

              if ("onClick" in item && item.onClick) await item.onClick();

              setIsLoading(false);
            }
      }
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : isElement ? (
        item
      ) : (
        <>
          {"icon" in item && item.icon}
          <span
            className={cn(
              "small hidden ms-1",
              RESPONSIVE_INLINE[btnTextResponsiveBreakPoint]
            )}
          >
            {"text" in item && item.text}
          </span>
        </>
      )}
    </Button>
  );
}

export type ItemProps = {
  icon?: React.ReactNode;
  text: React.ReactNode;
  onClick: () => void;
};

export function useMenuButton() {
  const deleteButton = useDeleteButton();

  const getItems = ({
    items,
    onSelect,
    onEdit,
    onDelete,
    deleteMessage,
  }: MenuButtonProps) =>
    [
      ...(items ?? []),
      {
        icon: <FaRegCircleCheck />,
        text: "Select",
        onClick: onSelect,
      },
      {
        icon: <LuPencil />,
        text: "Edit",
        onClick: onEdit,
      },
      {
        icon: <LuTrash2 />,
        text: "Delete",
        onClick: onDelete
          ? () =>
              deleteButton.tryRemove({
                onConfirm: onDelete,
                message: deleteMessage,
              })
          : undefined,
      },
    ] as ItemProps[];

  return {
    getItems,
  };
}

export type MenuButtonProps = {
  ellipsisClassName?: string;
  deleteMessage?: React.ReactNode;
  btnsClassName?: string;
  ellipsisResponsiveBreakPoint?: RESPONSIVE_KEYS;
  btnTextResponsiveBreakPoint?: RESPONSIVE_KEYS;
  className?: string;
  disabled?: boolean;
  items?: ItemType[];
  children?: React.ReactNode;
  stopPropagation?: boolean;
  onSelect?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

type ItemType =
  | {
      icon?: React.ReactNode;
      text: React.ReactNode;
      onClick?: () => void;
    }
  | React.ReactElement;

export default function MenuButton({
  ellipsisClassName = "",
  deleteMessage,
  btnsClassName = "",
  ellipsisResponsiveBreakPoint = "md",
  btnTextResponsiveBreakPoint = "xl",
  className,
  disabled,
  items = [],
  onSelect,
  onEdit,
  onDelete,
  children,
  stopPropagation = true,
}: MenuButtonProps) {
  const mediaQuery = useMediaQuery();
  const menuButton = useMenuButton();

  items = menuButton.getItems({
    items,
    onSelect,
    onEdit,
    onDelete,
    deleteMessage,
  });

  return (
    <>
      {items.some(
        (e) =>
          ("onClick" in e && e.onClick !== undefined) || React.isValidElement(e)
      ) && (
        <>
          {mediaQuery >= MediaQuery2Num[ellipsisResponsiveBreakPoint] ? (
            <div
              className={cn(
                "flex justify-end pointer-events-auto"
                // RESPONSIVE_FLEX[ellipsisResponsiveBreakPoint]
              )}
            >
              {items
                ?.filter(
                  (e) =>
                    ("onClick" in e && e.onClick !== undefined) ||
                    React.isValidElement(e)
                )
                .map((eachItem, i) => (
                  <EachButton
                    key={"menuItem" + i}
                    item={eachItem}
                    disabled={disabled}
                    btnsClassName={btnsClassName}
                    stopPropagation={stopPropagation}
                    btnTextResponsiveBreakPoint={btnTextResponsiveBreakPoint}
                  />
                ))}
            </div>
          ) : (
            <DropdownMenu>
              <Button
                asChild
                variant={"faded"}
                className={cn(
                  "",
                  { "px-1": !children },
                  // RESPONSIVE_HIDDEN[ellipsisResponsiveBreakPoint],
                  ellipsisClassName
                )}
                onClick={(ev) => {
                  if (stopPropagation) {
                    ev.stopPropagation();
                  }
                }}
              >
                <DropdownMenuTrigger disabled={disabled}>
                  {children ?? <FaEllipsisVertical />}
                </DropdownMenuTrigger>
              </Button>

              <DropdownMenuContent
                align="start"
                onClick={(ev) => {
                  if (stopPropagation) {
                    ev.stopPropagation();
                  }
                }}
              >
                {items
                  .filter(
                    (e) =>
                      ("onClick" in e && e.onClick !== undefined) ||
                      React.isValidElement(e)
                  )
                  .map((eachItem, i) => (
                    <React.Fragment key={"menuItem" + i}>
                      {React.isValidElement(eachItem) ? (
                        ["DropdownMenuLabel", "DropdownMenuSeparator"].includes(
                          (eachItem.type as Dict).displayName
                        ) ? (
                          eachItem
                        ) : (
                          <DropdownMenuItem className="group/button">
                            <div className="py-1 capitalize w-full">
                              {eachItem}
                            </div>
                          </DropdownMenuItem>
                        )
                      ) : (
                        <DropdownMenuItem
                          className="group/button"
                          onClick={
                            "onClick" in eachItem ? eachItem.onClick : undefined
                          }
                        >
                          <div className="py-1 capitalize">
                            {React.isValidElement(eachItem) ? (
                              eachItem
                            ) : (
                              <>
                                {"icon" in eachItem && eachItem.icon}{" "}
                                {"text" in eachItem && eachItem.text}
                              </>
                            )}
                          </div>
                        </DropdownMenuItem>
                      )}
                    </React.Fragment>
                  ))}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </>
      )}
    </>
  );
}
