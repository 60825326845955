import React from "react";
import { cn, makeid } from "services/UtilServices";

export default function Truncate({
  maxCount = 3,
  children,
  className,
  preppendChildren,
  wrapperClassName,
  appendChildren,
}: {
  maxCount?: number;
  children: React.ReactNode;
  className?: string;
  preppendChildren?: React.ReactNode;
  wrapperClassName?: React.ReactNode;
  appendChildren?: React.ReactNode;
}) {
  return (
    <>
      {React.Children.count(children) > 0 && (
        <div className={cn("flex items-center gap-2", wrapperClassName)}>
          {preppendChildren}

          <div className={cn("flex gap-1 items-center ", className)}>
            {React.Children.map(children, (e, i) => e)?.slice(0, maxCount)}
            <span className="whitespace-nowrap">
              {React.Children.count(children) > maxCount
                ? "+" + (React.Children.count(children) - maxCount)
                : ""}
            </span>
          </div>

          {appendChildren}
        </div>
      )}
    </>
  );
}
