"use client";

import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";

import { cn } from "services/UtilServices";
import { useNavigate } from "react-router";

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root> & {
    baseReplaceUrl?: string;
    wholeReplaceUrl?: string;
  }
>(
  (
    { className, baseReplaceUrl, defaultValue, wholeReplaceUrl, ...props },
    ref
  ) => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(defaultValue);

    React.useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    return (
      <TabsPrimitive.Root
        ref={ref}
        {...props}
        value={value}
        onValueChange={(v) => {
          if (wholeReplaceUrl || baseReplaceUrl) {
            navigate(
              wholeReplaceUrl ??
                baseReplaceUrl + (baseReplaceUrl?.endsWith("/") ? "" : "/") + v
            );
          }

          setValue(v);
          props.onValueChange?.(v);
        }}
      />
    );
  }
);
Tabs.displayName = TabsPrimitive.Root.displayName;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center overflow-x-auto p-2 bg-muted text-muted-foreground w-full z-10",
      className
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center gap-1 whitespace-nowrap",
      " rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background ",
      "transition-all focus-visible:outline-none focus-visible:ring-2",
      " focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none ",
      "hover:bg-foreground/5 dark:hover:bg-foreground/5 data-[state=active]:hover:bg-background",
      "disabled:opacity-50 data-[state=active]:bg-background ",
      "data-[state=active]:text-foreground data-[state=active]:shadow-sm",
      className
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      // "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
