import Dict from "./Dict";





export type ChartTypesType =
  | 0 // "PIE"
  | 1 // "DONUT"
  | 2 // "LINE"
  | 3 // "SMOOTH_LINE"
  | 4 // "STACKED_LINE"
  | 5 // "P100_STACKED_LINE"
  | 6 // "BAR"
  | 7 // "STACKED_BAR"
  | 8 // "P100_STACKED_BAR"
  | 9 // "HORIZONTAL_BAR"
  | 10 // "HORIZONTAL_STACKED"
  | 11 // "P100_HORIZONTAL_STACKED_BAR"
  | 12 // "AREA"
  | 13 // "STACKED_AREA"
  | 14 // "P100_STACKED_AREA"
  | 15 // "BUBBLE";

export class ChartTypes {
  static PIE = 0 as ChartTypesType;// "PIE";
  static DONUT = 1 as ChartTypesType;// "DONUT";
  static LINE = 2 as ChartTypesType;// "LINE";
  static SMOOTH_LINE = 3 as ChartTypesType;// "SMOOTH_LINE";
  static STACKED_LINE = 4 as ChartTypesType;// "STACKED_LINE";
  static P100_STACKED_LINE = 5 as ChartTypesType;// "P100_STACKED_LINE";
  static BAR = 6 as ChartTypesType;// "BAR";
  static STACKED_BAR = 7 as ChartTypesType;// "STACKED_BAR";
  static P100_STACKED_BAR = 8 as ChartTypesType;// "P100_STACKED_BAR";
  static HORIZONTAL_BAR = 9 as ChartTypesType;// "HORIZONTAL_BAR";
  static HORIZONTAL_STACKED = 10 as ChartTypesType;// "HORIZONTAL_STACKED";
  static P100_HORIZONTAL_STACKED_BAR = 11 as ChartTypesType;// "P100_HORIZONTAL_STACKED_BAR";
  static AREA = 12 as ChartTypesType;// "AREA";
  static STACKED_AREA = 13 as ChartTypesType;// "STACKED_AREA";
  static P100_STACKED_AREA = 14 as ChartTypesType;// "P100_STACKED_AREA";
  static BUBBLE = 15 as ChartTypesType;// "BUBBLE";
  static NUMBER_BOX = 16 as ChartTypesType;

  static apexChart: Dict = {
    [ChartTypes.PIE]: { chart: { type: "pie", } },
    [ChartTypes.DONUT]: { chart: { type: "donut", } },

    [ChartTypes.LINE]: { chart: { type: "line", } },
    [ChartTypes.SMOOTH_LINE]: { chart: { type: "line", }, stroke: { curve: "smooth", } },
    [ChartTypes.STACKED_LINE]: { chart: { type: "line", stacked: true } },
    [ChartTypes.P100_STACKED_LINE]: { chart: { type: "line", stackType: "100%", stacked: true } },

    [ChartTypes.BAR]: { chart: { type: "bar", } },
    [ChartTypes.STACKED_BAR]: { chart: { type: "bar", stacked: true } },
    [ChartTypes.P100_STACKED_BAR]: { chart: { type: "bar", stackType: "100%", stacked: true } },

    [ChartTypes.HORIZONTAL_BAR]: { chart: { type: "bar", }, plotOptions: { horizontal: true } },
    [ChartTypes.HORIZONTAL_STACKED]: { chart: { type: "bar", stacked: true }, plotOptions: { horizontal: true } },
    [ChartTypes.P100_HORIZONTAL_STACKED_BAR]: { chart: { type: "bar", stackType: "100%", stacked: true }, plotOptions: { horizontal: true } },

    [ChartTypes.AREA]: { chart: { type: "area", } },
    [ChartTypes.STACKED_AREA]: { chart: { type: "area", stacked: true } },
    [ChartTypes.P100_STACKED_AREA]: { chart: { type: "area", stackType: "100%", stacked: true } },

    [ChartTypes.BUBBLE]: { chart: { type: "bubble", } },
  }

  static groups = {
    "pie": [ChartTypes.PIE, ChartTypes.DONUT],

    "line": [
      ChartTypes.LINE,
      ChartTypes.SMOOTH_LINE,
      ChartTypes.STACKED_LINE,
      ChartTypes.P100_STACKED_LINE,
    ],

    "bar": [
      ChartTypes.BAR,
      ChartTypes.STACKED_BAR,
      ChartTypes.P100_STACKED_BAR,
    ],

    "columns": [
      ChartTypes.HORIZONTAL_BAR,
      ChartTypes.HORIZONTAL_STACKED,
      ChartTypes.P100_HORIZONTAL_STACKED_BAR,
    ],

    "area": [
      ChartTypes.AREA,
      ChartTypes.STACKED_AREA,
      ChartTypes.P100_STACKED_AREA,
    ],

    "bubble": [ChartTypes.BUBBLE],

    "numberBox": [ChartTypes.NUMBER_BOX],
  }

  static options: Dict = {
    [ChartTypes.PIE]: "pie",
    [ChartTypes.DONUT]: "donut",

    [ChartTypes.LINE]: "line",
    [ChartTypes.SMOOTH_LINE]: "smooth line",
    [ChartTypes.STACKED_LINE]: "stacked line",
    [ChartTypes.P100_STACKED_LINE]: "100% stacked line",

    [ChartTypes.BAR]: "bar",
    [ChartTypes.STACKED_BAR]: "stacked bar",
    [ChartTypes.P100_STACKED_BAR]: "100% stacked bar",

    [ChartTypes.HORIZONTAL_BAR]: "horizontal bar",
    [ChartTypes.HORIZONTAL_STACKED]: "horizontal stacked",
    [ChartTypes.P100_HORIZONTAL_STACKED_BAR]: "100% horizontal stacked bar",

    [ChartTypes.AREA]: "area",
    [ChartTypes.STACKED_AREA]: "stacked area",
    [ChartTypes.P100_STACKED_AREA]: "100% stacked area",

    [ChartTypes.BUBBLE]: "bubble",
    // [ChartTypes.NUMBER_BOX]: "number box",
  }

  static images: Dict = {
    [ChartTypes.PIE]: "/images/charts/pie-chart.svg",
    [ChartTypes.DONUT]: "/images/charts/donut-chart.svg",

    [ChartTypes.LINE]: "/images/charts/line-chart.svg",
    [ChartTypes.SMOOTH_LINE]: "/images/charts/smooth-line-chart.svg",
    [ChartTypes.STACKED_LINE]: "/images/charts/stacked-line-chart.svg",
    [ChartTypes.P100_STACKED_LINE]: "/images/charts/100p-stacked-line-chart.svg ",

    [ChartTypes.BAR]: "/images/charts/bar-chart.svg",
    [ChartTypes.STACKED_BAR]: "/images/charts/stacked-bar-chart.svg",
    [ChartTypes.P100_STACKED_BAR]: "/images/charts/100p-stacked-bar-chart.svg",

    [ChartTypes.HORIZONTAL_BAR]: "/images/charts/horizontal-bar-chart.svg",
    [ChartTypes.HORIZONTAL_STACKED]: "/images/charts/horizontal-stacked-chart.svg",
    [ChartTypes.P100_HORIZONTAL_STACKED_BAR]: "/images/charts/100p-horizontal-stacked-bar-chart.svg ",

    [ChartTypes.AREA]: "/images/charts/area-chart.svg",
    [ChartTypes.STACKED_AREA]: "/images/charts/stacked-area-chart.svg",
    [ChartTypes.P100_STACKED_AREA]: "/images/charts/100p-stacked-area-chart.svg",

    [ChartTypes.BUBBLE]: "/images/charts/bubble-chart.svg",
    [ChartTypes.NUMBER_BOX]: "/images/charts/bubble-chart.svg",
  }

  static toLabelValues = () =>
    Object.entries(ChartTypes.options).map(
      ([key, val]) => ({
        value: parseInt(key),
        label: val,
      })
    );
}


export type ActivityLabelValueStackOptions = "Id" | "DateAdd" | "DateDone" | "DateApproved" | "IsApproved" | "RecordStatus" | "DayAdd" | "MonthAdd" | "YearAdd" | "DayDone" | "MonthDone" | "YearDone" | "DayApproved" | "MonthApproved" | "YearApproved" | "User" | "Effort" | "Type" | "Project" | "Task" | "Subtask" | "Ticket" | "Approver"




export type ChartColType = {
  id: string,
  title: string,
  color: string,
  count: number,
  sum: number,
  sumApproved: number
}

export interface ChartData {
  labelList?: (ChartColType & {
    valueList?: (ChartColType & {
      stackList?: ChartColType[]
    })[]
  })[],
  values?: string[],
  labels?: string[],
}


export default interface Chart {
  title: string,
  type?: ChartTypesType,
  labelBy?: ActivityLabelValueStackOptions,
  valueBy?: ActivityLabelValueStackOptions,
  stackBy?: ActivityLabelValueStackOptions,
}