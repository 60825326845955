import * as React from "react";

import { cn } from "services/UtilServices";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & {
    asDiv?: boolean;
  }
>(({ className, asDiv, ...props }, ref) => {
  const Comp = asDiv ? "div" : "table";

  return (
    <Comp
      cellSpacing={20}
      ref={ref}
      className={cn(
        "w-full caption-bottom text-sm rounded border-collapse border-spacing-0 ",
        { table: asDiv },
        className
      )}
      {...props}
    />
  );
});
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn("", className)} {...props} /> //[&_tr]:border-b
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    asDiv?: boolean;
  }
>(({ className, asDiv, ...props }, ref) => {
  const Comp = asDiv ? "div" : "tbody";

  return (
    <Comp
      ref={ref}
      className={cn("", { "table-row-group": asDiv }, className)} //[&_tr:last-child]:border-0
      {...props}
    />
  );
});
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "border-t bg-muted/50 font-medium [&>tr]:last:border-b-0",
      className
    )}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & {
    asDiv?: boolean;
  }
>(({ className, asDiv, ...props }, ref) => {
  const Comp = asDiv ? "div" : "tr";

  return (
    <Comp
      ref={ref}
      className={cn(
        " group/row transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted ", //border
        { "table-row": asDiv },
        className
      )}
      {...props}
    />
  );
});
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & {
    asDiv?: boolean;
  }
>(({ className, asDiv, ...props }, ref) => {
  const Comp = asDiv ? "div" : "th";

  return (
    <Comp
      ref={ref}
      className={cn(
        "border h-12 px-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0", //text-muted-foreground
        { " table-cell": asDiv },
        className
      )}
      {...props}
    />
  );
});
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & {
    asDiv?: boolean;
  }
>(({ className, asDiv, ...props }, ref) => {
  const Comp = asDiv ? "div" : "td";

  return (
    <Comp
      ref={ref}
      className={cn(
        "border border-border/60 px-4 py-1 whitespace-nowrap relative align-middle [&:has([role=checkbox])]:pr-0 text-foreground",
        { "table-cell": asDiv },
        className
      )}
      {...props}
    />
  );
});
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
