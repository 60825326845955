import React from "react";
import { App as CapacitorApp } from "@capacitor/app";
import { useNavigate } from "react-router";

import { useModalContext } from "../../providers/ModalProvider";
import { DialogContent } from "components/ui/dialog";
import { Button } from "components/ui/button";

var globalCallbacks: (() => void)[] = [];

// function onBackButtonClick(canGoBack: boolean) {//event: KeyboardEvent
//   if(canGoBack && globalCallback !== undefined) {//event.key === "ArrowUp"
//     globalCallback();
//   }
// }

function addBackButtonEventListener(callback: () => void) {
  // globalCallback = callback;
  // document.addEventListener("keydown", onBackButtonClick, false);
  // CapacitorApp.addListener('backButton', ({ canGoBack }) => onBackButtonClick(canGoBack));

  globalCallbacks.push(callback);
}

function removeBackButtonEventListener() {
  //
  // CapacitorApp.removeAllListeners();
  // globalCallback = undefined;
  // document.removeEventListener("keydown", onBackButtonClick);

  globalCallbacks.pop();
}

function BackButtonHandler({ children }: { children: React.ReactNode }) {
  const _modalContext = useModalContext();
  const navigate = useNavigate();

  const _onBackButtonClick = (canGoBack: boolean) => {
    //event: KeyboardEvent
    // if(event.key !== "ArrowUp") return;

    if (globalCallbacks.length > 0) {
      globalCallbacks[globalCallbacks.length - 1]();
      return;
    }

    if (canGoBack) {
      navigate(-1);
    } else {
      _modalContext.open(
        <DialogContent title="Exit" size="sm" fullScreen={false}>
          <div>
            <p className="">Exit?</p>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="destructive"
              className="flex-1"
              onClick={() => CapacitorApp.exitApp()}
            >
              خروج
            </Button>

            <Button
              variant={"light"}
              className="flex-1"
              onClick={() => _modalContext.dismiss()}
            >
              بیخیال
            </Button>
          </div>
        </DialogContent>
      );
    }
  };

  React.useEffect(() => {
    CapacitorApp.addListener("backButton", ({ canGoBack }) =>
      _onBackButtonClick(canGoBack)
    );
    // document.addEventListener("keydown", _onBackButtonClick, false);

    return () => {
      CapacitorApp.removeAllListeners();
      // document.removeEventListener("keydown", _onBackButtonClick);
    };
  }, []);

  return <>{children}</>;
}

export {
  BackButtonHandler,
  addBackButtonEventListener,
  removeBackButtonEventListener,
};
