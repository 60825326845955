import React from "react";

import Tag from "models/Tag";
import User, { UserRoles } from "models/User";

import LocalStorageServices from "services/localServices/LocalStorageServices";
import useMainApi from "hooks/api/UseMainApi";

import {
  CurrentUserContext,
  useCurrentUserContext,
} from "./CurrentUserProvider";
import Dict from "models/Dict";
import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { listUnique } from "services/UtilServices";

interface MainContextProps {
  efforts: Effort[] | undefined;

  setEffort: (effort: Effort) => void;
  setEfforts: (items: React.SetStateAction<Effort[] | undefined>) => void;
  setEffortPartially: (item: Dict) => void;
}

const MainContext = React.createContext({} as MainContextProps);
MainContext.displayName = "MainContext";

var isRequesting = false;

function MainProvider({ children }: { children: React.ReactNode }) {
  const mainApi = useMainApi();
  const _effortUtils = useEffortUtils();
  const currentUserContext = useCurrentUserContext();

  const [_efforts, _setEfforts] = React.useState<Effort[] | undefined>();

  React.useEffect(() => {
    LocalStorageServices.get("PROJECTS").then((r) =>
      _setEfforts(r === null ? undefined : r)
    );
  }, []);

  React.useEffect(() => {
    if (currentUserContext.isLoggedIn() && _efforts === undefined) {
      _init();
    } else if (!currentUserContext.isLoggedIn() && _efforts) {
      setEfforts(undefined);
    }
  }, [currentUserContext.user]);

  const init = () => {
    _setEfforts(undefined);

    // _init();
  };

  const _init = async () => {
    if (isRequesting || !currentUserContext.user) return;
    isRequesting = true;

    try {
      let { items } = await mainApi.all();

      if (currentUserContext.user?.role !== UserRoles.ADMIN) {
        items = listUnique(
          items
          // .filter((eachEffort: Effort) => {
          //   let project = _effortUtils.getParentProject(eachEffort.id, items);

          //   return project?.members?.some(
          //     (a) => a.userId === currentUserContext.user!.id
          //   );
          // })
        );
      }

      setEfforts(items);
    } catch (e) {
      console.log(e);
    }

    isRequesting = false;
  };

  const setEfforts = (items: React.SetStateAction<Effort[] | undefined>) => {
    _setEfforts(items);
  };

  const setEffort = (effort: Effort) => {
    setEfforts((prev) =>
      prev?.map((e) => (e.id !== effort.id ? e : { ...effort }))
    );
  };

  const setEffortPartially = (item: Dict) => {
    setEfforts((prev) =>
      prev?.map((e) =>
        e.id !== item.id
          ? e
          : {
              ...e,
              ...item,
            }
      )
    );
  };

  return (
    <MainContext.Provider
      value={
        {
          init,

          efforts: _efforts,
          setEfforts: setEfforts,
          setEffort,
          setEffortPartially,
        } as MainContextProps
      }
    >
      {children}
    </MainContext.Provider>
  );
}

export function useMainContext() {
  const _context = React.useContext(MainContext);

  if (!_context) {
    throw new Error("cannot use MainContext outside of its provider.");
  }

  return _context;
}

export { MainContext, MainProvider };
export type { MainContextProps };
