import * as React from "react";
import { cn } from "services/UtilServices";
import MainHeaderBody from "./MainHeader/MainHeader";

interface MainSectionProps {
  children: React.ReactNode;
  header?: React.ReactNode | null;
  title?: React.ReactNode;
  sideMenu?: React.ReactNode;
  back?: boolean;
  className?: string;
}

export default function MainSection({
  children,
  header = null,
  title,
  back = false,
  className,
}: MainSectionProps) {
  return (
    <>
      {header !== null && (
        <MainHeaderBody title={title} back={back}>
          {header}
        </MainHeaderBody>
      )}

      {/* <ScrollArea
        className={cn("overflow-x-auto overflow-y-auto flex-1 p-2", className)}
      >
        <div id="ehsan" className="h-full"> */}
      <div
        className={cn(
          " flex-1 m-2 overflow-auto ", //overflow-hidden hover:
          className
        )}
      >
        {children}
        {/* <div className="table h-full">{children}</div> */}
        {/* <ScrollBar orientation="horizontal" /> */}
      </div>
      {/* <MobileFooterPadding /> */}
      {/* </div> */}
      {/* <ScrollBar orientation="vertical" /> */}
      {/* </ScrollArea> */}
    </>
  );
}
