import React, { useRef, useContext } from "react";

import { ValidationType } from "./UseValidation";
import { useModalContext } from "providers/ModalProvider";
import { SelectManyImageModal } from "services/SelectImageServices";
import { FaCamera } from "react-icons/fa6";
import { Button, ButtonProps } from "components/ui/button";
import { Input } from "components/ui/input";
import useValidation from "./UseValidation";

interface Props {
  onChange?: (files: File[]) => void;
  children?: React.ReactNode;
  validations?: ValidationType[];
  multiple?: boolean;
  galleryCameraAccessType?: number;
  wrapperProps?: ButtonProps;
  accept?: string;
  [rest: string]: any;
}

const SelectImageButtonStandalone = ({
  onChange = (files: File[]) => {},
  children,
  validations,
  multiple = false,
  wrapperProps,
  accept = "image/*;capture=camera",
  ...rest
}: Props) => {
  const upload = useRef() as React.MutableRefObject<HTMLInputElement>;
  const _modalContext = useModalContext();
  const validation = useValidation();

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if(event.target.files !== null && event.target.files.length > 0) {
    //     onChange(event.target.files);
    //     // event.target.value = "";
    // }
  };

  const isFieldRequired = () => {
    const _isRequiredType = validation.isRequired().type;

    return (
      rest.required ||
      (validations !== undefined &&
        validations!.some((each) => each.type === _isRequiredType))
    );
  };

  return (
    <Button asChild {...wrapperProps}>
      <div
        onClick={() => {
          _modalContext.open(<SelectManyImageModal onChange={onChange} />);
        }}
      >
        {children ?? (
          <Button size={"sm"} variant={"outline"} className=" me-2" {...rest}>
            <FaCamera />
          </Button>
        )}

        <Input
          id="myInput"
          type="file"
          ref={upload}
          accept={accept}
          capture
          className="hidden"
          multiple={multiple}
          onChange={onChangeFile}
        />
      </div>
    </Button>
  );
};

export default SelectImageButtonStandalone;
