import React from "react";

import Dict from "../../../models/Dict";

import ItemsListProvider, {
  useItemsListContext,
  ItemsListProviderInputProps,
} from "./ItemsListProvider";

import ItemListSearchbarSection from "./ItemsListSearchbarSection";
import ItemsListTableSection from "./ItemsListTableSection";
import ItemsListCardSection from "./ItemsListCardSection";
import useMediaQuery, { MediaQuery2Num } from "../useMediaQuery";
import EmptyListIndicator from "../EmptyListIndicator";
import { RESPONSIVE_KEYS } from "models/views/responsive";

export function ItemsListSectionBody({
  tableBreakPointSize = "md",
  needSearch = true,
}: {
  tableBreakPointSize?: RESPONSIVE_KEYS;
  needSearch?: boolean;
}) {
  const _itemsListContext = useItemsListContext();
  const {
    data,
    itemClassName,
    buildCardBodyItem,
    buildCardTitleRow,
    itemStyle,
    onSearch,
    onLoadMore,
    title,
  } = _itemsListContext;

  let size = useMediaQuery();

  const _isEmpty = !onLoadMore && data?.length === 0;

  return (
    <>
      {needSearch && (
        <ItemListSearchbarSection
          isSearchable={onSearch !== null}
          tableModeBreakPoint={tableBreakPointSize}
          {..._itemsListContext}
        />
      )}

      {_isEmpty ? (
        <EmptyListIndicator
          wrapperClassName="mt-5"
          text={"No " + title + " Found!"}
        />
      ) : size > 5 - MediaQuery2Num[tableBreakPointSize] ? (
        <ItemsListTableSection />
      ) : (
        <ItemsListCardSection
          buildCardBodyItem={buildCardBodyItem}
          buildCardTitleRow={buildCardTitleRow}
          itemClassName={itemClassName}
        />
      )}
    </>
  );
}

export interface PropTypes<T extends Dict>
  extends React.PropsWithChildren<ItemsListProviderInputProps<T>> {
  tableBreakPointSize?: RESPONSIVE_KEYS;
}

function ItemsListSection<T extends Dict>(props: PropTypes<T>) {
  return (
    <ItemsListProvider<T> {...props}>
      <ItemsListSectionBody tableBreakPointSize={props.tableBreakPointSize} />
    </ItemsListProvider>
  );
}

export default ItemsListSection;
