import React from "react";

import Tag from "models/Tag";
import Type from "models/Type";
import Dict from "models/Dict";
import State from "models/State";
import Prefix from "models/Prefix";
import Ticket from "models/Ticket";
import Priority from "models/Priority";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import {
  dateGetOnlyDate,
  objList2Options,
  strCapitalizefirstLetter,
} from "services/UtilServices";

import { useModalContext } from "providers/ModalProvider";
import {
  Switch,
  Form,
  Input,
  Select,
  SubmitButton,
} from "components/common/Forms/Form";

import TextEditor from "components/common/Forms/TextEditor";
import TicketsCountInputField from "./TicketsCountInputField";
import DatePickerInput from "components/common/Forms/DatePickerInput";
import TicketCount from "models/TicketCount";
import { useToast } from "components/ui/use-toast";
import { DialogContent, DialogFooter } from "components/ui/dialog";
import useValidation from "components/common/Forms/UseValidation";
import { useEffortResources } from "hooks/utils/UseEffortResources";

export type EffortSingleFieldUpdateModalFieldNameType =
  | "states"
  | "prefixes"
  | "prefix"
  | "tags"
  | "types"
  | "description"
  | "title"
  | "isActive"
  | "state"
  | "department"
  | "priority"
  | "priorities"
  | "dateDue"
  | "Available tickets"
  | "tickets Count";

export default function EffortSingleFieldUpdateModal({
  effort,
  onSubmit,
  fieldName,
}: {
  effort: Dict;
  onSubmit: (data: Dict) => Promise<Dict>;
  fieldName: EffortSingleFieldUpdateModalFieldNameType;
}) {
  const _modalContext = useModalContext();
  const _effortUtils = useEffortUtils();
  const validation = useValidation();

  const {
    resourceData: {
      tags,
      types,
      states,
      prefixes,
      departments,
      priorities,
      ticketsAvailable,
    },
    getResources,
  } = useEffortResources();

  const { toast } = useToast();

  const isProject = _effortUtils.isProject(effort);

  React.useEffect(() => {
    const _effortId = isProject ? undefined : effort.id;
    if (fieldName === "prefix" || fieldName === "prefixes") {
      getResources({ effortId: _effortId, fieldNames: ["prefixes"] });
    } else if (fieldName === "department") {
      getResources({ effortId: _effortId, fieldNames: ["departments"] });
    } else if (fieldName === "state" || fieldName === "states") {
      getResources({ effortId: _effortId, fieldNames: ["states"] });
    } else if (fieldName === "tags") {
      getResources({ effortId: _effortId, fieldNames: ["tags"] });
    } else if (fieldName === "types") {
      getResources({ effortId: _effortId, fieldNames: ["types"] });
    } else if (fieldName === "priority" || fieldName === "priorities") {
      getResources({ effortId: _effortId, fieldNames: ["priorities"] });
    } else if (
      fieldName === "tickets Count" ||
      fieldName === "Available tickets"
    ) {
      getResources({ effortId: _effortId, fieldNames: ["ticketsAvailable"] });
    }
  }, []);

  let _errorContent = undefined;
  if (states?.length === 0) {
    _errorContent = `You need to add a "State" ${isProject ? "" : "to your project"
      } before you can create tasks!`;
  } else if (priorities?.length === 0) {
    _errorContent = `You need to add a "Priority" ${isProject ? "" : "to your project"
      } before you can create tasks!`;
  } else if (ticketsAvailable?.length === 0) {
    _errorContent = `You need to add a "Ticket" type ${isProject ? "" : "to your project"
      } before you can create tasks!`;
  } else if (departments?.length === 0) {
    _errorContent = `You need to add a "Department" ${isProject ? "" : "to your project"
      } before you can create subtasks!`;
  }

  if (_errorContent) {
    _modalContext.dismiss();
    toast(_errorContent, {
      variant: "destructive",
    });
    return null;
  }

  return (
    <DialogContent title={"Update " + strCapitalizefirstLetter(fieldName)}>
      <Form
        data={{
          ...effort,

          ...(fieldName === "states"
            ? { stateList: effort.states.map((e: State) => e.id) }
            : {}),
          ...(fieldName === "prefixes"
            ? { prefixList: effort.prefixes.map((e: Prefix) => e.id) }
            : {}),
          ...(fieldName === "prefix" ? { prefixId: effort.prefixId } : {}),
          ...(fieldName === "tags"
            ? { tagList: effort.tags.map((e: Tag) => e.id) }
            : {}),
          ...(fieldName === "types"
            ? { typeList: effort.types.map((e: Type) => e.id) }
            : {}),
          ...(fieldName === "state" ? { stateId: effort.state?.id } : {}),
          ...(fieldName === "department"
            ? { departmentId: effort.department?.id }
            : {}),
          ...(fieldName === "priority"
            ? { priorityId: effort.priority?.id }
            : {}),
          ...(fieldName === "priorities"
            ? { priorities: effort.priorities.map((e: Priority) => e.id) }
            : {}),
          ...(fieldName === "Available tickets"
            ? {
              ticketsAvailable: effort.ticketsAvailable?.map(
                (e: Ticket) => e.id
              ),
            }
            : {}),
          ...(fieldName === "tickets Count" ? {} : {}),
          ...(fieldName === "dateDue"
            ? { dateDue: dateGetOnlyDate(effort.dateDue) }
            : {}),
        }}
        onSubmit={async (d) => {
          let _d = {
            ...d,
          };
          switch (fieldName) {
            case "states":
              _d.states =
                states?.filter((e) => _d.stateList?.includes(e.id)) ?? [];
              break;
            case "prefixes":
              _d.prefixes =
                prefixes?.filter((e) => _d.prefixList?.includes(e.id)) ?? [];
              break;
            case "prefix":
              _d.prefix = prefixes?.find((e) => e.id === _d.prefixId);
              break;
            case "tags":
              _d.tags = tags?.filter((e) => _d.tagList?.includes(e.id)) ?? [];
              break;
            case "types":
              _d.types =
                types?.filter((e) => _d.typeList?.includes(e.id)) ?? [];
              break;
            case "state":
              _d.state = states?.find((e) => e.id === _d.stateId);
              break;
            case "department":
              _d.department = departments?.find(
                (e) => e.id === _d.departmentId
              );
              break;
            case "priority":
              _d.priority = priorities?.find((e) => e.id === _d.priorityId);
              break;
            case "priorities":
              _d.priorities =
                priorities?.filter((e) => _d.priorities?.includes(e.id)) ?? [];
              break;
            case "Available tickets":
              _d.ticketsAvailable =
                ticketsAvailable?.filter((e) =>
                  _d.ticketsAvailable?.includes(e.id)
                ) ?? [];
              break;
            case "tickets Count":
              _d.ticketsCount =
                _d.ticketsCount?.map((eachCount: TicketCount) => ({
                  ...eachCount,
                  ticket: ticketsAvailable?.find(
                    (a) => a.id === eachCount.ticketId
                  ),
                })) ?? [];
              break;
          }

          return await onSubmit(_d);
        }}
      >
        {fieldName === "title" && (
          <Input
            name="title"
            required
            validations={[
              validation.notSpecificCharsUsed([
                "\\",
                "/",
                ":",
                "*",
                '"',
                "<",
                ">",
                "|",
                "?",
                "=",
                "#",
                "'",
              ]),
            ]}
          />
        )}

        {fieldName === "isActive" && <Switch name="isActive" label="Active" />}

        {fieldName === "state" && (
          <Select
            name="stateId"
            label="State"
            required
            options={objList2Options(states)}
          />
        )}

        {fieldName === "department" && (
          <Select
            name="departmentId"
            label="Department"
            required
            options={objList2Options(departments)}
          />
        )}

        {fieldName === "priority" && (
          <Select name="priorityId" options={objList2Options(priorities)} />
        )}

        {fieldName === "dateDue" && (
          <DatePickerInput name="dateDue" label="Deadline" />
        )}

        {fieldName === "states" && (
          <Select
            name="stateList"
            label="states List"
            multiple
            options={objList2Options(states)}
          />
        )}

        {fieldName === "priorities" && (
          <Select
            name="priorities"
            multiple
            options={objList2Options(priorities)}
          />
        )}

        {fieldName === "prefixes" && (
          <Select
            name="prefixList"
            label="prefixes"
            multiple
            options={objList2Options(prefixes)}
          />
        )}

        {fieldName === "Available tickets" && (
          <Select
            name="ticketsAvailable"
            label="ticket types"
            multiple
            options={objList2Options(ticketsAvailable)}
          />
        )}

        {fieldName === "tickets Count" && (
          <TicketsCountInputField
            name="ticketsCount"
            label="Tickets Count"
            tickets={ticketsAvailable}
          />
        )}

        {fieldName === "prefix" && (
          <Select
            name="prefixId"
            label="Prefix"
            options={objList2Options(prefixes)}
          />
        )}

        {fieldName === "tags" && (
          <Select
            name="tagList"
            label="tags"
            multiple
            options={objList2Options(tags)}
          />
        )}

        {fieldName === "types" && (
          <Select
            name="typeList"
            label="types"
            multiple
            options={objList2Options(types)}
          />
        )}

        {fieldName === "description" && (
          <TextEditor name="description" label="Description" isAdvance />
        )}

        <DialogFooter>
          <SubmitButton />
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
