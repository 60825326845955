
import Dict from 'models/Dict';
import { TicketFilter } from 'models/Ticket';
import { dataMapper, dateAdd, getNotEmptyFields } from 'services/UtilServices';
import useApiServices from './UseApiServices';
import { BASE_URL as EFFORT_BASE_URL } from './UseEffortApi';

export const BASE_URL = "tickets/";

export default function useTicketApi() {

  const apiServices = useApiServices();


  const get = async (filter: TicketFilter) => {
    let response = await apiServices.send({
      url: BASE_URL + "filter",
      method: "post",
      expirationDurationMinutes: 2,
      body: getNotEmptyFields({
        ...filter,
        dateAddTo: dateAdd({ date: filter.dateAddTo, days: 1 }),
      }),
    });

    return {
      ...response,
      items: response.result ?? []
    };
  }


  const create = async (ticket: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "new",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: ticket
    });


    return response;
  }


  const update = async (ticket: Dict) => {

    let response = await apiServices.send({
      url: BASE_URL + "update",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: ticket
    });

    return response;
  }


  const remove = async (ids: number[]) => {
    await apiServices.send({
      url: BASE_URL + "delete",
      method: "post",
      inValidateUrls: [EFFORT_BASE_URL],
      body: { ids }
    });
  }

  return {
    get,
    create,
    update,
    remove,
  };
}

