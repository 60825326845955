import React from "react";

import User from "models/User";
import useUserUtils from "hooks/utils/UseUserUtils";
import Avatar, { AvatarProps } from "components/common/Avatar";
import { ApiVariables } from "hooks/api/UseApiServices";
import UserProfileHoverCard from "components/pages/admin/UsersSection/UserProfileHoverCard";
import { useCurrentUserContext } from "providers/CurrentUserProvider";
import { cn, str2Color } from "services/UtilServices";

export type ProfileAvatarProps = {
  user?: User;
  needName?: boolean;
  needHover?: boolean;
  needAvatar?: boolean;
  needYou?: boolean;
  wrapperClassName?: string;
  align?: "center" | "start" | "end";
  side?: "top" | "right" | "bottom" | "left";
} & Omit<AvatarProps, "src">;

export default function ProfileAvatar({
  user,
  needHover = true,
  needName,
  needAvatar = true,
  align,
  side,
  needYou = true,
  wrapperClassName,
  size = 35,
  ...props
}: ProfileAvatarProps) {
  const _userUtils = useUserUtils();
  const _currentUserContext = useCurrentUserContext();
  const [_notFound, _setNotFound] = React.useState(false);

  const _fullName = _userUtils.getFullName(user);

  const _avatar = (
    <div className={cn("flex gap-1 items-center ", wrapperClassName)}>
      {needAvatar &&
        (_notFound && _fullName ? (
          <div
            title={_fullName}
            style={{
              // width: size,
              height: size,
              minWidth: size,
              minHeight: size,
              backgroundColor: str2Color(_fullName),
            }}
            className={cn(
              "rounded-full aspect-square border object-cover flex items-center justify-center uppercase",
              props.className
            )}
          >
            <span className="-mb-0.5 text-light">
              {_fullName
                .split(" ")
                .map((a) => a[0])
                .join("")}
            </span>
          </div>
        ) : (
          <Avatar
            placeholder="blurred"
            title={_fullName}
            size={size}
            {...props}
            secure={false}
            onImgNotFound={() => _setNotFound(true)}
            src={
              (user?.avatarUrl?.startsWith("http")
                ? ""
                : ApiVariables.PUBLIC_FILE_URL) +
              // + user?.avatarUrl
              user?.avatarUrl?.replace(".", "_256.")
            }
          />
        ))}
      {needName && _fullName}
      {needName &&
        needYou &&
        user &&
        _currentUserContext.user?.id === user?.id && (
          <span className="opacity-80">(You)</span>
        )}
    </div>
  );

  return needHover ? (
    <UserProfileHoverCard user={user} align={align} side={side}>
      {_avatar}
    </UserProfileHoverCard>
  ) : (
    _avatar
  );
}
