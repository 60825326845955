import React from "react";

import Dict from "models/Dict";
import Effort from "models/Effort";
import useAttachmentUtils from "hooks/utils/UseAttachmentUtils";

import { getFileType, isEmpty, listFirst } from "services/UtilServices";

import { useAttachmentsContext } from "providers/AttachmentsProvider";

import AttachmentItem from "./AttachmentItem";

import { useToast } from "components/ui/use-toast";

import { LuPaperclip, LuPlus } from "react-icons/lu";
import { DropZone } from "components/common/DropZone";
import { useEffortsContext } from "providers/EffortsProvider";
import { useSelectFile } from "components/common/Forms/SelectFileButton";
import SectionBase from "../SettingSection/SectionBase";
import { useGalleryViewContext } from "providers/GalleryViewProvider";

export default function AttachmentsSection({ effort }: { effort: Effort }) {
  const _attachmentsContext = useAttachmentsContext();
  const _effortsContext = useEffortsContext();
  const _attachmentUtils = useAttachmentUtils(
    _effortsContext.currentEffort!.id
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const selectFile = useSelectFile();
  const _galleryViewContext = useGalleryViewContext();

  const { toast } = useToast();

  const onUpload = async (data: File | File[]) => {
    setIsLoading(true);
    let _error = await _attachmentsContext.createMany({ files: data });
    setIsLoading(false);

    if (!isEmpty(_error)) {
      let _message = listFirst(listFirst(Object.values(_error) as Dict[]));
      if (_message) {
        toast(_message, {
          variant: "destructive",
        });
      }
    }
  };

  const openGalleryView = (url: string) => {
    _galleryViewContext.open({
      initUrl: url,
      items: effort.attachments!.filter((e) => getFileType(e.name) === "image"),
    });
  };

  return (
    <DropZone
      onDrop={onUpload}
      className=""
      disabled={!_attachmentUtils.canCreate()}
    >
      <SectionBase
        effort={effort}
        title={"Attachments"}
        icon={
          <>
            <LuPaperclip className="!text-base" />
            <LuPlus className="!text-base" />
            <span className="!text-sm text-muted-foreground">Or Drag&Drop</span>
          </>
        }
        onClick={() => {
          selectFile.select(onUpload, {
            multiple: true,
          });
        }}
        editable={_attachmentUtils.canCreate() && !isLoading}
      >
        {effort.attachments?.map((e, i) => (
          <AttachmentItem
            key={"eachAttachment" + i}
            attachment={e}
            onClick={
              getFileType(e.name) === "image"
                ? () => openGalleryView(e.url)
                : undefined
            }
            onDelete={
              _attachmentUtils.canDelete(e)
                ? () => _attachmentsContext.removeMany([e])
                : undefined
            }
          />
        ))}
      </SectionBase>
    </DropZone>
  );
}
