import React from "react";
import { ReactSortable, Sortable } from "react-sortablejs";

import Effort from "models/Effort";

import EachCardItem from "./EachCardItem";
import { cn, isEmpty, isFarsi } from "services/UtilServices";
import {
  ItemType,
  useItemsListContext,
} from "components/common/ItemsListSection/ItemsListProvider";
import { useMainContext } from "providers/MainProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import Dict from "models/Dict";
import EffortCreateUpdateModal from "../EffortCreateUpdateModal";
import { useModalContext } from "providers/ModalProvider";
import MenuButton from "components/ui/menu-button";
import { EffortGroupType, useEffortUtils } from "hooks/utils/UseEffortUtils";
import { LuPlus } from "react-icons/lu";
import Directionality from "components/common/Directionality";
import { TrelloSectionTypeItemType } from "../GroupBySelectSection";

type EactColoumType = {
  col: EffortGroupType;
  disabled?: boolean;
  basedOnType: TrelloSectionTypeItemType;
  onDragEnd: (ev: Sortable.SortableEvent) => void;
  onDragStart: (ev: Sortable.SortableEvent) => void;
  onCardRemove: (e: Effort) => void;
  colActions?: (col: EffortGroupType) => ItemType[];
  colClick?: (col: EffortGroupType) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "onDragEnd" | "onDragStart">;

const EachColumn = React.forwardRef<HTMLDivElement, EactColoumType>(
  (
    {
      col,
      basedOnType,
      onDragEnd,
      onDragStart,
      onCardRemove,
      disabled,
      colActions,
      colClick,
      style,
      className,
      ...props
    },
    ref
  ) => {
    const { onItemDelete, onItemEdit } = useItemsListContext();
    const _mainContext = useMainContext();
    const _modalContext = useModalContext();
    const _effortsContext = useEffortsContext();
    const _effortUtils = useEffortUtils();

    const _isHome = _effortsContext.currentEffort === undefined;
    const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
    const _isTask = !_isHome && !_isProject;

    const _effort: Effort | undefined =
      basedOnType.label === "effort"
        ? _mainContext.efforts?.find((e) => e.id === parseInt(col.id))
        : undefined;

    const onSubmit = async (data: Dict) => {
      let _errors = await _effortsContext.create(data);

      if (isEmpty(_errors)) {
        _modalContext.dismiss();
      }

      return _errors;
    };

    const openCreateUpdateModal = (formData?: Dict) => {
      // title: _isHome
      //   ? "Project Information"
      //   : _isProject
      //   ? "Task Information"
      //   : "SubTask Information",
      _modalContext.open(
        <EffortCreateUpdateModal onSubmit={onSubmit} parentId={_effort!.id} />
      );
    };

    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          " pb-2 border-s border-y last:border-e w-80 min-w-[20rem] flex flex-col ",
          { "opacity-25": disabled },
          className
        )}
        style={{
          background: col.color + "33",
          ...style,
        }}
      >
        {/* <BeautifulDnDDraggableHandle
          onClick={colClick ? () => colClick(col) : undefined}
          className={cn(
            "flex items-center rounded-t py-3 relative w-full group ",
            { "cursor-grab": basedOnType.colsSortable }
          )}
        > */}
        <div
          onClick={colClick ? () => colClick(col) : undefined}
          className={cn(
            "flex items-center rounded-t py-3 relative w-full group ",
            { "cursor-grab": basedOnType.colsSortable }
          )}
        >
          <div className="flex justify-center flex-1 items-center">
            <Directionality
              className={cn(
                "capitalize text-center mx-3 truncate text-foreground "
              )}
            >
              {col.title}
            </Directionality>
          </div>

          <div className="absolute right-0 md:invisible group-hover:visible">
            <MenuButton
              ellipsisResponsiveBreakPoint="always"
              onEdit={
                !_effort || onItemEdit(_effort) === undefined
                  ? undefined
                  : () => onItemEdit(_effort)?.(_effort)
              }
              onDelete={
                !_effort || onItemDelete([_effort]) === undefined
                  ? undefined
                  : () => onItemDelete([_effort])?.([_effort])
              }
              items={
                !_effort
                  ? undefined
                  : [
                      {
                        text: "new",
                        icon: <LuPlus />,
                        onClick: () => openCreateUpdateModal(),
                      },
                    ]
              }
            />
          </div>
          {/* </BeautifulDnDDraggableHandle> */}
        </div>

        <ReactSortable
          id={col.id + ""}
          group={"Col"}
          ghostClass="opacity-25" //invisible
          setList={() => {}}
          onEnd={onDragEnd}
          onStart={onDragStart}
          list={col.effortIds?.map((e) => ({ id: e })) ?? []}
          disabled={disabled || basedOnType.draggable === false}
          // style={{
          //   maxHeight: `calc(100vh - ${ref.current?.offsetTop}px - 30px)`,
          // }}
          className={cn("p-2 overflow-y-auto  flex-1 shadow-inner ")}
        >
          {/* <BeautifulDnDDroppable
          className={cn(
            "p-2 overflow-y-auto scrollbar-hide flex-1 shadow-inner"
          )}
          droppableProps={{ droppableId: "board" }}
        > */}
          {col.effortIds?.map((e, i) => (
            // <BeautifulDnDDraggable
            //   asChild
            //   key={"eachItem" + e}
            //   draggableProps={{
            //     draggableId: col.id,
            //     index: i,
            //   }}
            // >
            <EachCardItem
              effortId={e}
              key={"eachItem" + e}
              onRemove={basedOnType.required ? undefined : onCardRemove}
              className="cursor-grab mb-2"
            />
            // {/* </BeautifulDnDDraggable> */}
          ))}
          {/* </BeautifulDnDDroppable> */}
        </ReactSortable>
      </div>
    );
  }
);

export default EachColumn;
