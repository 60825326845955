import Dict from "models/Dict";
import { stringify } from 'flatted';
import { format } from "date-fns";
import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"


export function listUnique<T>(arr?: T[] | null, predicate?: (a: T, b: T) => boolean) {
   return arr?.filter((e, i, arr) =>
      arr.findIndex(
         predicate ?
            ((a) => predicate(a, e)) :
            (a => (a as Dict).id === (e as Dict).id)
      ) === i
   );
}

export function listFlatten<T>(arr?: T[][]) {
   return arr?.reduce((r, e) =>
      [...r, ...e],
      [] as T[]
   );
}

export function objSafeGet(obj?: unknown, ...keys: string[]) {
   let _firstKey = listFirst(keys);
   let _result = obj;

   if (
      _firstKey &&
      obj &&
      typeof obj === "object" &&
      _firstKey in obj
   ) {
      _result = objSafeGet((obj as Dict)[_firstKey], ...keys.slice(1));
   }


   return _result;
}

export function listRandomItem<T>(arr?: T[]) {
   return arr?.[Math.floor(arr?.length * Math.random())]
}

export function objSort(obj?: any) {
   let _result = obj;

   if (obj === undefined || obj === null) {

   }
   else if (Array.isArray(obj)) {
      _result = obj.slice().sort().map(e => objSort(e));
   }
   else if (typeof obj === "object") {
      _result = Object.keys(obj).sort()
         .reduce((r, k) =>
            ({ ...r, [k]: objSort(obj[k]) }),
            {}
         );
   }

   return _result;
}

export function listSum<T>(l?: T[] | null, getField?: keyof T | ((e: T) => (number | null | undefined))) {
   return l === undefined || l === null ? l :
      !getField ? undefined :
         (
            typeof getField === "string" ?
               l.map((e) => e[getField]) :
               l.map((e) => (getField as Function)(e))
         ).filter(e => !Number.isNaN(e))
            .reduce((r, e) => r + e, 0) as number;
}

export function sortedStringify(d: any) {
   return stringify(objSort(d));
}

export function makeid(length = 10) {
   var result = '';
   var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

export function objKeysToLowerCase(obj: Dict) {
   return Object.entries(obj)?.map(([key, value]) =>
      ({ [key.toLowerCase()]: value })
   );
}

export function strSplitCammelCase(str?: string) {
   return str?.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function objList2Options(objList?: Dict[], labelFieldName: string = "name") {
   return objList?.map(e => ({ value: e.id, label: e[labelFieldName] }));
}

export function isNumeric(value: string) {
   return /^-?\d+$/.test(value);
}

export function hasOtherKeysExcept<T extends Dict>(data: T, exceptKeys: (keyof typeof data)[]) {
   return Object.keys(getNotEmptyFields(data)!)
      .filter(
         e => !exceptKeys.includes(e)
      ).length > 0;
}

export function getNotEmptyFields(data: Dict | undefined) {
   if (data === undefined) {
      return undefined;
   }

   let _result: Dict = {};

   Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
         if (
            value !== "" ||
            (Array.isArray(value) && value.length > 0)
         ) {
            _result[key] = value;
         }
      }
   })

   return _result;
}

export function objRemoveKeys<T extends Dict>(obj?: T, keys?: string[]) {
   let _result = { ...obj };

   keys?.forEach(e => delete _result[e]);

   return _result;
}

export function objGetParameterCaseInsensitive(object: Dict, key: string) {
   const asLowercase = key.toLowerCase();
   let caseKey = Object.keys(object).find(
      k => k.toLowerCase() === asLowercase
   );

   return caseKey === undefined ? undefined : object[caseKey];
}



export function getItem<T>(index: number | string, l?: T[] | Dict | null) {
   let _result = undefined;

   if (l) {
      if (Array.isArray(l)) {
         _result = l.length > (index as number) && (index as number) >= 0 ?
            l[index as number] : undefined;
      }
      else {
         _result = index in l ? l[index] : undefined;
      }
   }

   return _result;
}


export function str2Color(str: string) {
   let hash = 0;
   str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
   })
   let colour = '#'
   for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      colour += value.toString(16).padStart(2, '0')
   }
   return colour
}


export function getOnlyFields(obj: Dict | Dict[] | undefined, fieldNames: (string | { [fieldName: string]: string[] })[]): Dict | Dict[] | undefined {
   let _result: Dict | Dict[] | undefined = undefined;

   if (obj === undefined) {
      _result = undefined;
   }
   else if (Array.isArray(obj)) {
      _result = obj?.map(e => getOnlyFields(e, fieldNames));
   }
   else {
      let _r = {} as Dict;

      fieldNames.forEach(eachField => {
         if (typeof eachField === "string") {
            if (eachField in obj) {
               _r[eachField] = obj[eachField];
            }
         }
         else {
            let fieldName = Object.keys(eachField)[0];
            let _fieldNames = Object.values(eachField)[0];
            _r[fieldName] = getOnlyFields(obj[fieldName], _fieldNames);
         }
      });

      _result = _r;
   }

   return _result;
}

export function toggleElement(value: string | number | Dict, l?: (string | number | Dict)[]) {
   return l === undefined ? [value] :
      l.includes(value) ?
         l.filter(e => e !== value) :
         l.concat(value);
}


export function strCapitalizefirstLetter(str?: string) {
   return str ? str.charAt(0).toUpperCase() + str.slice(1) : undefined;
}

export function getFileType(name: string) {
   let _result = undefined;

   if (["png", "jpg", "jpeg", "svg"].some(e => name.endsWith(e))) {
      _result = "image";
   }

   return _result;
}


export function base64toFile(content: string, ex?: string) {
   var arr = content.split(','),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

   while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
   }

   return new File([u8arr], makeid() + (ex?.startsWith(".") ? "" : ".") + ex, { type: mime });
}

export function fileToBase64(file: File) {
   const reader = new FileReader()
   return new Promise<string>(resolve => {
      reader.onload = ev => {
         resolve(ev.target!.result as string)
      }
      reader.readAsDataURL(file);
   });
}

export function isBase64(str: string) {
   if (str === '' || str.trim() === '') { return false; }
   try {
      return btoa(atob(str)) === str;
   } catch (err) {
      return false;
   }
}

// export function ensureInRange({ min, max, value }: { min?: number, max?: number, value: number }) {
//    let _result: Dict = {};

//    fieldNames.forEach(fieldName => {
//       if(fieldName in obj) {
//          _result[fieldName] = obj[fieldName];
//       }
//    })

//    return value < min ? value;
// }


export function groupBy<T>({ list, callbackFunc }: { list?: T[] | null, callbackFunc: (item: T) => string | number }) {
   return list?.reduce((result, eachItem) => {

      let _key = callbackFunc(eachItem);

      return {
         ...result,
         [_key]: result[_key] ? [...result[_key], eachItem] : [eachItem]
      };
   },
      {} as { [key: string | number]: T[] }
   );
}


export function dataMapper(data: Dict | undefined, map: (string | Dict)[]) {
   if (data === undefined) {
      return undefined;
   }

   let _result: Dict = {};

   map.forEach(eachItem => {
      if (typeof eachItem === "string") {
         _result[eachItem] = data[eachItem];
      }
      else {
         let [from, to] = Object.entries(eachItem)[0];
         _result[to] = data[from];
      }
   });

   return _result;
}

export function randomInt(max = 1, min = 0) {
   return Math.floor(Math.random() * (max - min) + min);
}

export function randomHexColor() {
   return "#000000".replace(/0/g, () => (~~(Math.random() * 16)).toString(16));
}


export function convert2Date(date?: Date | number | string | null) {
   date = typeof date === "string" &&
      date.includes("T") &&
      !date.includes("Z") ?
      date + "Z" :
      date;

   return date === undefined || date === null ? date : new Date(date);
}

export function dateCompare(d1?: Date | string | number | null, d2?: Date | string | number | null) {
   if (d1 === undefined || d1 === null) return d1;
   if (d2 === undefined || d2 === null) return d2;

   return dateGetOnlyDate(d1)!.localeCompare(dateGetOnlyDate(d2)!);
}

export function dateGetOnlyDate(date?: Date | string | number | null) {
   let _result = undefined;

   try {
      if (date !== undefined && date !== null) {
         _result = format(convert2Date(date)!, "y-MM-dd");
      }
      // listFirst(convert2Date(date)?.toISOString().split("T"));
   }
   catch (_) { }

   return _result;
}

export function dateGetTime(date?: Date | string | number | null) {
   return date === undefined || date === null ? 0 : convert2Date(date)!.getTime();
}


export function dateAdd({ date, days = 0, hours = 0, minutes = 0, seconds = 0 }: { date?: Date | string | number | null, days?: number, hours?: number, minutes?: number, seconds?: number }) {
   return date === undefined || date === null ? date :
      new Date(
         dateGetTime(date) +
         (days * 24 * 60 * 60 * 1000) +
         (hours * 60 * 60 * 1000) +
         (minutes * 60 * 1000) +
         (seconds * 1000)
      );
}

export function isDateUntil({ date, days = 0, hours = 0, minutes = 0, seconds = 0 }: { date?: Date | string | number | null, days?: number, hours?: number, minutes?: number, seconds?: number }) {
   return date === undefined || date === null ? date :
      (
         new Date().getTime() <=
         dateGetTime(dateAdd({ date, days, hours, minutes, seconds }))
      );
}


export function isEmpty(value?: any) {
   return (value === undefined || value === null || Number.isNaN(value)) ||
      (typeof value === "string" ? (value === '' || value === "") : false) ||
      (Array.isArray(value) ? (value.length === 0) : false) ||
      (typeof value === "object" && !Array.isArray(value) && !(value instanceof Date) && Object.keys(value).length === 0);
}


export function dateIsToday(date?: Date | number | string | null) {
   return date ? new Date(date).getDate() === new Date().getDate() : date
}


export function dateGetWeekDayName(date = dateGetToday()) {
   var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
   return days[date.getDay()];
}

export function listPartition<T,>(
   arr: T[],
   predicate: (element: T, index: number, array: T[]) => boolean
) {
   return arr.reduce(function (r, e, i) {
      let _predicate = predicate(e, i, arr);

      listLast(r)!.push(e);

      if (_predicate) r.push([])

      return r;
   }, [[]] as T[][]);
};


export function listChunk<T>(arr?: T[], chunkSize = 1) {
   let _result: T[][] = [];

   for (let i = 0; i < (arr?.length ?? 0); i += chunkSize) {
      _result.push(arr!.slice(i, i + chunkSize));
   }

   return _result;
}


export function deepClone<T>(obj?: T | null) {
   return obj === undefined || obj === null ? obj :
      JSON.parse(JSON.stringify(obj)) as T;
}

export function listFirst<T>(arr?: T[] | null | Dict) {
   return getItem(0, arr) as T | undefined;
}

export function listLast<T>(arr?: T[]) {
   return arr ? getItem(arr.length - 1, arr) as T | undefined : arr;
}

export function dateGetToday(index = 0) {
   let date = new Date();
   return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
}

export function dateGetWeek(index = 0) {
   let date = dateGetToday();
   var first = dateAdd({ date, days: -(date.getDay() + 1) + (index * 7) });

   return Array(7).fill(null)
      .map((_, i) => {
         let _r = dateAdd({ date: first, days: 1 + i })!;
         return new Date(_r.getFullYear(), _r.getMonth(), _r.getDate(), 0, 0, 0)
      })
}

export function dateGetMonth(index = 0) {
   let date = new Date();
   let _thisMonthDays = new Date(date.getFullYear(), date.getMonth() + 1 + index, 0).getDate();
   return Array(_thisMonthDays).fill(null)
      .map((_, i) => new Date(date.getFullYear(), date.getMonth() + index, i + 1, 0, 0, 0))
}

export function isBright(hexcolor: string = "") {
   var r = parseInt(hexcolor.substring(1, 3), 16);
   var g = parseInt(hexcolor.substring(3, 5), 16);
   var b = parseInt(hexcolor.substring(5, 7), 16);
   var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
   return yiq >= 128;
}

export function dateToTimeString(d: Date) {
   let result = d.toLocaleTimeString("fa-IR").split(":").splice(0, 2).join(":");
   return result === "۰:۰۰" ? "" : result;
}

export function isFarsi(str?: string) {
   var p = /^[\u0600-\u06FF\s]+$/;
   return str !== undefined && str.length > 0 && p.test(str[0]);
}

export function cn(...inputs: ClassValue[]) {
   return twMerge(clsx(inputs))
}