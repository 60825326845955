import React from "react";
import { useNavigate } from "react-router";

import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider";
import { useModalContext } from "../../../../providers/ModalProvider";

import Avatar from "components/common/Avatar";
import EmailEditModal from "./EmailEditModal";
import PhoneEditModal from "./PhoneEditModal";
import AccountEditModal from "./AccountEditModal";
import PasswordEditModal from "./PasswordEditModal";
import DividerWithTitle from "components/common/DividerWithTitle";
import ProfileAvatar from "./ProfileAvatar";
import { LuPencil, LuLogOut } from "react-icons/lu";
import { Button } from "components/ui/button";

function EachEditItem({
  title,
  onClick,
  content,
}: {
  title: string;
  onClick: () => void;
  content: React.ReactNode;
}) {
  return (
    <div className="col-12 md:col-6 p-1">
      <Button
        variant={"light"}
        onClick={onClick}
        className=" border w-full p-3 bg-card flex-col items-start"
      >
        <div className="flex items-center">
          <LuPencil className="me-2 text-secondary-foreground" />
          Edit {title}
        </div>

        <div className="flex items-center justify-center w-full h-14">
          <b>{content}</b>
        </div>
      </Button>
    </div>
  );
}

export default function AccountSectionContent({ isAdminPanel = false }) {
  const _currentUserContext = useCurrentUserContext();
  const _modalContext = useModalContext();
  const navigate = useNavigate();

  if (!_currentUserContext.isLoggedIn()) {
    navigate("/");
    return null;
  }

  const openAccountEditModal = () => {
    _modalContext.open(
      <AccountEditModal onSubmit={() => _modalContext.dismiss()} />
    );
  };

  const openPhoneEditModal = () => {
    _modalContext.open(
      <PhoneEditModal onSubmit={() => _modalContext.dismiss()} />
    );
  };

  const openEmailEditModal = () => {
    _modalContext.open(
      <EmailEditModal onSubmit={() => _modalContext.dismiss()} />
    );
  };

  const openPasswordEditModal = () => {
    _modalContext.open(
      <PasswordEditModal onSubmit={() => _modalContext.dismiss()} />
    );
  };

  return (
    <section className="px-4 mx-auto max-w-[600px]">
      <span className="block my-4 text-foreground">
        You can edit your info by clicking on the below tiles.
      </span>

      <div className="row p-0 m-0 ">
        <EachEditItem
          title="Info"
          content={
            <div className="flex items-center">
              <ProfileAvatar user={_currentUserContext.user} size={40} />
              <span className="ms-2">{_currentUserContext.user!.userName}</span>
            </div>
          }
          onClick={openAccountEditModal}
        />

        <EachEditItem
          title="Phone"
          content={_currentUserContext.user?.phone ?? "-"}
          // content={
          //           _currentUserContext.user?.phone === null || isNaN(_currentUserContext.user?.phone) ?  "-" : _currentUserContext.user?.phone
          //       }
          onClick={openPhoneEditModal}
        />

        <EachEditItem
          title="Email"
          content={_currentUserContext.user?.email ?? "-"}
          onClick={openEmailEditModal}
        />

        <EachEditItem
          title="Password"
          content={""}
          onClick={openPasswordEditModal}
        />
      </div>

      <DividerWithTitle title="Account Logout" />

      <span className="text-foreground">
        You can logout from your account with the following button.
      </span>

      <Button
        variant={"light"}
        className=" border w-full justify-start p-4 mt-3 bg-card"
        onClick={() => _currentUserContext.logout()} //isAdminPanel ? "/admin/login" : "/"
      >
        <LuLogOut />
        <span className="mb-0">Logout from your Account</span>
      </Button>
    </section>
  );
}
