
import ApiToken from "models/views/ApiToken";
import { z } from "zod";



export default function useApiTokenUtils() {

    const isAccessTokenValid = (token: ApiToken) => {
        return new Date(token.accessTokenExpiryDate) > new Date();
    }

    const isRefreshTokenValid = (token: ApiToken) => {
        return new Date(token.refreshTokenExpiryDate) > new Date();
    }

    return {
        isAccessTokenValid,
        isRefreshTokenValid,
    }

}
