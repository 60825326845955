import React from "react";

import Dict from "models/Dict";

import { useCurrentUserContext } from "providers/CurrentUserProvider";
import {
  useAuditLogsContext,
  AuditLogsProvider,
} from "providers/AuditLogsProvider";

import { MobileFooterPadding } from "components/layouts/MainFooter";
import ItemsListSection from "components/common/ItemsListSection/ItemsListSection";
import AuditLog, { AuditLogFilter, AuditLogSortType } from "models/AuditLog";
import FilterButton from "components/common/AdminSection/FilterButton";
import { useModalContext, ModalProvider } from "providers/ModalProvider";
import AuditLogFilterModal from "./AuditLogFilterModal";
import { useUsersContext, UsersProvider } from "providers/UsersProvider";
import ProfileAvatar from "../profile/account/ProfileAvatar";
import ItemsListProvider, {
  FieldType,
} from "components/common/ItemsListSection/ItemsListProvider";
import LocalDateTime from "components/common/LocalDateTime";
import { dateGetTime } from "services/UtilServices";
import useAuditLogUtils from "hooks/utils/UseAuditLogUtils";
import MainHeaderBody from "components/layouts/MainHeader/MainHeader";
import MainSection from "components/layouts/MainSection";
import ItemsListMainSection from "components/common/ItemsListSection/ItemsListMainSection";

function EachAuditLogItem({
  auditlog,
  index,
}: {
  auditlog: AuditLog;
  index: number;
}) {
  return (
    <div className="flex items-center px-1">
      <h6 className="mb-0"> {index + 1} </h6>

      <div className="flex ps-3">
        <div className="flex p-0 mb-2">
          {/* <ProfileAvatar user={user} /> */}
          <h6 className="mb-0 my-auto text-justify me-auto ms-1">
            {<ProfileAvatar user={auditlog.user} />}
          </h6>
          {/* <h6 className="mb-0 my-auto text-justify me-auto ms-1">{_userUtils.getFullName(user)}</h6> */}
          {/* <h6 className="mb-0 my-auto text-justify me-auto ms-3">{auditlog.loginProvider}</h6> */}
        </div>

        <div className="flex p-0 mb-2">
          <h6 className="mb-0 my-auto text-justify me-auto ms-3">
            {auditlog.identityActionType}
          </h6>
          {/* <h6 className="mb-0 my-auto text-justify me-auto ms-3">{auditlog.device}</h6>*/}
          <h6 className="mb-0 my-auto text-justify me-auto ms-3">
            <LocalDateTime date={auditlog.dateCreated} />
          </h6>
        </div>
      </div>
    </div>
  );
}

function AuditLogSectionBody() {
  const _modalContext = useModalContext();
  const _auditLogsContext = useAuditLogsContext();
  const _usersContext = useUsersContext();
  const auditLogUtils = useAuditLogUtils();

  const onFilterSubmit = (formData: Dict) => {
    _auditLogsContext.get({
      ...formData,
      pageNumber: -1,
    } as AuditLogFilter);
    _modalContext.dismiss();
  };

  const openFilterModal = () => {
    _usersContext.getAll();

    _modalContext.open(
      <AuditLogFilterModal
        pageType={_auditLogsContext.pageType}
        filter={_auditLogsContext.filter}
        onSubmit={onFilterSubmit}
      />
    );
  };

  return (
    <ItemsListMainSection<AuditLog>
      title="Audit Logs"
      data={_auditLogsContext.auditlogs}
      // ellipsisResponsiveBreakPoint=""

      fields={[
        // {
        //   value: "",
        //   label: "#",
        //   className: "text-start whitespace-nowrap",
        //   width: "70px",
        //   isSortable: false,
        //   getUiContent: (e, i) => i + 1,
        // },

        {
          value: "client",
          label: "client",
          width: "auto",
          // getUiContent: (e, i) => UserRoles.options[e.role],
          className: "whitespace-nowrap",
          // isSortable: false,
        },

        {
          value: "device",
          label: "device",
          width: "auto",
          // isSortable: false,
          // getUiContent: (e, i) => e.office?.name,
          className: "whitespace-nowrap",
        },

        {
          value: "identityActionType",
          label: "Action",
          width: "auto",
          isSortable: false,
          // getUiContent: (e, i) => e.office?.name,
          className: "whitespace-nowrap",
        },

        {
          value: "ipv4Address",
          label: "IP Address",
          width: "auto",
          className: "whitespace-nowrap",
          // isSortable: false,
        },

        ...(_auditLogsContext.pageType === "admin"
          ? ([
              {
                value: "userId",
                label: "User",
                className: "text-start whitespace-nowrap",
                width: "70px",
                isSortable: false,
                getUiContent: (e, i) => <ProfileAvatar user={e.user} />,
              },
              {
                value: "loginProvider",
                label: "Login Provider",
                width: "10%",
                isSortable: false,
                // getUiContent: (e, i) => <ProfileAvatar user={e} />,
              },
              {
                value: "providerKey",
                label: "Provider Key",
                width: "auto",
                className: "whitespace-nowrap",
                // isSortable: false,
              },
              {
                value: "providerDisplayName",
                label: "Provider DisplayName",
                width: "auto",
                className: "whitespace-nowrap",
                // isSortable: false,
              },
            ] as FieldType<AuditLog>[])
          : []),

        {
          value: "dateCreated",
          label: "Created On",
          width: "auto",
          // isSortable: false,
          getValue: (e) => dateGetTime(e.dateCreated),
          getUiContent: (e, i) => <LocalDateTime date={e.dateCreated} />,
          className: "whitespace-nowrap",
        },
      ]}
      onLoadMore={
        !_auditLogsContext.hasNext
          ? undefined
          : async () => {
              await _auditLogsContext.get({
                ..._auditLogsContext.filter,
                pageNumber: _auditLogsContext.filter.pageNumber + 1,
              });
            }
      }
      onSortField={(v) => {
        _auditLogsContext.get({
          ..._auditLogsContext.filter,
          sortBy: v as AuditLogSortType,
          pageNumber: -1,
        });
      }}
      onSortDir={(v) => {
        _auditLogsContext.get({
          ..._auditLogsContext.filter,
          ascOrder: v === 1,
          pageNumber: -1,
        });
      }}
      initialSelectedSortFieldValue="dateAdd"
      buildCardTitleRow={(v, i) => <EachAuditLogItem auditlog={v} index={i} />}
      // buildCardBodyItem={(v, i) => <EachUserItem user={v} index={i} />}

      // onItemEdit={(u) => openCreateUpdateModal}
      // onItemClick={(u) => openCreateUpdateModal}
      // onItemDelete={() => _auditLogsContext.removeMany}

      actionsSection={
        <>
          <FilterButton
            count={_auditLogsContext.totalNumber}
            isActive={auditLogUtils.isFilterActive({
              filter: _auditLogsContext.filter,
              pageType: _auditLogsContext.pageType,
            })}
            onClick={openFilterModal}
          />

          {/* <NewButton onClick={() => openCreateUpdateModal()} /> */}
        </>
      }
    />
  );
}

export default function AuditLogsPage({
  pageType = "profile",
}: {
  pageType?: "profile" | "admin";
}) {
  return (
    <AuditLogsProvider pageType={pageType}>
      <UsersProvider>
        <ModalProvider>
          <AuditLogSectionBody />
        </ModalProvider>
      </UsersProvider>
    </AuditLogsProvider>
  );
}
