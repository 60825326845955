import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import State from "models/State";
import Effort, { EffortFilter, EffortSortTypes } from "models/Effort";

import useEffortApi from "hooks/api/UseEffortApi";
import LocalStorageServices from "services/localServices/LocalStorageServices";

import { useMainContext } from "./MainProvider";
import { useCurrentUserContext } from "./CurrentUserProvider";
import { groupBy, listFlatten, sortedStringify } from "services/UtilServices";
import TicketCount from "models/TicketCount";
import useTicketCountUtils from "hooks/utils/UseTicketCountUtils";
import useDownloadServices from "hooks/UseDownloadServices";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import useApiServices from "hooks/api/UseApiServices";
import useExportApi from "hooks/api/UseExportApi";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";
import { useToast } from "components/ui/use-toast";
import { useNavigate } from "react-router";
import { TRELLO_SECTION_TYPES } from "components/pages/EffortsSection/GroupBySelectSection";
import { ActivitysProvider } from "./ActivitysProvider";
import { DepartmentsProvider } from "./DepartmentsProvider";
import { MembersProvider } from "./MembersProvider";
import { ModalProvider } from "./ModalProvider";
import { PrefixsProvider } from "./PrefixsProvider";
import { PrioritiesProvider } from "./PrioritiesProvider";
import { StatesProvider } from "./StatesProvider";
import { TagsProvider } from "./TagsProvider";
import { TicketsProvider } from "./TicketsProvider";
import { TypesProvider } from "./TypesProvider";
import { UsersProvider } from "./UsersProvider";
import { AttachmentsProvider } from "./AttachmentsProvider";

export interface UpdateStateInputType {
  effortIds: number[];
  stateId: number;
  state: State;
}

interface EffortsContextProps {
  currentEffort: Effort | undefined;
  efforts: Effort[] | undefined;
  create: (item: Dict) => Promise<Dict>;
  update: (item: Dict) => Promise<Dict>;
  updateState: (item: UpdateStateInputType) => Promise<Dict>;
  removeMany: (items: Dict[]) => Promise<Dict>;

  setCurrent: (data: Dict) => void;
  get: (filter: EffortFilter) => Promise<void>;
  filter: EffortFilter;
  hasNext: boolean;

  groupBy: keyof typeof TRELLO_SECTION_TYPES | null;
  setGroubBy: (d: keyof typeof TRELLO_SECTION_TYPES | null) => void;
  viewType: number | string;
  setViewType: React.Dispatch<React.SetStateAction<number | string>>;
}

const EffortsContext = React.createContext({} as EffortsContextProps);
EffortsContext.displayName = "EffortsContext";

// var items: Effort[] | undefined = undefined;
// var globalFilter: EffortFilter = {
//   pageNumber: -1,
//   ascOrder: false,
//   numberInPage: 999,
//   sortBy: EffortSortTypes.ADDWHEN,
//   parentId: "0"
// isActive: true,
// };

function EffortsProvider({
  children,
  effortId,
}: {
  children: React.ReactNode;
  effortId?: number;
}) {
  const _currentUserContext = useCurrentUserContext();
  const _mainContext = useMainContext();

  const effortApi = useEffortApi();
  const downloadServices = useDownloadServices();
  const textEditorUtils = useTextEditorUtils();
  const apiServices = useApiServices();
  const exportApi = useExportApi();
  const _effortUtils = useEffortUtils();
  const _ticketCountUtils = useTicketCountUtils();

  const { toast } = useToast();
  const navigate = useNavigate();
  const currentEffort = _mainContext.efforts?.find((e) => e.id === effortId);

  // const [_items, _setEfforts] = React.useState<Effort[]>();
  const [_groupBy, _setGroubBy] = React.useState<
    keyof typeof TRELLO_SECTION_TYPES | null
  >(null);
  const [viewType, setViewType] = React.useState<number | string>();
  const [_filter, _setFilter] = React.useState<EffortFilter>({
    pageNumber: -1,
    ascOrder: false,
    sortBy: EffortSortTypes.ADDWHEN,
    numberInPage: 900,
    parentId: _effortUtils.isProject(currentEffort) ? 0 : effortId,
    isActive: true,
  });

  React.useEffect(() => {
    LocalStorageServices.get("EFFORT_GROUP_BY").then((r) => setGroubBy(r));

    LocalStorageServices.get("EFFORT_SECTION_VIEW_TYPE").then((r) =>
      setViewType(r ?? "table")
    );

    // LocalStorageServices.get("EFFORT_FILTER").then((r) => {
    //   _setFilter(
    //     r === null
    //       ? {
    //           pageNumber: -1,
    //           ascOrder: false,
    //           sortBy: EffortSortTypes.ADDWHEN,
    //           parentId: parentId === null ? "0" : parentId,
    //           isActive: true,
    //         }
    //       : r
    //   );
    // });
  }, []);

  const setGroubBy = (value: keyof typeof TRELLO_SECTION_TYPES) => {
    _setGroubBy(value);
    LocalStorageServices.set("EFFORT_GROUP_BY", value);
  };

  const setFilter = (o: EffortFilter) => {
    // globalFilter = o;
    _setFilter(o);
    // LocalStorageServices.set("EFFORT_FILTER", o);
  };

  const get = async (filter: EffortFilter) => {
    // if(JSON.stringify(filter) === JSON.stringify(globalFilter)) return;

    setFilter(filter);

    // if(filter.pageNumber <= 0) {
    //   setHasNext(true);
    //   setEfforts(undefined);
    // }

    // let response = await effortApi.get(filter);

    // setHasNext(response?.length >= 20);

    // response = response.filter((eachRes: Dict) =>
    //   !items?.find(e => e.id === eachRes.id)
    // );

    // setEfforts([
    //   ...items ?? [],
    //   ...response as Effort[]
    //   ]);
  };

  const setCurrent = (data: Dict) => {
    _mainContext.setEffort({
      ...currentEffort,
      ...data,
    } as Effort);
  };

  const create = async (formData: Dict) => {
    let _errors = {};

    try {
      // if (effortId !== null) {
      //   formData.parentId = effortId;
      // }

      if (formData.description === undefined) {
        formData.description = "";
      }

      toast("Creating...");
      formData.isActive = true;
      let { id, url, memberships, dateAdd } = await effortApi.create(formData);

      formData = {
        ...formData,

        dateAdd,
        id,
        url,
        parentId: formData.parentId ?? null,

        members: [memberships],
        creator: _currentUserContext.user,
      };

      if (formData.avatarUrl || formData.description) {
        toast("Uploading Files...");
        _errors = await update(formData);
      }

      const _project = _effortUtils.getParentProject(formData.parentId);
      _mainContext.setEfforts((prev) =>
        [formData as Effort, ...(prev ?? [])].map((eachPrev) =>
          _project?.id !== eachPrev.id
            ? eachPrev
            : {
                ...eachPrev,
                ticketsCount: _ticketCountUtils.add(
                  ...(eachPrev.ticketsCount ?? []),
                  ...(formData!.ticketsCount ?? ([] as TicketCount[]))
                ),
              }
        )
      );
      toast("Done!");
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const update = async (formData: Dict) => {
    let _errors = {};

    try {
      formData.avatarUrl = await apiServices.sendFile({
        file: formData.avatarUrl,
        effortId: formData.id,
        fieldName: "avatarUrl",
      });

      formData.description = await textEditorUtils.uploadImages({
        value: formData.description,
        fieldName: "description",
        effortId: formData.id,
      });

      toast("Updating...");
      await effortApi.update(formData);
      toast("Updated!");

      const _oldEffort = _mainContext.efforts?.find(
        (e) => e.id === formData.id
      );
      const _project = _effortUtils.getParentProject(formData.id);

      _mainContext.setEfforts((prev) =>
        prev?.map((eachPrev) =>
          eachPrev.id === formData.id
            ? {
                ...(formData as Effort),
                url: (formData.index ?? formData.url) + "",
              }
            : _project?.id === eachPrev.id
            ? {
                ...eachPrev,
                ticketsCount: _ticketCountUtils.add(
                  ...(_ticketCountUtils.subtract(
                    eachPrev.ticketsCount,
                    ...(_oldEffort!.ticketsCount ?? [])
                  ) ?? []),
                  ...(formData!.ticketsCount ?? ([] as TicketCount[]))
                ),
              }
            : eachPrev
        )
      );

      if (currentEffort && formData.title !== _oldEffort?.title) {
        navigate(
          _effortUtils
            .makeUrl(formData.id)!
            .replace(_oldEffort!.title, formData.title)
        );
      }
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);
    }

    return _errors;
  };

  const updateState = async (data: UpdateStateInputType) => {
    let _errors = {};
    let oldEfforts = _mainContext.efforts!.filter((e) =>
      data.effortIds.includes(e.id)
    );

    try {
      _mainContext.setEfforts((prev) =>
        prev?.map((e) =>
          !data.effortIds.includes(e.id)
            ? e
            : ({
                ...e,
                stateId: data.stateId,
                state: data.state,
              } as Effort)
        )
      );

      await effortApi.updateState(data);
    } catch (e) {
      _errors = e as AxiosError;
      console.log(e);

      _mainContext.setEfforts((prev) =>
        prev?.map((eachEffort) =>
          data.effortIds.includes(eachEffort.id)
            ? oldEfforts.find((e) => e.id === eachEffort.id)!
            : eachEffort
        )
      );
    }

    return _errors;
  };

  const removeMany = async (itemsToDelete: Effort[]) => {
    let _errors = {};

    try {
      const ids = itemsToDelete.map((e) => e.id);

      await effortApi.remove(ids);

      let _allIdsToDelete = itemsToDelete
        .map((e) =>
          _effortUtils.getSubEffortsDeep({
            id: e.id,
          })
        )
        .reduce((result, e) => [...result, ...e], [] as Effort[])
        .map((e) => e.id);

      const _groupedByProject = groupBy({
        list: itemsToDelete,
        callbackFunc: (e) => _effortUtils.getParentProject(e.id)!.id,
      })!;

      _mainContext.setEfforts((prev) =>
        prev
          ?.filter((e) => !_allIdsToDelete.includes(e.id))
          .map((eachPrev) =>
            !(eachPrev.id in _groupedByProject)
              ? eachPrev
              : {
                  ...eachPrev,
                  ticketsCount:
                    _ticketCountUtils.subtract(
                      eachPrev.ticketsCount,
                      ..._ticketCountUtils.add(
                        ...listFlatten(
                          _groupedByProject[eachPrev.id].map(
                            (e) => e.ticketsCount ?? []
                          )
                        )!
                      )
                    ) ?? [],
                }
          )
      );
    } catch (e) {
      _errors = e as AxiosError;
      // console.log(e);
    }

    return _errors;
  };

  return (
    <EffortsContext.Provider
      value={
        {
          currentEffort,
          efforts: _mainContext.efforts
            ?.filter((e) => e.parentId === (effortId ?? null))
            .filter((e) => _effortUtils.isMatch(e, _filter))
            .map((e) => ({
              ...e,
              index: parseInt(e.url),
              url: _effortUtils.makeUrl(e.id),
              subTasksCount:
                _effortUtils.getSubEffortsDeep({
                  id: e.id,
                }).length - 1,
            })),
          create,
          update,
          updateState,
          removeMany,
          setCurrent,

          get,
          filter: _filter,
          // hasNext,

          groupBy: _groupBy,
          setGroubBy,
          viewType,
          setViewType,
        } as EffortsContextProps
      }
    >
      <ActivitysProvider>
        <TicketsProvider>
          <PrioritiesProvider>
            <PrefixsProvider>
              <DepartmentsProvider>
                <StatesProvider>
                  <TagsProvider>
                    <TypesProvider>
                      <UsersProvider>
                        <MembersProvider>
                          <AttachmentsProvider>
                            <ModalProvider>{children}</ModalProvider>
                          </AttachmentsProvider>
                        </MembersProvider>
                      </UsersProvider>
                    </TypesProvider>
                  </TagsProvider>
                </StatesProvider>
              </DepartmentsProvider>
            </PrefixsProvider>
          </PrioritiesProvider>
        </TicketsProvider>
      </ActivitysProvider>
    </EffortsContext.Provider>
  );
}

export function useEffortsContext() {
  const _context = React.useContext(EffortsContext);

  if (!_context) {
    throw new Error("cannot use EffortsContext outside of its provider.");
  }

  return _context;
}

export { EffortsContext, EffortsProvider };
export type { EffortsContextProps };
