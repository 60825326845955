import React from "react";
import { AxiosError } from "axios";

import Dict from "models/Dict";
import {
  CurrentUserContext,
  useCurrentUserContext,
} from "./CurrentUserProvider";

import useAuditLogApi from "hooks/api/UseAuditLogApi";
import AuditLog, { AuditLogFilter, AuditLogSortType } from "models/AuditLog";
import { useToast } from "components/ui/use-toast";

interface AuditLogsContextProps {
  auditlogs: AuditLog[] | undefined;
  get: (filter: AuditLogFilter) => Promise<void>;
  filter: AuditLogFilter;
  hasNext: boolean;
  totalNumber?: number;
  pageType?: "profile" | "admin";
}

const AuditLogsContext = React.createContext({} as AuditLogsContextProps);
AuditLogsContext.displayName = "AuditLogsContext";

function AuditLogsProvider({
  children,
  pageType = "profile",
}: {
  children: React.ReactNode;
  pageType?: "profile" | "admin";
}) {
  const _currentUserContext = useCurrentUserContext();

  const DEFAULT_FILTER = {
    pageNumber: -1,
    ascOrder: false,
    sortBy: AuditLogSortType.dateCreated,

    userIds: pageType === "profile" ? [_currentUserContext.user?.id!] : null,
  };

  const itemsRef = React.useRef<AuditLog[] | undefined>(undefined);
  const globalFilterRef = React.useRef<AuditLogFilter>({
    ...DEFAULT_FILTER,
  });

  const [_items, _setAuditLogs] = React.useState<AuditLog[]>();
  const [_filter, _setFilter] = React.useState<AuditLogFilter>(
    globalFilterRef.current
  );
  const [hasNext, setHasNext] = React.useState<boolean>(true);
  const [totalNumber, setTotalNumber] = React.useState<number>();

  const { toast } = useToast();
  const auditLogApi = useAuditLogApi();

  React.useEffect(() => {
    setAuditLogs(undefined);
    setFilter({ ...DEFAULT_FILTER });
    setHasNext(true);
    setTotalNumber(undefined);
    return () => {
      setAuditLogs(undefined);
      _setFilter({ ...DEFAULT_FILTER });
      setHasNext(true);
      setTotalNumber(undefined);
    };
  }, []);

  const setAuditLogs = (o?: AuditLog[]) => {
    itemsRef.current = o;
    _setAuditLogs(o);
  };

  const setFilter = (o: AuditLogFilter) => {
    globalFilterRef.current = o;
    _setFilter(o);
  };

  const get = async (filter: AuditLogFilter) => {
    if (JSON.stringify(filter) === JSON.stringify(globalFilterRef.current)) {
      setAuditLogs(itemsRef.current);
      return;
    }

    setFilter(filter);

    if (filter.pageNumber < 0) {
      setHasNext(true);
      setAuditLogs(undefined);
      setTotalNumber(undefined);
      return;
    }

    try {
      let { items, totalNumber } = await auditLogApi.get(filter);

      setHasNext(items?.length >= 20);
      setTotalNumber(totalNumber);

      items = items.filter(
        (eachRes: Dict) =>
          !itemsRef.current?.some((e) => e.providerKey === eachRes.providerKey)
      );

      setAuditLogs([...(itemsRef.current ?? []), ...(items as AuditLog[])]);
    } catch (e) {
      toast((e as AxiosError).message, {
        variant: "destructive",
      });
      setHasNext(false);
      if (itemsRef.current === undefined) {
        setAuditLogs([]);
      }
    }
  };

  return (
    <AuditLogsContext.Provider
      value={
        {
          auditlogs: itemsRef.current,
          get,
          // getAll,
          filter: _filter,
          hasNext,
          pageType,
          totalNumber,
        } as AuditLogsContextProps
      }
    >
      {children}
    </AuditLogsContext.Provider>
  );
}

export function useAuditLogsContext() {
  const _context = React.useContext(AuditLogsContext);

  if (!_context) {
    throw new Error("cannot use AuditLogsContext outside of its provider.");
  }

  return _context;
}

export { AuditLogsContext, AuditLogsProvider };
export type { AuditLogsContextProps };
