import React from "react";

import MainSection from "components/layouts/MainSection";
import { MobileFooterPadding } from "components/layouts/MainFooter";

function StatsPageBody() {
  return (
    <div className="px-2 lg:px-3 ">
      <MobileFooterPadding />
    </div>
  );
}

function StatsPage() {
  return (
    <MainSection title="آمار">
      <StatsPageBody />
    </MainSection>
  );
}

export default StatsPage;
