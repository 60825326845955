import { TypeFilter } from "models/Type";
import { hasOtherKeysExcept } from "services/UtilServices";


export default function useTypeUtils() {
  const isFilterActive = ({ filter }: { filter: TypeFilter }) => {
    return hasOtherKeysExcept(filter, ["pageNumber", "ascOrder", "sortBy", "numberInPage"]);
  }

  return {
    isFilterActive
  }
}
