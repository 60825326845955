import React from "react";

import { z } from "zod";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as EditorType } from "tinymce/tinymce";

import Attachment, { attachmentSchema } from "models/Attachment";

import { SelectManyImageModal } from "services/SelectImageServices";
import {
  cn,
  fileToBase64,
  getFileType,
  isEmpty,
  isFarsi,
  makeid,
} from "services/UtilServices";

import { useFormContext } from "./Form";
import { useModalContext } from "providers/ModalProvider";

import Skeleton from "../Skeleton";
import { ValidationType } from "./UseValidation";
import FormFieldBase from "./FormFieldBase";
import AttachmentItem from "components/pages/EachEffort/AttachmentsSection/AttachmentItem";
import { useGalleryViewContext } from "providers/GalleryViewProvider";
import { useToast } from "components/ui/use-toast";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import { DialogContent } from "components/ui/dialog";
import useValidation from "./UseValidation";
import { useThemeContext } from "providers/ThemeProvider";
import { DropZone } from "../DropZone";
import { useSelectFile } from "./SelectFileButton";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";

export function CollapsableTextEditorValue({
  value,
  className,
}: {
  value?: TextEditorType;
  className?: string;
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const textEditorUtils = useTextEditorUtils();

  const _hasContent = textEditorUtils.hasContent(value);

  let isCollapsable = false;
  if (value?.content || value?.attachments) {
    isCollapsable =
      textEditorUtils.getInnerText(value).length > 300 ||
      textEditorUtils.getInlineImages(value).length > 0;
  }

  React.useEffect(() => {
    setIsCollapsed(true);
  }, [value]);

  return (
    <>
      {Boolean(_hasContent || !isEmpty(value?.attachments)) && (
        <div
          className={cn(
            "relative group ",
            {
              "max-h-[200px] overflow-y-clip": isCollapsed,
            },
            className
          )}
        >
          <TextEditorValue value={value} />

          {isCollapsable && (
            <div
              onClick={(ev) => {
                ev.stopPropagation();
                setIsCollapsed(!isCollapsed);
              }}
              className={cn(
                " flex items-center justify-center gap-2 ",
                " cursor-pointer hover:bg-card/80 py-4",
                "bg-gradient-to-b from-card/70 to-card ",
                { "absolute inset-x-0 bottom-0": isCollapsed }
              )}
            >
              {isCollapsed ? (
                <>
                  <LuChevronDown className="text-xl" /> More
                </>
              ) : (
                <>
                  <LuChevronUp className="text-xl" /> Less
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export function TextEditorValue({
  value,
  className,
}: {
  value?: TextEditorType;
  className?: string;
}) {
  const _galleryViewContext = useGalleryViewContext();
  const textEditorUtils = useTextEditorUtils();

  const imageUrls = textEditorUtils.getImages(value);
  const _text = textEditorUtils.getInnerText(value);
  const _hasContent = textEditorUtils.hasContent(value);

  const openGalleryView = (url: string) => {
    _galleryViewContext.open({
      items: imageUrls,
      initUrl: url,
    });
  };

  return (
    <>
      {Boolean(_hasContent || !isEmpty(value?.attachments)) && (
        <div className={cn("w-full ", className)}>
          {_hasContent && (
            <div
              dir={isFarsi(_text) ? "rtl" : undefined}
              className=" w-full flow-root whitespace-normal overflow-hidden text-editor-content break-words"
            >
              {textEditorUtils.parse(value?.content, {
                onClick: (ev: React.MouseEvent<HTMLImageElement, MouseEvent>) =>
                  openGalleryView(ev.currentTarget.alt),
                className: "cursor-pointer",
              })}
            </div>
          )}

          {!isEmpty(value?.attachments) && (
            <div className="flex flex-wrap mt-1 gap-1 w-full">
              {value!.attachments!.map((e, i) => (
                <AttachmentItem
                  key={"eachAttachment" + i}
                  attachment={e}
                  onClick={
                    getFileType(e.name) === "image"
                      ? () => openGalleryView(e.url)
                      : undefined
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

interface Props {
  name: string;
  label?: string;
  value?: string;
  validations?: ValidationType[];
  isAdvance?: boolean;
  [rest: string]: any;
}

export interface Command {
  command: string;
  text: string;
  description?: string;
  icon?: string;
  onClick?: (cmd: Command) => void;
}

export const textEditorTypeSchema = z.object({
  content: z.string().optional(),
  attachments: attachmentSchema.array().optional(),
});

export type TextEditorType = z.infer<typeof textEditorTypeSchema>;

// export interface TextEditorType {
//   content?: string;
//   attachments?: Attachment[];
// }

export default function TextEditor(props: Props) {
  const _formContext = useFormContext();
  const _modalContext = useModalContext();
  const _themeContext = useThemeContext();
  const selectFile = useSelectFile();

  let { name, label, value, validations, isAdvance = false, ...rest } = props;

  const { toast } = useToast();
  const textEditorUtils = useTextEditorUtils();
  const validation = useValidation();

  const [_isAdvance, _setIsAdvance] = React.useState(false);
  const [_isLoading, _setisLoading] = React.useState(false);
  const [_isDraggingAttachment, _setIsDraggingAttachment] =
    React.useState(false);

  const tinymceEditorRef = React.useRef<Editor>(null);
  const valueRef = React.useRef<TextEditorType | null | undefined>(null);

  valueRef.current = textEditorUtils.server2Dict(_formContext.data[name]);

  React.useEffect(() => {
    if (!_isLoading && valueRef.current?.content?.includes(' src=""')) {
      _setisLoading(true);

      textEditorUtils
        .downloadImages({
          content: valueRef.current.content,
        })
        .then((content) => {
          _formContext.setData({ [name]: { ...valueRef.current, content } });
          _setisLoading(false);
        })
        .catch(() => _setisLoading(false));
    }
  }, []);

  const onAddAttachments = async (files: File[] | File) => {
    let _srces = [];

    for (const eachFile of files as File[]) {
      if (eachFile.size < 5000000) {
        _srces.push({
          id: makeid(),
          name: eachFile.name,
          url: await fileToBase64(eachFile),
        });
      } else {
        toast("File size can not be more than 5M!", {
          variant: "destructive",
        });
      }
    }

    _formContext.setData({
      [name]: {
        ...valueRef.current,
        attachments: [...(valueRef.current?.attachments ?? []), ..._srces],
      },
    });
  };

  const onRemoveAttachments = async (attachments: Attachment[]) => {
    _formContext.setData({
      [props.name]: {
        ...valueRef.current,
        attachments: (valueRef.current?.attachments as Attachment[]).filter(
          (e) => !attachments.some((a) => e.url === a.url)
        ),
      },
    });

    return {};
  };

  const onImageClick = (editor: EditorType) => {
    _modalContext.open(
      <SelectManyImageModal
        onChange={async (files) => {
          for (const eachFile of files) {
            if (eachFile.size < 5000000) {
              const _src = await fileToBase64(eachFile);
              editor.insertContent(`<img src="${_src}" width="300" />`);
            } else {
              toast("File size can not be more than 5M!", {
                variant: "destructive",
              });
            }
          }
        }}
      />
    );
  };

  const onRecordClick = (editor: EditorType) => {
    _modalContext.open(
      <DialogContent size={"sm"} fullScreen={false} onBgClickClosable={true}>
        <div></div>
      </DialogContent>
    );
  };

  return (
    <>
      {
        <FormFieldBase
          {...props}
          needFocus={false}
          isNullBtnActive={
            !isEmpty(valueRef.current?.content) ||
            !isEmpty(valueRef.current?.attachments)
          }
          validations={[
            ...(props.validations ?? []),
            validation.notLargeFilesInEditor(),
          ]}
        >
          {(baseProps) => (
            <DropZone
              onDrop={onAddAttachments}
              exceptTypes={[
                "image/png",
                "image/gif",
                "image/bmp",
                "image/jpeg",
              ]}
              className={cn("relative rounded border p-1 is-invalid ", {
                "opacity-50 pointer-events-none": baseProps.isLoading,
              })}
            >
              {valueRef.current?.content?.includes(' src=""') !== true && (
                <Editor
                  ref={tinymceEditorRef}
                  tinymceScriptSrc="/tinymce/tinymce.min.js"
                  // onDragOver={onDragOver}
                  disabled={baseProps.isLoading}
                  value={valueRef.current?.content ?? ""}
                  onEditorChange={(e) => {
                    baseProps.setData({
                      [props.name]: {
                        ...valueRef.current,
                        content: tinymceEditorRef.current?.editor?.getContent(),
                      },
                    });
                  }}
                  init={{
                    skin: _themeContext.isDark ? "CUSTOM-dark" : "CUSTOM",
                    content_css: _themeContext.isDark
                      ? "CUSTOM-dark"
                      : "CUSTOM",
                    browser_spellcheck: true,
                    max_height: 500,
                    menubar: false,
                    contextmenu: false,
                    plugins: [
                      "lists",
                      "directionality",
                      "wordcount",
                      "table",
                      "link",
                      "autolink",
                      "codesample",
                      "fullscreen",
                      "autoresize",
                      "charmap",
                    ],
                    toolbar_mode: "sliding",
                    toolbar:
                      "undo redo | " +
                      "customInsertImg customInsertAttachment customInsertRecord | " +
                      "bold backcolor forecolor fontsize | italic removeformat | bullist numlist | " +
                      "align | rtl ltr outdent indent | link unlink | table | " +
                      "codesample charmap | fullscreen",

                    setup: (editor) => {
                      editor.ui.registry.addIcon(
                        "paperclip",
                        `<svg style="width:20px;height:20px">
                          <use xlink:href="/icons/regular.svg#paperclip"></use>
                        </svg>`
                      );

                      editor.ui.registry.addButton("customInsertImg", {
                        icon: "image",
                        onAction: (_) => {
                          onImageClick(editor);
                        },
                      });

                      editor.ui.registry.addButton("customInsertAttachment", {
                        icon: "paperclip",
                        onAction: (_) => {
                          selectFile.select(onAddAttachments, {
                            multiple: true,
                          });
                        },
                      });
                    },
                  }}
                />
              )}

              {!isEmpty(valueRef.current?.attachments) && (
                <div className="border-t p-2 flex flex-wrap mt-1 gap-1">
                  {valueRef.current!.attachments!.map((e, i) => (
                    <AttachmentItem
                      key={"e" + e.id}
                      attachment={e}
                      disabled={baseProps.isLoading}
                      onDelete={async () => await onRemoveAttachments([e])}
                    />
                  ))}
                </div>
              )}

              {_isLoading && (
                <Skeleton className="w-full h-full absolute p-3 top-0" />
              )}
            </DropZone>
          )}
        </FormFieldBase>
      }
    </>
  );
}
